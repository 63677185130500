.card-services {
    margin-top: 19px;
    .ant-checkbox-wrapper {
        .ant-checkbox {
            &.ant-checkbox-checked {
                &::after {
                    display: none;
                }
                .ant-checkbox-inner{
                    background-color: #24DCAE !important;
                }
            }
        }

        .ant-checkbox-inner {
            position: relative;
            top: 0;
            left: 0;
            display: block;
            width: 35px !important;
            height: 35px !important;
            direction: ltr;
            background-color: #ffff !important;
            border: 0;
            border-radius: 50%;
            border-collapse: separate;
            transition: all .3s;
        }
    }

    .action-button {
        height: 20px;
        width: 20px;
        margin-top: -12px;

        &:hover {
            &::before {
                background-color: transparent !important;
            }
        }
    }
}
