.agency-profile-page {
    .agency-services {
        .filters {
            margin-bottom: 30px;

            .ant-input-affix-wrapper {
                border: none;
            }

            .ant-select.sort-tour {
                box-shadow: none !important;
    
                .ant-select-selector {
                    background-color: $global-color-10;
                    padding: 10px !important;
    
                    .ant-select-selection-placeholder {
                        color: $global-color-4 !important;
                    }
                }
    
                .ant-select-arrow {
                    svg {
                        g {
                            path {
                                fill: $global-color-4;
                            }
                        }
                    }
                }
            }
        }
    }
}
