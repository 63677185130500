.service-list-wrapper {
    .service-list {
        width: 100%;
        .services {
            // border: 2px solid #E5EAF0;
            // border-radius: $border-radius-small;
            // padding: 23px 26px;
            // background: $color-white;
            margin-top: 20.5px;
            .ant-checkbox-wrapper {
                border: 2px solid #E5EAF0;
                border-radius: $border-radius-small;
                padding: 23px 23px;
                background: $color-white;
                width: 100%;
            }
            .ant-checkbox + span{
                padding-left: 18px !important;
            }
            .ant-checkbox-wrapper:not(.ant-checkbox-wrapper-checked){
                .ant-checkbox-inner{
                    background-color: #EEF0F2 !important;
                }
            }
            .ant-checkbox-inner{
                width: 33px !important;
                height: 32px !important;
            }
        }
        .role-name {
            color: #758CA1;
            line-height: 21px;
            letter-spacing: -0.14px;
            margin-bottom: 1px;
        }

        .description {
            font-size: 12px;
            font-family:"Poppins-Light";
            line-height: 18px;
            letter-spacing: -0.12px;
            color: #BBC6D1;
        }
    }

    .tour-categories {
        margin-top: 30px;
        .ant-checkbox-group {
            width: 100%;

            .ant-checkbox-wrapper {
                span:nth-child(2) {
                    color: $global-color-4;
                    padding-left: 18px !important;
                }

                .ant-checkbox {
                    &.ant-checkbox-checked {
                        &::after {
                            display: none;
                        }
                        .ant-checkbox-inner{
                            background-color: #24DCAE !important;
                        }
                    }
                }

                .ant-checkbox-inner {
                    background-color: #ffff !important;
                }
            }
        }
    }
}
