#registration-base-form {
    width: 609px;
    max-width: 609px;
    margin-top: 105px;

    .ant-checkbox + span {
        padding-left: 11px;
        line-height: 21px;
        color: #bcc7d9;
        letter-spacing: -0.14px;
    }
    .ant-form-item-feedback-icon-success {
        svg > path {
            fill: #2fe479;
        }
    }
    a {
        color: #005ae7;
    }
    svg {
        height: 19px;
        width: 23px;
        transform: none !important;
    }
    .ant-form-item-feedback-icon-error {
        svg > path {
            fill: #fc7886;
        }
    }
    > div:nth-child(8) {
        margin-bottom: 0;
        .ant-btn {
            @include transition(all 0.3s);
            &:hover {
                transform: translateY(-7px);
            }
        }
    }
    .mui-form-item {
        .ant-form-item-label {
            label {
                color: #bcc7d9;
            }
        }
        &.active {
            .ant-form-item-control-input {
                border-color: #005ae7;
                box-shadow: 0px 3px 6px 0px rgba(0, 90, 231, 0.22) !important;
            }
            label {
                color: #005ae7;
            }
        }
    }
    .ant-input-affix-wrapper {
        border: none !important;
        box-shadow: none !important;
    }
    .phone-input {
        .ant-form-item-control-input {
            border: 2px solid #d0d9e6;
            border-radius: 5px;
        }
    }

    .ant-select {
        height: 42px !important;
        box-shadow: none !important;
        .ant-form-item-feedback-icon {
            display: none;
        }
        svg {
            width: 12px;
            height: 7px;
            path {
                fill: #495274;
            }
        }
        .ant-select-arrow {
            top: 55%;
        }

        .ant-select-selection-item {
            padding-right: 56px !important;
            color: #495274 !important;
            img {
                margin-right: 10px;
            }
        }
    }

    .ant-checkbox {
        &:not(.ant-checkbox-checked) {
            .ant-checkbox-inner {
                background-color: #ffffff !important ;
                border: 2px solid #bcc7d9 !important;
            }
        }
        .ant-checkbox-inner {
            background-color: #2fe479 !important ;
            border: 2px solid #2fe479 !important;
        }
    }
}

.agency-details-form {
    width: 609px;
    max-width: 609px;
    margin-top: 214px;
    .ant-form-item-feedback-icon-success {
        svg > path {
            fill: #2fe479;
        }
    }
    a {
        color: #005ae7;
    }
    svg {
        height: 19px;
        width: 23px;
        transform: none !important;
    }
    .ant-form-item-feedback-icon-error {
        svg > path {
            fill: #fc7886;
        }
    }
    > div {
        &:first-child {
            .ant-form-item-control-input {
                min-height: 100%;
                small {
                    line-height: 18px;
                }
            }
        }
        &:last-child {
            button {
                @include transition(all 0.3s);
                span {
                    line-height: 21px;
                }
                &:hover {
                    transform: translateY(-7px);
                }
            }
        }
    }
    .mui-form-item {
        .ant-input-affix-wrapper,
        .ant-select {
            border: none;
            box-shadow: none !important;
        }
        .ant-form-item-label {
            label {
                color: #bcc7d9;
            }
        }
        &.active {
            .ant-form-item-control-input {
                border-color: #005ae7;
                box-shadow: 0px 3px 6px 0px rgb(0 90 231 / 22%) !important;
            }
            .ant-form-item-label {
                label {
                    color: #005ae7;
                }
            }
        }
    }
}
