.tour-card {

    padding:  14px 28px 14px !important;

    .ant-card-cover {
        margin: 0 !important;
        margin-top: auto !important;
        margin-bottom: auto !important;
        @include d-flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        max-height: 109px;

        .ant-image {
        @include d-flex;
        max-height: 109px;

        img {
            border-radius: 0 !important;
            max-width: 100%;
            max-height: 100%;
        }
        }
    }

    .ant-card-meta {
        margin: 0 !important;
        margin-bottom: 17px !important;
    }

    .ant-card-meta-title {
        text-align: center;
        font-size: 16px;
        font-family: 'Poppins-SemiBold';
        color: $global-color-15;
        text-transform: uppercase;
        margin-bottom: 0 !important;
    }

    .ant-card-meta-description {
        text-align: center;
        font-size: 12px;
        font-family: 'Poppins-Regular';
        color: $global-color-5;
        margin-bottom: 15px;
        line-height: 18px;
        margin-bottom: 0 !important;
        margin-top: 10px;
    }

    .ant-card-body {
        padding: 0 !important;
        // margin-top: 24px;
    }
}

.trip-card {
    .ant-card-meta {
        margin: -1px 0;
    }

    .ant-card-meta-title {
        a {
            font-size: 18px;
            color: $global-color-9;
        } 
    }

    .ant-card-meta-description {
        font-size: 14px;
        color: $global-color-4;
    }
}
