.ant-form-item {
    .ant-select {
        &, &.ant-select-open {
            .ant-select-selection-item {
                color: #000000;
            }
        }

        &.ant-select-lg {
            .ant-select-arrow {
                right: 23px;

                span svg {
                    font-size: 10px;
                    transform: scale(2, 1.4);
                }
            }
        }
    }
}
.ant-select-item-option-selected:not(.ant-select-item-option-disabled),.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected{
    background-color: #FFFFFF !important;
}
.ant-menu-item:active{
    background: #FFFFFF !important;
}
.ant-select-selection-overflow{
    .ant-select-selection-overflow-item{
        .ant-select-selection-item{
            background: transparent !important;
            border: none !important;
            padding-right: 0px !important;
                }
    }
  
}
.ant-select-item-option-content{
    font-family: "Poppins-Light";
    color: #758CA1;
    letter-spacing: 0.14px;
    line-height: 21px;
}



.language-select{
    padding: 0 !important;

    >div{
        > div{
            &:first-child{
                padding: 23px 19px;
                background-image: url(../../../assets/images/language-dropdown-bg.png) !important;
                background-repeat: no-repeat !important;
                background-size: cover !important;
                position:  relative !important;
                align-items: center !important;
                z-index: -1;
                &::before{
                    content: "" !important;
    position: absolute !important;
    left: 0 !important;
    top: 0 !important;
    width: 100% !important;
    height: 100% !important;
    background-color: rgba(83, 109, 254, 0.9) !important;
    z-index: -1;
}
>.block-description{
    gap: 16px !important;
    > div{
        &:first-child{
            @include d-flex;
            > span{
                width: 37px;
                height: 37px;
                background-color: #FFFFFF;
                box-shadow: 0px 1px 6px rgb(0 0 0 / 13%);
                border-radius: 100%;
                position: relative;
                overflow: hidden;
                border: 1px solid #FFFFFF !important;
                > img{
                    position: absolute;
    top: 50%;
    left: 50%;
    width: 34px;
    height: 34px;
    object-fit: cover;
    transform: translate(-50%, -50%) scale(1.5)!important;
                }
            }
        }
        &:last-child{
            .label{
                font-family: "Poppins-Medium";
                line-height: 21px;
                letter-spacing: 0.14px;
                color: #FFFFFF;
            }
            .content{
                font-family: "Poppins-Light";
                line-height: 18px;
                letter-spacing: 0.12px;
                color: rgba(#FFFFFF, 0.67);
            }
        }
    }
}
                
            }

          
        }
        >.rc-virtual-list{
            .rc-virtual-list-holder-inner{
                padding: 36px 53px;
                flex-direction: row !important;
            
            .ant-select-item-option-active{
                background-color: #FFFFFF !important;
            }
             .ant-select-item-option{
                // padding: 0;
                >div{
                    width: 55px;
                    height: 55px;
                    border-radius: 50%;
                    @include d-flex;
                    justify-content: center;
                    align-items: center;
                    > span{
                        width: 100%;
                        height: 100%;
                        img{
                            width: 100% !important;
                            transform: scale(1.25);
                            height: 100% !important;
                            object-fit: cover;
                        }
                    }
                }
             }
            }
        }
    }
}

.ant-select-selection-overflow{
svg{
    vertical-align: bottom;
}
}
.ant-popconfirm{
z-index: 9999 !important;
}

.select-cabin{
    border-radius: 10px !important;
    .ant-notification-notice-message{
        padding: 26.5px 30px 0 30px !important;
    }
    .ant-notification-notice-description{
.ant-card-body{
    padding: 0;
    .ant-card-meta{
        margin: 0;
        .ant-card-meta-avatar{
            padding-right: 30px;
        }
        .ant-card-meta-title{
            margin-bottom: 1px;
            >.ant-space{
                >.ant-space-item:first-child{
                    span{
                        color: #4D677E;
                    }
                }
            }
        }
        .ant-card-meta-description{
            >div{
                color: #BBC6D1;
                line-height: 35px;
            }
        }
    }
   
}
.ant-divider-dashed{
    margin: 21.5px 0 24px 0;
}
    }
}
