$global-color-1: #3B55EB;
$global-color-2: #BBC6D1;
$global-color-3: #FCB151;
$global-color-4: #758CA1;
$global-color-5: #BBC6D1;
$global-color-6: #FFFFFF;
$global-color-7: #536DFE;
$global-color-8: #F4F6F8;
$global-color-9: #4D677E;
$global-color-10: #DDE4EB;
$global-color-11: #E7EBEF;
$global-color-12: #24DCAE;
$global-color-13: #FC7886;
$global-color-14: #5B6D6D;
$global-color-15: #4D677E;
$global-color-16: #F9FBFC;
$global-color-17: #089ECB;
$global-color-18: #646D82;
$global-color-19: #13258D;
$global-color-20: #A6B0BB;
$global-color-21: #DFE7EE;

$color-white: #FFFFFF;
$color-primary: #3B55EB;
$color-success: #2FE479;

// body
$color-body-background: #EDEFF2;
$color-text: #BCC7D9;

// topbar
$color-topbar-icon-background: #E7EBEF;
$color-topbar-text: #758CA1;
$color-topbar-bottom-border: #BBC6D1;

// input
$color-input-label-active: #005AE7;
$color-input-box-shadow-active: #005AE738;
$color-input-text: #495274;

// steps
$steps-color: $color-text;
$steps-color-active: #005AE7;
$steps-color-completed: $color-success;

// scroll
$_thumb-color: $global-color-10;

