.create-section {
    .input-section {
        display: none;

        &.open {
            display: block;
        }
    }
    > .input-section.open{
        .ant-input{
            border:2px solid #DDE4EB !important; 
            border-radius: 5px !important;
        }
 }
}
