.ant-modal.add-new-offer {
    .ant-btn.ant-btn-default {
        display: none;
    }
    .ant-modal-content{
        border: 1px solid #E7EBEF;
    }
    .ant-modal-body{
        padding: 26px 30px 30px 30px;
    }
}
.existing_promotions{
    .ant-modal-header{
        padding: 38px 29px !important;
    }
    .ant-modal-body{
        padding: 30px;
    }
    .ant-modal-content{
        border: 1px solid #E7EBEF;
    }
}

.map-modal{
    height: 567px !important;
    width: 905px !important;
    .ant-modal-content{
    width: 100%;
    height: 100%;
    border-radius: 10px;

        > div{
            width: 100%;
            height: 100%;

            > div >div{
                border-radius: 10px;
            }
        }
    }
}
.ant-modal-mask{
    background-color: rgba(#4D677E, 0.7) !important;
}

.add-new-offer, .compare-report, .terms-and-conditions, .share-product, .add-tax-form {
    .ant-modal-close{
        top: -36px;
        right: -47px;
        width: 40px;
        height: 40px;
        background-color: #FFFFFF;
        border-radius: 50%;
        @include d-flex;
        justify-content: center;
        align-items: center;
        @include transition(all 0.3s ease);
        box-shadow: 0px 2px 3px 0px rgba(0,0,0,0.16);
        svg{
            fill: #4D677E;
        }
        &:hover{
            background-color: #FC7886;
            svg{
                fill: #FFFFFF;
            }
        }
    }
}

.ant-modal.confirm-modal {
    .ant-modal-confirm-btns {
        button::after {
            content: none;
        }
    }
}
