@import './Employee/style';
@import './Roles/style';
.role-list {
      .roles {
          border-radius: $border-radius-small;
          padding: 26px;
          background: $global-color-8;
          margin-bottom: 20px;
          >.check-list{
            @include d-flex;
            > .ant-col{
                @include d-flex;
            }
          }
          .ant-checkbox-wrapper:not(.ant-checkbox-wrapper-checked){
              .ant-checkbox-inner{
                  background-color: #BBC6D1 !important;
              }
          }
          .ant-checkbox-inner{
              width: 33px !important;
              height: 33px !important;
          }
          .ant-checkbox + span{
            word-break: break-all;
              color: #758CA1;
              letter-spacing: -0.14px;
              padding-left: 18px;
              line-height: 21px;
              .select-all{
                font-size: 14px !important;
              }
          }
      }
  }

   
 