.ant-segmented {
    background-color: $color-white !important;
    border-radius: $border-radius-small !important;
    box-shadow: none !important;

    .ant-segmented-item-selected {
        background-color: $global-color-7;
        
        .ant-segmented-item-label {
            color: $color-white;
        }
    }
    .ant-segmented-item-label {
        color: $global-color-2;
        font-family: "Poppins-Regular";
        letter-spacing: 0.14px;
        line-height: 21px;
        padding: 10px 28px;
        @include d-flex;
        align-items: center;
    }
}

