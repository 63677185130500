.loading-invoices {
    @import './Graphs/style';
    @import './Filters/style';
    
    .loading-table {
        &.ant-skeleton  {
            width: 100%;
    
            .ant-skeleton-button {
                width: 100%;
                height: 350px;
            }
        }
    }
}

