.ant-form-large {
    .mui-form-item {
        .ant-form-item-label {
            transform: translate(13px, 2px) scale(1);
            label {
                height: $input-label-height-lg;
            }
        }

        &.active,
        &.filled {
            .ant-form-item-label {
                transform: translate(8px, -10px) scale(0.857);
            }
        }
    }
}

.mui-form-item {
    margin-bottom: 0 !important;
    .ant-form-item-control-input,
    .ant-form-item-label label,
    input {
        // cursor: pointer;
    }

    input:not(.ant-input-lg):not(.ant-input-sm) {
        line-height: 100%;
    }

    .ant-form-item-label {
        position: absolute;
        padding: 0 !important;
        z-index: 2;
        transform: translate(8px, 14px) scale(1);
        transition: all 200ms cubic-bezier(0, 0, 0.2, 1);

        label {
            background: $color-white;
            padding: 0px 8px;
            color: #758ca1;
        }
    }

    &.active,
    &.filled {
        .ant-form-item-label {
            transform: translate(7px, -10px) scale(0.857);

            label {
                height: 100%;
            }
        }
    }

    &:hover {
        .ant-input,
        .ant-input-affix-wrapper,
        .ant-select .ant-select-selector {
            border-color: $color-input-label-active;
        }
    }

    &.active,
    &:hover {
        .ant-form-item-label {
            label {
                color: $color-input-label-active;
            }
        }
    }
}
