.quill {
    border: 2px solid #DDE4EB;
    border-radius: 10px;
}

.quill .ql-toolbar.ql-snow,
.quill .ql-container.ql-snow {
    border: none;
}

.quill .ql-toolbar.ql-snow {
    background-color: #F5F5F5;
    border-radius: 10px 10px 0px 0px;
    height: 39px;
}

.quill .ql-container.ql-snow {
    background-color: #ffff;
    border-radius: 0px 0px 10px 10px;
}

.quill .ql-container.ql-snow .ql-editor {
    resize: vertical;
}

.quill .ql-container.ql-snow .ql-editor.ql-blank::before {
    color: #758CA1;
    font-family: "Poppins-Regular";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.7px;
}
