#tour-view-all{
form{
    margin-bottom: 30px;
}
.advanced-filter-content{
    .ant-input-suffix{
        svg{
            width: 20.11px;
            height: 24px;
        }
    }
    .trip-slider{
        width: 377px !important;
    }
}
.tour-type-list-card{
.ant-card-meta-detail{
    .title{
        display: block;
        text-decoration: none;
        color: $global-color-9;
    
        >div{
            font-family: "Poppins-Medium";
            line-height: 21px;
            letter-spacing: 0.14px;
            margin-bottom: 4px;
        }
    }
    >div>div>div:first-child{
        svg{
            width: 12px;
            height: 12px;
        
        }
    }
 
    .price , .size, .location ,.date, .agency{
        font-family: "Poppins-Regular";
        color: #BBC6D1;
        letter-spacing: 0.12px;
        @include d-flex;
        align-items: center;
        line-height: 18px;
        svg{
            margin-right: 5px;
        }
    }
    .view-button{
        padding: 13px 26px;
    }
}
}
}


////////////////////////////////////////////////
@media (max-width:1599px){
    #tour-view-all{
        .grid-col{
            flex: 0 0 33.33333333% !important;
            max-width: 33.33333333% !important;
        }
    }
}
@media (max-width:1199px){
    #tour-view-all{
>form{
    >div{
        &:first-child{
            row-gap: 30px !important;
        }
    }
}

.grid-col{
    flex: 0 0 50% !important;
    max-width: 50% !important;
}
.advanced-filter-content{
    >div:first-child{
        >.ant-input-affix-wrapper{
            margin-bottom: 15px;
        }
    }
}
    }
}



@media (max-width:991px){
    #tour-view-all{
        padding-top: 20px;
>div,>form{
    padding-left: 20px;
    padding-right: 20px;
}
> div{
    &:last-child{
        padding-bottom: 20px;
    }
}
.grid-col{
    .ant-card-body{
        .ant-card-meta-description{
            >div{
                >div{
                    &:first-child{
                        flex-direction: column;
                    }
                }
            }
        }
    }
}
    }
}




@media (max-width:767px){
#tour-view-all{
    >form{
        >div{
            &:first-child{
                >div{
                    &:first-child{
                        >div{
                            flex-wrap: wrap;
                        }
                    }
                }
            }
        }
    }
 
    .tour-type-list-card.list .ant-card-meta-description {
        >div{
            >div{
                &:first-child{
                    >div{
                        &:last-child{
                            flex-direction: column;
                            gap: 5px !important;
                            align-items: flex-start;
                        }
                    }
                }
            }
        }
    }
    .grid-col{
        flex: 0 0 100% !important;
        max-width: 100% !important;
    }
}
}
@media (max-width:575px){
    #tour-view-all{
        >form{
           .advanced-filter-content{
            row-gap: 30px !important;
            >div{
                &:last-child{
                    >div{
                        &:first-child, &.trip-slider{
                            width: 100% !important;
                        }
                    }
                }
                &:first-child{
                    >.ant-input-affix-wrapper{
                        margin-bottom: 0;
                    }
                    >div{
                        margin-bottom: 0;
                    }
                }
            }
        
           
           }
           .search{
            width: auto;
           }
        }

     .tour-type-list-card.list{
        .ant-card-meta-description{
            >div{
                row-gap: 10px !important;
            }
        }
     }

        }
}
@media (max-width:399px){
    #tour-view-all{
        .tour-type-list-card.list{
            .ant-card-meta{
                @include d-flex;
                flex-direction: column;
                >div{
                    &:first-child{
                        @include d-flex;
                        padding-right: 0 !important;
                        justify-content: center;
                    }
                    &:last-child >div >div > div:last-child{
width: 100%;
>div{
    width: 100%;
    >div{
        &:last-child{
            width: 100%;
            @include d-flex;
            justify-content: flex-end;
        }
    }
}
                    }
                }
            }
        }
        >form{
            .advanced-filter-content > div:last-child > div:first-child > div{
                padding: 5px 73px 5px 10px;
            }
        }
    }
  
}
@media (min-width:2560px){
    #tour-view-all{
        >.ant-row{
            >.grid-col{
                flex: 0 0 16.66666667% !important;
                max-width: 16.66666667% !important;
                
            }
    
                    }
 
    }
}
