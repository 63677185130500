.left-col {
    @import './ContactDetails/style';
    @import './Policies/style';

    .ant-divider-vertical {
        height: 100%;
    }
    .ant-card-body{
        padding: 31px;
        border: 1px solid #E7EBEF;
        border-radius: 5px;
        height: 100%;

        .add-button-outlined{
            margin-right: 27.5px;
            border-width: 1px !important;
            padding: 10px 22px;
            height: 40px;
            svg{
                width: 10.59px;
                height: 10.59px;
            }
            span{
                font-family: "Poppins-Regular";
                font-size: 14px;
                line-height: 21px;
                letter-spacing: 0.14px;
            }
        }
        >div{
            &:nth-child(3){
                >div{
                    &:nth-child(2){
                        >div{
                            row-gap: 16px !important
                        }
                    }
                }
            }
            &:first-child{
                >div:last-child{
                span{
                    color: #BBC6D1;
                    line-height: 22px;
                    letter-spacing: 0.14px
                }
            }
            }
            &:last-child{
                >div{
                    &:first-child{
                        >.ant-row{
                            .tour-info-section-content{
                                >.ant-space{
                                    >.ant-space-item:last-child{
                                        letter-spacing: 0.14px;
                                        line-height: 21px;
                                    }
                                }
                            }
                        }
                    }
                    &:nth-child(2){
                        @include d-flex;
                        align-items: center;
                        justify-content: center;
                        > .ant-divider{
                            border-color: #DDE4EB;
                        }
                    }
                    &:last-child{
                        padding-left: 25px
                    }
                }
            }
        }
    }

    .accommodation-general_data{
        @include d-flex;
        align-items: center;
        .icon_bg{
            margin-right: 11px;
            width: 40px;
            height: 40px;
            border-radius: 50%;
            background: $global-color-8;
            @include d-flex;
            justify-content: center;
            align-items: center;
            svg{
                width: 20px;
                height: 16px;
                path{
                    fill:$global-color-2;
                }
            }
        }
        span{
            line-height: 21px;
            color: #BBC6D1;
            letter-spacing: 0.14px;
        }
      
    }

    .offer-section {
        align-items: flex-start;
        display: flex !important;
        justify-content: space-between;

        .share-button {
            background-color: rgba($global-color-3, 0.15);
            border-radius: $border-radius-small;
            color: $global-color-3;
            font-size: 12px;
            &::after {
                content: none;
            }
            .btn-text {
                display: flex;
                align-items: center;
                justify-content: center;
            }
            svg {
                width: 18px;
                height: 18px;
                margin-right: 10px;
                path {
                    stroke: $global-color-3;
                }
            }

            &.shared-success {
                background-color: #EAFFFA;
                color: $global-color-12;

                .btn-text {
                    .svg-wrapper {
                        width: 26px;
                        height: 26px;
                        border-radius: 100%;
                        background-color: $global-color-12;
                        margin-right: 10px;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                    }
                    svg {
                        margin-right: 0px;
                        #icon-tick {
                            path {
                                stroke: $color-white;
                            }
                        }
                    }
                }
            }
        }

        .price_data {
            text-align: end;
            padding-right: 31px;
            .ant-btn{
                &:hover,&:active, &:focus{
                    background: none;
                }
            }
            .ant-btn-text{
                height: auto;
                padding: 2px 0;
                span{
                    font-size: 12px;
                    color: $global-color-7;
                    text-decoration: underline;
                    line-height: 20px;letter-spacing: 0.12px;
                }
            }
            >div:first-child{
                span{
                    color: #BBC6D1;
                    line-height: 20px;
                    letter-spacing: 0.14px;
                }
            }
        }

        .agency-name {
            border-radius: $border-radius-small;
            padding: 10px;
            color: $global-color-7;
            display: flex;
            justify-content: center;
            align-items: center;
            margin-bottom: 20px;
    
            &.base-color {
                background-color: $global-color-8;
            }
    
            &.collected-color {
                background-color: #EAFFFA;
            }
    
            &.pending-color {
                background-color: #FFFAF3;
            }

            .ant-avatar {
                margin-right: 20px;
            }
    
            svg {
                margin-right: 10px;
            }
    
            .svg-wrapper {
                width: 26px;
                height: 26px;
                border-radius: 100%;
                background-color: $global-color-12;
                margin-right: 10px;
                display: flex;
                justify-content: center;
                align-items: center;
                position: absolute;
                top: 0;
                right: 0;
                margin-top: -8px;
                margin-right: -8px;
    
                svg {
                    margin-right: 0px !important;
                    #icon-tick {
                        path {
                            stroke: $color-white;
                        }
                    }
                }
            }
        }
        .earning {
            font-size: 16px;
            color: $global-color-12;
            margin-top: 10px;
            span:first-child {
                font-family: 'Poppins-SemiBold';
                margin-right: 5px;
            }
    
            span:nth-child(2) {
                font-family: 'Poppins-LightItalic';
            }
        }
    }
   
    .tour-info-section-title{
        margin-bottom: 21px;
            font-family: "Poppins-Medium";
            font-size: 16px;
            color: $global-color-9;
            line-height: 20px;

    }
    .tour-info-section-content{
        font-family: "Poppins-Light";
        color: $global-color-4;
        line-height: 15px;
        .payment-icon-bg{
            width: 22px;
            height: 22px;
            border-radius: 50%;
            background: $global-color-8;
            @include d-flex;
            justify-content: center;
            align-items: center;
            svg{
                width: 9px;
                height: 6px;
                path{
                    stroke: $global-color-4;
                }
            }
        }
    }
   
    .payment-block{
        margin-bottom: 15px;
        font-size: 12px;
        font-family: "Poppins-Regular";
        color: $global-color-2;
        line-height: 20px;
    }
    .accommodation-block-details{
        line-height: 21px;
        .label{
            color: #758CA1;
            line-height: 20px
        }
    }

    .widget-categories {
        margin-top: 22px;
    }

}

.right-col {
   @import './Images/style';
}
