.empty-list-approve-page {
    svg {
        margin-bottom: 0px;
    }
    .text {
        max-width: 700px;
        width: 100%;
        margin: 0 auto;
        text-align: center;
        .text-title {
            font-family: "Poppins-Bold";
            font-size: 48px;
            color: $global-color-1;
            letter-spacing: 0.1px;
            text-transform: uppercase;
        }
        .text-paragraph {
            font-size: 14px;
            font-family: "Poppins-Light";
            margin: 21px 23px;
            letter-spacing: 0.1px;
            line-height: 21px;
            color: $global-color-4;
        }
    }
    .buttons-waiting-wrapper {
        margin-top: 50px;
        display: flex !important;
        .button-waiting {
            display: flex !important;
            border-radius: 5px !important;
            justify-content: center;
            align-items: center;
            padding: 16px 27px;
            font-size: 12px;
            font-family: "Poppins-Medium";
            color: #ffffff;
            font-weight: normal;
            margin-left: 20px;
            text-transform: uppercase;
            svg {
                width: 16.5px;
                height: 18px;
                margin-left: 30px;
            }
        }
        .button-need-help {
            background-color: #fcb151 !important;
        }
        .button-log-out {
            background-color: #fc7886 !important;
        }
    }
}
