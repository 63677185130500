label.ant-checkbox-wrapper {
    align-items: center;

    .ant-checkbox {
        top: 0;

        &.ant-checkbox-checked {
            .ant-checkbox-inner {
                border-color: $color-success;
            }
        }
    }
}

#add-tour-trip-page, #add-tour-packages-page ,#add-cruise-tour-page{
    .ant-checkbox-wrapper {
        align-items: center;
    }

    .ant-checkbox+span {
        font-size: 14px;
        color: $global-color-4;
    }

    .ant-checkbox-inner {
        width: 25px !important;
        height: 25px !important;
        border-radius: 4px;
        background-color: $global-color-10;
        border-color: $global-color-10;
    }

    .ant-checkbox-inner::after {
        left: 30%;
    }

    .ant-checkbox-checked::after {
        border-radius: 4px;
    }

    .ant-checkbox-group {
        .ant-checkbox-checked .ant-checkbox-inner {
            background-color: $global-color-12;
            border-color: $global-color-12;
        }

        .ant-checkbox-checked::after {
            border-color: $global-color-12;   
        }
    }
}

#add-tour-activity-page, .add-employee{
    .ant-switch-handle{
        width: 28px;
        height: 28px;
        top: 0px;
        &::before{
            background-color: #536DFE;
            border-radius: 50%;
        box-shadow: 0px 3px 6px 0px rgba(0,0,0,0.16);

        }
    }
    .ant-switch{
        background: #E7EBEF;
        min-width: 75px;
        height: 28px;
    }
}