.tour-itenerary-list {
    border-width: 1px !important;
    border-radius: 0 5px 5px 0;
    height: 100%;
    max-height: 798px;
    >.ant-card-body{
        height: 100% !important;
        overflow-x: hidden;
        @include scrollbar($_thumb-color);
        margin-top: 0;
        margin-bottom: 0;
    }
    .tour-itenerary-item:not(:last-child){
        margin-bottom: 20px;
    }
    
    .tour-itenerary-item {
        background-color: $global-color-8;
        border-radius: 5px;
        padding:18px 15px;
        .ant-divider{
            border-width: 2px;
        }

        .tour-itenerary-content {
            border-radius: 5px;
            padding: 0 0 0 21px;
            font-family: "Poppins-Medium";
            .itenerary-day{
                width: 23px;
                height: 23px;
                background: #536DFE;
                border-radius: 50%;
                @include d-flex;
                justify-content: center;
                align-items: center;
                span{
                    color: #FFFFFF;
                }
              
            }
         span{
             color: #536DFE;
             word-break: break-all;
             line-height: 20px;
         }

            .main-details {
                margin-top: 15px;
                @include d-flex;
                align-items: center;
                gap: 10px;

                .itenerary-address {
                    font-size: 14px;
                    color: #758CA1;
                    font-family: "Poppins-Regular";
                    word-break: break-word;
                    line-height: 20px;
                }
                .location-icon-block{
                    width: 23px;
                    height: 23px;
                    border-radius: 50%;
                    @include d-flex;
                    justify-content: center;
                    align-items: center;
                }
            }

            .itenerary-description {
                font-size: 14px;
                color: #758CA1;
                word-break: break-word;
                font-family: "Poppins-Light";
                line-height: 22px;
                letter-spacing: 0.14px;
            }

            .ant-divider-horizontal {
                margin: 10px 0;
                border-color: $global-color-11;
            }
        }
        .ant-image{
            height: 100%;
            width: 100%;
            img{
                height: 100%;
                border-radius: 5px;
            }
        }
    }
}
