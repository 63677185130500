.loading-graphs {
    &.ant-skeleton {
        width: 100%;

        .ant-skeleton-button {
            width: 370px;
            height: 90px;
        }
    }
}
