
.tour-type-list-card {
    border-radius: $border-radius-small !important;
    border: 1px solid #E7EBEF !important;

    &.list {
        .ant-card-body {
            padding: 16px;
            > .ant-card-meta{
                @include d-flex;
                align-items: center;
                > .ant-card-meta-detail{
                    width: 100%;
                    overflow: visible;
                }
            }
        }
        .ant-card-meta-avatar{
            padding-right: 31px;
        }

        .ant-card-meta-description {
            height: 100%;
            > div{
                row-gap: 15px !important;
            }

            .ant-row {
                height: 100%;

                .ant-col:nth-child(2) {
                    .ant-space {
                        width: 200px;
                    }
                }
            }
        }

        .price {
            font-size: 12px;
        }

        .ant-dropdown-trigger {
            &.display-none {
                display: none;
            }
        }
    }

    &.grid {
        border-color: #DDE4EB !important;
        .ant-card-body {
            padding: 0px;
        }
        .ant-divider {
            border-width: 1px;
        }
        .ant-card-meta-title {
            margin: 0px;
            padding-top: 22px;
            >.title {
                font-size: 16px;
                text-align: center;
                > div {
                    line-height: 25px !important;
                    margin-bottom: 6px !important;
                    letter-spacing: 0.16px !important;
                }
            }
        }
        .ant-row-middle {
            padding: 0 25px 25px 25px;
            row-gap: 10px !important;
        }.ant-row-center {
            padding: 0px 30px;
            word-break: break-word;
        }
        .cover {
            position: relative;
            display: flex;
            padding-top: 30px;
            justify-content: center;

            .ant-dropdown-trigger {
                position: absolute;
                right: 30px;
                &.display-none {
                    display: none;
                }
            }
        }

        .location , .date{
            color: #758CA1 !important;
            justify-content: center;
            font-size: 12px;
            svg{
                margin-right: 5px;
                path,circle{
                    fill: #758CA1;
                }
            }
        }

        .description {
            text-align: center;
            margin-left: 30px;
            margin-right: 30px;
            font-family: "Poppins-Regular";
            color: #BBC6D1;
            line-height: 18px;
            margin-top: 19px;
            letter-spacing: 0.12px;
        }
        .size, .agency {
            line-height: 18px;
            margin-bottom: 5px;
        }
        .price{
            font-family: "Poppins-SemiBold" !important;
            color: #758CA1 !important;
            line-height: 21px !important;
            letter-spacing: 0.14px !important;
            @include d-flex;
            flex-direction: column;
            align-items: flex-start !important;
            > div{
                font-family: "Poppins-Regular";
                font-size: 12px;
                color: #BBC6D1;
                line-height: 18px;
                letter-spacing: 0.12px;
                margin-bottom: 2px;
            }

        }
        .view-button{
         padding: 13px 28px !important;
         span{
            font-family: "Poppins-Medium" !important;
            margin-right: 9px !important;
            line-height: 18px !important;
         }
         svg{
            width: 5px !important;
            height: 8px !important;
         }
        }
    }

    .ant-dropdown-trigger {
        padding: 0px;
        border: none;
    }

    .description,
    .location,
    .duration,
    .agency,
    .size {
        font-size: 12px;
    }

    .ant-card-body {
        .ant-card-meta {
            margin: 0px;
        }
    }
}
