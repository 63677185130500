#add-cruise-tour-page {
    @import './GeneralDetails/style';
    @import './ShipDetails/style';
    @import './CruiseItenerary/style';
    @import './Steps/style';
    .left-col{

        #cruise-general-details-form{
            .cruise-details{
                >div{
                    &:nth-child(4),&:nth-child(5){
                        .ant-picker-clear{
                            top: 53%;
                            right: 5px;
                        }
                    }
                }
            }
            .cover-upload-content{
                >div{
                    font-family: "Poppins-Medium";
                    &:first-child{
                        margin-bottom: 11.3px;
                    }
                    &:nth-child(2){
                        margin-bottom: 8.7px;
                        span{
                            font-size: 14px;
                            line-height: 21px !important;
                        }
                    }
                    &:nth-child(3){
                        span{
                            font-size: 14px;
                            line-height: 21px !important;
                        }
                    }
                    &:last-child{
                        margin-top: 10.3px;
                        button{
                            span{
                                font-size: 12px;
                                line-height: 25px;
                            }
                        }
                    }
                }
            }
            > div {
                padding-left: 30px;
                padding-right: 30px;
                &:nth-child(4){
                    padding-top: 6px;

                }
              
            }
            > .ant-row{
                    margin-bottom: 0;
            }
            .ant-image{
                width: 100%;
                height: 100%;
                img{
                    height: 100%;
                    border-radius: 5px;
                }
            }
            .input-group-container{
                margin-top: 4px;
            }
            .ant-upload{
                background-color: #F4F6F8;
                >span{
                    border-radius: 5px;
                }
            }
        }
        .ant-input-affix-wrapper{
            border: none !important;
        }
        .ant-radio-button-wrapper{
            width: 88px;
            height: 35px;
            margin: 0;
            padding: 0;
            @include d-flex;
            align-items: center;
            justify-content: center;
            border: none;
            background-color: #E7EBEF;
            color: #758CA1;
              }
              .ant-radio-group{
                  @include d-flex;
                  padding-left: 15px;
              }
        
            .ant-input-search{
                padding-top: 6px;
            }
    }
   .right-col{
    .input-group-container{
        margin-bottom: 0;
        margin-top: 0;
        margin-right: 10px;
    }
 .steps-container .ant-steps-item-tail {
        margin-left: 47%;
        padding: 3.5px 21px
   }

   }


    .ant-divider-horizontal {
        border-color: $global-color-10;
    }

    @media screen and (max-width: 991px) {
        .right-col {
            background-color: #EDEFF2;
        }
    }
}


////////////RESPONSIVE

@media (max-width:1399px){
#add-cruise-tour-page{
    >div{
        &:first-child{
width: 50%;
max-width: 50%;
.accommodation-text{
    flex-direction: column;
    row-gap: 5px;
    text-align: center;
}
        }
        &:last-child{
>div{
    width: 42%;
    max-width: 42%;
}
        }
    }
}
}
@media (max-width:1199px){
    #add-cruise-tour-page{
        .accommodation-text{
            >span{
                align-items: flex-start !important;
            }
        }
        #cruise-general-details-form{
            >div{
                &:nth-child(5){
                    .ant-radio-button-wrapper{
                        width: auto;
                        padding: 5px !important;
                        font-size: 10px;
                    }
                    label{
                        font-size: 13px;
                    }
                }
            }
        
        }
        .ship-card{
            .ant-card-body{
                padding: 15px 15px;
            }
        }
        .right-col-container{
            .right-col{
                .ant-steps{
                    padding: 0 20px;
                }
            }
            > .ant-row-end{
                > div{
                    &:first-child{
                        > div{
                            padding-left: 0 !important;
                            padding-right: 0 !important;
                        }
                    }
                }
            }
        }
    }
    }
    @media (max-width:575px){
        #add-cruise-tour-page{
            .ship-card{
                .ant-card-meta-description{
                    >div{
                        flex-flow: nowrap;
                    }
                }
            }
            #cruise-general-details-form{
                >div{
                    &:nth-child(5){
                        label{
                            font-size: 10px;
                        }
                    }
                }
                }
            #cruise-itenerary-form{
                .input-group-container{
                    .ant-radio-button-wrapper{
                        width: auto;
                        padding: 5px !important;
                        font-size: 12px;
                    }
                }
            }
        }
    }
    @media (max-width:399px){
        #add-cruise-tour-page{

            #cruise-itenerary-form{
                .input-group-container{
                    .ant-radio-button-wrapper{
                        padding: 3px !important;
                    }
                }
            }
            #cruise-general-details-form{
                >div{
                    &:nth-child(5){
                        label{
                            font-size: 9px;
                        }
                    }
                }
                }
        }
    }
