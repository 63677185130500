.loading-filters {
    .ant-skeleton-element .ant-skeleton-button {
        height: 40px;
    }

    .location .ant-skeleton-button {
        width: 400px;
    }

    .date, .created .ant-skeleton-button {
        width: 250px;
    }

    .search .ant-skeleton-button {
        width: 300px;
    }

    .add .ant-skeleton-button {
        width: 200px;
    }
}
