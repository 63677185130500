$auth-layout-offset: 15px;
$auth-layout-content-offset: 30px;

// border-radius
$border-radius-small: 5px;
$border-radius-medium: 10px;
$border-radius-large: 15px;

// sidebar
$sidebar-width: 250px;
$sidebar-width-collapsed: 64px;
$sidebar-padding-x: 13px;
$sidebar-padding-x-collapsed: 13px;
$sidebar-element-width: 48px;
$sidebar-element-height: 48px;
$sidebar-element-padding-y: 0;
$sidebar-element-padding-x: 0;

// topbar
$topbar-height: 88px - $auth-layout-offset;
$topbar-padding-y: 20px;

// form
$form-item-margin-bottom: 30px;

// input
$input-height-lg: 60px;
$input-label-height-lg: $input-height-lg - 4px;
$input-font-size-lg: 14px;
$input-padding-horizontal-lg: 21px;
