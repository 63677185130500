/*
 * carousel rec-pagination
 * kept the prefix just to be sure that no conflicts would happen
 */

.tour-card-container {

    text-align: center;

    
    .sc-iBPTik.iHAfmq.rec.rec-pagination {
        display: none;

        @media screen and (max-width: 1700px) {
            display: block;

            .sc-gKseQn.jJKuoL.rec.rec-dot.rec.rec-dot_active {
                background-color: $global-color-4;
                @include box-shadow(0 0 1px 2px $global-color-4);
            }

            .sc-gKseQn.gQIqMI.rec.rec-dot {
                background-color: $global-color-5;
                @include box-shadow(0 0 1px 2px $global-color-5);
            }
        }
    }
    
    .first-line {
        font-size: 12px;
        font-family: 'Poppins-Regular';
        color: #BBC6D1;
        text-transform: uppercase;
        display: block;
    }

    .sec-line {
    font-size: 20px;
    font-family: 'Poppins-Medium';
    color: #4D677E;
    display: block;
    margin-top: 10px;
    margin-bottom: 38px;
    }
    .tour-card-wrapper{
        @include d-flex;
        justify-content: center;
        .tour-card-wrapper_2{
            @include d-flex;
            gap: 30px;
            @include transition(all 0.3s ease);
            .single-tour-card{
                @include transition(all 0.3s ease);
            }
            &:hover {
                >.single-tour-card {
                    opacity: 0.2;
                }
                .single-tour-card {
                    &:hover {
                        opacity: 1;
                        .tour-card{
                            box-shadow: 0px 0px 30px 0px rgba(33,36,45,0.07);
                        }
                    }
                }
            }

        }
        .ant-btn-text{
            @include transition(all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1));
            &:hover{
                span{
                    u{
                        color:$global-color-7 ;
                    }
               
                }
            }
        }
     
    }
   
}
