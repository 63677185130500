.form-step-title {
    color: $global-color-15 !important;
    font-size: 18px;
    font-family: 'Poppins-Medium';
    padding-left: 30px;
    line-height: 21px;
}

.custom-tooltip {
    .ant-tooltip-inner{
        background-color: transparent !important;
        box-shadow: 0px 0px 20px 0px rgba(0,0,0,0.11) !important; 
    }
    .ant-card {
        .ant-card-head {
            border-bottom: 2px solid $global-color-7;

            .ant-card-head-title {
                color: $global-color-7;
            }
        }
    }  
}

.ant-btn.tooltip-button {
    border: none;
    box-shadow: none;
    height: auto !important;
    padding: 0 0 0 5px !important;
    svg{
        margin: -10px 0px 7px 12px;
    }
    &::after{
        display: none;
    }

}
