#hotel-rooms-form {
    .child-price-policy {
        padding: 20px 30px 0 30px;
        .item-label {
            margin-bottom: 15px;
            color: #758CA1;
        }

        .ant-row:not(:first-child) {
            .ant-col.hidden-input-wrapper:first-child {
               opacity: 0;
            }
        }

        .ant-radio-group {
            margin-top: 0px;
            @include d-flex;
            margin-left: 10px;
            >label{
                &:last-child{
                    margin-left: 0;
                }
            }
            .ant-radio-button-wrapper{
                height: 35px;
                width: 34px;
                border-radius: 5px !important;
                border: none;
                background: $global-color-10;
                @include d-flex;
                justify-content: center;
                align-items: center;
                margin: 7.5px 5px;

            }
        }
    }
    > div{
        margin-bottom: 0px !important;
        &:first-child{
            padding: 6px 30px 0 30px;
            // .ant-form-item-control-input-content{
            //     >.ant-row{
            //         >div{
            //             &:last-child{
            //                 .ant-input-affix-wrapper{
            //                     padding: 0 3px 0 21px !important;
            //                 }
            //             }
            //         }
            //     }
            // }

        }
        &:nth-child(2){
            padding: 0 30px;
        }
        &:nth-child(3){
            padding: 20px 30px 0 30px;
            .create-room-form-switch{
                .ant-input-affix-wrapper{
            padding: 0 3px 0 21px !important;

                }
            }
            
        }
        &:nth-last-child(-n+2){
            padding: 0 30px
        }
        .ant-checkbox-inner{
            width: 25px !important;
            height: 25px !important;
            &::after{
                transform: translate(-50%, -42%) scale(0.9)!important;
            }
        }
      
    }
    .ant-radio-button-wrapper:not(.ant-radio-button-wrapper-checked),.ant-radio-button-wrapper-disabled{
        span{
            color: #758CA1
        }
    }

    .increment-number-input{
        button{
            @include d-flex;
            justify-content: center;
            align-items: center;
            svg{
                path{
                    fill: #B3C2C2;
                }
                line{
                    stroke: #B3C2C2;
                  }
            }
            &:hover{
                background-color: unset !important;
            }
            &:last-child{
                svg{
                    width: 21px;
                    height: 21px;
                }
            }
        }
        span{
            font-family: "Poppins-Regular";
            color: #758CA1;
        }
  
    }
    .input-group-container{
        margin: 10px 30px 32px 30px !important;
        .checkbox-multi-select{
            >div{
                row-gap: 15px !important;
                .ant-checkbox + span{
                    padding-left: 15px;
                }
            }
        }
        > .ant-row{
            >div{
                .ant-col{
                    >div:last-child{
                        margin-bottom: 0;
                    }
                }
                .ant-form-item-control-input-content{
                    >.ant-row>.ant-col>.mui-form-item{
margin-bottom: 35px;
                    }
                }
            }
        }
        .border-text{
            margin: -40px 0 0 -10px;
        }
        .ant-checkbox-inner{
            width: 30px !important;
            height: 30px !important;
        }

    }
    .input-select-wrapper {
        display: flex;
    
        .input-select-children {
            flex-basis: 100%;
        }
        .input-select {
            max-width: 75px;
            width: 100%;
    
            .ant-select-selector {
                background-color: #edeff2 !important;
            }
        }
    } 
    .ant-input[disabled] {
        background-color: unset !important;
    }
    .ant-btn-text[disabled]{
        svg{
            path{
                fill: rgba(#B3C2C2, 0.3);
            }
        }
    }
    .ant-checkbox-wrapper{
    span{
        font-family: "Poppins-Regular";

    }
    }
    .create-room-checkbox{
        margin-bottom: 33px;
        .ant-checkbox-checked{
            .ant-checkbox-inner{
                background-color: #536DFE !important;
            }
        }
    }
    .input-select-children{
        .mui-form-item{
            .ant-form-item-control-input{
                border-right-style: none;
                box-shadow:none !important;
                border-radius: 5px 0px 0px 5px;
            }
     
        }
    }
    .phone-number-input{
   
        .ant-select{
            height: 55px !important;
            border-radius: 0px 5px 5px 0px !important;
            box-shadow: none !important;
            border: 2px solid #DDE4EB;
            border-left-style: none;
            width: 76px !important;

        }
        .ant-select:not(.ant-select-customize-input) .ant-select-selector{
            background-color: #EDEFF2 !important;
            border-radius: 0px;
        }
        .ant-select-selection-item{
           color: #4D677E !important;
           padding-right: 50px !important;
        }
        .ant-select-arrow{
            svg{
                fill: #758CA1;
            }
        }
    
    }

}

.add-new-room-button{
    padding: 30px;
    @include d-flex;
    justify-content: flex-end;
    button{
        background-color: #536DFE;
        width: 275px;
        height: 50px;
        @include d-flex;
        align-items: center;
        justify-content: center;
        svg{
            width: 15.64px;
            height: 15.64px;
            margin-right: 25.4px;
        }
        span{
            font-family: "Poppins-Medium";
            color: #FFFFFF;
            letter-spacing: 0.14px;
            text-transform: uppercase;
        }
    }
}
