.auth-layout .site-layout {
    &.sidebar-collapsed {
        .topbar-wrapper {
            // left: $sidebar-width-collapsed + $auth-layout-content-offset + $auth-layout-offset;
            right: 0;
        }
    }

    .topbar-wrapper {
        // position: fixed;
        // padding-top: 0px;
        padding-top: $auth-layout-offset;
        top: 0;
        // left: $sidebar-width + $auth-layout-content-offset + $auth-layout-offset;
        // right: $auth-layout-content-offset;
        z-index: 20;
        background-color: $color-body-background;
        right: -(($sidebar-width + $auth-layout-content-offset) -
                    ($sidebar-width-collapsed + $auth-layout-content-offset));
        transition: right 0.25s ease !important;
        width: 100%;
        // max-width: calc(100% - $sidebar-width-collapsed - 15px);
        // padding-right: 17px;
        padding-right: $auth-layout-content-offset;
        padding-left: $auth-layout-content-offset;

        &.with-border {
            border-bottom: 2px solid $color-topbar-bottom-border;
        }

        .topbar {

            line-height: 1;
            @include d-flex;
            align-items: center;
            width: 100%;

            > .ant-row {
                width: 100%;
                align-items: center;
                // height: 130px;
            }

            .ant-col {

                &:last-child {
                    // height: 131px;
                    // width: 275px;
                    // display: flex;
                    // align-items: center;
                    // background-color: #ffff;

                    .general-menu {
                        @include d-flex;
                        align-items: center;


                        .ant-space-item {
                            @include d-flex;
                            align-items: center;
                            cursor: pointer;

                            &:nth-child(1) {

                                > span {
                                    @include d-flex;
                                    .language-select-toggle {
                                        width: 24px;
                                        height: 24px;
                                        background-color: #ffffff;
                                        box-shadow: 0px 1px 6px rgba(#000000, 0.13);
                                        border-radius: 100%;
                                        position: relative;
                                        overflow: hidden;
                                        border: 1px solid #ffffff !important;

                                        img {
                                            position: absolute;
                                            top: 50%;
                                            left: 50%;
                                            width: 34px;
                                            height: 34px;
                                            object-fit: cover;
                                            @include transform(translate(-50%, -50%));
                                        }
                                    }
                                }
                            }
                            &:nth-child(2) {
                            }
                            &:nth-child(3) {
                                .action-button {
                                    &:hover {
                                        svg{
                                            path{
                                                fill: #bbc6d1;
                                            }
                                        }
                                        &::before {
                                            background-color: transparent;
                                        }
                                    }
                                }
                            }
                            &:nth-child(4) {
                            }
                        }
                    }
                }
            }

            svg.stbi {
                width: 18px;
                height: 14px;
                cursor: pointer;
                rect {
                    fill: #bbc6d1;
                }
            }

            .m-page-title {
                color: #4d677e;
                font-size: 16px;
                font-family: "Poppins-Medium";
                line-height: 25px;
                letter-spacing: 0.16px;
            }

            span.anticon {
                color: $color-topbar-text;
            }

            .subroutes-menu {
                display: inline-flex;
                background-color: transparent;

                .subroutes-menu-item {
                    display: block;
                    color: $color-topbar-text;
                    line-height: $topbar-height;
                    text-decoration: none;
                    border-bottom: 2px solid transparent;
                    > div {
                        > .ant-space-item {
                            line-height: 21px;
                        }
                    }
                    svg {
                        fill: $global-color-4;
                    }
                    &.active,
                    &:hover {
                        color: $global-color-7;
                        svg {
                            fill: $global-color-7;
                            #Group_4562,
                            #Group_4563,
                            #Group_4564,
                            #Group_5564 {
                                path,
                                circle {
                                    stroke: $global-color-7;
                                }
                            }

                            #Group_6360 {
                                path {
                                    stroke: $global-color-7;
                                }
                            }

                            #Group_1645 {
                                path {
                                    fill: $global-color-7;
                                }
                            }

                            #Group_6363 {
                                path {
                                    stroke: $global-color-7;
                                }
                                circle {
                                    fill: $global-color-7;
                                }
                            }

                            #Path_6861,
                            #Path_7108,
                            #Path_6875
                            {
                               stroke: $global-color-7;
                            }

                            #Path_4117,
                            #Path_4118,
                            #Path_4119,
                            #Path_4120 {
                                fill: $global-color-7;
                            }
                        }

                        .icon {
                            color: $global-color-7;
                            span.anticon {
                                color: $color-white;
                            }
                        }
                    }

                    &.active {
                        border-bottom-color: $color-primary;
                    }

                    .icon {
                        padding: 9px 4px;
                        border-radius: 50%;
                        @include d-flex;
                        align-items: center;
                    }

                    .text {
                        font-family: "Poppins-Regular";
                        line-height: 21px;
                        letter-spacing: 0.14px;

                        @include screen(tablet) {
                            display: none;
                        }
                    }
                }
            }

            .general-menu {
                .ant-divider-vertical {
                    border-left: 1px solid $color-topbar-bottom-border;
                    margin: 0px;
                    height: 2em;
                }
            }
        }
    }
}
