#login-page {
    .left-header-side {
        // max-width: $base-layout-sidebar-width;
        // width: 30%;
        height: 100%;
        @include d-flex;
        align-items: center;
        svg {
            margin-left: 60px;
        }

        .desktop-logo {
            display: block;
        }
        .mobile-logo {
            display: none;
        }
    }
    .ant-form-item-label > label {
        color: #bcc7d9;
    }
    .right-header-side {
        height: 100%;
        @include d-flex;
        justify-content: flex-end;
        align-items: center;
        background: #ffffff;
        button {
            padding-right: 60px;
        }
    }
    .main-header {
        @include d-flex;
        justify-content: center;
        width: 100%;
        height: 105px;
        > div {
            width: 100%;
            height: 100%;
        }
    }

    .sidebar {
        // background: #3b55eb;
        background: rgb(59, 85, 235);
        background: linear-gradient(180deg, rgba(59, 85, 235, 1) 0%, rgba(37, 220, 174, 1) 100%);
        .sidebar-content {
            max-width: 500px;
            height: 100%;
            width: 100%;
            @include d-flex;
            align-items: center;
            justify-content: center;
        }
        .side-bottom-image {
            display: block;
            position: absolute;
            width: 100%;
            max-width: 500px;
            bottom: 30px;
            padding: 0 10px;
        }
    }

    .side-bottom-image-mobile {
        display: none;
        width: 360px;
    }

    .login-form {
        max-width: 445px;
        margin: 0 auto;
        width: 100%;
        @include d-flex;
        height: 100%;
        justify-content: center;
        align-items: center;

        h1 {
            font-family: "Poppins-Light";
            font-size: 24px;
            color: #1a223f;
            line-height: 35px;
        }
        .ant-checkbox {
            &:not(.ant-checkbox-checked) {
                .ant-checkbox-inner {
                    background-color: #ffffff !important ;
                    border: 2px solid #bcc7d9 !important;
                }
            }
            .ant-checkbox-inner {
                background-color: #2fe479 !important ;
                border: 2px solid #2fe479 !important;
            }
        }
        a {
            color: #005ae7;
            line-height: 21px;
        }
        .ant-input-affix-wrapper {
            border: none !important;
            box-shadow: none !important;
            svg {
                width: 23px;
                height: 19px;
                path {
                    fill: #d0d9e6;
                }
            }
        }
        .remember-me-forgot-password-section {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-bottom: 35px;
            .ant-checkbox + span {
                line-height: 21px;
                letter-spacing: -0.14px;
                padding-left: 11px;
            }

            .ant-form-item {
                margin-bottom: 0px;
            }
        }

        .dont-have-account {
            text-align: center;
            line-height: 21px;
            letter-spacing: -0.14px;
        }
        #submit {
            @include transition(all 0.3s);
            &:hover {
                transform: translateY(-7px);
            }
        }
        > form {
            width: 100%;
            > .remember-me-forgot-password-section {
                .ant-form-item-control-input {
                    min-height: 100%;
                }
            }
            > div {
                &:nth-child(6) {
                    margin-bottom: 26px;
                }
            }
        }
    }

    @media screen and (max-width: 699px) {
        .sidebar {
            display: none;
            .side-bottom-image {
                display: none;
            }
        }
        .left-header-side {
            background: #ffffff;

            .desktop-logo {
                display: none;
            }
    
            .mobile-logo {
                display: block;
            }

            svg {
                width: 50px;
            }
        }

        .side-bottom-image-mobile {
            display: block;
        }
        .main-content {
            flex-direction: column;
            .login-form {
                max-width: 370px;
            }
        }

        .login-form {
            > form {
                margin-top: 145px;
            }
        }
    }
    @media screen and (max-width: 399px) {
        .login-form {
            max-width: 283px !important;
        }
    }
}
