@mixin screen($size) {
    $desktop: "(min-width: 1024px)";
    $tablet: "(max-width: 1023px)";
    $mobile: "(max-width: 767px)";
    @if $size == desktop {
        @media only screen and #{$desktop} {
            @content;
        }
    } @else if $size == tablet {
        @media only screen and #{$tablet} {
            @content;
        }
    } @else if $size == mobile {
        @media only screen and #{$mobile} {
            @content;
        }
    } @else {
        @media only screen and #{$size} {
            @content;
        }
    }
}

@mixin font-face($font-name, $file-name, $weight: normal, $style: normal) {
    @font-face {
        font-family: quote($font-name);
        src: url('/assets/fonts/' + $file-name + '.ttf');
        // src: url('/assets/fonts/' + $file-name + '.eot');
        // src: url('/assets/fonts/' + $file-name + '.eot#iefix') format("embedded-opentype"),
        //      url('/assets/fonts/' + $file-name + '.woff2') format("woff2"),
        //      url('/assets/fonts/' + $file-name + '.woff') format("woff");
        font-weight: $weight;
        font-style: $style;
    }
}

@mixin box-shadow($params...) {
    -webkit-box-shadow: $params;
    -moz-box-shadow: $params;
    box-shadow: $params;
}

@mixin ant-steps-component-step($_bg-color, $_border-color, $_icon-color, $_title-color, $_seperator-color) {
    padding-bottom: 80px;

    .ant-steps-item-icon {
        background: $_bg-color;
        border-color: $_border-color;
        width: 40px;
        height: 40px;
        text-align: center;
        border-radius: 50%;
        line-height: 40px;

        span {
            color: $_icon-color;
            top: -2px;
            font-size: 16px;
            font-weight: 700;

        
        }
    }

    .ant-steps-item-title {
        color: $_title-color !important;
        line-height: 21px;
        font-weight: 700;
        font-size: 14px;
        font-family: "Poppins-SemiBold";
    }

    .ant-steps-item-description {
        font-size: 12px;
        color: $color-text;
        padding-top: 2px;
        padding-bottom: 0px !important;
        line-height: 18px;
    }

    .ant-steps-item-tail {
        position: absolute;
        top: 0px;
        left: 20px;
        width: 1px;
        margin-left: -1px;
        height: 100%;
        padding: 45px 0 5px;

        &::after {
            background-color: $_seperator-color;
            // height: 100%;
            width: 2px;
        }
    }
}

@mixin d-flex {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
}

@mixin d-flex-i {
    display: -webkit-box !important;
    display: -moz-box !important;
    display: -ms-flexbox !important;
    display: -webkit-flex !important;
    display: flex !important;
}
  
@mixin d-inline-flex {
    display: -webkit-inline-flex;
    display: -ms-inline-flexbox;
    display: inline-flex;
}
  
@mixin transition($var) {
    -webkit-transition: $var !important;
    -moz-transition: $var !important;
    -o-transition: $var !important;
    transition: $var !important;
}
  
@mixin transform($var) {
    -webkit-transform: $var !important;
    -moz-transform: $var !important;
    -ms-transform: $var !important;
    -o-transform: $var !important;
    transform: $var !important;
}
  
@mixin animation($var){
    -webkit-animation: $var;
    -moz-animation:    $var;
    -o-animation:      $var;
    animation:         $var;
}
  
  
@mixin placeholder-color($var){
&::-webkit-input-placeholder { /* Chrome/Opera/Safari */
    color: $var !important;
    }
    &::-moz-placeholder { /* Firefox 19+ */
    color: $var !important;
    }
    &:-ms-input-placeholder { /* IE 10+ */
    color: $var !important;
    }
    &:-moz-placeholder { /* Firefox 18- */
    color: $var !important;
    }
}

@mixin scrollbar($_thumb-color) {
    height: 300px;
    overflow-y: scroll;
    margin-top: 10px;
    margin-bottom: 25px;

    &::-webkit-scrollbar {
        background-color: transparent;
        width: 5px;
    }

    &::-webkit-scrollbar-button {
    display: none !important;
    }
    
    &::-webkit-scrollbar-thumb {
    background-color: $_thumb-color;
    border-radius: 5px;
    }
    
    &::-webkit-scrollbar-track {
    background-color: transparent;
    }
}
