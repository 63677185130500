
.right-col {
    // steps
    // @import './GeneralDetails/style';
    // @import './Itenerary/style';
    // @import './InclusionsExclusions/style';
    .steps-container {
        .ant-steps-item{
            width: 30%
        }
        .ant-steps-item-tail{
            margin-left: 49%;
            top: 62px !important;
        }
        .ant-steps-item-title{
            font-family: "Poppins-Regular";
        }

        }
    .data-display {
        height: 465px !important;
        @include scrollbar($_thumb-color);
        overflow-x: hidden;
        > div{
            > div{
                &:first-child{
                    > .data-container{
                        margin-top: 40px;

                        > .ant-space{
                            width: 100%;
                            > div{
                                &:nth-child(5){
                                    .info-section{
                                        margin-bottom: 5px;
                                    }
                                }
                                &:last-child{
                                    > .ant-card{
                                        border: none !important;
                                        .ant-card-body{
                                            > div{
                                                width: 695px;
                                                height: 199px !important;
                                                > div{
                                                    border-radius: 5px;
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
       
    }
    .data-container{
        margin: 0 30px 0 30px;

        .border-text{
            font-size: 12px;
            margin: -31px 0 0 -6px;
        }
        .info-section{
            margin-bottom: 10px;
            .name{
                margin-bottom: 2px;
                    font-family: "Poppins-Medium";
                    font-size: 12px;
                    color: #BBC6D1 !important;
                    letter-spacing: 0.12px;
                    line-height: 18px;
            }
            .content{
                    color: #758CA1;
                    line-height: 21px;
                    letter-spacing: 0.14px;
                    word-break: break-all;
            }
        }
      
        .ant-space {
            width: 100%;
            .ant-space-item:last-child{
                .ant-space {
                    .ant-divider {
                        display: none;
                    }
                }
            }
        }
    }

    @media screen and (max-width: 991px) {
        .data-display {
            display: none;
        }
    }
}
