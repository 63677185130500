.ant-pagination.ant-pagination-mini {

    font-size: 12px !important;
    font-family: 'Poppins-Regular' !important;
    color: #758CA1 !important;
    font-weight: normal !important;
    line-height: 1.15 !important;
    @include d-flex-i;
    align-items: center !important;

    .ant-pagination-prev {
        min-width: 0 !important;
        height: auto !important;
        line-height: 1 !important;
        button {
            width: 36px !important;
            height: 36px !important;
            @include d-flex-i;
            align-items: center !important;
            justify-content: center !important;
            background-color: #DDE4EB !important;
            border: 0 !important;
            outline: none !important;
            box-shadow: none !important;
            border-radius: 100% !important;

            svg {
                path {
                    fill: #BBC6D1 !important;
                }
            }
        }
    }
 
    .ant-pagination-next {
        min-width: 0 !important;
        height: auto !important;
        line-height: 1 !important;
        button {
            width: 36px !important;
            height: 36px !important;
            @include d-flex-i;
            align-items: center !important;
            justify-content: center !important;
            background-color: #DDE4EB !important;
            border: 0 !important;
            outline: none !important;
            box-shadow: none !important;
            border-radius: 100% !important;
            svg {
                path {
                    fill: #BBC6D1 !important;
                }
            }
        }
            
    }

    > li {

        &:not(:last-child){
            margin-right: 15px !important;
        }

    }

    .ant-pagination-item {
        min-width: 0 !important;
        line-height: 1 !important;
        width: 36px !important;
        height: 36px !important;
        background-color: #FFFFFF !important;
        border-radius: 100% !important;
        @include d-flex-i;
        align-items: center !important;
        justify-content: center !important;
        border: 0 !important;
        outline: none !important;
        box-shadow: none !important;

            a {
                text-decoration: none !important;
                font-size: 12px !important;
                font-family: 'Poppins-Regular' !important;
                color: #758CA1 !important;
                font-weight: normal !important;
                padding: 0 !important;
            }

        &.ant-pagination-item-active {
            width: 45px !important;
            height: 45px !important;
            background-color: #536DFE !important;
            box-shadow: 0px 0px 6px rgba(#000 , 0.48) !important;

            a {
                color: #fff !important;
            }
        }
    }
}
