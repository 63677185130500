.gm-style {
    .location {
        svg {
            width: 18px;
            height: 28px;
            path {
                fill: red;
            }
        }
    }
}
