.customer-view-one {
    .personal-info {
        @import './PersonalDetails/style';

        background-color: $color-white;
        border-radius: $border-radius-small;
        box-shadow: 0px 2px 15px 0px rgba(0,0,0,0.04);
        .ant-card-head{
            padding: 30px 30px 22px 30px;
            border-color: #DDE4EB;
            .ant-card-head-title{
                padding: 0;
                >div{
                    justify-content: space-between;
                    width: 100%;
                    >div:first-child{
                        .ant-space{
                        >div:last-child{
                            span{
                                font-family: "Poppins-Medium";
                                line-height: 27px;
                                font-size: 18px;
                                color: #536DFE;
                                letter-spacing: 0.18px;
                            }
                            .description{
                                span{
                                    font-size: 12px;
                                    font-family: "Poppins-Regular";
                                    line-height: 24px;
                                }
                
                                .label{
                                    color: #A4AAB7;
                                }
                                .content{
                                    color: #646D82;
                                }
                            }
                        }
                        }
                    }
                }
            }
            .img-wrapper {

                img {
                    width: 40px;
                    height: 40px;
                }
            }
        }
        .ant-card-body{
            padding: 0 30px !important;
            .info-section{
                padding: 24px 30px 15px 0;
                &:not(:first-child){
                    padding-top: 0 !important;
                }
                .name{
                    span{
                        font-size: 12px;
                        line-height: 18px;
                        letter-spacing: -0.12px;
                        color: #BBC6D1;
                    }
                }
                .content{
                    letter-spacing: -0.14px;
                    line-height: 21px;
                    color: #758CA1;
                    margin-top: 4px;

                    button{
                        @include d-flex;
                        align-items: center;
                        padding: 5px !important;
                        border-radius: 20px;
                        svg{
                            margin: 0 7px 0 0!important;
                            path{
                                fill: #536DFE;
                            }
                        }
                        span{
                            font-size: 10px;
                            font-family: "Poppins-Regular";
                            color: #536DFE;
                        }
                    }
                    .ant-tooltip-open{
                        background-color: #536DFE;
                        svg{
                            path{
                                fill: #FFFFFF;
                            }
                        }
                        span{
                            color: #FFFFFF;
                        }
                    }
                }
            }
            .ant-divider{
                border-width: 1px
            }
        }
    }

    .work-info {
        @import './WorkDetails/style';
        .filter-content{
            button:not(.ant-input-search-button){
                @include d-flex;
                align-items: center;
                svg{
                    margin-right: 14px;
                }
                span{
                    font-family: "Poppins-Medium";
                    letter-spacing: 0.14px;
                    color: #536DFE;
                    line-height: 21px;
                    font-size: 14px;
                }
            }

            .customer-invoices {
                div {
                    span:first-child {
                        color: #A4AAB7;
                    }
                    span:last-child {
                        color: $global-color-9;
                    }
                }
            }
        }
        .ant-table-content{
            width: 100%;
            overflow-x: auto;
            > table{
                max-width:3412px;;
            }
        }
    }

    @media (max-width: 1199px) {
        .filter-content {
            .ant-col {
               &:last-child {
                    > div {
                        flex-flow: wrap;
                    }
                }
            }
        }
    }
}

@media (max-width:991px){
    .customer-view-one{
        padding:20px;
        flex-direction: column;
        row-gap: 20px !important;
        > div{
            flex: 0 0 100%;
            max-width: 100%;
        }
    }
        }
