#view-tour-trip-page,
#view-tour-package-page,
#view-tour-cruise-page,
#view-tour-activity-page {
    @import './TourInfo/style';
    @import './TourItenerary/style';
    @import './Reservation/style';

    .ant-radio-button-wrapper {
        height: 40px;
        line-height: 40px;
        border: none;
        margin: 0px;
   }
    
    font-family: "Poppins-Regular";
    .left-col{
        border: 1px solid #E7EBEF;

        .widget-categories {
            margin-top: 22px;
        }
    }
    .right-col{
        height: 100%;
        >.ant-card-body{
            height: 100%;
            max-height: 775px;

        }
        .ant-image{
            height: 100%;
            width: 100%;
            border: 1px solid #DDE4EB;
            border-radius: 5px;
        }
    }
    .general-data >div:first-child{
       >div:nth-child(2){
           @include d-flex;
           justify-content: center;
       }
    }
    .ant-divider {
        border-width: 1px;
    }

    .view-content {
        margin-top: 30px;
    }
    .go-back{
        box-shadow: 0px 0px 20px 0px rgba(26,38,43,0.06) !important;   
        padding: 10px 16px;
        svg{
            margin-right: 15px;
            width: 14.26px;
            height: 9px;
        }
        span{
            font-family: "Poppins-Regular";
            color: #BBC6D1;
            line-height: 21px;
            letter-spacing: 0.14px;
        }
    }
    .header-title {
        font-family: "Poppins-Medium";
    }
    .tour-description {
        font-family: "Poppins-Light";
        line-height: 22px;
        letter-spacing: 0.14px;
        color: #4D677E;
    }
    .price-description {
       line-height: 21px;
       letter-spacing: 0.14px;
       margin-bottom: 2px;
    }
    .price-section {
        align-items: flex-start;
        display: flex !important;
        justify-content: space-between;

        .ant-btn.ant-btn-default {
            margin-top: 10px;
            background-color: rgba($global-color-3, 0.15);
            border-radius: $border-radius-small;
            color: $global-color-3;
            font-size: 12px;
            &::after {
                content: none;
            }
            svg {
                width: 18px;
                height: 18px;
                margin-right: 10px;
                path {
                    stroke: $global-color-3;
                    fill: none;
                }
            }

            .btn-text {
                display: flex;
                justify-content: space-between;
                align-items: center;
            }

            &.shared-success {
                background-color: #EAFFFA;
                color: $global-color-12;

                .btn-text {
                    .svg-wrapper {
                        width: 26px;
                        height: 26px;
                        border-radius: 100%;
                        background-color: $global-color-12;
                        margin-right: 10px;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                    }
                    svg {
                        margin-right: 0px;
                        #icon-tick {
                            path {
                                stroke: $color-white;
                            }
                        }
                    }
                }
            }
        }

        .agency-name {
            border-radius: $border-radius-small;
            padding: 10px;
            color: $global-color-7;
            display: flex;
            justify-content: center;
            align-items: center;
            margin-bottom: 20px;
    
            &.base-color {
                background-color: $global-color-8;
            }
    
            &.collected-color {
                background-color: #EAFFFA;
            }
    
            &.pending-color {
                background-color: #FFFAF3;
            }

            .ant-avatar {
                margin-right: 20px;
            }
        }

        .ant-badge .ant-scroll-number-custom-component {
            width: 26px;
            height: 26px;
            border-radius: 100%;
            background-color: $global-color-12;
            display: flex;
            justify-content: center;
            align-items: center;
            margin-top: 5px;

            svg {
                #icon-tick {
                    path {
                        stroke: $color-white;
                    }
                }
            }
        }

        .ant-badge-count {
            background-color: transparent !important;
        }

        .earning {
            font-size: 16px;
            color: $global-color-12;
            margin-top: 10px;
            span:first-child {
                font-family: 'Poppins-SemiBold';
                margin-right: 5px;
            }
    
            span:nth-child(2) {
                font-family: 'Poppins-LightItalic';
            }
        }
    }
 
    .general-data{
        .accomodation-block-details{
            .label{
                color: #536DFE !important;
                font-family: "Poppins-Medium";
                line-height: 16px !important;
                font-size: 12px;
            }
            .content{
                line-height: 16px !important;
                color: #758CA1 !important;
                font-size: 14px;
            }
        }
     
        >div:first-child{
            >div{
                &:last-child{
                    padding-left: 10px;
                }
                &:first-child{
                    padding-right: 20px;
                }
            }
        }
    }
    .extras-title{
     font-family: "Poppins-Medium" !important;
     color: #4D677E !important;
     letter-spacing: 0.14px !important;
     line-height: 21px;
    }
   .extras-block{
    margin-top: 17px;
    .label{
        color: #758CA1;
        line-height: 20px;
    }
    .content{
        color: #BBC6D1;
        line-height: 20px;
    }
}
 
}
#view-tour-trip-page{
    .right-col{
        .ant-card-body{
            padding: 0;
        }
    }
    .left-col{
        height: 100%;
        .ant-card-body{
            padding: 36px 30px;
        }
    }
}

////////////RESPONSIVE

@media (max-width:1399px){
    #view-tour-trip-page{
        .search-form{
            >div{
                flex-direction: column;
                >div{
                    &:last-child{
                        justify-content: flex-start;
                        padding-bottom: 21px;
                        padding-top: 21px;
                        margin-top: 21px;
                        border-top: 1px dashed #DDE4EB;
                    }
                    &:first-child{
                        > form{
                            >.ant-row{
                                >.ant-col{
                                    width: 100%;
                                    >.ant-space{
                                        width: 100%;
                                        >div{
                                            &:first-child,&:last-child{
                                                width: 40%;
                                            }
                                        }
                                        >span{
                                            width: 20%;
                                            @include d-flex;
                                            justify-content: center;
                                            padding-top: 21px;
                                            >div{
                                                height: 70px;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
        .add-group-form{
            >div{
                row-gap: 30px !important;
                >div:last-child{
                    padding-left: 86px !important;
                    width: 100%;
                    display: flex;
                    justify-content: flex-start;
                }
            }
        }
    }
    }
    @media (max-width:1199px){
        #view-tour-trip-page{
            .add-group-form{
                >div{
                    >div{
                        &:nth-child(2){
                            width: 90%;
                        }
                        &:nth-child(3){
                            padding-left: 85px !important;
                            padding-right: 23px !important;
                            width: 100%;
                        }
                    }
                }
            }
            .group-cards{
                .ant-card-meta-description{
                    >.ant-row{
                        >div:last-child{
                            width: 100%;
                            justify-content: flex-end;
                        }
                    }
                }
            }
        }
    }
    @media (max-width:991px){
        #view-tour-trip-page{
            .extras-block{
                justify-content: space-between;
            }
            .search-form{
                .add-button_col{
                    justify-content: space-between !important;
                }
            }
            .add-group-form{
                >div{
                    >div{
                        &:nth-child(2){
                            max-width: 100%; flex: 0 0 100%; width: 100%;
                            padding-left: 0 !important;
                        }
                        &:nth-child(3){
                            padding-left: 0 !important;
                            padding-right: 15px !important;
                            width: 100%;
                        }
                        &:last-child{
                            padding-left: 0 !important;
                        }
                    }
                }
            }
        }
    }
    @media (max-width:767px){
        #view-tour-trip-page{
            .search-form{
                >div{
                    >div{
                        &:first-child{
                            > form{
                                >.ant-row{
                                    >.ant-col{
                                        >.ant-space{
                                            flex-direction: column;
                                            >div{
                                                &:first-child,&:last-child{
                                                    width: 100%;
                                                }
                                                &:last-child{
                                                    padding-left: 10px;
                                                }
                                            }
                                            >span{
                                             display: none;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
            .group-cards{
                .ant-card-meta-description{
                    >.ant-row{
                        >div{
                            &:first-child{
                                width: 50%;
                                >.ant-space{
                                    align-items: flex-start;
                                    >.ant-space-item:last-child{
                                        >.ant-space{
                                            align-items: flex-start;
                                            flex-direction: column;
                                            >.ant-space-item-split{
                                                display: none;
                                            }
                                        }
                                    }
                                }
                            }
                            &:last-child{
width: 50%;
                            }
                        }
                    }
                }
            }
        }
    }
@media (max-width:575px){
#view-tour-trip-page{
    .tour-itenerary-list{
        .tour-itenerary-item{
            >div{
                row-gap: 15px !important;
            }
        }
    }
    .search-form{
        .add-button_col{
            flex-direction: column;
            >.ant-col{
                padding-right: 0 !important;
                padding-left: 0 !important;
                margin-bottom: 15px;
            }
            >div,button{
                width: 100%;
                button{
                width: 100% !important;
                }
            }
        }
    }
    .add-group-form{
        input{
            width: auto !important;
        }
    }
    .group-cards{
        .ant-card-meta-description{
            >.ant-row{
                >div{
                    &:first-child,&:last-child{
                        width: 100%;
                    }
                  
                }
            }
        }
    }
}
}
