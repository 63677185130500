.coming-soon-content {
    max-height:calc(100vh - 222px);
    height:calc(100vh - 222px);
    @include d-flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-family: "Poppins-Medium";
    font-size: 16px;

    .coming-soon-svg {
        margin-bottom: 25px;
    }

    .text {
        font-family: 'Poppins-Light';
        font-size: 14px;
        color: $global-color-9;
        margin-bottom: 38px;
        line-height: 21px;
        letter-spacing: 0.10px;
        width: 40%;
        text-align: center;
    }
    span{
        @include transition(all 0.3s);
    }

    .ant-btn {
        background-color: $global-color-3;
        color: $color-white;
        font-size: 12px;
        font-family: "Poppins-Medium";
        @include d-flex;
        align-items: center;
        justify-content: center;

        #fi-rr-interrogation {
            width: 20px;
            height: 20px;
            path, rect {
                fill: $color-white;
            }
        }

        span {
            margin-right: 15px;
        }
    }
}
@media (max-width:575px){
    .coming-soon-content {
        >svg{
            width: 300px;
            margin-bottom: 0;
        }
        .text{
            text-align: center;
        }
        button{
            width: auto !important;
        }
    }
}
