
    .pay {
                background: $global-color-12;
                color: $color-white;
                border-color: $global-color-12;
                padding: 0 111px;
                width: 100%;
                span{
                    line-height: 18px;
                    letter-spacing: -0.12px;
                }
                &.disabled {
                    background-color: rgba($global-color-12, 0.18);
                }
            }

.invoice-payment{
    padding-left: 0 !important;
    .ant-checkbox-inner{
        border-radius: 5px;
    }
    .ant-input-affix-wrapper{
        box-shadow: none !important;
    }
    .ant-checkbox + span{
        padding-left: 15px;
        line-height: 21px;
        color: #BBC6D1;
        letter-spacing: -0.14px;
    }
    form{
        height: 100%;
        background-color: #FFFFFF;
        border-radius: 10px;
        border: 1px solid #DDE4EB;
    }
  
}




.payment-block {
    background-color: $color-white;
    border-radius: $border-radius-medium;
    padding: 40px;
    .ant-form-item{
        margin-bottom: 0px
    }
    .ant-divider{
        border-width: 1px;
    }
> div{
    &:first-child{
        @include d-flex;
        flex-direction: column;
        justify-content: space-evenly;
        span:last-child{
            font-size: 12px;
            line-height: 35px;
            color: #BBC6D1;
        }
    }
    &:nth-child(2){
        margin-top: 18.5px;

        @include d-flex;
        justify-content: center;
        align-items: center;
        > .ant-row > .ant-col:nth-child(3){
            .ant-input-affix-wrapper{
                input{
                    color: #536DFE !important;
                }
            }
        }
    }
    &:nth-child(3){
        margin: 33px 0 18px 0;

    }
    &:nth-child(4){
        width: 100%;
        .ant-form-item{
           margin-top: 13px;
        }
        span:first-child{
            font-size: 12px;
            line-height: 35px;
        }
    }
    &:nth-child(5){
        margin:21px 0 27px 0;
        .ant-form-item-control-input-content{
            @include d-flex;
        }
    }

}

    .large-text {
        font-size: 20px;
        color: $global-color-7;
        line-height: 30px;
        letter-spacing: -0.2px;
    }
    .ant-radio-button-wrapper.quantity {
        width: 100%;
        line-height: 20px;
        border-radius: 50px;
        height: 77px;
        @include d-flex;
        align-items: center;
        // border: 2px solid #DDE4EB;
        >span{
            &:last-child{
                @include d-flex;
            }
            .icon{
                @include d-flex;
            }
        }
        .block-description{
            gap: 16px !important;
            .label{
                color: #758CA1;
                letter-spacing: -0.14px;
                line-height: 21px;
                padding-bottom: 4px;
            }
            .content{
                letter-spacing: -0.12px;
                color: #BBC6D1;
                line-height: 18px;
            }
        }
    }
    .ant-radio-button-wrapper.ant-radio-button-wrapper-checked.quantity {
        border-color: #536DFE;
        background-color: rgba(#536DFE, 0.11);
        .ant-space-item {
            &:first-child {
                #Path_4086 , #Path_4087 ,#Path_4084,#Path_4085 {
                    fill: #536DFE;
                }
            }
            &:last-child {
                .label , .content {
                    color: #536DFE;
                }
            }
        }
    }

    #paymentMethod{
        @include d-flex;
        justify-content: space-between;
        >.ant-radio-button-wrapper {
            &:first-child{
                svg{
                    width: 27px;
                    height: 18px;
                }
            }
        }

        .ant-radio-button-wrapper{
            border-radius: 5px;
            border-left: 2px solid #D0D9E6;
            @include d-flex;
            align-items: center;
            
            &:not(:first-child){
                padding-right: 45px;
                &::before{
                    display: none !important;
                }
            }
            >span{
                &:last-child{
                    @include d-flex;
                    .ant-space-item{
                        @include d-flex;
                        justify-content: center;
                        font-size: 12px;
                        color: #758CA1;
                        line-height: 18px;
                        letter-spacing: -0.12px;
                    
                }

                }
               
                  
            }
        }
        .ant-radio-button-wrapper-checked.ant-radio-button-wrapper {
            border-left-color: #6580f7;
            background-color: rgba(#536DFE, 0.11);
            .ant-space-item{
                color: #536DFE !important;
                }
            svg{
                path{
                    fill: #536DFE;
                  
                }
                line, rect{
                    stroke: #536DFE;
                }
            }

            }
    }
}

@media (max-width:1399px){
.invoice-payment{
    .payment-block{
        >div{
            &:nth-child(4){
                >.ant-row{
                    .ant-radio-group{
                        flex-direction: column;
                        gap: 15px !important;   
                    }
                  
                }
            }
        }
    }
}
}
