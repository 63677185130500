@import './New/style';
@import './ViewAll/style';
@import './ViewOne/style';

.ant-radio-group.ant-radio-group-outline.ant-radio-group-large .ant-radio-button-wrapper-checked:not(:first-child) {
    border-left: 2px solid $global-color-3 !important;
}
.ant-radio-group-solid .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled){
    background: $global-color-7 !important;
    border: none !important;
    span{
        color: $color-white ;
    }

   }
   #discount_type , #commission_type, #commission_type_bb, #commission_type_bh, #commission_type_ai, #extra_bed_charges_type, .price_type{
    margin-top: 0px;
    @include d-flex;
    margin-left: 10px;
    >label{
        &:last-child{
            margin-left: 0;
        }
    }
    .ant-radio-button-wrapper{
        height: 35px;
        width: 34px;
        border-radius: 5px !important;
        border: none;
        background: $global-color-10;
        @include d-flex;
        justify-content: center;
        align-items: center;
        margin: 7.5px 5px;

    }
    

}
