.add-customer {
    .form {
        background-color: $color-white;
        max-width: 800px;
        margin: 0 auto;
        border-radius: 10px;
        border: 1px solid #DDE4EB;
        .inner-content {
            padding: 40px;
        }
            .ant-checkbox-checked{
                 .ant-checkbox-inner{
                     background-color: #536DFE !important;
                     box-shadow: 0px 2px 3px rgba(#000 , 0.08) !important;
                                   }
             }
             .ant-checkbox-inner{
                width: 25px !important;
                height: 25px !important;
                &::after{
                    transform: translate(-50%, -50%) scale(0.9) !important;
                }
             }
             .ant-checkbox + span{
                padding-left: 11px;
                line-height: 21px;
                letter-spacing: 0.14px;
                color: #BBC6D1;
             }
        .ant-form-item-label > label{
            height: auto;
            color: #BBC6D1;
        }
        .if-company-content{
            margin-left: 0 !important;
            margin-right: 0 !important;
            padding-top: 6px;
            >div:nth-last-child(-n+2){
                .mui-form-item{
                    margin-bottom: 0;
                }
            }
        }
    
    }

    .form-button {


        .save-button {
            width: 300px;
        }
    }
    .divider{
        width: 100%;
        padding-left: 15px;
        padding-right: 15px;
        >div{
            border-width: 1px 0 0;
        }
    }
}

////////////RESPONSIVE

@media (max-width:991px){
    .add-customer{
        padding: 20px;
    }
}


@media (max-width:767px){
    .add-customer .form .if-company-content > div:nth-last-child(2) .mui-form-item {
        margin-bottom: 30px;
    }

}
@media (max-width:399px){
    .add-customer .form-button {
        .ant-col{
            width: 100%;
        }
        .save-button {
            width: 100%;
        }
    }
}