.loading-filters {
    .ant-skeleton-element .ant-skeleton-button {
        height: 40px;
    }

    .search .ant-skeleton-button {
        width: 340px;
    }

    .filters-toggle .ant-skeleton-button {
        width: 150px;
    }

    .add .ant-skeleton-button {
        width: 209px;
    }
}
