.check-list {
    .select-all {
        font-size: 16px;
    }

    .checkbox-multi-select {
        width: 100%;

        .commission {
            margin-left: 15px;
        }
    }
}
