.dashboard {
    margin-left: -15px !important;
    margin-right: -15px !important;

    .left-dashboard {
        padding-left: 15px !important;
        padding-right: 15px !important;

        .form-step-title {
            padding-left: 0 !important;
        }

        .sales-overview {
            @import "./ProgressContent/style";
            .ant-card-head {
                border-color: #dde4eb;
            }
            .ant-card-body {
                > div {
                    padding-top: 70px;
                }
                
            }
            .ant-card,
            .progress {
                box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.05);
                border-width: 1px;
                height: 100%;
            }
            .ant-card-extra {
                .ant-picker {
                    background-color: #f4f6f8 !important;
                }
            }
            .report-button {
                background: $global-color-12;
                color: $color-white;
                span {
                    margin-right: 20px;
                    font-size: 12px;
                    font-family: "Poppins-Medium";
                    line-height: 18px;
                    letter-spacing: 0.12px;
                }
            }
            .ant-picker-suffix {
                svg {
                    width: 14px;
                    height: 7px;
                    path {
                        fill: #bbc6d1;
                    }
                }
            }

            @import "./Map/style";
            .g2-tooltip {
                padding: 0 !important;
                box-shadow: 0px 5px 25px 0px rgba(0, 0, 0, 0.05) !important;
                .g2-tooltip-title {
                    background-color: #536dfe;
                    margin: 0 !important;
                    padding: 13px 20px;
                    border-radius: 5px 5px 0 0;
                    color: #ffffff;
                }
                .g2-tooltip-list {
                    padding: 21px 26px !important;
                    .g2-tooltip-list-item {
                        margin: 15px 0 !important;
                        @include d-flex;
                        align-items: center;
                        .g2-tooltip-name {
                            font-family: "Poppins-Light";
                            color: #758ca1;
                            line-height: 18px;
                            letter-spacing: 0.12px;
                        }
                        .g2-tooltip-marker {
                            width: 10px !important;
                            height: 10px !important;
                        }
                        .g2-tooltip-value {
                            font-family: "Poppins-Medium";
                            color: #4d677e;
                            margin-left: 10px !important;
                        }
                    }
                }
            }
        }
        .sales-locations {
            .ant-card-head-title {
                padding: 27px 0;
            }
            .ant-card-head {
                border-color: #dde4eb;
            }
            .ant-card {
                border: none;
                box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.05);
            }
            .worldmap__figure-container {
                text-align: center;
            }
            .ant-card-body {
                > div {
                    > div {
                        &:last-child {
                            > div {
                                height: 100%;
                                > div {
                                    &:first-child {
                                        height: 100% !important;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    .right-dashboard {
        padding-left: 15px !important;
        padding-right: 15px !important;

        > .ant-row {
            background: $color-white;
            border-radius: $border-radius-small;
            // border-radius: 0px 0px $border-radius-small, $border-radius-small;
            @include d-flex;

            .ant-col:first-child {
                padding: 10px;
            }

            .ant-col:nth-child(2) {
                padding: 0px 20px 30px 20px;
            }
            .ant-divider {
                border-width: 1px;
                border-color: #d8e4ef;
                margin: 10px 0px 24px 0px;
            }
        }

        @import "./SharedServiceList/style";
    }

    .rec-carousel-wrapper {
        .rec-item-wrapper {
            .ant-list {
                width: 100%;
                .ant-list-item {
                    display: list-item;
                    padding: 13px 16px;
                    > div {
                        align-items: center;
                        .ant-col {
                            font-size: 12px;
                            line-height: 18px;
                            &:last-child {
                                text-align: end;
                                font-family: "Poppins-Regular";
                                color: #4d677e;
                            }
                            &:first-child {
                                font-family: "Poppins-Light";
                                color: #758ca1;
                                letter-spacing: 0.12px;
                            }
                        }
                    }
                }
            }
        }
    }
    .rec-pagination {
        @include d-flex;
        justify-content: center;
        align-items: center;
        button {
            width: 9px !important;
            height: 9px !important;
            border-radius: 50% !important;
            background-color: #dde4eb !important;
        }
        .rec-dot_active {
            width: 12px !important;
            height: 12px !important;
            background-color: #758ca1 !important;
        }
    }
    .right_dashboard_carousel {
        .rec-dot_active {
            width: 12px !important;
            height: 12px !important;
            background-color: #536dfe !important;
        }
        .rec-carousel-item {
            .ant-card-body {
                background: #f4faff;
                border-radius: 5px;
            }
        }
    }

    @media screen and (max-width: 991px) {
        padding-top: 35px;
    }

    @media screen and (max-width: 1200px) {
        padding-right: 30px;
    }
}

// @media (min-width: 768px) and (max-width: 991px) {
//     .right_dashboard_carousel {
//         display: flex;
//         flex-direction: row !important;

//         .rec-carousel-item {
//             margin-bottom: 0px !important;
//         }
//     }

//     .rec-pagination {
//         flex-direction: column;
//     }
// }
