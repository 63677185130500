.search-box-wrapper {
    border-radius: $border-radius-small;
    background: $global-color-8;
    padding: 10px;
    .search-box-menu {
        font-size: 8px;
        .ant-space-item {
            padding: 9px 18px;
            .ant-typography {
                color: $global-color-4;
                line-height: 12px;
            }
        }
        .ant-space-item:first-child {
            background: $color-white;
            border-radius: $border-radius-small $border-radius-small 0px 0px;
        }

        &.hide-menu {
            display: none;
        }
    }

    .search-box-form {
        background: $color-white;
        border-radius: 0px $border-radius-small $border-radius-small $border-radius-small;
        padding: 3px;
        font-size: 8px;
        >div:first-child{
            opacity: 0.41;
            padding: 7px 0 6px 4px;
            .ant-space-item-split{
                >div{
                    margin: 0 12.5px;
                    border-left: 1px solid #D1D8E0 !important;
                    height: 22.75px;
                }
            }
        }

        .ant-space {
            .ant-space-item{
                >div {
                    @include d-flex;
                    align-items: center;
                    .ant-typography {
                        color: $global-color-4;
                    }
                  
                }
            }
            >div{
                &:first-child{
                    svg {
                        width: 11px;
                        height: 11px;
                        margin-right: 3px;
                    }
                }
                &:nth-child(3){
                    svg {
                        width: 8px;
                        height: 8px;
                        margin-right: 4px;
                    }
                }
                &:last-child{
                    svg {
                        width: 10.75px;
                        height: 10.33px;
                        margin-right: 4px;
                    }
                }
            }
        }

        .search-box-button {
            background: $global-color-3;
            color: $color-white;
            border-radius: $border-radius-small;
            text-align: center;
            padding: 12px 20px;
            @include d-flex;
            align-items: center;
            justify-content: center;
            line-height: 12px;
        }

        &.add-border-radius {
            border-radius: $border-radius-small;
        }
    }
}
