#register-page {
    background: #ffffff;
    @import "./AgencyDetails/style.scss";
    @import "./BaseRegister/style.scss";
    @import "./ServiceList/style.scss";
    @import "./VerifyEmail/style.scss";
    @import "./VerifyPhone/style.scss";

    .ant-btn-primary {
        background: #005ae7;
    }

    .left-header-side {
        height: 100%;
        @include d-flex;
        align-items: center;
        svg {
            margin-left: 60px;
        }
    }

    .right-header-side {
        height: 100%;
        @include d-flex;
        align-items: center;
        background: #ffffff;
        > div {
            width: 100%;
            > div {
                &:first-child {
                    padding-left: 60px;
                }
                &:last-child {
                    padding-right: 60px;
                }
            }
        }
        .hello-icon {
            width: 18px;
            height: 19px;
            @include d-flex;
            align-items: center;
            g {
                path,
                rect {
                    fill: #ffa509;
                }
            }
        }
        small {
            color: #bcc7d9;
            line-height: 18px;
        }

        .buttons-waiting-wrapper {
            display: flex !important;
            align-items: center;
            .button-waiting {
                display: flex !important;
                border-radius: 5px !important;
                justify-content: center;
                align-items: center;
                padding: 16px 27px;
                font-size: 12px;
                font-family: "Poppins-Medium";
                color: #ffffff;
                font-weight: normal;
                margin-left: 20px;
                text-transform: uppercase;
                svg {
                    width: 16.5px;
                    height: 18px;
                    // margin-left: 30px;
                }
            }
            .button-log-out {
                background-color: #fc7886 !important;
            }
        }
    }
    .sidebar-content {
        width: 390px;
        max-width: 390px;
    }

    .animated-user-icon {
        position: absolute;
        bottom: 80px;
        margin-left: 15px;

        .image-1 {
            position: absolute;
            top: 62px;
            left: -30px;
        }

        .image-2 {
            vertical-align: bottom;
        }

        .image-3 {
            vertical-align: bottom;
        }

        .background-icon {
            background-color: $color-topbar-icon-background;
            border-radius: $border-radius-small;
            width: 93x;
            max-width: 93px;
            height: 122px;

            > svg {
                margin: 20px 0 0 70px;
            }
        }
    }

    .corner {
        position: absolute;
        right: 0;
        bottom: 0;
        border-bottom: 190px solid $color-topbar-icon-background;
        border-left: 340px solid transparent;
    }

    .registration-steps,
    .horizontal-registration-steps {
        .ant-steps-item {
            @include ant-steps-component-step(transparent, $steps-color, $steps-color, $steps-color, $steps-color);
        }

        .ant-steps-item-active {
            @include ant-steps-component-step(
                transparent,
                $steps-color-active,
                $steps-color-active,
                $steps-color-active,
                $steps-color
            );
        }

        .ant-steps-item-finish {
            @include ant-steps-component-step(
                $steps-color-completed,
                $steps-color-completed,
                $color-white,
                $steps-color-completed,
                $steps-color-completed
            );
            .ant-steps-item-icon {
                background: #2fe479 !important;
            }
            .ant-steps-item-tail {
                &::after {
                    background-color: #2fe479 !important;
                }
            }
            .ant-steps-item-title {
                color: #2fe479 !important;
            }
        }
        .ant-steps-item-container {
            flex-direction: row;
            align-items: unset;
            justify-content: unset;
        }
        .ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-icon {
            background: transparent !important;
            border-color: #005ae7;
        }
        .ant-steps-item-icon {
            border: 2px solid #bcc7d9;
            font-family: "Poppins-SemiBold";
            span {
                top: 0 !important;
            }
        }
        .ant-steps-item-tail {
            &::after {
                background-color: #bcc7d9 !important;
            }
        }
        .ant-steps-item-content {
            @include d-flex;
            flex-direction: column;
            justify-content: center;
        }
    }

    .ant-steps-horizontal {
        display: none;
        margin: 60px 0 0 0;
        max-width: 100%;
    }

    .stepper-description {
        margin-top: 20px;
        display: none;
        h2 {
            color: #bcc7d9;
        }
    }

    .register-form {
        // height: 100%;
        // margin-top: 75px;
        display: flex;
        justify-content: center;
        width: 100%;
        align-items: center;
        flex-direction: column;
        flex-basis: 100%;
        .title {
            font-size: 24px;
            line-height: 24px;
            margin-bottom: 0px;
        }
    }
    @media only screen and (max-width: 1599px) {
        #registration-services-form {
            margin-top: 300px;
        }
    }
    @media only screen and (max-width: 991px) {
        .main-content {
            width: 100%;
            margin-top: 0 !important;
            .register-form {
                margin-top: 0 !important;
                > #registration-services-form {
                    margin-top: 0;
                }
            }
        }
        .sidebar-content {
            margin-top: 100px !important;
        }

        .left-header-side {
            background: #fff;
            svg {
                width: 50px;
            }
        }
        .right-header-side > div {
            justify-content: flex-end;
            > div:first-child {
                display: none;
            }
        }

        .corner,
        .animated-user-icon,
        .ant-steps-vertical,
        .stepper-description {
            display: none;
        }

        .ant-steps-horizontal {
            display: flex;
        }

        .register-form form:not(#registration-services-form) {
            max-width: 536px;
            margin: 0 auto;
            width: 530px;
        }
        .base-layout-container {
            flex-direction: column;
            height: 100%;
        }
        .sidebar-content {
            width: 456px !important;
            max-width: 456px !important;
            .ant-steps-item-title::after {
                top: 0;
                height: 2px;
                background: #d0d9e6;
            }
            .ant-steps-item-icon {
                margin: 0;
            }
            .ant-steps-item-finish {
                .ant-steps-item-title::after {
                    top: 0;
                    height: 2px;
                    background: #24dcae;
                }
            }
        }
    }
    @media only screen and (max-width: 1450px) {
        .sidebar-content {
            width: 300px;
            max-width: 300px;
        }
    }
    @media only screen and (max-width: 1200px) and (min-width: 992px) {
        .sidebar-content {
            width: 255px;
            max-width: 255px;
        }
    }
    @media only screen and (max-width: 991px) {
        .register-form {
            padding-bottom: 30px;
        }
    }
    @media only screen and (max-width: 575px) {
        .register-form form:not(#registration-services-form) {
            width: 350px;
            max-width: 350px;
        }
        #registration-phone-verification-form {
            > .verify-otp-form {
                > .editing-component-wrapper {
                    flex-flow: wrap;
                    > div {
                        width: 100%;
                        button {
                            width: 100%;
                        }
                    }
                }
            }
        }
    }
    @media only screen and (max-width: 399px) {
        .register-form form:not(#registration-services-form) {
            width: 285px;
            max-width: 285px;
        }
        .verify-otp-form .otp-code-input input:not(:last-of-type) {
            margin-right: 10px;
        }
    }
}
