.empty-list-content {
    max-height:calc(100vh - 222px);
    height:calc(100vh - 222px);
    @include d-flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-family: "Poppins-Medium";
    font-size: 16px;

    .ant-image {
        margin-bottom: 25px;
    }

    .text {
        color: #BBC6D1;
        margin-bottom: 38px;
        line-height: 25px;
        letter-spacing: 0.16px;
    }
    span{
        @include transition(all 0.3s);
    }
    svg{
        margin-bottom: 45px;
    }
}
@media (max-width:575px){
    .empty-list-content {
        >svg{
            width: 300px;
            margin-bottom: 0;
        }
        .text{
            text-align: center;
        }
        button{
            width: auto !important;
        }
    }
}
