.progress {
    background: linear-gradient(180deg, rgba(19,37,141,1) 50%, rgba(83,109,254,1) 100%);
    border-radius: $border-radius-small;
  >div:nth-child(3){
    svg{
        g{
            opacity: 1;
        }
    }
  }

    .ant-progress {
        display: block;
        text-align: center;
        margin-bottom: 54px;
        margin-top: 46px;
    }
   .ant-progress-inner {
    width: 200px !important;
    height: 200px !important;
    max-width: 100%;
        svg {
            circle:first-child {
                stroke: rgba(255, 255, 255, 0.39) !important;
            }
        }
        .ant-progress-text {
            div {
                div:first-child {
                    font-size: 22px;
                    font-family: 'Poppins-Medium';
                    color: $color-white;
                }

                div:last-child {
                    font-size: 13px;
                    font-family: 'Poppins-Light';
                    color: $color-white;
                }
            }

        }
    }
    .ant-space {
        margin-bottom: 30px;
        margin-left: 33px;
        gap:16px !important;
        align-items: flex-start;
        > div{
            &:first-child{
               line-height: 40px; 
            }
        }
        .label{
            color: #FFFFFF;
            font-size: 12px;
            font-family: 'Poppins-Light';
            line-height: 18px;
            letter-spacing: 0.24px;
        }
        .content{
            color: #FFFFFF;
            font-size: 18px;
            font-family: 'Poppins-Medium';
            line-height: 27px;
            letter-spacing: 0.36px;
        }
    }

    .ant-divider {
        border-top: 0.5px solid $color-white;
        opacity: 0.35;
    }
    .ant-divider-horizontal {
        margin: 0px 0px 34px;
    }

    .description {
        text-align: center;
        padding: 0px 21px 41px 21px;
        font-size: 12px;
        color: rgba(#FFFFFF, 0.65);
        font-family: 'Poppins-Light';
        line-height: 18px;
        letter-spacing: 0.24px;
    }
}
