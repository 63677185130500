.invoice {
    @import './StepDisplay/style';
 font-family: "Poppins-Regular";
    .go-back {
        border-radius: 50%;
        width: 33px;
        height: 33px;
        padding: 0;
        @include d-flex;
        align-items: center;
        justify-content: center;
    }
    .find-or-create-resource{
        .ant-divider{
            margin: 30px 0;
        }
        .divider{
            width: 100%;
            padding-right: 30px;
        }
        .ant-checkbox-inner{
            width: 25px !important;
            height: 25px !important;
            &::after{
                transform: translate(-50%, -50%) scale(0.8)!important;
            }
        }
        .ant-checkbox + span{
            padding-left: 11px;
        }
    }

    .form {
        margin-top: 30px;
        margin-left: 140px !important ;
        margin-right: 140px !important;
        border: 1px solid #DDE4EB;
        border-radius: 5px;
        > div{
            &:first-child{
                padding-left: 0 !important;
            }
            &:last-child{
                padding-right: 0 !important;
            }
        }
        .form-step, .form-preview {
            padding: 40px;
            background-color: $color-white;
            height: 100%
        }

        .form-step {
            @import './Details/style';
            @import './Extras/style';
            @import './Participants/style';
            border-radius: $border-radius-small 0px 0px $border-radius-small;
          
        }


        .if-company-content{
            margin-left: 0px !important;
            margin-right: 0px !important;
            > div:nth-last-child(-n+2){
                .mui-form-item{
                    margin-bottom: 0;
                }
            }
        }

   
        .select-customer, .select-vendor{
            border: 2px solid $global-color-10;
            border-radius: 5px;
            box-shadow: none !important;
        }
        .ant-form-item-label > label {
          height: 20px;
          color: #BBC6D1;
        }
        .ant-divider-dashed{
            margin-left: 15px;
            margin-right: 15px;
            min-width: auto;
        }
        .ant-checkbox-wrapper-checked{
            .ant-checkbox-inner{
                background-color: #536DFE !important;
            }
        }

    }

    .form-button {
        margin-top: 20px;
        margin-right: 140px;
        margin-left: 140px;
        .ant-btn-default{
            svg{
                path{
                    fill: #536DFE;
                }
            }
            .ant-space-item{
                color: #536DFE;
                
            }
        }
        button{
            padding: 18px 49px;
        }
    }
    
    
}

///////////////////RESPONSIVE

@media (max-width:1750px){
    .invoice{
        .participants{
            .person-card{
                >.ant-card-body{
                    > div{
                        > div{
                            &:nth-child(4){
                                flex: 0 0 50%;
        max-width: 50%;
        // margin-bottom: 30px;
                            }
                            &:last-child{
                                flex: 0 0 50%;
                                max-width: 50%;
                            }
                        }
                    }
                }
            }
        }
    } 
}




@media (max-width:1399px){
    .invoice{
        .preview{
            .general-details,.tour-details,.accommodation-details{
                flex-direction: column;
                row-gap: 15px !important;
                > div{
                    flex: 0 0 100% !important;
                    max-width: 100% !important;
                    width: 100%;
                    &:last-child{
                        > div{
                            > div{
                                &:first-child{
                                    padding-left: 0 !important;
                                }
                            }
                        }
                    }
                    &:first-child{
                        text-align: start !important;

                    }
                }
            }
            .rooms,.extra-bed{
                flex-direction: column;
                row-gap: 15px !important;
                >div{
                    padding-left: 0 !important;
                    flex: 0 0 100% !important;
                    max-width: 100% !important;
                    width: 100%;
                    &:last-child{
                        >div{
                            flex-flow: nowrap;
                        }
                    }
                }
            }
        }
    }
    }


    @media (max-width:1199px){
.invoice{
    .tour-package-details,.accommodation-details>div:last-child>div{
        flex-direction: column;
        row-gap: 15px !important;
        >div{
            flex: 0 0 100%;
            max-width: 100%;
            &:last-child{
                padding-left: 0 !important;
            }
        }
        }
    }
}
    


    @media (max-width:991px){
        .invoice{
         >.form, >div:first-child,>.form-button{
            margin-left: 20px !important;
            margin-right: 20px !important;
         }
         .form-preview{
            padding-top: 0 !important;
         }
         .form-step{
            padding-bottom: 0 !important;
            .if-company-content{
                padding-bottom: 24px;
             }
         }
         > .form{
            >div{
                padding-left: 0 !important;
                padding-right: 0 !important;
            }
         }
         .participants{
            margin-bottom: 40px;
            .person-card{
                .ant-card-body{
                    >div{
                        >div{
                            &:nth-child(3),&:nth-child(1),&:nth-child(2){
                                flex: 0 0 33%;
                                max-width: 33%;
                            }
                            &:nth-child(4){
                                margin-bottom: 0;
                            }
                        }
                    }
                }
            }
         }
         .extras{
            margin-bottom: 40px;
         }
         > .form-button{
            margin-bottom: 20px;
        }
       
        }
        }
        @media (max-width:767px){
            .invoice{
                .form-step{
                   .if-company-content{
                    >div:nth-child(4){
                        margin-bottom: 30px;
                    }
                    }
                }
                .step-display{
                    >div{
                        width: 100%;
                        .ant-steps-item{
                            max-width: 33%;
                            width: 33%;
                            >div{
                                width: auto;
                            }
                        }
                    }
                 
                }
                > .form-button{
                    > div{
                        > div{
                            flex-flow: wrap;
                        }
                    }
                }
              
               }
        }
        @media (max-width:575px){
            .invoice{
                .step-display{
                    margin-top: 30px;
                    margin-left: 20px;
                    >div{
                        .ant-steps{
                            row-gap: 10px !important;
                        }
                        .ant-steps-item{
                            max-width: 100%;
                            width: 100%;
                            >div{
                                flex-direction: row;
                                justify-content: flex-start;
                                .ant-steps-item-content{
                                    min-height: unset;
                                }

                            }
                        }
                    }
                 
                }
                .form-step{
                    padding: 20px !important;
                }
                .form-preview{
                    padding: 0 20px 20px 20px !important;
                }
                .participants{
                    .person-card{
                        .ant-card-body{
                            >div{
                                >div{
                                    flex: 0 0 100% !important;
                                    max-width: 100% !important;
                                    // &:nth-last-child(-n+3):not(:last-child){
                                    //     margin-bottom: 30px;
                                    // }
                                }
                            }
                        }
                    }
                 }
                 > .form-button{
                    > div{
                        > div{
                            > div{
                                width: 100%;
                                button{
                                    width: 100%;
                                }
                            }
                        }
                    }
                }
                .discount-card{
                    .ant-radio-button-wrapper{
                        width: auto !important;
                    }
                }
               }
        }

        
        @media (max-width:399px){
.invoice{
    >.form{
        margin-left: 0 !important;
        margin-right: 0 !important;
    }
    .accommodation-details{
        >div:last-child{
            >div{
                flex-direction: column;
                >div{
                    flex: 0 0 100%;
    max-width: 100%;
    width: 100%;
                }
            }
        }
    }
    .total{
        >div{
            left: 0 !important;
            flex: 0 0 100%;
            max-width: 100%;
        }
    }
    >.form-button{
        margin: 20px 0 20px 0 !important;
    }
}
        }
