.general-data {
   

    // .price {
    //     font-size: 16px;
    //     color: $global-color-3;
    // }

    
   >div{
    &:nth-child(3){
        svg{
            width: 24px;
            height: 24px;
            path{
                fill:#536DFE ;
            }
        }
    }
   }
}
