.loading-filters {
    .ant-skeleton-element .ant-skeleton-button {
        height: 40px;
    }

    .search .ant-skeleton-button {
        width: 460px;
    }

    .filter-invoices .ant-skeleton-button {
        width: 100px;
    }

    .sort-invoices .ant-skeleton-button {
        width: 150px;
    }
}
