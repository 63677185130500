.itenerary {
    .google-map {
        height: 300px;
    }
    >div{
        &:first-child{
            @include d-flex;
            justify-content: center;
        }
    }

    .hidden-inputs {
        &.hidden {
            display: none;
        }
        &.show {
            display: block;
        }
    }
}

////////////RESPONSIVE

@media (max-width:1399px){
.itenerary{
    flex-direction: column;
    >div{
        flex: 0 0 100% !important;
        max-width: 100% !important;
    }
}
}
@media (max-width:575px){
    .itenerary{
       .button-underline{
        margin-right: 0 !important;
       }
    }
    }
