.right-col, .left-col-photo-gallery {
    .default-image {
        width: 100%;
        height: 100%;
        border-radius: 5px;
    }
    .ant-image-mask{
        border-radius: 5px;
    }

    .image-collection {
        display: none;
    }
}
