.b2b-request-filters {
    .ant-input-affix-wrapper {
        border: none;
    }

    .ant-input-group-wrapper {
        box-shadow: 0px 2px 3px rgb(0 0 0 / 8%);
    }

    .total-requests {
        text-align: right;

        .ant-typography {
            color: $global-color-9;
            font-family: 'Poppins-Medium';
        }
    }

    .ant-select.sort-request {
        box-shadow: none !important;

        .ant-select-selector {
            background-color: $global-color-10;

            .ant-select-selection-placeholder {
                color: $global-color-4 !important;
            }
        }

        .ant-select-arrow {
            svg {
                g {
                    path {
                        fill: $global-color-4;
                    }
                }
            }
        }
    }
}
