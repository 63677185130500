.share-product {
    .ant-modal-body {
        padding: 0px;

        .service-name {
            padding: 24px;

            .ant-typography {
                font-size: 20px;
                color: $global-color-9;
            }
        }

        .ant-divider.ant-divider-horizontal {
            margin: 0px 0px 10px 0px;
            border-top: 2px solid $global-color-11;
        }

        .ant-form {
            padding: 24px;

            .ant-input-affix-wrapper {
                box-shadow: none !important;
                &:hover {
                    border-color: #D0D9E6;
                }
            }

            .ant-input-affix-wrapper-focused {
                border-color: #D0D9E6;
            }

            .input {
                border: 2px solid #D0D9E6 !important;
                padding: 21px;
            }

            .terms-and-condition-info {
                margin-top: 20px;
                svg {
                    width: 14px;
                    height: 14px;
                    path, rect {
                        fill: $global-color-2;
                    }
                }

                color: $global-color-2;
                font-size: 12px;
            }

            .ant-btn {
                background-color: $global-color-7;
                font-size: 14px;
                font-family: 'Poppins-Medium';
                color: $color-white;

                &::after {
                    content: none;
                }
            }
        }
    }
}
