.my-partner-page {
    @import'./Filters/style';

    .partner-table {
        margin-top: 24px;
        background-color: $color-white;
        border-radius: $border-radius-medium;
        width: 100%;
        overflow-x: auto;
        > div{
            min-width: 1174px;
        }

        .ant-col {
            .ant-row {
                padding: 20px;

                &:first-child {
                    padding-top: 30px;
                }
            }

            .partner-divider {
                padding: 0px 20px;
                .ant-divider.ant-divider-horizontal {
                    border-top: 1px solid #D8DDE1;
                    margin: 0px;
                }
            }

            .ant-space.actions {
                display: flex;
                justify-content: flex-end;
            }

            &:last-child {
                .partner-divider {
                    display: none;
                }
            }

            .ant-typography.label {
                font-family: 'Poppins-Medium';
            }

            .info-section {
                .name {
                    .ant-typography {
                        font-size: 12px;
                    }
                }

                .content {
                    font-size: 12px;
                    color: $global-color-9;
                }
            }
        }
    }

    .partner-list-pagination {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        padding-top: 30px;
    }
}

@media (max-width:991px){
    .my-partner-page {
        padding: 20px;
    }
}

.add-vendor {
    .ant-modal-body {
        padding: 0px;
        .steps-container {
            .ant-steps-item {
                width: 33.33%
            }
            .ant-steps-item-tail {
                margin-left: 49%;
                top: 62px !important;
            }
            .ant-steps-item-title {
                font-family: "Poppins-Regular";
            }

            .ant-steps-item-finish > .ant-steps-item-container >
            .ant-steps-item-icon {
                background-color: $global-color-12 !important;
            }

            .ant-steps-item-finish > .ant-steps-item-container >
            .ant-steps-item-tail::after {
                background-color: $global-color-12 !important;   
            }

            .ant-steps-item-finish > .ant-steps-item-container > .ant-steps-item-content >
            .ant-steps-item-title {
                color: $global-color-12 !important;
            }
        }

        .ant-divider.ant-divider-horizontal.ant-divider-dashed {
            border-top: 1px dashed $global-color-2;
        }

        .ant-form {
            padding: 0px 35px 30px 35px;

            &.padding-top {
                padding: 30px 35px;
            }

            .ant-typography.step-title {
                font-size: 18px;
                color: $global-color-9;
            }

            .step-subtitle {
                font-size: 12px;
                color: #BCC7D9;
                margin-bottom: 30px;
                margin-top: 5px;

                .agency-name {
                    color: $global-color-4;
                    font-size: 14px;
                }
            }

            .ant-input-affix-wrapper {
                border: none !important;
                box-shadow: none !important;
            }

            .phone-input {
                .ant-form-item-control-input {
                    border: 2px solid #D0D9E6;
                    border-radius: 5px;
                }
            }

            .agency-account {
                .ant-select {
                    height: 42px !important;
                    box-shadow: none !important;
                    .ant-form-item-feedback-icon {
                        display: none;
                    }
    
                    svg {
                        width: 16px;
                        height: 11px;
                        path {
                            fill: #495274;
                        }
                    }
                    .ant-select-arrow {
                        top: 55%;
                    }
             
                    .ant-select-selection-item {
                        padding-right: 56px !important;
                        color: #495274 !important;
                        img {
                            margin-right: 10px;
                        }
                    }
                }

                .hidden-inputs {
                    &.change-visibility {
                        opacity: 0.5;
                    }
                }
            }
        
            .ant-form-item-feedback-icon-success {
                svg 
                > path {
                    fill: #2FE479;
                }
            }

            .ant-form-item-feedback-icon-error {
                svg > path {
                    fill: #FC7886;
                }
            }

            .ant-radio-group {
                margin-top: 0px;
                display: flex;
                margin-left: 10px
            }

            .ant-radio-button-wrapper {
                height: 35px;
                width: 34px;
                border-radius: 5px !important;
                border: none;
                background: #DDE4EB;
                display: flex;
                justify-content: center;
                align-items: center;
                margin: 7.5px 5px;
            }
            .ant-btn.save-vendor-btn {
                background-color: $global-color-7;
                color: $color-white;
    
                &::after {
                    content: none;
                }
    
                svg {
                    margin-left: 20px;
                }
            }
        }
    }
}
