#add-accommodation-page {
    @import './Details/style';
    @import './Rooms/style';
    @import './SeasonalPrices/style';
    @import './Steps/style';

    .ant-picker-clear{
        right: 5px;
    }

    .left-col, .right-col {
        padding: 25px 0px;
        background-color: $global-color-6;
        border-radius: 5px;
        border: 1px solid #E7EBEF;
    }
    @media screen and (max-width: 991px) {
        .right-col {
            background-color: #EDEFF2;
            margin-top: 30px;
        }
    }
    @media screen and (max-width: 399px) {
        .create-room-form-switch {
         label{
            font-size: 12px;
         }
        }
    }
    .right-col-container{
        >.ant-row-end{
            >div{
                &:first-child{
                    @include d-flex;
                }
            }
        }
    }

    .ant-form-item{
        .ant-typography{
        color: $global-color-2;
        line-height: 21px;

        }
        .ant-rate{
            svg{
                width: 30px;
                height: 30px;
            }

        }
        .ant-rate-star:not(:last-child) {
            margin-right: 15px;
        }
    }
    .ant-input-affix-wrapper{
        border: unset !important;
    }
    .images-upload_text{
        font-family: "Poppins-Medium" !important;
        font-size: 16px;
        color: $global-color-9 !important;
        line-height: 25px !important;
        letter-spacing: 0.16px;
    }
   

    .ant-checkbox-wrapper{
        .select-all{
            font-size: 16px;
            letter-spacing: 0.16px;
            font-family: "Poppins-Regular";
        }
        span{
            font-family: "Montserrat-Regular";
            color: $global-color-4;
            letter-spacing: -0.14px;
            line-height: 21px;
        }
    
    }

}

