.flight-card {
    .ant-card-head {
        background: $global-color-11;
        .ant-card-head-title {
            color: $global-color-7;
        }
    }
    .ant-timeline{
        .ant-checkbox-inner::after{
            transform: translate(-50%, -50%) scale(0.7)!important;
        }
    }

    .ant-card-body {
        background: $global-color-8;

        .ant-timeline-item-head-blue {
            border-color: $global-color-7;
            background: $global-color-7;
        }
        .timeline-title-1 {
            color: $global-color-7;
            font-size: 12px;
            letter-spacing: 0.12px;
        }

        .ant-timeline-item-head-red {
            color: $global-color-3;
            background: transparent;
            top: -1.5px !important;
        }
        .timeline-title-2 {
            color: $global-color-3;
            font-size: 12px;
            letter-spacing: 0.12px;
        }

        .ant-timeline-item-head-green {
            border-color: $global-color-12;
            background: $global-color-12;
        }
        .timeline-title-3 {
            color: $global-color-12;
            font-size: 12px;
            line-height: 18px;
        }

        .arrival {
            background: $global-color-11;
            border-radius: $border-radius-small;
            padding: 17px 30px;
            > div{
                gap: 125px !important;
                .ant-space-item:last-child{
                  position: absolute;
                  right: 30px;
                  bottom: 8px;
                  svg{
                    width: 32px;
                    height: 74px;
                  }
                }
            }
            .info-section{
                margin-right: 70px;
                margin-left: 10px;
                .name{
                    span{
                        font-family: "Poppins-Medium";
                        color: #4D677E !important;
                    }
                }
                .content{
                    span{
                        font-size: 12px;
                        color: #A6B0BB;
                    }
                }
            }
            .ant-checkbox {

                &:not(.ant-checkbox-checked){
                    .ant-checkbox-inner{
                        background-color: #fff !important;
                        box-shadow: 0px 2px 3px rgba(#000 , 0.08) !important;
                                      }
                }

            }
        }
    }
}
