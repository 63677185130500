.reservation-list {
    margin-top: 30px;
    .ant-card-bordered{
        border-width: 1px;
    }
    .ant-avatar {
        width: 305px;
        height: 100%;
    }

    .ant-divider-vertical {
        height: 100%;
        min-height: 200px;
    }
    .reservation-list_right-col{
        >div:nth-child(1){
            .ant-select-selection-placeholder{
               line-height: 21px !important;
               letter-spacing: -0.14px;
            }
        }
        .ant-btn[disabled]{
            background: none;
        }
        .reservation-info {
            font-size: 11px;
        }
        .ant-select{
            border:2px solid #DDE4EB;
            box-shadow: none !important;
        }
        .ant-dropdown-trigger{
            padding: 0px !important;
            
            svg{
                margin-left: 10px;
            }
            &::after{
                display: none;
            }
        }
        > div:nth-child(2), div:nth-child(3){
            >div{
                .increment-number-input{
                    button,span{
                        @include d-flex;
                        justify-content: center;
                        align-items: center;
                        width: 35px;
                        height: 35px;
                        border-radius: 50%;
                        padding: 0;
                        svg{
                            height: 10px;
                            width: 10px;
                            path,line{
                                fill: #BBC6D1;
                                stroke: #BBC6D1;
                            }
                        }
                    }
                    span{
                        margin-left: 5px;
                        margin-right: 5px;
                    }
                }
            }
        }
        .no-rooms {
            margin-top: 10px;
        }
    }
    .ant-card-bordered.promotion-card-dashed {
        width: 520px;
        border: 2px dashed $global-color-11;
    }
    .ant-card-meta-detail{
        padding-left: 14px;
        .ant-card-meta-title{
            font-family: "Poppins-Medium";
            font-size: 16px;
            line-height: 35px;
            margin-bottom: 0;
            color: #4D677E;
        }
        .block-description{
            margin-bottom: 9px;
            .label{
                font-family: "Poppins-Regular";
                line-height: 20px;
            }
            .content{
                font-family: "Poppins-Regular";
                line-height: 20px;
                color: $global-color-2;
            }
            svg{
                height: 15px;
                width: 15px;
            }
        }
        .room{
            >div:not(:last-child,.reservation-list-divider){
                margin-top: 25.7px;
            }
            >div:first-child{
                @include d-flex;
                flex-direction: column;
                >.ant-space{
                    row-gap: 16.9px !important;
                    align-items: flex-start;
                    svg{
                        vertical-align: -webkit-baseline-middle;
                    }
                }
            }
            >div:nth-child(2){
                >div > div:nth-child(2){
                    .ant-row{
                        .title-amenitites{
                            color: $global-color-9;  
                            line-height: 35px;
                        }
                        >div:nth-child(2){
                            margin-top: 10px;
                        }

                     >div > .ant-space >div{
                        &:first-child{
                            @include d-flex;
                            svg{
                                width: 8px;
                                height: 6px;
                                path{
                                    stroke: #BBC6D1;
                                    stroke-width: 1.5px;
                                }
                            }
                        }
                        &:last-child{
                            font-size: 12px;
                            line-height: 25px !important;
                            color: #BBC6D1;
                        }
                     }     
                          button{
                            padding: 0;
                            height: auto;
                            margin-top: 9.6px;
                                  u{
                            color:$global-color-7 ;
                             font-family: "Poppins-Regular";
                            font-size: 12px;
                               }
                               &:hover{
                                background: transparent;
                               }
                            }        
                    }
                }

            }
        }
        .ant-checkbox-wrapper{
            .ant-checkbox{
                margin-right: 5px;
            }
            span{
                font-family: "Poppins-Regular"; 
                color: $global-color-4;
                line-height: 16px;
                letter-spacing: 0.14px;
            }
            &:not(.ant-checkbox-wrapper-checked){
                .ant-checkbox-inner{
                    background-color: #E7EBEF !important;
                }
            }
        }
    
    }
    .amenities{
        margin-top: 8px;
    }
    .extra-services {
        margin-top: 10px;

        .ant-checkbox-inner {
            width: 25px !important;
            height: 25px !important;
        }
    }
    .promotion-card-dashed{
        .ant-card-meta{
            @include d-flex;
            align-items: center;
        }
        .ant-avatar {
            &.early-booking {
                background: rgba(#089ECB , 0.2);

                .ant-avatar-string {
                    color: #089ECB;
                }
            }

            &.last-minute {
                background: rgba($global-color-12, 0.2);

                .ant-avatar-string {
                    color: $global-color-12;
                }
            }

            &.custom-offer {
                background: rgba($global-color-3, 0.2);

                .ant-avatar-string {
                    color: $global-color-3;
                }
            }

            &.free-night {
                background: rgba(#13258D, 0.2);

                .ant-avatar-string {
                    color: #13258D;;
                }
            }
        }
        .ant-avatar-string {
            font-family: "Poppins-SemiBold";
            font-size: 20px;
        }
        .ant-card-meta-description{
            font-family: "Poppins-SemiBold";
            font-size: 16px;
            color: $global-color-17;
            .offer-name {
                &.early-booking {
                    color: #089ECB;
                }

                &.last-minute {
                    color: $global-color-12;
                }

                &.custom-offer {
                    color: $global-color-3;
                }

                &.free-night {
                    color: #13258D;
                }
            }
            .description{
                margin-top: 5px;
                font-family: "Poppins-Regular";
                line-height: 16px;
                .content{
                    color: $global-color-4;
                }
            }
        }
  

    }
    .room_price{
        font-family: "Poppins-Medium";
        font-size: 16px;
        line-height: 35px;
        color: $global-color-3;
    }
    .tour-item_amenities{
        .ant-space-item{
        font-size: 12px;
        line-height: 25px;
        color: #BBC6D1;
        }
        svg{
            width: 9px;
            height: 6px;
            margin-right: 5px;
        }
    }
}
.ant-notification{
    padding: 0 !important;

    .selected-cabin{
        font-family: "Poppins-Medium";
        font-size: 16px;
        color: #536DFE;
        line-height: 35px;
    }
    .ant-divider-dashed{
        border-width: 1px 0 0;
    }
    .ant-card-body{
        padding: 10px 0px;
    }
    .ant-card-meta{
        @include d-flex;
        align-items: center;
        .ant-card-meta-detail{
            width: 100%;
        }
    }
    .ant-notification-notice{
        padding: 0 !important;
        box-shadow: 0px 3px 35px 0px rgba(0,0,0,0.15);

    }
    .ant-notification-notice-message{
padding: 24px 31px 0 31px;
margin-bottom: 1px !important;
>div{
    @include d-flex;
    justify-content: space-between;
}
    }
    .ant-notification-notice-description {
        >div{
            padding: 6px 30px 8px 30px;
        }
    }

    .ant-card-meta-title{
        margin-bottom: 0 !important;
        .ant-space{
            @include d-flex;
            justify-content: space-between;
          
            .room-type_name{
                color: $global-color-9; 
                line-height: 35px;
            }
            .room-type_total_price{
              color:$global-color-4;
            }
          
        }
        
    }
    .selected-details{
    color: $global-color-2;
   
    }
    .notification-title{
       
        font-size: 16px;
        line-height: 35px;
        color: $global-color-2;
    }
    .notification-price{
        font-family: "Poppins-Medium";
        font-size: 16px;
        line-height: 35px;
        color: $global-color-3;
    }
    .ant-avatar{
        width: 58px;
        height: 58px;
    }
    button{
        background-color: $global-color-3;
        span{
            color: $color-white;
            font-family: "Poppins-Medium";
            letter-spacing: 0.14px;
            line-height: 21px;
        }
        &:hover{
            background: #FCB151 !important;
        }
    }
}

.amenities-tooltip{
    .ant-tooltip-inner{
        background-color: #FFFFFF !important;
        box-shadow: 0px 3px 6px 0px rgba(0,0,0,0.16) !important;
        border-radius: 5px;
        padding: 26px !important;
        span{
           .ant-space {
            >div{
                &:first-child{
                    svg{
                        width: 8px;
                        height: 6px;
                        path{
                            stroke: #BBC6D1;
                            stroke-width: 1.5px;
                        }
                    }
                }
                &:last-child{
                    font-size: 12px;
                    line-height: 25px;
                    color: #BBC6D1;
                }
            }
            }
        }
    }
    .ant-tooltip-arrow-content{
        --antd-arrow-background-color: #FFFFFF !important;
    }
}
