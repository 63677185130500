.tour-card {

    &.ant-card {
        margin-top: 25px;
        border-radius: 10px;
        border: 1px solid #E7EBEF;
        background-color: #FFFFFF;
    }

    max-width: 282px;
    width: auto;
    height: 404px;
    @include d-flex;
    flex-direction: column;
    justify-content: space-between;


    & ~ .ant-scroll-number.ant-badge-count {
        @include d-flex;
        justify-content: center;
        text-align: center;
        align-items: center;
        width: 50px;
        height: 50px;
        border-radius: 50px;
        border: 3px solid #F4F6F8;
        background-color: #E7EBEF !important;
        font-size: 16px;
        font-family: 'Poppins-SemiBold';
        color: #FCFCFD;
        padding: 0 !important;
        box-shadow: none !important;
        @include transform(translateX(50%));

        &:not([title="0"]){
            background-color: #FCB151 !important;
        }

    }
}
