.right-col {
    .steps-container {
        .ant-steps-item{
            width: 25%;
        }
        .ant-steps-item-tail{
            top: 43px !important;
            margin-left: 50%;
            padding: 3.5px 17px;
        }
        .ant-steps-item-content{
            width: 100%;
        }
        .ant-steps-item-title{
            font-family: "Poppins-Regular";
            line-height: 18px;
            margin-bottom: 15px;
            height: 17px;
        }
    }
    .data-container{
        margin:10px 14px 0 30px;
        padding: 23px 18px; 
        // .ant-card {
        //     width: 635px;
        // }

        .info-section {
            .content {
                .ant-image {
                    .ant-image-img {
                        border-radius: 5px;
                    }
                }
            }
        }
    }

    .data-display {
        @import './Seasons/style';
        height: 465px !important;
        overflow-x: hidden;
        margin-right: 11px;
        >.ant-row{
            >.ant-col{
                &:not(:first-child){
                    .data-container{
margin-top: 30px
                    }
                }
                &:nth-child(3){
                    .data-container{
                        padding: 18.5px 28px;
                        .border-text{
                            margin: -28px 0 0 -6px;
                        }
                    }
                }
            }
        }
        @include scrollbar($_thumb-color);
                .border-text{
                    font-size: 12px;
                    font-family:"Poppins-Regular";
                    color: $global-color-9;
                    line-height: 18px;
                    padding: 0 9px 0 4px;
                    margin: -32px 0 0 -6px;
                }
                .info-section{
                    .name{
                        margin-bottom: 2px;
                        span{
                            font-family: "Poppins-Medium";
                            font-size: 12px;
                            letter-spacing: 0.12px;
                            color: $global-color-2;
                            line-height: 18px;
                        }
                
                    }
                    .content{
                        .ant-typography{
                            font-family: "Poppins-Regular";
                            font-size: 14px;
                            color: $global-color-4;
                            letter-spacing: 0.14px;
                            line-height: 21px;

                        }

                    }
                }
                .services-details{
                    font-size: 12px;
                    >div{
                        &:nth-child(2){
                            margin-top: 12px;
                        }
                    }
                    .ant-typography{
                        font-family: "Poppins-Medium";
                        color: #BBC6D1;
                        letter-spacing: 0.12px;
                    }
                    .ant-col{
                        svg{
                            margin-right: 10px;
                        }
                        .facility-name{
                            font-family: "Poppins-Regular";
                            line-height: 25px;
                            color: #758CA1;
                        }
                    }
                }
           >div{
            >div{
                &:first-child{
                    .data-container{
                        margin-top: 40px;
                        > .ant-space{
                            width: 100%;
                            > div{
                                &:nth-child(4){
                                    margin-bottom: 10px;
                                    .ant-card{
                                        border: none !important;
                                    }
                                    .ant-card-body{
                                        > div{
                                            width: 695px;
                                            height: 199px !important;
                                            > div{
                                                border-radius: 5px;
                                            }
                                        }
                                    }
                                }
                                &:first-child{
                                    .name{
                                        margin-bottom: 5.8px;
                                    }
                                }
                                &:nth-child(5){
                                    .content{
                                        > div:first-child{
                                            margin-bottom: 2px;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
           }
    }

    @media screen and (max-width: 991px) {
        .data-display {
            display: none;
        }
    }
 
}

#hotel-facilities-services-form{
    .ant-checkbox-inner{
        width: 29px !important;
        height: 29px !important;
    }
    .ant-checkbox-wrapper:not(.ant-checkbox-wrapper-checked){
        .ant-checkbox-inner{
         background-color: #E7EBEF !important;
        }
    }
    .facilities{
        > .ant-row{
            margin-bottom: 0;
        }
        .ant-form-item{
            padding: 10px 30px 20px 30px;
        }
        .ant-checkbox + span{
            padding-left: 14px;
        }
        > div{
            &:nth-child(4){
                .check-list{
                    > div{
                        &:first-child{
                            .ant-checkbox + span{
                                padding-left: 10px;
                            } 
                        }
                    }
                }
             
            }
        }

    }

    .create-section{
        padding-left: 30px;
        padding-right: 30px;
        .ant-form-item{
            padding: 0;
            margin-bottom: 0;
        }
    }
    .services{
        margin-top: 51px;
        .input-group-container{
            margin: 37px 30px 5px 30px;
            padding: 40px !important;
            .border-text{
                margin: -51px 0 0 -12px;
            }
        }
    }
 
}


