.details {
    .select-customer, .select-vendor {
        &.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
            
            width: 610px;
        }
    }
    .ant-divider-dashed{
        border-width: 1px 0 0;
    }

    .select-vendor {
        margin-bottom: 3px;
    }

    .info {
        font-size: 12px;
    }
}
