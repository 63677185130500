.billing {
    .bills-table {
        background-color: $color-white;
        border-radius: 10px;

        .ant-form {
            padding: 30px 30px 0px 30px;

            .ant-input, .ant-btn, .ant-picker {
                background-color: $global-color-8;
            }

            .ant-picker {
                box-shadow: none !important;
            }
        }

        .ant-divider {
            margin: 30px 0px 14px 0px;
        }

        .ant-table {
            padding: 0px 30px 30px 30px;
            box-shadow: none;
            border-radius: 10px;
        }

        .ant-table table .ant-table-tbody > tr > td {
            border-bottom: 1px solid #E7EBEF !important;
        }
    }

    .table-action {
        margin-top: 30px;

        .export {
            background-color: $global-color-3;
            border-color: $global-color-3;
            color: $color-white;

            display: flex;
            justify-content: center;
            align-items: center;

            svg {
                margin-left: 20px;
            }
        }
    }
}
