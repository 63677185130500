.tour-details {
    .cabins , .rooms {
        border: 1px dashed $global-color-2;
        border-radius: $border-radius-small;
        padding: 14px 19px;
        h5{
            font-size: 14px;
            color: #758CA1;
            font-weight: normal;
            margin-bottom: 0;
            line-height: 20px;
        }
        .description{
            span{
                font-family: "Poppins-Regular";
                font-size: 12px;
                color: #758CA1;
                line-height: 20px;
            }
        }
    }
    .ant-col{
        &:last-child{
            .ant-row{
                .ant-col{
                    padding-left: 15px;
                    &:first-child{
                        h5{
                            font-size: 14px;
                            color: #4D677E;
                        }
                        .ant-typography{
                            &:not(:first-child){
                             margin-bottom: 6px;
                             font-family: "Poppins-Regular";
                             font-size: 12px;
                             color: #BBC6D1;
                             line-height: 16px;
                            }
                        }
                    }
              
                }
            }
        }
    }
}
