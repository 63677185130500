.integrations-page {
    @import './SearchWidgetType/style';
    @import './SearchWidgetServices/style';
    @import './GeneratedWidget/style';
    @import './SearchBox/style';
    @import './CardWidgetServices/style';
    @import './CardWidgetType/style';

    // font-family: "Poppins";

    .section-title {
        color: $global-color-9 !important;
        line-height: 18px;
        letter-spacing: 0.14px;
    }
    >div{
        &:first-child{
            >div{
                &:first-child{
                    h5{
                        font-family: "Poppins-SemiBold";
                        font-size: 18px;
                        line-height: 16px;
                        color: #4D677E;
                        margin-bottom: 0;
                    }
                }
                &:nth-child(2){
                    span{
                        font-size: 12px;
                        line-height: 16px;
                        color: #A6B0BB;
                    }
                }
                &:last-child{
                    margin: 19px 0 30px 0;
                }
            }
        }
        &:last-child{
            >.ant-row{
                >.ant-col:first-child{
                    >.ant-row{
                        >.ant-col{
                            width: 100%;
                        }
                    }
                }
            }
        }
    
    }
}

////////////RESPONSIVE

@media (max-width:1800px) {

    .integrations-page{
        >div:last-child{
            .widget-options{
                flex-direction: column;
                row-gap: 20px !important;
                >.ant-col{
                    flex: 0 0 100%;
                    max-width: 100%;
                }
            }
        }
    }
}
@media (max-width:1599px){
    .integrations-page{
        .generated-widgets{
            .search-box-wrapper{
                .search-box-form{
                    >.ant-col{
                        &:first-child{
                            >.ant-space{
                                >div{
                                    &:nth-child(3){
                                        padding-right: 40px !important;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
@media (max-width:1399px){
    .integrations-page{
        >div:last-child{
            .list-card{
                .ant-card-meta{
                    align-items: flex-start !important;
                }
                    .ant-card-meta-description{
                        >.ant-row{
                            row-gap: 15px !important;
                            >.ant-col{
                                &:last-child{
                                    width: 100%;
                                    @include d-flex;
                                    justify-content: flex-end;
                                }
                            }
                        }
                    
                }
            }
        }
        .generated-widgets{
            .search-box-wrapper{
                .search-box-form{
                    >.ant-col{
                        &:first-child{
                            flex: 0 0 75%;
                            max-width: 75%;
                            >.ant-space{
                                gap: 10px !important;
                                >div{
                                    &:first-child{
                                        padding-right: 0 !important;
                                    }
                                    &:nth-child(3){
                                        padding-right: 0 !important;
                                    }
                                }
                            }
                        }
                        &:last-child{
                            flex: 0 0 25%;
                            max-width: 25%; 
                        }
                    }
                }
            }
            .data-list-wrapper{
                .grid-card{
                    .description{
                        >.ant-space{
                            flex-direction: column !important;
                        }
                    }
                }
            }
        }
    }
}
@media (max-width:1199px){
    .integrations-page{
        .generated-widgets{
            .search-box-wrapper{
                .search-box-form{
                    flex-direction: column;
                    >.ant-col{
                        flex: 0 0 100% !important;
                        max-width: 100% !important;
                        width: 100%;
                        &:first-child{
                            >.ant-space{
                                gap: 0px !important;
                            }
                        }
                     
                    }
                }
            }
            .data-list-wrapper{
                .grid-card{
                    .description{
                        >.ant-space{
                            flex-direction: row !important;
                        }
                    }
                }
            }
        }
     
    }
}
@media (max-width:991px){
    .integrations-page{
        padding: 20px;
        >div:last-child{
            >.ant-row{
                flex-direction: column;
                row-gap: 20px !important;
                >.ant-col{
                    flex: 0 0 100%;
                    max-width: 100%;
                }
            }
            .list-card{
                .ant-card-meta{
                    align-items: center !important;
                }
                    .ant-card-meta-description{
                        >.ant-row{
                            >.ant-col{
                                &:last-child{
                                    width: auto !important;
                                }
                            }
                        }
                    
                }
            }
        }
        .generated-widgets{
            .search-box-wrapper{
                .search-box-form{
                    flex-direction: row;
                    >.ant-col{
                     
                        width: 100%;
                        &:first-child{
                            flex: 0 0 75% !important;
                            max-width: 75% !important;
                            >.ant-space{
                                gap: 0px !important;
                            }
                        }
                        &:last-child{
                            flex: 0 0 25% !important;
                            max-width: 25% !important; 
                        }
                     
                    }
                }
            }
          
        }
      
    }
}
@media (max-width:767px){
    .integrations-page{
        >div:last-child{
            .list-card{
                .ant-card-meta{
                    align-items: flex-start !important;
                }
                    .ant-card-meta-description{
                        >.ant-row{
                            >.ant-col{
                                &:last-child{
                                    width: 100% !important;
                                    @include d-flex;
                                    justify-content: flex-end;
                                }
                            }
                        }
                    
                }
            }
        }
        

        }
}
@media (max-width:575px){
    .integrations-page{
        .generated-widgets,.widget-options{
            .search-box-wrapper{
                .search-box-form{
                    flex-direction: column !important;
                    >.ant-col{
                  
                        &:first-child{
                            flex: 0 0 100% !important;
                            max-width: 100% !important;
                        }
                        &:last-child{
                            flex: 0 0 100% !important;
                            max-width: 100% !important;
                            width: 100%;
                        }
                     
                    }
                }
            }
        }
    }
}
@media (max-width:399px){
    .integrations-page{
        padding-left: 0;
        padding-right: 0;
    }
}
