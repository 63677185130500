#hotel-seasonal-price-form {

    .date-divider{
        margin: 25px 29.3px;
        &::after{
            content: "";
    display: block;
    width: 21px;
    height: 2px;
    background: #DDE4EB;
        }
    }
        >div{
            &:last-child{
                margin-bottom: 0;
                padding: 29px 29px 4px;
            }
            &:first-child{
padding: 5px 29px 0 29px;
            }
            &:nth-child(2),&:nth-child(3){
                padding: 0 29px;
            }

        }
    .checkbox-block {
        padding: 31px;
        background-color: #F9FBFC;
        .check-list{
            .checkbox-multi-select{
                >div{
                    row-gap: 15px !important;
                }
            }
        }
        .ant-typography{
            color: #758CA1;
            line-height: 21px;
            letter-spacing: 0.14px;
        }
        > div{
            margin-top: 30px;
            .select-all{
                font-family: "Poppins-SemiBold";
                color: #4D677E;
                letter-spacing: 0.14px;
                font-size: 14px;
                line-height: 21px;
            }
            .ant-checkbox + span{
                padding-left: 15px;
                font-family: "Poppins-Regular";
            }
       
        }
    }
}
