.itenerary {
    padding-left: 30px;
    padding-right: 30px;
    padding-top: 11px;
    >div{
        &:first-child{
            @include d-flex;
            justify-content: center;
        }
    }
    .google-map {
        height: 300px;
    }
    .hidden-inputs {
        &.hidden {
            display: none;
        }
        &.show {
            display: block;
        }
    }

    .ant-upload-select{
        svg{
            width: 64px;
            height: 52px;
        }
    }
    .ant-checkbox-wrapper{
        span{
line-height: 21px;
letter-spacing: 0.14px;
        }
    }
    .ant-checkbox-wrapper-checked{
        .ant-checkbox-inner{
            background-color: #536DFE !important;
        }
    }
    .map-location-text{
        font-size: 12px;
        color: #BBC6D1;
    }
    >div{
        &:last-child{
            >div{
                &:first-child{
                    margin-bottom: 16px;
                }
                &:last-child{
                    margin-top: 20px;
                }
             
            }
            .hidden-inputs{
                    
                > .mui-form-item{
                     margin-bottom: 22px;
                }
                >div{
                    &:nth-child(2){
                        >.ant-col{
                            >div{
                                margin-bottom: 17px;
                            }
                        }
                    }
                    &:nth-child(3){
                        margin-bottom: 9px;
                        margin-top: 7px;
                        .ant-form-item-control-input-content{
                            >div{
                                height: 450px !important;
                                max-height: 450px;
                                >div{
                                    border-radius: 5px;
                                }
                            }
                        }
                    }
                }
             
         }
        }
    }
}


////////////RESPONSIVE

@media (max-width:1399px){
    .itenerary{
        flex-direction: column;
        >div{
            flex: 0 0 100% !important;
            max-width: 100% !important;
        }
    }
    }
