.flight-direction {
    background: $global-color-8;
    border-radius: $border-radius-small;
    padding: 11.5px 28px;
    margin-bottom: 30px;
    >div{
        &:first-child,&:last-child{
            >.ant-space{
                gap: 18px !important;
                >.ant-space-item{
                    &:last-child,&:first-child{
                        > div{
                            > div{
                                &:first-child{
                                    font-size: 12px;
                                    font-family: "Poppins-Medium";
                                    line-height: 18px;
                                    color: #BBC6D1;
                                    letter-spacing: -0.12px;
                                }
                              
                            }
                        }
                    }

                }
            }
        }
        &:nth-child(2){
            svg{
                circle{
                    fill: #E7EBEF;
                }
            }
        }
    }
    .ant-select{
        width: 200px !important;
        background-color: transparent !important;
        .ant-select-selection-placeholder, .ant-select-selection-item{
            letter-spacing: -0.14px;
            color: #536DFE !important;
        }
        .ant-select-arrow{
            svg{
                path{
                    fill: #536DFE;
                }
            }
        }
    }

    .destination {
        text-align: right;
    }
}

.switch {
    text-align: center;
    margin-bottom: 30px;
}

.flight-details-form {
    .direction, .return {
        @import'./FlightDirection/style';
    }

    .disabled {
        opacity: 0.3;
    }
    .direction {
        margin-bottom: 40px;
    }
    .flight-card{
        >.ant-card-head{
            padding: 0 42px;
        }
        >.ant-card-body{
            padding: 26px 35px 30px 30px;
            border-radius: 5px;
>.ant-select{
    margin-bottom: 30px;
}
>.prices{
    >.free-carry-on-bag,>.extra-baggage{
        background-color: #E7EBEF;
        border-radius: 5px;
        padding: 17px 30px;
        align-items: center;
        >div{
            &:first-child{
>.ant-form-item{
    margin-bottom: 0;
    .ant-checkbox-wrapper:not(.ant-checkbox-wrapper-checked){
        >.ant-checkbox{
>.ant-checkbox-inner{
    background-color: #FFFFFF !important;
    border: 1px solid #DDE4EB !important;
}
        }
      
    }
    .ant-checkbox + span {
        padding-left: 17px;
        .info-section{
            font-family: "Poppins-Medium";
            .name{
                span{
                    color: #4D677E;
            line-height: 16px;

                }
            }
            .content{
                span{
font-size: 12px;
color: #A6B0BB;
line-height: 16px;

                }
            }
        }
    }
}
            }
            &:last-child{
svg{
    width: 33.49px;
    height: 75.53px;
    position: absolute;
    right: 0;
    bottom: -34px;
}
            }
        }
        .extra-price{
            margin-top: 20px;
        }

    }
    >.free-carry-on-bag{
        margin-bottom: 30px;
    }
}
        }
    }
}
