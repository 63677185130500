.participants {
    .person-card {
        margin-top: 45px;
        border-color: #DDE4EB !important;
        .ant-card-head {
            background: $global-color-11;
            .ant-card-head-title{
                line-height: 21px;
                letter-spacing: 0.14px;
                color: #4D677E;
            }
        }
        .ant-card-body{
     padding: 24px 24px 0 24px;
        }
    }

    .showInfo {
        display: block;
    }

    .prices {
        margin-top: 25px;
        padding: 20px;
        background: $global-color-8;

        .disabled-child-price {
            opacity: 0.3;
        }
    }
}
