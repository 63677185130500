#cruise-itenerary-form {
    @import './IteneraryItem/style';
    .add-form{
        padding-top: 6px;
    }
    >.ant-row{
        margin-bottom: 0;
    }
    .add-button-outlined {
        width: auto;
    }
    .submit-form{
        padding-right: 30px;
        >div:first-child{
            padding-right: 30px;
        }
    }
    .ant-checkbox-inner{
        width: 29px !important;
        height: 29px !important;
    }
    .ant-checkbox + span {
        padding-left: 13px;
    }

    
}
