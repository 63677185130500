@import './InHouseStats/style';

.in-house-stats, .b2b-stats {
    .filters {

        .ant-row {
            .ant-col:nth-child(4) {
                display: grid;

                .compare-button {
                    justify-self: end;
                }
            }
        }

        .compare-button {
            background: $global-color-7;
            color: $color-white;
            font-size: 14px;
            font-weight: 500;
            display: flex;
            justify-content: center;
            align-items: center;
    
            &::after {
                content: none;
            }
    
            svg {
                margin-right: 15px;
            }
        }
    }
    .ant-row.orders {
        .ant-col {
            width: 20%;
        }
    }
    
    .ant-card.total-incoming-card, .ant-card.b2b-incoming-card {
        box-shadow: 0px 15px 25px 0px rgba(0,0,0,0.08);
        border: none !important;
        height: 100%;
        background: $global-color-12;
    
        .ant-card-head {
            padding: 4px 20px 6.5px 21px;
            border-bottom: 1px dashed #E7EBEF;
    
            .ant-card-head-wrapper {
                align-items: flex-start;
    
                .ant-card-extra {
                    width: 32px;
                    text-align: center;
                    height: 32px;
                    display: flex;
                    border-radius: 50%;
                    justify-content: center;
                    align-items: center;
                    margin-top: 16px;
                    background: $color-white;
                }
            
                .ant-card-head-title {
                        font-size: 12px;
                        font-family: 'Poppins-Light';
                        line-height: 18px;
                        letter-spacing: 0.24px;
                        color: $color-white;
                        > div {
                            font-size: 24px;
                            font-family: 'Poppins-Medium';
                            line-height: 35px;
                            letter-spacing: 0.48px;
                            color: $color-white;
                        }
                }
            }
        }
        .ant-card-body {
            padding: 24px 20px;
            @include d-flex;
            align-items: center;
            font-size: 12px;
            color: $color-white;

            .ant-space {
                .ant-divider.ant-divider-vertical {
                    height: 30px;
                    border-left: 1px solid #80EBD0;
                }
            }
    
            .order-costs, .order-earned {
                margin-left: 7.4px;
            }
    
            .order-costs {
                margin-right: 10px;
            }
    
            svg {
    
                #Path_6148, #Path_6149 {
                    stroke: $color-white;
                }
            }
        }
    }

    .ant-table-container {
        > div {
            width: 100%;
            overflow-x: auto;
            > table {
                min-width: 800px;
    
                .ant-checkbox-checked .ant-checkbox-inner {
                    background-color: $global-color-7 !important;
                }
    
                tbody {
                    .textSize14 {
                        font-size: 14px;
                        .ant-typography {
                            color: $global-color-9;
                        }
                    }
                    
                    .textSize12 {
                        font-size: 12px;
                        color: $global-color-2;
                    }
                    
                    .paid {
                        color: $global-color-12;
                    }
                    
                    .unpaid {
                        color: $global-color-13;
                    }
                    
                    .partial {
                        color: $global-color-17;
                    }
                    
                    .canceled {
                        color: $global-color-3;
                    }

                    .draft {
                        color: $global-color-2;
                    }
                }
            }
        }
    }
    
    .ant-table-footer {
        padding: 25px 20px !important;
        background-color: $color-white;
    
        button {
            border: 2px solid $global-color-7 !important;
            border-radius: $border-radius-small;
            color: $global-color-7;
            font-size: 12px;
            font-weight: 500;
    
            &::after {
                content: none;
            }
    
            svg {
                width: 15px;
                height: 15px;
                margin-right: 15px;
                path {
                    fill: $global-color-7;
                }
            }
        }
        .ant-pagination {
            font-size: 14px;
            color: $global-color-2;
            font-weight: 500;
            line-height: 1.15;
            display: flex;
            align-items: center !important;
    
            > li {
                &:not(:last-child){
                    margin-right: 15px !important;
                }
            }
    
            .ant-pagination-prev, .ant-pagination-next {
                min-width: 0;
                height: auto !important;
                line-height: 1;
    
                button {
                    background-color: transparent;
                    border: 0 !important;
    
                    svg {
                        path {
                            fill: $global-color-2;
                        }
                    }
                }
            }
    
            .ant-pagination-item {
                min-width: 0;
                line-height: 1;
                display: flex;
                align-items: center;
                justify-content: center;
                border: 0;
                outline: none;
                box-shadow: none;
                background: transparent;
                a {
                    text-decoration: none;
                    font-size: 14px;
                    color: $global-color-2;
                    font-weight: 500;
                    padding: 0;
                }
    
                &.ant-pagination-item-active {
                    width: 26px !important;
                    height: 26px !important;
                    background-color: $global-color-7;
                    border-radius: 100%;
                    a {
                        color: $color-white;
                    }
                }
            }
        }
    }

    @media only screen and (max-width: 1024px) {
        .ant-row.orders {
            .ant-col {
                width: 50%;
            }
        }
    }

    @media only screen and (max-width: 576px) {
        .ant-row.orders {
            .ant-col {
                width: 100%;
            }
        }
    }
}

@media (max-width:991px){
    .in-house-stats, .b2b-stats {
        padding: 20px;
    }
}
