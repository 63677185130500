.total{
    color: #758CA1;
    line-height: 21px;
    padding-top: 7px;
    padding-bottom: 23.3px;
    > div{
        > div{
            &:first-child,&:nth-child(2){
                margin-bottom: 14.4px;
            }
        }
    }
    .invoice-total{
        color: #4D677E;
        font-family: "Poppins-Medium";
        font-size: 18px;
        line-height: 27px;
        font-weight: unset ;
        margin-bottom: 0;
    }
}