.inclusion {
    font-size: 12px;
    color: $global-color-4;
    // list-style-image: url('/assets/images/icons/mark.svg');
    line-height: 25px;
}

.exclusion {
    font-size: 12px;
    color: $global-color-4;
    line-height: 25px;
}
.incl-excl-row{
    margin-top: 22px;
}
