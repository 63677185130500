#forgot-password-page {
    .left-header-side {
        // max-width: $base-layout-sidebar-width;
        // width: 30%;
        height: 100%;
        @include d-flex;
        align-items: center;
        svg {
            margin-left: 60px;
        }

        .desktop-logo {
            display: block;
        }

        .mobile-logo {
            display: none;
        }
    }
    .right-header-side {
        height: 100%;
        @include d-flex;
        justify-content: flex-end;
        align-items: center;
        background: #ffffff;
        button {
            padding-right: 60px;
        }
    }

    .main-header {
        @include d-flex;
        justify-content: center;
        width: 100%;
        height: 105px;
        > div {
            width: 100%;
            height: 100%;
        }
    }

    .sidebar {
        background: #3B55EB;
        align-items: flex-end;

        .side-bottom-image {
            display: block;
    position: absolute;
    width: 100%;
    max-width: 383px;
    bottom: 0px;
        }
        .sidebar-content{
            @include d-flex;
            justify-content: center;
            align-items: center;
            width: 100%;
            height: 100%;
            max-width: 500px;
        }
    }
    .base-layout-container{
        margin-top: 0 !important;
    }
    .main-content{
        flex-direction: column;
        justify-content: unset;
        align-items: unset;
        >div:first-child{
            padding: 60px;
        }
    }

    .forgot-password-form {
     width: 100%;
     margin: 0 auto;
     max-width: 100%;
     height: 100%;
     @include d-flex;
     justify-content: center;
     align-items: center;
     form{
        max-width: 445px;
        width: 445px;
        > div{
            &:nth-child(4){
                margin-bottom: 26px;
            }
        }
    }
    h1{
        font-family: "Poppins-Light";
        font-size: 24px;
        color: #1A223F;
        line-height: 35px;
    }

        .dont-have-account, .back-to-login {
            text-align: center;
            line-height: 21px;
            letter-spacing: -0.14px;
        }

        .back-to-login {
            margin-top: 20px;
        }
    }

    
    @media screen and (max-width: 699px) {
        .sidebar {
            display: none;
            .side-bottom-image {
                display: none;
            }
        }

        .left-header-side {
            background: #ffffff;

            .desktop-logo {
                display: none;
            }
    
            .mobile-logo {
                display: block;
            }

            svg {
                width: 50px;
            }
        }
    }
    @media screen and (max-width: 499px) {
     .forgot-password-form>form{
        width: 385px;
        max-width: 385px;
     }
    }
    @media screen and (max-width: 399px) {
        .forgot-password-form>form{
           width: 290px;
           max-width: 290px;
        }
       }
}
