@import './ShipItem/style';

#cruise-itenerary-form{
    >div{
        &:nth-child(2){
            >div>div>div>div>div{
                &:last-child{
                    display: flex;
                    justify-content: flex-end;
                    padding-right: 30px;
                }
            }
        }
        &:last-child{
            >div>div>div>div{
                &:last-child{
                    display: flex;
                    justify-content: flex-end;
                    padding-right: 30px;
                }
            }
        }

    }

    .add-ship-name{
        padding-left: 30px;
        padding-right: 30px;
        padding-top: 6px;
        .ant-row{
            margin-bottom: 5px;
        }
    }
    .ship-item-container{
        .input-group-container{
          padding: 30px;
            >.ant-row{
                >div{
                    &:last-child{
                        >div{
                            margin-bottom: 0;
                        }
                    }
                    &:nth-child(6){
                        .ant-form-item-control-input{
                            min-height: 100%;
                            .ant-checkbox-inner{
                                width: 25px !important;
                                height: 25px !important;
                            }
                        }
                    }
                    .ant-upload-select-picture-card{
                        margin-top: -16px;
                    }
                    .ant-upload{
                        >.ant-row{
                            >.ant-col{
                                &:nth-child(2){
                                    margin-bottom: 10.7px;
                                }
                                &:last-child{
                                    margin-top: 13.2px;
                                }
                            }
                        }
                    }
                }
            }
        }
        .border-text{
            font-family: "Poppins-Regular";
            font-size: 14px;
            color: #BBC6D1;
            line-height: 21px;
            letter-spacing: -0.14px;
            margin: -42px 0 0 -4px;
        }
        .upload-info-text_container{
            font-family: "Montserrat-Regular";
            font-size: 10px;
            color: #BBC6D1;
            margin-top: 0;
            line-height: 19px;
        }
        .ant-row{
            margin-left: 0;
            margin-right: 0;
        }
        .ant-checkbox-wrapper{
            .ant-checkbox + span{
                padding-left: 8px;
                font-family: "Poppins-Light";
                line-height: 21px;
                letter-spacing: 0.14px;
            }
        }
        .ant-checkbox-wrapper-checked{
            .ant-checkbox-inner{
                background-color: #536DFE !important;
            }
        }
        .amenities-title{
            font-family: "Poppins-Medium";
            color: #758CA1;
            letter-spacing: -0.14px;
        }
        
    }
 

}
