#view-accommodation-page,
#view-tour-package-page,
#view-tour-cruise-page {
    @import "./AccommodationDetails/style";

    .ant-radio-button-wrapper {
        height: 40px;
        line-height: 40px;
        border: none;
        margin: 0px;
    }

    .ant-card-body {
        padding: 0px;
        .ant-card-meta {
            @include d-flex;
            margin: 0px;
            .ant-card-meta-avatar {
                min-height: 100%;
                padding: 20px;
            }
            .ant-card-meta-detail {
                padding: 20px 20px 20px 10px;
                width: 100%;
                // because the dropdown is trying to pop inside that container !!! Attention
                overflow: visible;
                .reservation-list-divider {
                    @include d-flex;
                    align-items: center;
                    justify-content: center;
                    .ant-divider {
                        position: absolute;
                        min-height: 460px;
                        top: -55px !important;
                    }
                }
            }
            .promotion-card-dashed {
                .ant-card-body {
                    padding: 26px 29px;
                    .ant-card-meta-detail {
                        padding: 0px;
                    }
                    .ant-card-meta-avatar {
                        padding: 0 16px 0 0;
                    }
                }
            }
        }
    }
    .right-col {
        height: 100%;
        border-radius: 5px;
        .ant-image {
            max-height: 775px;
            height: 100%;
            width: 100%;
            border-radius: 5px;
            border: 1px solid #dde4eb;
        }
    }
}
.ant-modal-header {
    padding: 25px 30px !important;
    .ant-modal-title {
        color: $global-color-9;
        font-family: "Poppins-SemiBold";
        font-size: 18px;
    }
}
.add-new-offer {
    .ant-modal-body {
        .ant-input-affix-wrapper {
            padding: 0 3px 0 21px !important;
            border: none;
        }
        span {
            color: $global-color-4;
        }
        .ant-radio-group {
            @include d-flex;
            margin-top: 14px;
            justify-content: space-between;
        }
        .ant-radio-button-wrapper {
            @include d-flex;
            justify-content: center;
            align-items: center;
            span {
                @include d-flex;
                justify-content: center;
                align-items: center;
                flex-direction: column;
            }
            .center-icon {
                width: 50px;
                height: 50px;
                border-radius: 50%;
                background: $global-color-8;
                @include d-flex;
                justify-content: center;
                align-items: center;
                svg {
                    width: 20px;
                    height: 20px;
                    path {
                        fill: $global-color-2;
                    }
                }
            }
            .center-text {
                color: $global-color-2;
                letter-spacing: 0.14px;
                line-height: 21px;
                margin-top: 16px;
                text-align: center;
            }
        }

        .check-list {
            margin-top: 24px;
            .select-all {
                font-family: "Poppins-SemiBold";
                color: $global-color-9;
                letter-spacing: 0.14px;
                margin-left: 7px;
                font-size: 14px;
                line-height: 21px;
            }
            #room_types {
                .ant-checkbox {
                    margin-right: 8px;
                }
            }
        }
    }
    .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):active,
    .ant-radio-button-wrapper-checked:not(
            [class*=" ant-radio-button-wrapper-disabled"]
        ).ant-radio-button-wrapper:first-child,
    .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
        border-color: $global-color-3;
        &:hover {
            border-color: $global-color-3;
        }
        .center-icon {
            background: rgba($global-color-3, 0.2);
            svg {
                path {
                    fill: $global-color-3;
                }
                circle {
                    fill: $global-color-3;
                }
            }
        }
        .center-text {
            color: $global-color-3;
        }
    }

    .ant-form-item-label > label {
        height: unset;
    }
    .ant-modal-footer {
        padding: 24px 0px;
        margin: 6px 35px 13px 35px;
        .ant-btn {
            width: 203px;
            height: 50px;
            background: $global-color-7;
            span {
                font-family: "Poppins-Medium";
                letter-spacing: 0.14px;
            }
        }
    }
    .ant-modal-header {
        .ant-modal-title {
            line-height: 16px;
        }
    }
    #add_new_offer {
        > span {
            &:first-of-type {
                line-height: 16px;
            }
            &:last-of-type {
                line-height: 21px;
                letter-spacing: 0.14px;
            }
        }
        .ant-radio-group.ant-radio-group-outline.ant-radio-group-large .ant-radio-button-wrapper {
            margin: 0px;
        }
        .ant-picker-suffix > svg {
            width: 24px;
            height: 24px;
            > path,
            circle {
                fill: #dde4eb;
            }
        }
        > div:last-child {
            margin-bottom: 0;
        }
        .checkbox-multi-select {
            > div {
                row-gap: 15px !important;
            }
            .ant-checkbox + span {
                line-height: 21px;
                letter-spacing: 0.14px;
            }
        }
        .add-offer-btn {
            @include d-flex;
            align-items: center;
            background: #536dfe;
            span {
                color: #ffffff;
                font-family: "Poppins-Medium";
                line-height: 21px;
                letter-spacing: 0.14px;
                padding: 15px 83px;
            }
        }
    }
}

#view-accommodation-page {
    .cruise-slider {
        width: 200px;
    }
    .view-content {
        .ant-divider {
            border-width: 1px;
        }
    }
}

/////////////////////////////////////////
@media (max-width: 1299px) {
    #view-accommodation-page {
        .reservation-list {
            .ant-card-meta-description {
                > .room {
                    > div {
                        &:nth-child(2) {
                            flex: 0 0 80% !important;
                            max-width: 80% !important;
                            padding-left: 30px;
                        }
                        &:last-child {
                            flex: 0 0 100% !important;
                            max-width: 100% !important;
                            margin-top: 30px;
                        }
                    }
                    > .reservation-list-divider {
                        display: none !important;
                    }
                }
            }
        }
    }
}

@media (max-width: 1299px) {
    #view-accommodation-page {
        .left-col {
            > div {
                > div {
                    &:first-child {
                        > div {
                            &:first-child {
                                > div {
                                    > div {
                                        &:last-child {
                                            margin-top: 20px;
                                            width: 100%;
                                            > div {
                                                > div {
                                                    &:last-child {
                                                        right: 0;
                                                        top: -60px;
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
        .reservation-list {
            .ant-card-bordered {
                .ant-card-meta:not(.promotion-card-dashed .ant-card-meta) {
                    flex-direction: column;
                }
            }
        }
    }
}
@media (max-width: 1199px) {
    #view-accommodation-page {
        .left-col {
            > div {
                > div {
                    &:last-child {
                        > .ant-col {
                            &:first-child {
                                > .time {
                                    > div {
                                        row-gap: 15px !important;
                                    }
                                }
                            }
                        }
                    }
                    &:first-child {
                        > div {
                            &:first-child {
                                > div {
                                    > div {
                                        &:last-child {
                                            > div {
                                                > div {
                                                    &:nth-child(2) {
                                                        button {
                                                            margin: 0 !important;
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
@media (max-width: 991px) {
    #view-accommodation-page {
        padding: 20px 0;
        > div {
            &:first-child,
            &:last-child {
                padding-left: 15px;
                padding-right: 15px;
            }
        }
        .left-col {
            > div {
                > div {
                    &:last-child {
                        > div {
                            &:nth-child(2) {
                                display: none;
                            }
                            &:last-child {
                                margin-top: 20px;
                                padding-left: 0 !important;
                            }
                        }
                    }
                }
            }
        }

        .reservation-list {
            .room {
                > div {
                    &:nth-child(2) {
                        > div > div {
                            &:nth-child(2) {
                                > div {
                                    flex-direction: column;
                                }
                            }
                        }
                    }
                    &:last-child {
                        > div {
                            flex-direction: column;
                        }
                    }
                }
            }
        }
    }

    .add-new-offer {
        .ant-modal-close {
            top: 17px;
            right: 17px;
        }
    }
}
@media (max-width: 767px) {
    .existing_promotions {
        .promotion-card {
            .ant-card-meta-description {
                > div {
                    flex-flow: nowrap;
                }
            }
        }
    }
    .add-new-offer {
        #add_new_offer {
            > div {
                &:nth-child(2) {
                    .offer-options {
                        flex-wrap: wrap;
                        justify-content: center;
                        > label {
                            margin: 5px !important;
                        }
                    }
                }
            }
        }
    }
}
@media (max-width: 575px) {
    #view-accommodation-page {
        .left-col {
            > div {
                > div {
                    &:nth-child(3) {
                        > div {
                            &:last-child {
                                > div {
                                    row-gap: 15px !important;
                                }
                            }
                        }
                    }
                    &:first-child {
                        > div:first-child {
                            .add-button-outlined {
                                padding: 10px 33px;
                            }
                            .price_data {
                                text-align: start;
                            }
                            > div > div:last-child {
                                > div {
                                    row-gap: 10px !important;
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    .add-new-offer {
        #add_new_offer {
            > div {
                &:nth-child(2) {
                    .offer-options {
                        > label {
                            &:nth-last-child(-n + 2) {
                                margin-top: 3px;
                            }
                        }
                    }
                }
            }
        }
    }
    .ant-picker-dropdown {
        .ant-picker-body {
            tbody {
                > tr {
                    > td {
                        padding: 3px 5px !important;
                    }
                }
            }
        }
    }
    .existing_promotions {
        .promotion-card {
            > div {
                > div {
                    flex-direction: column;
                    > .ant-card-meta-avatar {
                        margin-bottom: 15px;
                        padding-right: 0;
                    }
                    > .ant-card-meta-detail {
                        > div > div > div {
                            &:nth-child(2) {
                                > div {
                                    margin: 0px 20px;
                                }
                            }
                            &:last-child {
                                padding-left: 0;
                            }
                        }
                    }
                }
            }
        }
    }
}
@media (max-width: 399px) {
    #view-accommodation-page {
        > div {
            &:first-child {
                > div {
                    &:first-child {
                        > div {
                            margin-left: 0 !important;
                            > div {
                                &:last-child {
                                    padding-right: 0 !important;
                                }
                            }
                        }
                    }
                    &:last-child {
                        .ant-segmented-item-label {
                            white-space: normal;
                            height: 100%;
                        }
                    }
                }
            }
            .left-col {
                > div {
                    > div {
                        &:first-child {
                            > div {
                                &:first-child {
                                    > div {
                                        > div {
                                            &:last-child {
                                                > div {
                                                    > div {
                                                        &:nth-child(2) {
                                                            width: 100%;
                                                            button {
                                                                width: 100%;
                                                                margin: 0 !important;
                                                            }
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    .add-new-offer {
        #add_new_offer {
            > div {
                &:nth-child(2) {
                    .offer-options {
                        justify-content: center;
                        > label {
                            margin: 5px !important;
                        }
                    }
                }
            }
        }
    }
    .existing_promotions {
        .promotion-card {
            > div {
                > div {
                    > .ant-card-meta-detail {
                        > div > div {
                            flex-direction: column;
                            > div {
                                flex: 0 0 100%;
                                max-width: 100%;
                            }
                        }
                    }
                }
            }
        }
    }
}
