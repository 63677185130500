.generated-widgets {
    background: $color-white;
    border: 1px solid #E7EBEF;
    border-radius: $border-radius-small;
    padding: 35px 31px 35px 30px;
    margin-top: 9px;
    >.search-box-s2{
            margin-bottom: 25px;
            .search-box-wrapper{
                padding: 19px 9px 11px 10px;
                margin-top: 5.6px;
            }
            .search-box-title{
                line-height: 18px;
                color: #BBC6D1;
                letter-spacing: 0.12px;
            }
    }

    .title-1, .title-2, .title-3, .title-4, .title-5 {
        .ant-typography {
            color: $global-color-4;
            line-height: 18px;
            letter-spacing: 0.14px;
        }
        #section-number {
                display: block;
                width: 30px;
                height: 30px;
                background: $global-color-7;
                border: 4px solid #FFFFFF;
                border-radius: 50%;
                color: $color-white;
                text-align: center;
                line-height: 14px;
                @include d-flex;
                align-items: center;
                justify-content: center;
                font-family: "Poppins-Medium";
        }
    }

    .title-1{
        margin-bottom: 18.6px;
    }

    .title-2{
        margin-bottom: 24.1px;
        margin-top: 24.1px;
    }
    
    .title-3, .title-4, .title-5 {
        margin-bottom: 24.1px;
    }

    .info-text {
        font-size: 12px;
        line-height: 18px;
        color: #BBC6D1;
        letter-spacing: 0.12px;
        margin-bottom: 24.1px;
        float: right;
        font-family: "Poppins-Italic";
    }

    .search-box-title {
        font-size: 12px;
    }

    .ant-input:not(#domain) {
        border: 2px solid #DDE4EB !important;
        padding: 22.5px 25px 29.5px 25px;
        line-height: 18px;
        color: #BBC6D1;
        font-size: 12px;
        margin-bottom: 24.1px;
    }

    .result-widget {
        margin-bottom: 10px !important;
    }

    .search-box-wrapper{
        >.ant-space{
            >.ant-space-item{
                &:nth-child(2){
                    padding-left: 1px !important;
                    padding-right: 0 !important;
                }
            }
        }
        .search-box-form{
            >.ant-col{
                opacity: 1;
                &:first-child{
                    >.ant-space{
                        >div{
                            &:first-child{
                                padding-right: 64px;
                            }
                            &:nth-child(3){
                                padding-right: 144px;
                            }
                        }
                    }
                }
            }
        }
    }

    #domain {
        border: 2px solid #DDE4EB !important;
    }

    .add-domain-btn {
        background-color: $global-color-7;
        color: $color-white;
        font-size: 14px;
        font-family: 'Poppins-Medium';
    }

    .domain-list {
        .ant-row:not(:last-child) {
            margin-bottom: 15px;
        }
    }
}
