.auth-layout,
#main-wrapper {
    min-height: 100%;
}

.auth-layout {
    // padding-top: $topbar-height + $auth-layout-offset + 44px;
    // padding-bottom: 91px;
    padding-left: $auth-layout-offset;
    // some new design flow
    height: 100vh;
    margin: 0 auto;
    width: 100%;
    overflow: hidden;
    padding: 0;
    max-height: 100vh;
    min-height: 100vh;
    .site-layout {
        // margin-left: $sidebar-width + $auth-layout-content-offset;
        // margin-right: $auth-layout-content-offset;
        position: relative;
        right: -(($sidebar-width + $auth-layout-content-offset) -
                    ($sidebar-width-collapsed + $auth-layout-content-offset));
        transition: right 0.25s ease !important;
        margin-left: $sidebar-width-collapsed + 15px; // sidebar space from left // + $auth-layout-content-offset;
        &.sidebar-collapsed {
            // margin-left: $sidebar-width-collapsed + $auth-layout-content-offset;
            right: 0;
        }
    }

    .ant-layout-content {
        // padding-top: $topbar-height + $auth-layout-offset + 44px;
        padding-bottom: 30px;
        padding-top: 30px;
        // padding-left: $auth-layout-offset;
        overflow-y: auto;
        padding-left: $auth-layout-content-offset;
        // padding-right: $auth-layout-content-offset;
    }
}
