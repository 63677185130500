.activity-general-data {
    .price-section {
        .price {
            color: $global-color-3;
        }

        .ant-btn.ant-btn-default {
            svg {
                width: 18px;
                height: 18px;
                margin-right: 10px;
                path {
                    stroke: $global-color-3;
                    fill: none;
                }
            }
        }
    }
}
