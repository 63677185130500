.tour-itenerary-list { 
    border-width: 1px;
    height: 100%;
    .ant-card-body{
        padding: 37px 29px 36px 28px;
    }
    .tour-itenerary-item:not(:last-child){
        margin-bottom: 15px;
    }
    
    .tour-itenerary-item {
        background-color: $global-color-8;
        border-radius: 5px;
        padding: 15px;
        .tour-itenerary-content {
            border-radius: 5px;
            >div{
                &:nth-child(3){
                    margin-top: 7.5px;
                }
                &:nth-child(4){
                    margin-top: 5.9px;
                }
            }
            .main-details {
                margin-top: 15.3px;
                svg{
                    width: 12.64px;
                    height: 15.08px;
                }
            }
            .itenerary-address ,.content, .label {
                font-size: 14px;
                color:#758CA1;
                font-family: "Poppins-Regular";
                line-height: 21px;
                letter-spacing: -0.14px
            }
            .description{
                @include d-flex;
                align-items: center;
                .icon{
                    width: 23px;
                    height: 23px;
                    @include d-flex;
                    justify-content: center;
                    align-items: center;
                    margin-right: 0;
                    svg{
                        width: 14.77px;
                        height: 14.77px;
                        path , circle{
                            fill: #536DFE;
                        }
                    }
                }
                .label{
                    margin-left: 10px;
                }
            }

            .ant-divider-horizontal {
                margin: 10px 0;
                border-color: $global-color-11;
            }
        }
    }
}
