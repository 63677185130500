.person {
    margin-bottom: 15px;
    .contact-name{
        font-family: "Poppins-Regular";
        color: $global-color-4;
        line-height: 21px;
        letter-spacing: -0.14px;
    }
    .contact-position{
        font-family: "Poppins-Light";
        font-size: 12px;
        color: $global-color-2;
        line-height: 18px;
        letter-spacing: -0.12px;
    }
 
}
.contact-info{
        span{
            color: $global-color-2;
            line-height: 25px;
        }

}
