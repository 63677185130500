.sell-product-info {
    border-radius: 10px;
    padding: 20px 20px 0px 5px;
    margin-bottom: 20px;

    &.base-color {
        background: linear-gradient(to left, $global-color-3, $global-color-12);
    }

    &.pending-color {
        background-color: #FFFAF3;

        svg {
            #Path_6890, #Path_6891 {
                fill: #FFEDD5;
            }
        }
    }

    .ant-col:nth-child(2) {
        .ant-space {
            padding-bottom: 20px;
        }
    }

    .ant-typography {
        div:first-child {
            font-family: 'Poppins-Medium';
            font-size: 14px;
        }
        div:nth-child(2) {
            font-family: 'Poppins-LightItalic';
            font-size: 12px;
        }
    }
    .ant-typography.base-color {
        color: $color-white;
    }

    .ant-typography.pending-color {
        color: $global-color-3;
    }

    .earning-value {
        color: $color-white;
        div:first-child {
            font-family: 'Poppins-SemiBold';
            font-size: 24px;
            text-align: right;
        }

        div:nth-child(2) {
            font-family: 'Poppins-LightItalic';
            font-size: 12px;
        }

        &.pending-color {
            color: $global-color-3;
        }
    }

    .ant-btn {
        &::after {
            content: none;
        }
        &.base-button {
            background-color: $color-white;
            color: $global-color-3;
            font-size: 16px;
            font-family: 'Poppins-Medium';
            box-shadow: 0px 2px 3px rgb(0 0 0 / 8%);

            svg {
                margin-left: 15px;
                path {
                    fill: $global-color-3;
                }
            }
        }

        &.pending-button {
            background-color: rgba($global-color-3, 0.18);
            color: $global-color-3;
            font-size: 12px;
            font-family: 'Poppins-Medium';

            .btn-text {
                display: flex;
                justify-content: space-between;
                align-items: center;

                .svg-wrapper {
                    width: 26px;
                    height: 26px;
                    border-radius: 100%;
                    background-color: $global-color-3;
                    margin-right: 10px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                }
                svg {
                    width: 18px;
                    height: 18px;
                    fill: $color-white;
                }
            }
        }
    }
}
