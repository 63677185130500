@import './EmployeeList/style';
@import './Loading/style';

.employee-list{
    width: 100%;
    overflow-x: auto;
    margin-top: 30px;
    > div{
        min-width: 1174px;
    }
}
.employee-view-all{
    .filter-content{
        row-gap: 15px !important;
        > div{
            &:last-child{
                > div{
                    flex-flow: wrap;
                }
            }
        }
        .role-select {
            width: 100px !important;
        }
    }
}
////////////RESPONSIVE

@media (max-width:991px){
    .employee-view-all{
        padding:20px;
    }
}


@media (max-width:575px){
    .employee-view-all{
     .filter-content{
        > div{
            &:last-child{
                > div{
                    > .ant-space-item{
                        &:first-child{
                            width: 100%;
                        }
                    }
                }
            }
        }
     }
    }
}
