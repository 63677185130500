.input-group-container {
    border: 2px dashed $global-color-10;
    border-radius: 5px;
    padding: 30px;
    margin: 30px;
    >div{
        margin-bottom: 0 !important;
    }


    .border-text {
        float: left;
        font-size: 16px;
        color: $global-color-9;
        padding:0 13px 0 10px;
        margin:-32px 0 0 10px;
        background:#fff;
        font-family: "Poppins-Medium";
        letter-spacing: 0.16px;
    }
}
