@import './Accommodation/style';


////////////RESPONSIVE
@media (max-width:1720px){
    #add-accommodation-page,#add-tour-trip-page,#add-tour-packages-page,#add-cruise-tour-page,#add-tour-activity-page{
        .right-col-container{
            >.ant-row{
                >.ant-col{
                    &:last-child{
                        .btn-orange{
                            &:hover{
                                >div{
                                    >.ant-space-item{
                                        &:first-child{
                                            transform: translateX(-6px);
                                        }
                                        &:last-child{
                                            transform: translateX(4px);
                                        }
                                    }
                                }
                            }
                          
                        }
                    }
                }
            }
        }
    }
}
@media (max-width:1599px) {
    #add-accommodation-page{
        .right-col{
            .steps-container{
                .ant-steps-item-tail{
                    top: 51px !important;
    
                }
                .ant-steps-item-icon{
                    margin-top: 8px;
                }
            }
        
        }
    }
#hotel-rooms-form{
    >div{
        &:first-child{
            .ant-form-item-control-input-content{
                >div{
                    >div{
                        &:first-child{
                            width: 100%;
                            max-width: 100%;
                            flex: 0 0 100%;
                        }
                        &:nth-child(2),&:nth-child(3){
                            max-width: 50%;
                            flex: 0 0 50%;
                        }
                        
                    }
                }
            }
        }
        &:nth-child(2),&:nth-child(3){
            .ant-form-item-control-input-content{
                >div{
             >div{
                max-width: 100%;
                flex: 0 0 100%;
             }
                }
            }
        }
    }
}


}


@media (max-width:1399px) {
    #add-accommodation-page, #add-tour-trip-page,#add-tour-packages-page{
        >div{
            &:last-child{
                .right-col-container{
                    width: 42%;
                    max-width: 42%;
                    >.ant-row-end{
                        justify-content: space-evenly;
                        .ant-col{
                            padding: 0 !important;
                        }
                    }
                }
            
            }
            &:first-child{
                width: 50%;
                max-width: 50%;
            }
        }
        #hotel-rooms-form{
            >div{
                &:first-child{
                    .ant-form-item-control-input-content{
                        >div{
                            // >div{
                            //     &:nth-last-child(-n+2){
                            //         max-width: 100%;
                            //         flex: 0 0 100%;
                            //     }
                            // }
                            >div{
                                max-width: 100%;
                                flex: 0 0 100%;
                            }
                        }
                    }
                }
            }
            #amenities{
                > div{
                    > div{
                        max-width: 50%;
                                    flex: 0 0 50%;  
                                    @include d-flex;
                    }
                }
            }
        }
        #payment_methods{
            > div{
                >div{
                    flex: 0 0 33.33333333% !important;
    max-width: 33.33333333% !important;
                }
            }
        }
    
    
    }
  
        .tour-extra-services{
            >div{
                &:last-child{
                    padding-right: 0 !important;
                    button{
                        height: auto;
                        width: auto;
                    }
                }
            }
}

}

@media (max-width:1199px) {
    #add-accommodation-page, #add-tour-trip-page,#add-tour-packages-page,#add-tour-activity-page,#add-cruise-tour-page{
        >div{

            &:first-child{
                width: 50%;
                max-width: 50%;
            }
        }
   
        .season-card{
            .ant-card-meta{
                align-items: flex-start !important;
                .ant-card-meta-description{
                    >div{
                        >div{
                            &:last-child{
                                >div{
                                    margin-top: 0 !important;
                                }
                            }
                        }
                    }
                }
            }
        }
        #payment_methods{
            > div{
                >div{
                    flex: 0 0 50% !important;
    max-width: 50% !important;
                }
            }
        }
        .create-section{
            > div{
                >div >div{
                    flex: 0 0 100% !important;
                    max-width: 100% !important;
                    &:last-child{
                        padding-bottom: 15px;
                    }
                }
            }
        }
        #amenities{
            > div{
                > div{
                    max-width: 100% !important;
                                flex: 0 0 100% !important;  
                }
            }
        }
        #accommodation-details-form,#hotel-facilities-services-form{
            .create-section{
                .input-section.open{
                    .ant-form-item{
                        margin-bottom: 30px !important;
                    }
                }
            }
           
        }
        .right-col-container{
            .right-col{
                .data-display{
                 height: 412px !important;
                }
             }
        }
    
    }

}

@media (max-width:991px) {
    .mobile-topbar-wrapper{
        // background-color: transparent !important;
        box-shadow: 0px 3px 9px 0px rgba(0,0,0,0.06);
        .mobile-topbar{
            height: auto;
            >div{
                align-items: center;
                >div{
                    &:last-child{
                        .general-menu{
                            >div{
                                @include d-flex;
                            }
                        }
                    }
                }
            }
        }
    }
    .tour-card-container{
        padding-top: 57px;
        .tour-card-wrapper_2{
            flex-wrap: wrap;
            justify-content: center;
            margin-bottom: 30px;
        }
    }
    #add-accommodation-page,#add-tour-trip-page,#add-tour-packages-page,#add-cruise-tour-page,#add-tour-activity-page{
        >div{
            &:first-child{
                width: 100% !important;
                max-width: 100% !important;
                padding-right: 35px !important;
                padding-left: 35px !important;
                padding-bottom: 20px;
            }
            &:last-child{
                .right-col-container {
                    width: 100% !important;
                    max-width: 100% !important;
                    position: relative;
                    padding-left: 20px;
                    padding-right: 20px;
                    >div{
                        &:first-child{
                            .ant-steps-item-icon{
                                border: 4px solid #EDEFF2;;
                            }
                        }
                    }
                    >.ant-row-end{
                        .ant-col{
                            padding: 0 15px !important;
                        }
                    }
                }
            }
        }
        .season-card{
            .ant-card-meta{
                .ant-card-meta-description{
                    >div{
                        >div{
                            &:last-child{
                                >div{
                                    margin-top: -20px !important;
                                }
                            }
                        }
                    }
                }
            }
        }

        #hotel-facilities-services-form{
            .checkbox-multi-select{
                > div{
                    > div{
                        max-width: 25%;
                        flex: 0 0 25% ;  
                    }
                }
            }
        }
        .right-col-container{
            >.ant-row{
                >div:last-child{
                    button{
                    .ant-space{
                        gap:30px !important;
                        >.ant-space-item{
                            &:first-child{
                                font-size: 12px;
                            }
                        }
                    }
                    }
                }
            }
        }
    }

}

@media (max-width:767px) {
    #hotel-facilities-services-form{
        .checkbox-multi-select{
            > div{
                > div{
                    max-width: 50% !important;
                    flex: 0 0 50% !important;  
                }
            }
        }
    } 

}


@media (max-width:575px) {
    #add-accommodation-page,#add-tour-trip-page,#add-tour-packages-page,#add-cruise-tour-page,#add-tour-activity-page{
        >div{
            &:last-child{
                .right-col-container {
                    >div{
                        margin-bottom: 0;
                        &:first-child{
                            .ant-steps-item{
                                width: 100%;
                                .ant-steps-item-container{
                                    flex-direction: row;
                                    margin-bottom: 30px;
                                    justify-content: flex-start;
                                    >.ant-steps-item-tail{
                                        display: none;
                                    }
                                    >.ant-steps-item-content{
                                        min-height: auto;
                                        >div{
                                            margin-bottom: 0;
                                            height: auto;
                                            padding-right: 0;
                                        }
                                    }
                                }  
                            }
                        }
                        &:last-child{
                            >div{
                                width: 100%;
                                .btn-grey{
                                    margin-bottom: 15px;
                                }
                            }
                        }
                        &.ant-row-end{
                         >div{
                            &:first-child{
                                justify-content: space-around;
                                margin-bottom: 15px;
                            }
                         }
                        }
                    }
            
                }
            }
            #accommodation-details-form{
               #payment_methods{
                > div{
                    >.ant-col{
                        max-width: 100% !important;
                        flex: 0 0 100% !important;
                    }
                }
               }
            }
        }

        #hotel-rooms-form{
            >div{
                &:first-child{
                    .ant-form-item-control-input-content{
                        >div{
                            >div{
                             &:nth-child(2),&:nth-child(3){
                                max-width: 100%;
                                flex: 0 0 100%;
                             }
                            }
                        }
                    }
                }
            }
        }
        .season-card{
            .ant-card-meta{
                .ant-card-meta-description{
                    >div{
                        >div{
                            &:last-child{
                                >div{
                                    margin-top: 0 !important;
                                }
                            }
                        }
                    }
                }
            }
        }
        .add-new-room-button{
            button{
                width: 100%;
            }
        }
    }    

}

@media (max-width:399px) {
    #add-accommodation-page,#add-tour-trip-page,#add-tour-packages-page,#add-cruise-tour-page,#add-tour-activity-page{
        >div{
            &:first-child{
      padding-left: 0 !important;
      padding-right: 0 !important;
            }
            &:last-child{
                .right-col-container {
                    padding-left: 0;
                    padding-right: 0;
                }
            }
        }
.empty-season-list,.empty-room-list{
    span{
        font-size: 12px;
    }
}
#payment_methods{
    > div{
        >div{
            flex: 0 0 100% !important;
max-width: 100% !important;
        }
    }
}
    
    } 
    .tour-card-container{
        >span{
            padding-left: 20px;
            padding-right: 20px;
        }
        > .tour-card-wrapper{
            padding-left: 30px;
            padding-right: 30px;
        }
    }
    .create-room-form-switch{
        label{
            font-size: 12px;
        }
    }

}


// =====================================================================
// =====================================================================


@media (min-width:400px) {
      

}

@media (min-width:576px) {
      

}

@media (min-width:768px) {
      

}

@media (min-width:992px) {


}

@media (min-width:1200px) {


}

@media (min-width:1400px) {


}

@media (min-width:1600px) {


}

