@import './Dashboard/style';

@import './Trip/New/style';
@import './Trip/ViewAll/style';
@import './Trip/ViewOne/style';

@import './Packages/New/style';
@import './Packages/ViewAll/style';
@import './Packages/ViewOne/style';

@import './Cruise/New/style';
@import './Cruise/ViewAll/style';
@import './Cruise/ViewOne/style';

@import './Activities/ViewAll/style';
@import './Activities/ViewOne/style';
@import './Activities/New/style';
.empty-group-list,.empty-ship-list,.empty-room-list, .empty-season-list {
    text-align: center;

    .text {
        color: #BBC6D1;
        line-height: 21px;
        letter-spacing: 0.14px;
    }
    svg{
        margin-bottom: 20px;
    }
    .create-button-filled{
        padding: 12px 40px;
        width: 181px !important;
        height: auto !important;
        box-shadow: 0px 3px 10px 0px rgba(83,109,254,0.31) !important;
        span{
            font-size: 12px;
            font-family: "Poppins-Regular";
            letter-spacing: 0.12px;
            line-height: 18px;
        }
    }
}
.right-col{
    .steps-container {
        .data-display {
            margin-top: 50px;
            .empty {
                margin-top: 50px;
                margin-bottom: 82px;
                .empty-data-icon {
                    margin-bottom: 20px;
                }
            }
            .ant-typography {
                color: #DDE4EB;
                font-family: "Poppins-Regular";
                font-size: 12px;
            }
        }
        .data-container {
            .border-text {
                font-size: 12px;
                line-height: 18px;
            }
            .ant-avatar {
                width: 184px;
                height: 184px;
            }
            .info-section {
                margin-bottom: 10px;
                .name {
                    span {
                        font-family: "Poppins-Medium";
                        font-size: 12px;
                        color: #BBC6D1;
                        letter-spacing: 0.12px;
                        line-height: 18px;
                    }
                }
                .content {
                    word-break: break-all;
                    font-family: "Poppins-Regular";
                    color: #758CA1;
                    line-height: 21px;
                    letter-spacing: 0.14px;
                }
            }
        }
    }

    .incl-excl-content{
        @include d-flex;
        align-items: center;
        svg {
            width: 10px;
            height: 10px;
            margin-right: 5px;
        }
        span {
            line-height: 25px;
            font-size: 12px;
            color: #758CA1;
        }
    }
}

#add-tour-trip-page,#add-tour-packages-page,#add-cruise-tour-page,#activity-partner-form{
    .ant-input-affix-wrapper{
        padding: 0 7.5px 0 21px !important;
    }
}

#trip-general-details-form, #packages-general-details-form{
    .ant-radio-button-wrapper:first-child {
        margin-right: 10px;
    }
    > div.ant-form-item{
        padding: 6px 30px;
        margin-bottom: 0;
        .ant-row>div:nth-last-child(-n+2)>div{
margin-bottom: 0;
        }
    }
>div{
    &:nth-child(5){
        margin-top: 44px;
        margin-bottom: 41px;
    >div{
    .ant-row{
        >div{
            &:last-child{
                >div{
                    margin-bottom: 0;
                    min-height: unset;
                }
            }
            &:nth-child(6){
                >div{
                    margin-bottom: 0;
                }
            }
         
        }
    }
}
    }
    &:nth-child(6){
        margin-bottom: 34px;
    }
    &:last-child{
        .ant-row{
            margin-bottom: 0;
        }
    }
}
    .ant-input-affix-wrapper {
        border: none !important;
    }
    .input-group-container{
        .border-text{
            margin: -40px 0 0 -4px;
            font-size: 14px;
            font-family: "Poppins-Regular";
            color: #758CA1;
            letter-spacing: -0.14px;
        }
    }
    .ant-radio-button-wrapper{
  width: 88px;
  height: 35px;
  margin: 0;
  padding: 0;
  @include d-flex;
  align-items: center;
  justify-content: center;
  border: none;
  background-color: #E7EBEF;
  color: #758CA1;
  >span:nth-child(2){
    font-family: "Montserrat-Regular";
    line-height: 18px;
    letter-spacing: -0.14px
  }
    }
    .ant-radio-group{
        @include d-flex;
        padding-left: 15px;
    }
    .ant-checkbox-wrapper{
        .ant-checkbox-checked{
            .ant-checkbox-inner{
                background-color: #536DFE !important;
            }
        }
    }
    .ant-upload{
        font-family: "Poppins-Medium" !important;
       .ant-row{
        >div{
            &:first-child{
                svg{
                    width: 45.53px;
                    height: 35.51px;
                }
            }
            &:nth-child(2),&:nth-child(3){
                line-height: 21px;
                margin-bottom: 8px;
                span{
                    font-size: 14px;
                }
            }
            &:last-child{
margin-top: 2px;
span{
    font-size: 12px;
    line-height: 25px;
}
            }
        }
       }
    }
}
#trip-inclusion-exclusion-form, #packages-inclusion-exclusion-form ,#cruise-inclusion-exclusion-form{
    .ant-form-item{
      padding: 6px 30px 0 30px;
     }
  .check-list{
    >div{
        &:first-child{
            .ant-checkbox{
                margin-right: 2px;
            }
        }
    }
  }
     .ant-checkbox-wrapper{
         .ant-checkbox{
             margin-right: 6px;
         }
         span{
             font-family: "Montserrat-Regular";
             letter-spacing: 0.14px;
             line-height: 18px;
         }
         .select-all{
             font-family:"Poppins-Regular" ;
             font-size: 16px;
             letter-spacing: 0.16px;
             line-height: 25px;
         }
         .ant-checkbox-inner{
             width: 29px !important;
             height: 29px !important;
         }
     }
     .inclusions,.exclusions{
         >.ant-row{
             margin-bottom: 0;
         }
     }
    
         .create-section{
             padding: 20px 30px 50px 30px;
             .ant-form-item{
                padding: 0 !important;
             }
                span{
                    line-height: 18px;
                    font-family: "Montserrat-Regular";
                    letter-spacing: 0.14px;
                }
         
             
         }
     
     .exclusions{
         .create-section{
             padding-bottom: 0;
         } 
     }
 }
////////////RESPONSIVE

 @media (max-width:1599px){
    #trip-general-details-form,#packages-general-details-form{
    > div.ant-form-item .ant-row > div:nth-last-child(2) > div{
        margin-bottom: 30px !important;
    }
    }
    }

    @media (max-width:1199px){
        #trip-general-details-form,#packages-general-details-form{
            >div{
                &:nth-child(5){
                    .ant-form-item-control-input-content{
                        >div{
                            >div{
                                &:nth-last-child(2){
                                    >div{
                                        margin-bottom: 30px;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
        #view-tour-trip-page,#view-tour-package-page,#view-tour-cruise-page{
            >.view-content{
                >div>div{
                    &:first-child{
                        .general-data{
                            >div{
                                &:first-child{
                                    flex-direction: column;
                                    >div{
                                        &:nth-child(2){
                                            display: none;
                                        }
                                        &:first-child,&:last-child{
                                            flex: 0 0 100% !important;
                                            max-width: 100% !important;
                                            padding-left: 0;
                                            >div{
                                                width: 100%;
                                            }
                                        }
                                    }
                                }
                                &.accomodation-block-details{
                                    row-gap: 15px !important;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    @media (max-width:991px){
        #view-tour-trip-page,#view-tour-package-page,#view-tour-cruise-page{
            padding-top: 20px;
            >div{
                padding-left: 15px;
                padding-right: 15px;
                &:last-child{
                    padding-bottom: 15px;
                }
            }
        }
    }
    @media (max-width:767px){
        #view-tour-trip-page,#view-tour-package-page,#view-tour-cruise-page{
            >div{
               &:first-child{
                row-gap: 15px !important;
                flex-direction: column;
               }
            }
        }
    }
    @media (max-width:575px){
        #trip-general-details-form,#packages-general-details-form{
            >div{
                &:nth-child(5){
                    .ant-radio-button-wrapper{
                        width: auto;
                        padding: 5px;
                        font-size: 12px;
                    }
                    .ant-form-item-control-input-content{
                        >div{
                            >div{
                                &:nth-last-child(2){
                                    >div{
                                        margin-bottom: 30px !important;
                                    }
                                }
                            }
                        }
                    }
                }
            }
            .tour-extra-services{
                >div{
                    &:last-child{
                        padding-left: 0 !important;
                    }
                }
            }
         
        }
        .view-one-header{
            .ant-segmented-item-label{
                padding: 15px;
            }
        }
    }
    @media (max-width:399px){
        #view-tour-trip-page,#view-tour-package-page,#view-tour-cruise-page{
            .accomodation-block-details{
                row-gap: 15px !important;
                >div{
                    flex: 0 0 100% !important;
                    max-width: 100% !important;
                }
            }
            .incl-excl-row{
                >div{
                row-gap: 15px !important;
                    >div{
                        flex: 0 0 100% !important;
                        max-width: 100% !important;
                    }
                }
            }
            .view-one-header{
                .ant-segmented-item-label{
                    white-space: normal;
                    height: 100%;
                    @include d-flex;
                    align-items: center;
                }
                >div{
                    &:first-child{
                        display: flex;
    flex-direction: column;
    row-gap: 15px;
                    }
                }
            }
            
        }
    }
