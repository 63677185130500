#trip-itenerary-form {
    @import './IteneraryItem/style';
     .itenerary{
         padding: 11px 30px 0 30px;
         >div:last-child{
            >div{
                &:first-child{
                    margin-bottom: 16px;
                }
            
            }
            .hidden-inputs{
                .mui-form-item{
                    margin-bottom: 22px;
                }
                >.ant-form-item:not(.mui-form-item){
                    .ant-form-item-control-input-content{
                        >div{
                            >div{
                                border-radius: 5px;
                            }
                        }
                    }
                }
            }
         }

         .ant-form-item-control-input{
             border: none !important;
         }
         .mui-form-item{
            .ant-form-item-control-input-content{
                border: 2px solid #DDE4EB;
                border-radius: 5px;
                .ant-input-affix-wrapper{
                    border: none !important;
                }
              
            }
            .ant-form-item-required{
                letter-spacing: -0.14px;
            }
         }
         .button-underline{
             margin-right: 15px;
             span{
                 letter-spacing: -0.14px;
                 line-height: 21px;
                 color: #536DFE;
            }
         }
         .btn-grey{
             padding: 17px 30px;
             background: #F4F6F8;
             @include d-flex;
             align-items: center;
             span{
              letter-spacing: 0.12px;
              color: #BBC6D1;
              line-height: 18px;
             }
             svg{
              margin-right: 13px;
             }
         }
      
     }

    >div{
        &:last-child{
            >div>div>div>div{
                &:last-child{
                    display: flex;
                    justify-content: flex-end;
                    padding-right: 30px;
                }
            }
        }

    }

     .add-button-form{
         margin-bottom: 0px !important;
         padding-top: 6px;
     }
   
}
