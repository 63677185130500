.dashboard-deals-container {
    @media screen and (max-width: 1200px) {
        &-list {
            padding: 0 15px 15px 15px !important;
        }
    }
    @media screen and (min-width: 1200px) {
        position: sticky;
        top: 0;
        height: calc(100vh - 148px);
        flex-direction: column;
        flex-wrap: nowrap;
        &-info {
            // height: max-content;
        }
    }
}

.right_dashboard_carousel {
    .rec-carousel-item {
        // margin-bottom: 20px;
        .ant-card-bordered {
            width: 100%;
            border: none !important;
            .ant-card-body {
                border-radius: 10px;
            }
        }
    }
    .rec-slider-container {
        margin: 0px;
    }
}

.partnership-icon {
    width: 100px;
}

.text-block {
    :first-child {
        color: #4d677e;
        font-family: "Poppins-Regular";
        font-size: 14px;
        line-height: 21px;
        letter-spacing: 0.14px;
        margin-bottom: 6px;
    }
    :nth-child(2) {
        color: #bbc6d1;
        font-family: "Poppins-Light";
        font-size: 12px;
        line-height: 18px;
        letter-spacing: 0.12px;
        margin-bottom: 15px;
    }

    .ant-btn {
        background: $global-color-7;
        height: 35px;
        text-align: center;
        @include d-flex;
        justify-content: center;
        align-items: center;
        span {
            color: $color-white;
            font-family: "Poppins-Regular";
            font-size: 12px;
            line-height: 18px;
            letter-spacing: 0.12px;
            margin-bottom: 0px;
        }
    }
}

.ant-card {
    .ant-card-body {
        padding: 0px;

        .ant-card-meta {
            padding: 20px;
            .ant-card-meta-avatar {
                .logo {
                    position: absolute;
                    z-index: 1;
                    margin-top: -35px;
                    margin-left: 55px;
                    display: block;
                }
            }
            .ant-card-meta-detail {
                .ant-card-meta-title {
                    .ant-btn.view-tour {
                        background-color: #E7F6FC;
                        border-radius: 50px;
                        color: #089ECB;
                        font-size: 12px;
                        text-decoration: none;
                        margin-bottom: 5px;
                        height: 25px;
                        display: flex;
                        justify-content: center;
                        align-items: center;
    
                        svg {
                            margin-right: 5px;
                            width: 12px;
                            height: 12px;
        
                            #fi-rr-ship, #fi-rr-backpack, #Path_4247, #Path_4248 {
                                fill: #089ECB;
                            }

                            #Out_line {
                                width: 18px;
                                height: 18px;
                            }
                        }
                    }
                }
                .ant-card-meta-description {
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    .ant-typography {
                        color: $global-color-9;
                        font-family: "Poppins-Medium";
                    }

                    .details {
                        font-size: 12px;
                        color: $global-color-2;
                        margin-top: 10px;

                        > div:not(:first-child) {
                            margin-top: 5px;
                        }

                        svg {
                            margin-right: 5px;
                            width: 12px;
                            height: 12px;

                            #fi-rr-building {
                                fill: $global-color-2;
                            }
                        }
                    }
                }
            }
        }

        .ant-space {
            background-color: #e2f7ff;
            border-radius: 0px 0px 5px 5px;
            padding: 10px;
            width: 100%;
            display: flex;
            justify-content: space-between;
            align-items: center;
            flex-wrap: wrap;

            .price-content {
                font-size: 12px;
                div:first-child {
                    color: $global-color-9;
                    font-family: "Poppins-SemiBold";
                }

                div:nth-child(2) {
                    margin-top: 5px;
                    &.base-color {
                        color: $global-color-12;
                    }

                    &.second-color {
                        color: $global-color-3;
                    }

                    span:first-child {
                        font-family: "Poppins-SemiBold";
                        margin-right: 5px;
                    }

                    span:nth-child(2) {
                        font-family: "Poppins-LightItalic";
                    }
                }
            }

            .ant-btn {
                font-size: 12px;
                font-family: "Poppins-Medium";
                &::after {
                    content: none;
                }

                &.base-button {
                    height: 30px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    background-color: $global-color-3;
                    color: $color-white;
                    padding: 5px;
                    svg {
                        margin-left: 10px;
                    }
                }
            }
        }
    }
}
