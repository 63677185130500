@import './CustomerList/style';



////////////RESPONSIVE

@media (max-width:1599px){
    .cf-m1{
        .filter-content{
            row-gap: 15px !important;
            >div:last-child{
                >div{
                    flex-flow: wrap;
                }
            }
        }
    }
    .customer-page{
        .footer{
            >.ant-row{
                row-gap: 30px !important;
                > .ant-col:nth-child(1){
                    > .ant-space{
                        flex-flow: wrap;
                        gap: 30px !important;
                    }
                }
            }
        }
    }
}

@media (max-width:991px){
.cf-m1{
    padding: 20px 20px 0 20px;

}
.customer-page{
 padding: 0 20px 20px 20px;
}


}

@media (max-width:767px){
    .cf-m1{
        > .ant-form{
            > .advanced-filter-content{
                > .ant-col{
                  flex: 0 0 50% !important;
                  max-width: 50% !important;
                }
              }
        }
      
    }
}

@media (max-width:575px){
    .cf-m1{
        > .ant-form{
            > .advanced-filter-content{
                > .ant-col{
                  flex: 0 0 100% !important;
                  max-width: 100% !important;
                }
              }
        }
       
    }
}

@media (max-width:399px){
.customer-page{
    .ant-pagination.mini{
        .ant-pagination-item{
            width: 21px !important;
            height: 21px !important;
            &.ant-pagination-item-active{
                width: 30px !important;
                height: 30px !important;
            }
        }
        .ant-pagination-next,.ant-pagination-prev{
            button{
                width: 21px !important;
                height: 21px !important; 
            }
        }
    }
}
}