.view-one-header-with-map {
    margin-bottom: 20px;
    >div:first-child{
        >.ant-row{
            >div{
                &:first-child{
                    padding-right: 16px !important;
                }
                &:last-child{
                    padding-left: 0px !important;
                }
            }
        }
    }
    .go-back {
        border-radius: 50%;
        border-color:transparent;
        width: 40px;
        height: 40px;
        box-shadow: 0px 0px 20px 0px rgba(26,38,43,0.06) !important;
        padding: 15px 12px;
        @include d-flex;
        align-items: center;
        justify-content: center;
        svg{
            width: 14.26px;
            height: 9px;
        }
    }

    .header-title {
        font-size: 18px;
        color: $global-color-9;
        font-family: "Poppins-SemiBold";
        line-height: 16px;
        margin-bottom: 4px;
    }

    .ant-rate {
        font-size: 14px;
        color: $global-color-3;
        svg{
            width: 11.57px;
            height: 11px;
            vertical-align: super;
        }
    }
   .location {
       margin-top: -12px;
       span{
        color: $global-color-4;
        line-height: 14px;
        letter-spacing: 0.12px;
        @include d-flex;
        font-size: 12px;
        svg{
            margin-right: 8.4px;
            path{
                fill:$global-color-4 ;
            }
        }
       }
       button{
           span{
            font-family: "Poppins-Medium";
            color: $color-primary;
            text-decoration: underline;
            line-height: 20px;
            letter-spacing: 0;
           }
           &:hover,&:active,&:focus{
            background: none;
           }
       }
   }

   .ant-btn-text {
       line-height: 2;
   }
   .ant-segmented{
    box-shadow: 0px 0px 20px 0px rgba(26,38,43,0.06) !important;
       .ant-segmented-item-label{
           @include d-flex;
           align-items: center;
    }
    .ant-segmented-item{
        .ant-segmented-item-label{
            @include d-flex;
            align-items: center;
        }
    }
   }
}
