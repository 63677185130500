.employee-performance {
    padding: 35px 30px 46px 30px;
    background-color: $color-white;
    border-radius: 0px $border-radius-small $border-radius-small 0px;

    .add-evaluation-form {
        padding: 23px 24px 32px 28px;
        border: 2px solid $global-color-11;
        border-radius: $border-radius-small;
        margin-bottom: 20px;

        .ant-input {
            border: 2px solid $global-color-21 !important;
        }
        >form{
            >div{
                > .ant-col{
                    &:first-child{
                        > span{
                            color: #758CA1;
                            line-height: 20px;
                        }
                        > .ant-row{
                            margin-top: 17px;
                            row-gap: 15px !important;
                            .ant-select{
                                border: 2px solid #DDE4EB !important;
                                box-shadow: none !important;
                            }
                        }
                    }
                    &:nth-child(2){
                        margin-top: 37px;
                        >textarea{
                            padding: 26px 30px;
                            color: #BBC6D1;
                            height: 100%;
                        }
                    }
                    &:last-child{
                        > .ant-row{
                            > .ant-col{
                                &:first-child{
                                    button{
                                        @include d-flex;
                                        justify-content: center;
                                        align-items: center;
                                        svg{
                                            margin-right: 13px;
                                        }
                                        span{
                                            font-family: "Poppins-Medium";
                                            color: #FC7886;
                                            font-size: 12px;
                                            line-height: 18px;
                                            letter-spacing: 0.12px;
                                        }
                                    }
                                }
                                &:last-child{
                                    button{
                                        padding: 15px 100px;
                                        background: #536DFE;
                                        span{
                                            font-family:"Poppins-Medium";
                                            line-height: 21px;
                                            letter-spacing: 0.14px;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }

    }

    .skills-wrapper {
        border: 2px solid $global-color-11;
        border-radius: $border-radius-small;
        padding: 20px;

        .ant-divider-vertical {
            height: 10em;
        }

        .ant-progress {
            display: flex;
            flex-direction: column-reverse;
        }

        .ant-progress-circle .ant-progress-text {
            >div{
                >div{
                    &:first-child{
                        @include d-flex;
                        justify-content: center;
                        align-items: center;
                    }
                }
            }
            .em-scores {
                font-size: 24px;
                font-family: "Poppins-Bold";
                line-height: 27px;
            }
            .total-scores {
                font-size: 12px;
                line-height: 23px;

            }
            .scores-desc {
                font-size: 9px;
                color: #BFC7CE;
            }
        }

        .split-scores {
            width: 250px;
        }

        .ant-typography {
            color: $global-color-4;
        }

        .created-date {
            float: right;
            color: $global-color-3;
            svg {
                margin-right: 10px;
                path {
                    fill: $global-color-3;
                }
                circle {
                    fill: $global-color-3;
                }
            }
        }
    }
    

    > form{
        > .filter-content{
            > div{
                &:last-child{
                    @include d-flex;
                    justify-content: flex-end;
                    > .ant-space{
                        >div{
                            &:first-child{
                                .ant-picker{
                                    border: 2px solid #DDE4EB !important;
                                    box-shadow: none !important;
                                    svg{
                                        width: 20px;
                                        height: 20px;
                                        path,circle{
                                            fill: #DDE4EB;
                                        }
                                    }
                                }
                            }
                            &:nth-child(2){
                                .ant-select{
                                    border: 2px solid #DDE4EB !important;
                                    box-shadow: none !important;
                                }
                            }
                            &:last-child{
                                button{
                                    padding: 15px 35px;
                                    span{
                                        color: #FFFFFF;
                                        font-size: 14px;
                                        font-family: "Poppins-Regular";
                                        margin-left: 2px !important;
                                        line-height: 21px;
                                    }
                                    svg{
                                        width: 12px;
                                        height: 12px;
                                        line{
                                            stroke-width: 3;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
                &:first-child{
                    .info-section{
                        > .name{
                            span{
                                font-size: 12px;
                                color: #BBC6D1;
                            line-height: 20px;

                            }
                        }
                        > .content{
                            margin-top: 3.8px;
                            font-size: 14px !important;
                            line-height: 20px;
                            color: #758CA1 !important;
                        }
                    }
                }
            }
        }
    }
    > .ant-divider{
        margin: 31px 0 35px 0;
        border-width: 1px;
        border-color:#E7EBEF !important;
    }
    > .ant-row{
> div{
    padding: 35px 42px 32px 32px;

    > .ant-space{
        align-items: flex-start;
        height: 100%;
        > div{
            &:first-child{
            .ant-space{
                align-items: flex-start;
                > .ant-space-item{
                    &:first-child{
                        .ant-progress-inner{
                            width: 103px !important;
                            height: 103px !important;
                        }
                    }
                    &:last-child{
                        > .split-scores{
                            >div{
                                &:not(:last-child){
                                    margin-bottom: 14px;
                                }
                            }
                            > .ant-progress-line{
                                .ant-progress-inner{
                                    margin-top: 7px;
                                    .ant-progress-bg{
                                        height: 4px !important;
                                    }
                                }
                                .ant-progress-text{
                                    margin-left: 0;
                                    font-size: 12px;
                                    color: #758CA1;
                                    line-height: 24px;
                                }
                            }
                        }
                    }
                }
            }
            }




            &:last-child{
                .ant-space{
                    > .ant-space-item{
                        &:first-child{
                            margin-bottom: 15px;
                            span{
                                line-height: 16px;
                            }
                        }
                        &:nth-child(2){
                            margin-bottom: 20px;
                            span{
                                color: #BBC6D1;
                                line-height: 18px;
                                letter-spacing: 0.07px;
                            }

                        }
                        &:last-child{
                            > .created-date{
                                @include d-flex;
                                align-items: center;
                                background-color: rgba(#FCB151, 0.11);
                                padding: 5px 12.5px 4px 13px;
                                border-radius: 15px;
                                svg{
                                    width: 17.13px;
                                    height: 17.13px;
                                }
                                span{
                                    line-height: 16px;
                                }
                            }
                        }
                    }
                }
            }



        }



        > .ant-space-item-split{
            height: 100%;
            >div{
                height: 100%;
                border-color: #DDE4EB;
                margin: 0 40px 0 8px;
            }
        }
    }
}
    }
}
