.services-list {
    .item {
        width: auto;
        height: 250px;
        cursor: pointer;
        position: relative;
        border: 2px solid #E5EAF0;
        border-radius: $border-radius-small;
        background-color: $color-white;
        text-align: center;
        padding: 12px;



        >    div{

                @include d-flex;
                flex-direction: column-reverse;
                width: 100%; 

                > div{

                    &:last-child{
                        text-align: end;
                        margin-bottom: 6px;
                    }



                    &:first-child{
                        >.title-wrapper{
                            margin-top: 24px;
                            > span{
                                font-family: "Poppins-SemiBold";
                                color: #495274;
                                line-height: 21px;
                                letter-spacing: 0.14px;
                            }
                        }
                        > .description{
                            > span{
                                font-size: 12px;
                                line-height: 18px;
                                color: #BCC7D9;
                            }
                        }
                    }


                }
        }

        &.selected {
            border: 2px solid #005AE7;
            @include box-shadow(0px 7px 16px 0px #5D96F03D);
        }

        img {
            height: 90px;
            width: 50%;
        }


        .ant-checkbox-wrapper {
            .ant-checkbox {
                &.ant-checkbox-checked {
                    &::after {
                        display: none;
                    }
                    .ant-checkbox-inner {
                        background-color: #2FE479 !important;
                       
                    }
                }
               
            }

            .ant-checkbox-inner {
                position: relative;
                top: 0;
                left: 0;
                display: block;
                width: 35px !important;
                height: 35px !important;
                direction: ltr;
                background-color: #EEF0F2 !important;
                border: 0;
                border-radius: 50%;
                border-collapse: separate;
                transition: all .3s;
            }
        }
    }
}
