.package-view-all{
    .tour-type-list-card{
        &.grid{
            .ant-card-meta-title{
                >.title{
                    >div{
                        margin-bottom: 10.5px !important;
                    }
                }
            }
            .ant-card-meta-description{
         
                .location{
                    margin-bottom: 9px;
                }
                .location,.date{
                    color:#4D677E !important;
                    svg{
                        path,circle{
                            fill: #4D677E;
                        }
                    }
                }
                .description{
                    margin-top: 14.6px;
                }
            }
        }
    }
}
