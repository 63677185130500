// this needs to be categorized
#add-tour-trip-page ,#add-tour-packages-page ,#add-cruise-tour-page, #add-tour-activity-page{
    @import './GeneralDetails/style';
    @import './Itenerary/style';
    @import './Steps/style';

    .left-col, .right-col {
        padding: 25px 0px;
        background-color: $global-color-6;
        border-radius: 5px;
        border: 1px solid #E7EBEF;
    }
   .right-col-container{
    .ant-row-end{
        >div{
            &:first-child{
                @include d-flex;
            }
        }
    }
   }
    @media screen and (max-width: 991px) {
        .right-col {
            background-color: #EDEFF2;
        }
    }
    .right-col{
        padding-bottom: 30px;
        .data-display{
            .data-container{
                .ant-space{
                    >div:nth-child(4){
                        .name{
                            margin-bottom: 7px
                        }
                    }
                }
                .incl-excl-data{
                    >div:last-child{
                        svg{
                           path{
                            fill: #E20F0F;
                           }
                        }
                    }
                    .incl-excl-content{
                        @include d-flex;
                        align-items: center;
                        line-height: 20px;
                        svg{
                            margin-right: 8px;
                        }
                        span{
                            font-size: 12px;
                            color: #758CA1;
                        }
                    }
                    .incl-excl-title{
                        font-size: 12px;
                        font-family: "Poppins-Medium";
                        letter-spacing: 0.12px;
                        line-height: 18px;
                        margin-bottom: 7px;
                    }
                }
                .border-text{
                    font-size: 12px;
                    line-height: 18px;
                    margin: -32px 0 0 -4px;
                }
                .ant-avatar{
                    width: 184px;
                    height: 184px;
                }
                .info-section{
                    margin-bottom: 7px;
                    .name{
                        margin-bottom: 2px;
                        span{
                            font-family: "Poppins-Medium";
                            font-size: 12px;
                            color: #BBC6D1;
                            letter-spacing: 0.12px;
                            line-height: 18px;
                        }
                    }
                    .content{
                        @include d-flex;
                        flex-direction: column;
                        span{
                            color: #758CA1;
                            line-height: 21px;
                            letter-spacing: 0.14px;
                            word-break: break-all;
                        }
                        .ant-image {
                            .ant-image-img {
                                border-radius: 5px;
                            }
                        }
                    }
                }
            }
        }
    }
}


.custom-tooltip{

    .ant-tooltip-arrow-content {
        --antd-arrow-background-color: #FFFFFF !important;
    }
    .ant-tooltip-inner{
        padding: 0px !important;
    }
    .ant-card-head{
        border-bottom: 1px solid #536DFE !important;
    }
    .ant-card-body{
        padding: 12.5px 21px 33px 21px;
        p{
            font-family: "Poppins-Light";
            font-size: 12px;
            color: #758CA1;
            line-height: 18px;
        }
    }
}
