// this needs to be categorized
#add-tour-activity-page {
    @import './TimeAndDuration/style';
    @import './Steps/style';

    .left-col{

        >div:first-child{
            align-items: center;
        }
    }
    .right-col-container{
        >.ant-row-end{
            >div{
                &:first-child{
                    @include d-flex;
                }
            }
        }
        .border-text{
            color: #4D677E;
        }
    }
    #activity-general-details-form{
        .upload-info-text_container{
            margin-top: 6px;
        }
        .ant-upload{
            >.ant-row{
                >.ant-col{
                    &:first-child{
                        margin-bottom: 9.2px;
                        svg{
                            width: 30.24px;
                            height: 23.76px;
                        }
                    }
                    &:nth-child(2){
                        margin-bottom: 10.8px;
                        span{
                            font-size: 11px;
                            line-height: 17px;
                        }
                    }
                    &:nth-child(3){
                        span{
                            font-size: 8px;
                            line-height: 12px;
                            &::before,&::after{
                                top: 67%;
                            }
                        }
                    }
                    &:last-child{
                        margin-top: 16.2px;
                        button >span{
                            font-size: 8px;
                            line-height: 17px;
                        }
                    }
                }
            }
        }
        > div{
            padding-left: 30px;
            padding-right: 30px;
            &:nth-child(5){
                margin-top: 0;
                margin-bottom: 46px;
            }
            
        }
        > .ant-form-item{
            padding-top: 6px;
            margin-bottom: 0;
        }
        .text-circle-button{
            span{
                text-decoration: none;
                line-height: 21px;
            }
        }
        .activity-details{
            .ant-picker-clear{
                right: 20px !important;
            }
        }
    }
    .input-group-container{
        .ant-form-item-control-input-content{
            >div:last-child{
                margin-bottom: 0;
        .ant-form-item-control-input-content{
            >div{
                // height:274px !important;
                >div{
                    border-radius: 5px;
                }
            }
        }
            }
        }
        .border-text{
            margin: -42px 0 0 -4px;
        }
    }
    .ant-input-affix-wrapper{
        border: none;
    }
    .trip-slider{
        margin-left: 30px !important;
        margin-right: 30px !important;
    }
    #activity-partner-form{
        padding-left: 30px;
        padding-right: 30px;
        margin-top: 28px;
        >div:first-child{
            >div:nth-last-child(-n+2){
                >div{
                    margin-bottom: 0
                }
            }
        }
        .ant-divider{
            margin: 30px 0;
        }
        .ant-radio-button-wrapper:not(.ant-radio-button-wrapper-checked){
            span{
                color: #758CA1
            }
        }
        .ant-input-affix-wrapper{
            padding: 0 1.5px 0 21px !important;
        }
    }
    .partner-create-text{
        @include d-flex;
        justify-content: center;
        margin-top: 28px;
        color: #BBC6D1;
        line-height: 21px;
    }
    .ant-select-show-search{
        margin-right: 30px;
        margin-left: 30px;
        border: 2px solid #DDE4EB;
        margin-top: 28px;
        box-shadow: none !important;
    }
    .ant-radio-button-wrapper{
        width: 88px !important;
    }
 
    @media screen and (max-width: 991px) {
        .right-col {
            background-color: #EDEFF2;
        }
    }

}

////////////RESPONSIVE

@media (max-width:1199px){
    #add-tour-activity-page{ 
        >div{
            &:last-child{
                .right-col-container{
                    width: 42%;
                    max-width: 42%;
                    >.ant-row-end{
                        justify-content: space-evenly;
                        .ant-col{
                            padding: 0 !important;
                        }
                    }
                }
            
            }
            &:first-child{
                width: 50%;
                max-width: 50%;
                .duration-section{
                    margin-left: 10px !important;
                    margin-right: 10px !important;
                    flex-flow: nowrap;
                    >div{
                        text-align: center;
                    }
                }
                #minutes-duration-form,#days-duration-form{
                    >.duration-content{
                        flex-direction: column;
                        margin-left: 30px;
                        margin-right: 30px;
                        align-items: center;
                    }
                }
                #days-duration-form{
                    .default-price-check{
                        left: 0% !important;
                    }
                }
                #activity-partner-form{
                    .ant-radio-button-wrapper{
                        width: auto !important;
                    }>div{
                        &:first-child{
                            >div{
                                &:nth-last-child(2){
                                    >div{
                                        margin-bottom: 30px;
                                    }
                                }
                            }
                        }
                    }
                 
                }
            }
        }
    }
     
}
@media (max-width:1399px){
    #add-tour-activity-page{
        >div{
            &:first-child{
    width: 50%;
    max-width: 50%;
            }
            &:last-child{
    >div{
        width: 42%;
        max-width: 42%;
    }
            }
        }
        #activityTimeMinutes_0_days{
            >.ant-row{
                >.ant-col{
                    flex: 0 0 33%;
                    max-width: 33%;
                }
            }
        }
    }
}

@media (max-width:991px){
    .default-price-check{
        left: 0% !important;
    }
    #add-tour-activity-page{
        padding-top: 20px;
    }
}
@media (max-width:575px){
#add-tour-activity-page{
    .time-duration{
        >div{
            &:first-child{
                >span{
                    padding-left: 22px;
                }
            }
        }
    }
}
}
@media (max-width:399px){
    #add-tour-activity-page{
        .time-duration{
flex-direction: column;
row-gap: 5px !important;
        }
    }
    
}
