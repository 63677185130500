@import './TripItenerary/style';
.map-col{
    .left-col{
        height: 100%;
        max-height: 798px;
        .ant-card-body{
            height: 100%;
            >div{
                height: 793px !important;
                max-height: 793px;
            }
            >div>div{
                border-radius: 5px 0 0 5px;
            }
        }
    }
}