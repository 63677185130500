.ant-form-item {
    .ant-input,
    .ant-input-affix-wrapper,
    .ant-select-selector {
        &:focus {
            border-color: $color-input-label-active;
            @include box-shadow(0px 3px 6px 0px $color-input-box-shadow-active);
        }
    }

    &.ant-form-item-has-error:not(.ant-input-disabled):not(.ant-input-borderless) {
        .ant-input,
        .ant-input-affix-wrapper,
        .ant-select .ant-select-selector {
            border-color: #D0D9E6 !important;

            &:hover {
                border-color: $color-input-label-active;
            }

            &, &:hover {
                @include box-shadow(initial);
            }

            &:focus {
                border-color: $color-input-label-active;
                @include box-shadow(0px 3px 6px 0px $color-input-box-shadow-active);
            }
        }
    }
}

.ant-form-item-explain, .ant-form-item-extra {
    div {
        font-size: 12px;
    }

    &.ant-form-item-explain-error {
        text-align: right;
    }
}
