.increment-number-input {
    display: flex;
    align-items: center;
    flex-basis: 100%;
    flex-grow: 1;
    justify-content: space-between;
    &.border {
        border: 2px solid $global-color-10;
        border-radius: $border-radius-small;
    }
    button{
        
        // max-width: 35px;
        // width: 100%;
        // max-height: 35px;
        // height: 100%;
        &:hover{
            background-color: #F2F3F3;
            border-radius: 50%;
        }
    }
}
