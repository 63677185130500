@import "_colors.scss";
@import "_mixins.scss";
@import "_fonts.scss";
@import "_variables.scss";

html {
  // overflow-x: hidden;
}

body {
  background-color: #edeff2 !important;
  line-height: 1.15 !important;
  // overflow-x: hidden;
  font-weight: normal !important;
  font-family: "Poppins-Regular" !important;
  color: #bbc6d1 !important;
}

.ant-layout {
  background-color: #edeff2 !important;
}

.ant-layout-content {
    .integrations-page, #accommodation-view-all,
    #view-tour-package-page, #view-tour-cruise-page, #view-tour-trip-page,
    #view-accommodation-page, .in-house-stats, .b2b-stats, .worldwide-supplier-page,
    .my-partner-page, .b2b-requests-page, .agency-profile-page, .cf-m1, .customer-view-one,
    .employee-view-all, .employee-view-one, .role-page, .invoices-row-m1, .invoice,
    .coming-soon-content, .tour-card-container, #tour-view-all {
        padding-right: $auth-layout-content-offset;
    }
}

.ant-card {
  line-height: 1.15 !important;
}

.ant-form {
  line-height: 1.15 !important;
}

.ant-btn {
  line-height: 1.15 !important;
  border: 0 !important;
  box-shadow: none !important;
}

.rec-pagination {
  margin-top: 0 !important;
}

button.rec-dot {
  width: 21px !important;
  height: 2px !important;
  border-radius: 3px !important;
  background-color: #dde4eb !important;
  box-shadow: none !important;
  margin: 0 !important;
  margin-left: 6px !important;
  margin-right: 6px !important;
  margin-top: 30px !important;
  &.rec-dot_active {
    background-color: $global-color-1 !important;
    width: 48px !important;
  }

  &:first-child:last-child {
    display: none !important;
  }
}

.ant-input-group-addon {
  background-color: #fff !important;
  left: 0 !important;
}

.ant-input-search
  > .ant-input-group
  > .ant-input-group-addon:last-child
  .ant-input-search-button:not(.ant-btn-primary) {
  color: #bbc6d1 !important;
  border: 0 !important;
  box-shadow: none !important;
}

.ant-input {
  font-size: 14px !important;
  font-family: "Poppins-Regular" !important;
  // color: #758CA1 !important;
  font-weight: normal !important;
  @include placeholder-color(#bbc6d1);
  border: 0 !important;
  outline: none !important;
  box-shadow: none !important;
  line-height: normal !important;
}

.ant-select {
  width: auto !important;
  min-height: 50px !important;
  font-size: 14px !important;
  font-family: "Poppins-Regular" !important;
  color: #bbc6d1 !important;
  font-weight: normal !important;
  @include d-flex-i;
  align-items: center !important;
  justify-content: center !important;
  line-height: 1 !important;
  padding: 0 !important;
  background-color: #fff !important;
  box-shadow: 0px 2px 3px rgba($color: #000000, $alpha: 0.08) !important;
  border-radius: 5px !important;
  // !!! attention to all the selects
  // overflow: hidden !important;

  .ant-select-selector {
    width: 100% !important;
    height: 100% !important;
    border: 0 !important;
    padding: 0 0 0 15px !important;
    min-height: 50px !important;
    // .ant-select-selection-search {
    // display: none !important;
    // }

    .ant-select-selection-placeholder {
      @include d-flex-i;
      align-items: center !important;
      // height: 100% !important;
      line-height: 1 !important;
      font-size: 14px !important;
      font-family: "Poppins-Regular" !important;
      color: #bbc6d1 !important;
      font-weight: normal !important;
      padding-right: 73px !important;
      padding-left: 6px !important;
    }

    .ant-select-selection-item {
      @include d-flex-i;
      align-items: center !important;
      // height: 100% !important;
      line-height: 1 !important;
      font-size: 14px !important;
      font-family: "Poppins-Regular" !important;
      // color: #758CA1 !important;
      font-weight: normal !important;
      padding-right: 73px !important;
    }
  }

  .ant-select-arrow {
    right: 14px !important;
    color: #bbc6d1 !important;
  }
}

.ant-select-item {
  font-size: 14px !important;
  font-family: "Poppins-Regular" !important;
  color: #bbc6d1 !important;
  font-weight: normal !important;

  &.ant-select-item-option-selected {
    font-family: "Poppins-Medium" !important;
  }
}

// -----------------------

.ant-picker-range .ant-picker-active-bar {
  background: #536dfe !important;
}

.ant-picker {
  box-shadow: 0px 2px 3px rgba($color: #000000, $alpha: 0.08) !important;
  width: auto !important;
  height: 50px !important;
  padding: 0 15px !important;
  font-size: 14px !important;
  font-family: "Poppins-Regular" !important;
  color: #bbc6d1 !important;
  font-weight: normal !important;
  border: 0 !important;
  outline: none !important;
  line-height: normal !important;
  @include d-flex-i;
  align-items: center !important;

  .ant-picker-input > input {
    font-size: 14px !important;
    font-family: "Poppins-Regular" !important;
    color: #bbc6d1 !important;
    font-weight: normal !important;
    line-height: normal !important;
    @include placeholder-color(#bbc6d1);
    border: 0 !important;
    outline: none !important;
  }
}

.ant-picker-panel .ant-picker-footer {
  border: 0 !important;
  border-top: 2px solid #dde4eb !important;
  line-height: 40px !important;
}

.ant-picker-dropdown {
  .ant-picker-time-panel-column {
    width: 74px;
  }
  .ant-picker-time-panel-cell-inner {
    padding: 0 !important;
    display: flex !important;
    justify-content: center;
    align-items: center;
  }
  .ant-picker-body {
    padding: 10px 15px 15px 16px !important;
    > table {
      width: auto;
      > thead {
      }
      > tbody {
        > tr {
          .ant-picker-cell-inner {
            width: 31px;
            height: 31px;
            min-width: 31px;
            @include d-flex;
            align-items: center;
            justify-content: center;
            border-radius: 50%;
          }
          .ant-picker-cell {
            padding: 4px 10px;
            width: 31px;
            height: 31px;
            &::before {
              height: 31px;
            }
          }
        }
      }
    }
  }
  .ant-picker-date-panel {
    width: 100% !important;
  }
  .ant-picker-time-panel {
    .ant-picker-content > ul {
      &:not(:last-child) {
        .ant-picker-time-panel-cell-inner {
          color: #4d677e;
          line-height: 25px;
          height: 35px;
          padding-top: 10px !important;
        }
      }
      &:last-child {
        .ant-picker-time-panel-cell-inner {
          height: 48px;
        }
        .ant-picker-time-panel-cell-selected > div {
          color: #ffffff;
          background: #536dfe !important;
        }
      }
    }
  }
  .ant-picker-header {
    align-items: center;
    border-color: #dde4eb !important;
    padding: 28px !important;
    height: 70px;

    button {
      color: #a6b0bb !important;
      transition: color 0.3s !important;

      &:hover {
        color: #536dfe !important;
      }
    }

    .ant-picker-header-view {
      font-size: 14px !important;
      font-family: "Poppins-Medium" !important;
      color: #4d677e !important;
      font-weight: normal !important;
      line-height: 21px !important;

      button {
        color: #4d677e !important;

        &:hover {
          color: #536dfe !important;
        }
      }
    }
  }

  .ant-picker-panel {
    border-color: #e7ebef !important;
    border-width: 0 0 1px 0 !important;
    > div {
      &:first-child {
      }
      &:last-child {
        > ul {
          padding: 10px 13px 9.2px 31px;
          > li {
            &:first-child {
              a {
                color: #536dfe;
                line-height: 21px;
                text-decoration: none;
              }
            }
            &:last-child {
              button {
                padding: 7px 14px 8px 12px;
                @include d-flex;
                align-items: center;
                justify-content: center;
                height: auto;
                span {
                  font-family: "Poppins-Regular";
                  line-height: 21px;
                }
              }
            }
          }
        }
      }
    }
  }

  .ant-picker-content th {
    font-size: 14px !important;
    font-family: "Poppins-Regular" !important;
    color: #bbc6d1 !important;
    font-weight: normal !important;
  }

  .ant-picker-content td {
    font-size: 14px !important;
    font-family: "Poppins-Regular" !important;
    color: #4d677e !important;
    font-weight: normal !important;

    &.ant-picker-cell {
      color: #bbc6d1 !important;
    }

    &.ant-picker-cell-in-view {
      color: #4d677e !important;
    }

    &.ant-picker-cell-in-view.ant-picker-cell-today {
      color: #fff !important;
    }

    &.ant-picker-cell-today {
      .ant-picker-cell-inner {
        &::before {
          border-radius: 50%;
        }
      }
    }
    &.ant-picker-cell-selected {
      .ant-picker-cell-inner {
        border-radius: 50%;
      }
    }
    &.ant-picker-cell-in-view.ant-picker-cell-today .ant-picker-cell-inner::before {
      border-color: #536dfe !important;
      background: #536dfe !important;
      z-index: -1 !important;
    }

    &.ant-picker-cell-in-view.ant-picker-cell-selected .ant-picker-cell-inner,
    &.ant-picker-cell-in-view.ant-picker-cell-range-start .ant-picker-cell-inner,
    &.ant-picker-cell-in-view.ant-picker-cell-range-end .ant-picker-cell-inner {
      background: #536dfe !important;
    }
  }
  .ant-picker-time-panel-column:not(:first-child) {
    border-left: 1px solid #dde4eb;
  }
  .ant-picker-datetime-panel .ant-picker-time-panel {
    border-left: 2px solid #dde4eb;
  }
  .ant-picker-year-panel,
  .ant-picker-month-panel {
    table {
      width: 100%;
      > tbody {
        > tr {
          .ant-picker-cell-inner {
            width: 51px;
            height: 51px;
          }
        }
      }
    }
  }
  .ant-picker-decade-panel {
    table {
      width: 100%;
      > tbody {
        > tr {
          .ant-picker-cell-inner {
            width: 61px;
            height: 61px;
          }
        }
      }
    }
  }
}

.ant-slider {
  line-height: 1.15 !important;
  margin-left: 0 !important;
  margin-right: 0 !important;
  height: 14px !important;
}

.ant-slider-with-marks {
  margin-bottom: 18px !important;
}

.ant-slider-min-max-row {
  @include d-flex;
  align-items: center;
  justify-content: space-between;

  > span {
    width: 142px !important;
    height: 30px !important;
    border-radius: 4px !important;
    background-color: #ffffff !important;
    @include d-flex-i;
    align-items: center !important;
    font-size: 13px !important;
    font-family: "Poppins-Regular" !important;
    color: #536dfe !important;
    font-weight: normal !important;
    margin: 0 !important;
    padding: 0 !important;
    padding: 0 15px !important;
    line-height: normal !important;
  }

  &__line {
    width: 25px !important;
    min-width: 25px !important;
    height: 2px;
    background-color: #a5b4b6 !important;
    margin: 0 15px;
    padding: 0 !important;
  }
}

.ant-slider-mark {
  color: #7a888a !important;
  font-size: 10px !important;
  font-family: "Poppins-Regular" !important;
  font-weight: normal !important;
  top: 22px !important;

  .ant-slider-mark-text {
    color: #7a888a !important;
    font-size: 10px !important;
    font-family: "Poppins-Regular" !important;
    font-weight: normal !important;
  }
}

.advanced-filter-content .ant-slider-handle {
  box-shadow: 0px 2px 4px rgba($color: #535bfe, $alpha: 0.5) !important;
}

.advanced-filter-content .ant-slider-rail {
  border-radius: 0 !important;
}

// -------------------------------

.ant-checkbox::after {
  content: none !important;
}

.ant-checkbox-inner {
  width: 30px !important;
  height: 30px !important;
  background-color: #dde4eb !important;
  border: 0 !important;
  @include d-flex-i;
  align-items: center;
  justify-content: center;
  transition: all 0.3s !important;

  &::after {
    content: url(../../assets/images/icons/screens/tick.svg) !important;
    width: auto !important;
    height: auto !important;
    position: absolute !important;
    left: 50% !important;
    top: 50% !important;
    right: auto !important;
    bottom: auto !important;
    border: 0 !important;
    display: inline-block !important;
    transition: all 0.3s !important;
    @include transform(translate(-50%, -50%));
  }
}

.ant-checkbox-checked .ant-checkbox-inner {
  background-color: #24dcae !important;
}

.ant-checkbox-indeterminate .ant-checkbox-inner::after {
  content: none !important;
}

// --------------------------------

.ant-table-column-sorters {
  justify-content: flex-start !important;
}

.ant-table-column-title {
  flex: none !important;
}

.ant-table {
  line-height: 1.15 !important;
  font-size: 14px !important;
  font-family: "Poppins-Regular" !important;
  color: #4d677e !important;
  font-weight: normal !important;
  box-shadow: 0px 2px 6px rgba($color: #000000, $alpha: 0.05);

  .ant-table-selection-column {
    width: auto !important;
    min-width: 0 !important;
  }

  table {
    .ant-table-thead {
      > tr > th {
        font-size: 14px !important;
        font-family: "Poppins-Regular" !important;
        color: #a6b0bb !important;
        font-weight: normal !important;
        background-color: #fff !important;
        padding: 22px 24px !important;
        border-bottom: 2px solid #dde4eb !important;
        vertical-align: middle !important;

        &::before {
          display: none !important;
        }

        .ant-table-column-sorter {
          color: #dde4eb !important;
          margin-left: 20px;
        }

        .ant-table-column-sorter-up.active,
        .ant-table-column-sorter-down.active {
          color: #758ca1 !important;
        }
      }
    }

    .ant-table-tbody {
      > tr {
        &.ant-table-row-selected {
          > td {
            border-color: #e7ebef !important;
            background: #fafafa !important;
          }
        }

        > td {
          padding: 22px 24px !important;
          vertical-align: top !important;
          border-bottom: 0 !important;

          .a-btns-m1 {
            gap: 15px !important;
          }

          p {
            margin: 0 !important;
            line-height: 21px;
            letter-spacing: 0.14px;
          }
        }

        &:not(:last-child) {
          > td {
            border-bottom: 1px solid #e7ebef !important;
          }
        }
      }

      .info-section {
        .textSize14 {
          font-size: 14px !important;
          font-family: "Poppins-Regular" !important;
          color: #4d677e !important;
          font-weight: normal !important;
          .ant-typography {
            font-size: 14px !important;
            font-family: "Poppins-Regular" !important;
            color: #4d677e !important;
            font-weight: normal !important;
            line-height: 21px;
            letter-spacing: 0.14px;
          }
        }

        .content {
          font-size: 12px !important;
          font-family: "Poppins-Regular" !important;
          color: #bbc6d1 !important;
          font-weight: normal !important;
          .textSize12 {
            font-size: 12px !important;
            font-family: "Poppins-Regular" !important;
            color: #bbc6d1 !important;
            font-weight: normal !important;
            line-height: 18px;
            letter-spacing: 0.12px;
            &.differentColor {
              color: #758ca1 !important;
            }
          }
        }
      }
    }
  }
}

// ----------------------------------------------------------------------------

.auth-layout {
  .sidebar.sidebar-s1 {
    .ant-layout-sider-children {
      @include d-flex;
      flex-direction: column;
    }

    &:not(.ant-layout-sider-collapsed) {
      .ant-menu {
        .ant-menu-item {
          .ant-menu-title-content {
          }
        }
      }
    }
  }
}

.question-s1 {
  @include d-flex;
  align-items: center;
  justify-content: center;
  // width: 54px;
  // border-top: 1px solid #13258D;
  padding-top: 26px;
  padding-bottom: 13px;
  margin-left: 7px !important;
  margin-right: 7px !important;
  margin-top: auto;

  a {
    [fill] {
      // @include transition(all 0.3s);
      fill: #fff;
    }

    //   &:hover {

    //     [fill] {
    //       fill: #fff;
    //     }

    //   }
  }
}

.menu-s1 {
  @include d-flex;
  flex-direction: column;
  overflow-y: overlay;
  margin-top: 15px !important;
  padding-left: 8px !important;
  padding-right: 8px !important;
  padding-bottom: 26px !important;
  margin-left: -13px !important;
  margin-right: -13px !important;
  width: calc(100% + 26px) !important;

  &::before {
    display: none !important;
  }

  &::after {
    display: none !important;
  }

  &::-webkit-scrollbar {
    background-color: transparent;
    width: 3px;
  }

  &::-webkit-scrollbar-button {
    display: none !important;
  }

  &::-webkit-scrollbar-thumb {
    background-color: rgba(255, 255, 255, 0.8);
    border-radius: 5px;
  }

  &::-webkit-scrollbar-track {
    background-color: transparent;
  }
}

.logo-s1 {
  @include d-flex;
  justify-content: center;
  margin-top: 15px;
  //   width: 100%;
  //   max-width: 68px;
}
.ant-layout-sider-collapsed {
  .logo-s1 {
    @include d-flex;
    justify-content: center;
    //   width: 100%;
    //   max-width: 68px;
    #Group_8693 {
      & > path {
        visibility: hidden;
        // display: none;
        transition: all ease-out 0.3s;
      }
    }
  }
}
#Group_8693 {
  & > path {
    transition: all ease-out 0.3s;
  }
}

.ant-tooltip-inner {
  background-color: #000000 !important;
  a {
    color: #fff !important;
    text-decoration: none !important;
  }
}

// ---------------------------

.ant-dropdown {
  line-height: 1.15 !important;
  border-radius: 5px !important;

  div {
    text-align: left !important;
  }
}

.user-dropdown-m1 {
  width: 359px !important;
  background: #ffffff !important;
  box-shadow: 0px 3px 20px rgba($color: #000000, $alpha: 0.09) !important;
  border-radius: 5px !important;
  overflow: hidden !important;
  border: 0 !important;
  outline: 0 !important;
  transition: none !important;
  padding: 0px !important;

  &::before {
    display: none !important;
  }

  &::after {
    display: none !important;
  }

  ul {
    padding: 0px;
  }

  ul > li {
    margin: 0 !important;
    padding: 0 !important;
    height: auto !important;
    line-height: 1.15 !important;
    @include d-flex-i;
    transition: none !important;
    background: transparent !important;

    &::before {
      content: none !important;
    }

    &::after {
      content: none !important;
    }

    &:nth-child(1) {
      padding: 20px 15px !important;
      background-image: url(../../../src/assets/images/user-dopdown-bg.png) !important;
      background-repeat: no-repeat !important;
      background-size: cover !important;
      position: relative !important;
      align-items: center !important;

      &::before {
        content: "" !important;
        position: absolute !important;
        left: 0 !important;
        top: 0 !important;
        width: 100% !important;
        height: 100% !important;
        background-color: rgba($color: #536dfe, $alpha: 0.9) !important;
      }

      > .ant-dropdown-menu-item-icon {
        @include d-flex-i;
        width: 42px !important;
        height: 42px !important;
        border-radius: 100% !important;
        background-color: rgba($color: #13258d, $alpha: 0.34) !important;
        align-items: center !important;
        justify-content: center !important;
        position: relative;
        svg {
          #fi-rr-user {
            path {
              fill: #ffffff !important;
            }
          }
        }
      }
      > .ant-dropdown-menu-title-content {
        position: relative !important;
        z-index: 1;
        @include d-flex-i;
        margin-left: 15px !important;
        > .ant-space {
          @include d-flex-i;
          flex-direction: column !important;
          align-items: flex-start !important;
          justify-content: flex-start !important;
          gap: 0 !important;

          > .ant-space-item {
            @include d-flex-i;

            &:nth-child(1) {
              p {
                font-size: 14px !important;
                font-family: "Poppins-Medium" !important;
                color: #ffffff !important;
                font-weight: normal !important;
                margin: 0 !important;
              }
            }
            &:nth-child(2) {
              margin-top: 4px !important;
              p {
                font-size: 12px !important;
                font-family: "Poppins-Light" !important;
                color: rgba($color: #ffffff, $alpha: 0.67) !important;
                font-weight: normal !important;
                margin: 0 !important;
              }
            }
          }
        }
      }
    }

    &:nth-child(2) {
      margin-top: 33px !important;
    }

    &:nth-child(2),
    &:nth-child(3),
    &:nth-child(4),
    &:nth-child(5) {
      padding: 0 15px !important;
      margin-bottom: 30px !important;
      align-items: center !important;

      > * {
        &:nth-child(1) {
          width: 16px !important;
          @include d-flex-i;
          justify-content: center !important;
            .action-button {
                &:hover {
                    svg{
                        path{
                            fill: #bbc6d1 !important;
                        }
                    }
                    &::before {
                        background-color: transparent !important;
                    }
                }
            }
        }

        &:nth-child(2) {
          margin-left: 15px !important;
          font-size: 14px !important;
          font-family: "Poppins-Medium" !important;
          color: #4d677e !important;
          font-weight: normal !important;
            .action-button {
                justify-content: normal;
                &:hover {
                    &::before {
                        background-color: transparent !important;
                    }
                }
            }
        }
      }
    }

    &:nth-child(6) {
      border-top: 1px solid #dde4eb !important;
      padding: 25px 25px 25px 15px !important;
      justify-content: flex-end !important;
      margin-top: calc(42px - 30px) !important;

      > .ant-dropdown-menu-title-content {
        @include d-flex-i;
        flex: none;

        button {
          @include d-flex-i;
          height: 40px !important;
          border-radius: 5px !important;
          background-color: #fc7886 !important;
          padding: 0 34px !important;
          align-items: center !important;
          line-height: normal !important;

          > div {
            @include d-flex-i;
          }

          span {
            font-size: 14px !important;
            font-family: "Poppins-Medium" !important;
            color: #ffffff !important;
            font-weight: normal !important;
            margin-left: 20px !important;
          }
        }
      }
    }
  }
}

// -------------------------------------------------------

.ant-input-affix-wrapper {
  padding: 0 21px !important;
  height: 50px !important;
  line-height: 1.15 !important;
  background: #ffffff !important;
  border-radius: 5px !important;
  box-shadow: 0px 2px 3px rgba($color: #000000, $alpha: 0.08) !important;
  @include d-flex-i;
  align-items: center !important;

  .ant-input {
    width: 100% !important;
  }

  .ant-input-suffix {
    @include d-flex-i;
    font-family: "Poppins-Regular";
    color: #bbc6d1;
  }
}

// -------------------------------------------------------

.ant-picker {
  cursor: pointer;
}
.ant-upload-list-item-info > span {
  height: auto !important;
  width: auto !important;
  // display: unset !important;
  img {
    object-fit: cover;
  }
}
.ant-upload-list-picture-card .ant-upload-list-item {
  padding: 0px !important;
  border: none !important;
}
.ant-upload-list-item-info {
  border-radius: 5px;
  // margin-top:21px;
}

.text-circle-button {
  .create-section_button {
    @include d-flex;
    justify-content: center;
    align-items: center;
    width: 25px;
    height: 25px;
    background: #536dfe;
    border-radius: 50%;
  }

  span {
    line-height: 21px;
    font-family: "Poppins-Regular";
  }
}
///STEPS
.ant-steps {
  .ant-steps-item-container {
    @include d-flex;
    flex-direction: column-reverse;
    align-items: center;
    justify-content: center;

    .ant-steps-item-content {
      margin-top: 0px;
    }
    .ant-steps-item-icon {
      border: 4px solid #ffffff;
      @include d-flex;
      justify-content: center;
      align-items: center;
      background: #e7ebef;
      color: #bbc6d1;
      font-family: "Poppins-Medium";
      margin-left: 0px;
      margin-right: 0;
      flex-shrink: 0;
    }
    .ant-steps-item-tail {
      &::after {
        height: 2px;
        background-color: #dde4eb !important;
      }
    }
    .ant-steps-item-title {
      font-family: "Poppins-Medium";
      font-size: 12px;
    }
  }
}
.invoice,
.add-flight,
.add-employee {
  .step-display {
    .ant-steps-item-container {
      width: 256px;
      .ant-steps-item-icon {
        border: 4px solid #edeff2 !important;
        width: 40px;
        height: 40px;
        background: #dde4eb;
      }
      .ant-steps-item-tail {
        display: unset;
        top: 66px !important;
        margin-left: 49%;
      }
    }
  }
}
.ant-steps-item-finish > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title {
  color: #536dfe !important;
}
.ant-steps-item-finish > .ant-steps-item-container > .ant-steps-item-icon {
  background: #3b55eb !important;
  > .ant-steps-icon {
    color: #ffffff;
  }
}
.ant-steps-item-finish > .ant-steps-item-container > .ant-steps-item-tail::after {
  background-color: #3b55eb !important;
}
.ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title {
  color: #3b55eb !important;
}
.ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-icon {
  background: #3b55eb !important;
}
.ant-steps-item-wait > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title {
  color: #bbc6d1 !important;
}
///////////////////////////////////////
//////////EMPTY DATA DISPLAY
.data-display {
  margin-top: 0px !important;
  margin-bottom: 0px !important;
  .empty {
    width: 100%;
    height: 100%;
    @include d-flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    .empty-data-icon {
      margin-bottom: 20px;
    }
    .text {
      font-size: 12px;
      font-family: "Poppins-Regular";
      line-height: 19px;
      letter-spacing: 0.24px;
      color: $global-color-10;
    }
  }
}

input:-internal-autofill-selected {
  -webkit-box-shadow: 0 0 0 30px white inset !important;
}
/* Change the white to any color */
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px white inset !important;
}

.participants-tooltip {
  max-width: 100% !important;
  .ant-tooltip-content {
    box-shadow: 0px 7px 15px 0px rgba(0, 0, 0, 0.08);
  }

  .ant-tooltip-inner {
    background-color: #ffffff !important;

    padding: 0 !important;
    .ant-list-header {
      padding: 16px 29px;
      background-color: #e7ebef;
      border-radius: 5px 5px 0 0;
      > div {
        @include d-flex;
        align-items: center;
        span {
          color: $global-color-9 !important;
          text-decoration: none !important;
          white-space: nowrap;
          &:not(:last-child) {
            margin-right: 82px;
          }
        }
      }
    }
    .ant-list-items {
      .ant-list-item {
        padding: 16px 29px;
      }
    }
    span {
      font-size: 13px;
      color: #758ca1 !important;
      line-height: 20px;
      text-decoration: none !important;
    }
  }
  .ant-tooltip-arrow-content {
    --antd-arrow-background-color: #e7ebef !important;
  }
}

.mui-form-item {
  .ant-form-item-control-input {
    border: 2px solid $global-color-10;
    border-radius: 5px;
    box-shadow: none !important;
  }
}
.ant-picker-range .ant-picker-clear {
  right: -13px !important;
}
.ant-slider-rail {
  background-color: #d8d8d8;
  height: 2px !important;
}
.ant-slider-track {
  background-color: #536dfe !important;
  height: 2px !important;
}
.ant-slider-dot {
  display: none !important;
}
.ant-slider-handle {
  border: 3px solid #ffffff !important;
  background-color: #536dfe !important;
  box-shadow: 0px 0px 10px rgb(83 91 254 / 50%);
  margin-top: -7px !important;
}
.ant-slider-tooltip {
  padding-bottom: 0px !important;
  // top: 215px !important;

  .ant-tooltip-inner {
    background-color: transparent !important;
    color: #536dfe;
    font-family: "Poppins-Regular";
    letter-spacing: -0.12px;
    box-shadow: none !important;
  }
  .ant-tooltip-arrow {
    display: none;
  }
}
.ant-form-inline .ant-form-item-with-help {
  margin-bottom: 0px !important;
}

.right-col_cards {
  font-size: 14px;
  > div {
    > .ant-space > div:last-child > div > div:nth-child(2) {
      margin: 2px 0;
    }
    &:not(:last-child) {
      border-bottom: 1px solid #dde4eb;
    }
    &:first-child:not(:last-child) {
      padding-bottom: 18.5px;
      padding-top: 17.5px;
    }
    &:last-child:not(:first-child) {
      padding-top: 18.5px;
      padding-bottom: 20.5px;
    }
    &:not(:first-child, :last-child) {
      padding: 18.5px 0;
    }
  }
  .ant-rate {
    margin-left: 8px;
    svg {
      width: 12px;
      height: 11px;
    }
  }
  .season-price {
    width: 64px;
    height: 64px;
    border-radius: 50%;
    background-color: $global-color-3;
    text-align: center;
    color: $color-white;
    line-height: 12px;
    @include d-flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    font-family: "Poppins-Medium";
    margin-right: 14px;
    .season-price_item {
      letter-spacing: 0.14px;
      font-size: 14px;
    }
    > div {
      font-size: 9px;
      letter-spacing: 0.09px;
    }
  }
  .ant-typography {
    color: #758ca1;
    font-family: "Poppins-Medium";
    line-height: 21px;
    letter-spacing: 0.14px;
  }
  .description {
    span {
      font-size: 12px;
      font-family: "Poppins-Regular";
      color: #bbc6d1;
      line-height: 18px;
      letter-spacing: 0.12px;
    }
  }
  .rooms-created-card_image {
    margin-right: 14px;
    width: 64px;
    height: 64px;
    border-radius: 50%;
    img {
      width: 100%;
      border-radius: 50%;
    }
  }
}

.ant-upload-select-picture-card {
  font-family: "Montserrat-Medium" !important;

  > span {
    > div {
      @include d-flex;
      align-items: center;
      justify-content: center;
      > div {
        &:first-child {
          margin-bottom: 10px;
        }
        &:nth-child(2) {
          margin-bottom: 12px;
          line-height: 20px;
          span {
            font-size: 13px;
            font-family: "Montserrat-Medium";
            line-height: 20px !important;
          }
        }
        &:nth-child(3) {
          line-height: 18px;
          width: 60px;
          max-width: 60px;
          span {
            font-size: 12px;
            line-height: 18px !important;
            font-family: "Montserrat-Medium";
            &::before {
              content: "";
              display: block;
              width: 15.83px;
              height: 1px;
              background: #bbc6d1;
              left: 0;
              top: 50%;
              position: absolute;
            }
            &::after {
              content: "";
              display: block;
              width: 15.83px;
              height: 1px;
              background: #bbc6d1;
              right: 0;
              top: 50%;
              position: absolute;
            }
          }
        }
        &:last-child {
          margin-top: 15px;
          line-height: 21px;
          button {
            padding: 0;
            height: 100%;
            span {
              font-size: 10px;
              line-height: 21px;
              font-family: "Montserrat-Medium";
            }
          }
        }
      }
    }
  }
}
.ant-image {
  img {
    object-fit: cover;
  }
}
.form-button {
  margin-top: 20px;
  button {
    @include d-flex;
    justify-content: center;
    align-items: center;
    padding: 18px 49px;
    svg {
      margin-left: 20px;
    }
    .ant-space-item {
      font-family: "Montserrat-Medium";
      font-size: 12px;
      letter-spacing: -0.12px;
      line-height: 15px;
    }
  }
}
.ant-form-item-explain-error {
  text-align: end;
  font-family: "Poppins-Light";
  line-height: 18px;
  color: #fc7886;
}

.ant-picker-suffix,
.ant-input-suffix {
  svg {
    width: 24px;
    height: 24px;
    path,
    circle {
      fill: #dde4eb;
    }
  }
}
.ant-modal-header {
  border-color: #e7ebef !important;
}
.ant-modal-footer,
.ant-divider-horizontal,
.ant-card-bordered {
  border-color: #dde4eb !important;
}

.ant-dropdown-trigger {
  svg {
    path {
      fill: #bbc6d1;
    }
  }
}
.company-info-tooltip {
  .ant-tooltip-inner {
    padding: 0 !important;
    background-color: transparent !important;
  }
  .ant-card-body {
    padding: 0 30px !important;
  }
  .ant-card-head {
    border-bottom: 1px solid #536dfe;
    .ant-card-head-title {
      color: #536dfe;
      padding: 22px 0 16px 0;
    }
  }
  .info-section {
    padding: 22px 0 16px 0;
    .name {
      span {
        font-size: 12px;
        line-height: 18px;
        color: #bbc6d1;
        letter-spacing: -0.12px;
      }
    }
    .content {
      margin-top: 4px;
      line-height: 21px;
      color: #758ca1;
      letter-spacing: -0.14px;
    }
  }
  .ant-divider {
    border-width: 1px;
    margin: 0 !important;
  }
}

.discount-card {
  .ant-card-head {
    background-color: #e7ebef;
    .ant-card-head-title {
      color: #4d677e;
      letter-spacing: 0.14px;
    }
  }
  .ant-card-body {
    padding: 29px 30px 31px 25px;
    > div {
      margin-bottom: 0 !important;
    }
  }
  .ant-input-affix-wrapper {
    border: 1px solid #cbd9e5;
    box-shadow: none !important;
    padding: 0 3.5px 0 21px !important;
    &:hover {
      border-right-width: 1px !important;
    }
    input::placeholder {
      color: #758ca1 !important;
      font-family: "Poppins-Medium";
      letter-spacing: -0.14px;
    }
  }
  .ant-radio-button-wrapper {
    width: 88px !important;
    &:not(.ant-radio-button-wrapper-checked) {
      span {
        color: #758ca1;
      }
    }
  }
}
.right-col-container {
  width: calc(100% - 58% - 124px + 30px);
  max-width: calc(100% - 58% - 124px + 30px);
  position: fixed;
  .right-col {
    margin-bottom: 30px;
    width: 100%;
  }
}
.tour-extra-services {
  padding-top: 9px;
  > .ant-col:last-child {
    > div {
      margin-bottom: 0;
    }
  }
  > div {
    &:nth-child(2) {
      > div {
        &:first-child {
          margin-bottom: 15px;
        }
        &:last-child {
          margin-bottom: 0;
          .ant-checkbox + span {
            color: #bbc6d1 !important;
            letter-spacing: 0.14px !important;
          }
        }
      }
    }
  }
}
#fi-rr-list {
  path,
  circle {
    fill: #758ca1;
  }
}
.ant-image-mask-info {
  @include d-flex;
  align-items: center;
  border-radius: 5px;
}
.ant-notification-notice-close {
  top: 4px !important;
  right: 9px !important;
}

.ant-notification-notice-error, .ant-notification-notice-success {
  .ant-notification-notice-with-icon {
    @include d-flex;
    align-items: center;
    padding: 40px;
    > .ant-notification-notice-message {
      padding: 0 !important;
      margin: 0 !important;
    }
    > .ant-notification-notice-description {
      > div {
        padding: 0 !important;
      }
    }
    > .ant-notification-notice-icon-error {
      line-height: unset;
    }
  }
//   .ant-notification-notice-close  {
//     display: none;
// }
}

#add-accommodation-page,
#add-tour-trip-page,
#add-cruise-tour-page,
#add-tour-packages-page,
#add-tour-activity-page {
  .right-col-container {
    > .ant-row-end {
      align-items: center;
      .action-button {
        width: 50px;
        height: 50px;
        &:hover {
          &::before {
            background-color: #dde4eb;
          }
        }
      }
    }
  }
}
