.search-form {
    height: auto;
    background-color: $global-color-6;
    
    .ant-picker {
        border: none;
        padding-left: 9px !important;
    }

    .date-title{
        padding-left: 9px;
        font-size: 12px;
        color: #BBC6D1;
    }
    .ant-dropdown-trigger{
        span{
            font-size: 12px;
            color: #BBC6D1;
            font-family: "Poppins-Regular";
            padding-right: 10px;
        }
    }
    button{
        &::after{
            display: none;
        }
    }
    .search-button{
        background-color:#536DFE !important; 
    }
    .add-button_col{
        @include d-flex;
        align-items: center;
        justify-content: center;
        >.ant-col{
            padding-right: 30px!important;
        }
        .add-group-button{
            border: 2px solid #536DFE !important;
            border-radius: 5px;
            font-family: "Poppins-Medium";
            font-size: 16px;
            @include d-flex;
            justify-content: space-evenly;
            align-items: center;
            svg{
                line{
                    stroke:#536DFE;
                }
            }
            span{
                line-height: 25px;
                letter-spacing: 0.16px;
            }
        }
    }
   
}
