.billed-for {
    .ant-typography {
        font-size: 14px;
        color: $global-color-4;
    }

    .ant-select, .ant-input-affix-wrapper, .ant-picker {
        margin-top: 20px;
    }

    .ant-select, .ant-input-affix-wrapper, .ant-picker {
        border: 2px solid $global-color-10 !important;
        box-shadow: none !important;
    }

    textarea.ant-input {
        border: 2px solid $global-color-10 !important;
    }

    .info {
        margin-top: 20px;
        margin-bottom: 30px;
        svg {
            margin-right: 10px;
            fill {
                color: $global-color-2;
            }
        }
        .ant-typography {
            font-size: 11px;
            font-family: 'Poppins-Light';
            color: $global-color-2;
        }
    }

    .ant-btn {
        border: 2px solid $global-color-1 !important;
        color: $global-color-1;
        font-size: 14px;
        display: flex;
        align-items: center;
        justify-content: center;

        svg {
            margin-right: 10px;
            g {
                line {
                    stroke: $global-color-1;
                }
            }
        }
    }
}
