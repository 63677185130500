.preview {
    @import './Extras/style';
    @import './General/style';
    @import './Participants/style';
    @import './Total/style';
    @import './Reservation/style';

    .ant-divider{
        border-width: 1px;
    } 
        .general-details{
            >div:first-child{
                text-align: center;
            }
            .ant-avatar{
                background-color: #F4F6F8;
            }
                .ant-row{
                   .ant-col{
                    @include d-flex;
                    flex-direction: column;
                    padding-left: 15px;
                       &:first-child{
                           h5{
                            color: #758CA1;
                            font-family: "Poppins-Medium";
                            font-size: 14px !important;
                            line-height: 35px;
                            margin-bottom: 0;
                            font-weight: normal;
                           }
                           >.ant-typography{
                            margin-bottom: 7px;
                            >.ant-space{
                                align-items: flex-start;
                                >div{
                                    &:first-child{
                                        svg{
                                            width: 15px;
                                            height: 15px;
                                            vertical-align: bottom;
                                            path{
                                                fill: #BBC6D1;
                                            }
                                        }
                                    }
                                    &:last-child{
                                        font-size: 12px;
                                        color: #758CA1;
                                        line-height: 18px;
                                    }
                                }
                            }
                           }
                       }
                       &:last-child{
                        h5{
                            color: #BBC6D1;
                            font-weight: unset;
                            margin-bottom: 0;
                            line-height: 35px !important;
                            font-size: 14px !important;
        
                           }
                           >.ant-typography{
                            margin-bottom: 0 !important;
                            line-height: 20px;
                            font-size: 12px;
                            color: #758CA1;
                            &:nth-child(2){
                                color: #4D677E;
                                font-size: 14px;
                            }
                           }
                       }
                
                   }
                }
        }

        .accommodation-details{
            >div:first-child{
                text-align: center;
            }
                .ant-row{
                    .ant-col{
                        &:first-child{
                            padding-left: 20px;
                            > ul > li{
                                svg{
                                    width: 11px;
                                    height: 11px;
                                }
                            }
                            h5{
                                font-family: "Poppins-Medium";
                                color: #4D677E;
                                margin: 3.8px 0;
                                font-weight: normal;
                                line-height: 25px;
                                letter-spacing: 0.16px;
                            }
                        }
                        &:last-child{
                           padding-left: 15px;
                           >div{
                            &:first-child{
                                margin-bottom: 10px;
                                svg{
                                    width: 20px;
                                    height: 20px;
                                    vertical-align: -webkit-baseline-middle;
                                }
                            }
                            &:last-child{
                                svg{
                                    width: 14.8px;
                                    height: 19.73px;
                                    vertical-align: -webkit-baseline-middle;
                                }
                            }
                           }
                           .ant-space{
                            align-items: flex-start;
                               .ant-space-item{
                                   &:last-child{
                                       .label{
                                           font-size: 12px;
                                           line-height: 20px;
                                           color: #BBC6D1;
                                       }
                                       .content{
                                           font-size: 14px;
                                           line-height: 20px;
                                           color: #4D677E;
                                       }
                                   }
                               }
                           }
                        }
                    }
              
                }
        }

        .date-of-issue, .service-list {
            h5, .date, .total-price {
                color: $global-color-9;
            }
        }

        .service-list {
            .service-key {
                width: 23px;
                height: 23px;
                border-radius: 100%;
                border: 2px solid $global-color-2;
                display: flex;
                justify-content: center;
                align-items: center;
                font-family: 'Poppins-Medium';
                color: $global-color-2;
                font-size: 14px;
            }
            .description {
                font-size: 13px;
                color: $global-color-4;
                font-family: 'Poppins-Italic';
            }
        }

        .rooms, .extras ,.participants,.extra-bed {
            align-items: baseline;
            .ant-col{
                &:first-child{
                    svg{
                        width: 23px;
                        height: 19px;
                        vertical-align: bottom;
                    }
                }
                &:last-child{
                    padding-left: 20px;
                    h5{
                        color: #BBC6D1;
                        font-family: "Poppins-Medium";
                        font-weight: unset;
                        font-size: 14px;
                        margin-bottom: 3px;
                        line-height: 3;
                     }
                     .ant-typography{
                         &:nth-child(2){
                             color: #758CA1;
                             margin-bottom: 3px;
                         }
                     }
                }
            }

        }
        .participants, .extras{
            >.ant-col{
                &:last-child{
                    .ant-row{
                        align-items: baseline;
                        .ant-col{
                            &:first-child{
                             .info-section{
                                margin-bottom: 14px;

                                 .name{
                                     span{
                                        color: #758CA1 !important;
                                        line-height: 20px;
                                     }
                                 }
                                 .content{
                                     span{
                                         font-size: 12px;
                                         color: #BBC6D1;
                                         line-height: 20px;
                                     }
                                 }
                             }
                        
                            }
                        }
                    }
                }
                &:first-child{
                    text-align: end;
                }
            }

            .total-price{
             color: #BBC6D1;
             letter-spacing: -0.14px;
             line-height: 21px;
            }
        }
    

}
.finished{
    .invoice-divider{
        padding: 0 40px;
    }
}
