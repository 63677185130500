#registration-services-form {
    padding: 0 70px;
    > div{
        &:first-child{
            .ant-form-item-control-input{
                min-height: 100%;
                small{
                    line-height: 18px;
                }
            }
        }
        &:last-child{
            margin-top: 35px;
            .end-row{
                > .ant-col{
                    &:first-child{
                        button{
                            @include d-flex;
                            align-items: center;
                            .ant-space{
                                > .ant-space-item{
                                    &:first-child{
                                        svg{
                                            width: 16.05px;
                                            height: 13.55px;
                                            path{
                                                fill: #495274;
                                            }
                                        }
                                    }
                                    &:last-child{
                                        color: #495274;
                                        line-height: 21px;
                                    }
                                }
                            }
                            &:hover{
                               background: none;
                            }
                        }
                    }
                    &:last-child{
                        button{
                            @include d-flex;
                            align-items: center;
                            .ant-space{
                                >.ant-space-item{
                                    &:first-child{
line-height: 21px;
                                    }
                                    &:last-child{
svg{
    width: 16.05px;
    height: 13.55px;
}
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
 
    button {
        padding: 20px 46px;

        span {
            line-height: 100%;
        }
    }

    .go-back-button {
        padding: 0px;
    }
}
