#accommodation-view-all {
    > .ant-row {
        > div:first-child {
            margin-bottom: -10px;
        }
    }
    .filter-content {
        margin-bottom: 30px;
        .info-section {
            > .content {
                span {
                    font-size: 14px !important;
                }
            }
        }
    }
    .advanced-filter-content {
        > div:last-child {
            > div:first-child {
                background: #fcfdfd;
                padding: 6px 13px;
                border-radius: 4px;
                min-width: 142px;
                display: inline-block;
            }
        }
        .ant-form-item-control-input {
            svg {
                width: 16px;
                height: 19.5px;
                path {
                    fill: #bbc6d1;
                }
            }
        }
        .display-price {
            font-family: "Poppins-Regular";
            font-size: 12px;
            color: #536dfe;
            letter-spacing: -0.12px;
            line-height: 18px;
        }
        .trip-slider {
            width: 100%;
            margin-top: 18px;
        }
    }
}
.filter-divider {
    border-color: #bbc6d1 !important;
    border-width: 1px 0 0 !important;
}
