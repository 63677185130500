.card-widget-options {
    margin-top: 19px;
    .item {
        cursor: pointer;
        position: relative;
        padding: 24px 21px 20.5px 20px;
        height: 100%;
        border: 2px solid #E5EAF0;
        border-radius: $border-radius-small;
        background-color: $color-white;
        >div:first-child{
            min-height: 38px;
        }

        &.selected {
            border: 2px solid #005AE7;
            @include box-shadow(0px 7px 16px 0px #5D96F03D);
        }

        .ant-typography {
            color: $global-color-4;
        }

        .ant-checkbox-wrapper {
            position: absolute;
            right: 10px;
            top: 1px;

            .ant-checkbox {
                &.ant-checkbox-checked {
                    &::after {
                        display: none;
                    }
                    .ant-checkbox-inner{
                        background-color: #24DCAE !important;
                    }
                }
            }

            .ant-checkbox-inner {
                position: relative;
                top: 0;
                left: 0;
                display: block;
                width: 35px !important;
                height: 35px !important;
                direction: ltr;
                background-color: #EEF0F2 !important;
                border: 0;
                border-radius: 50%;
                border-collapse: separate;
                transition: all .3s;
            }
        }

        .carousel-widget {
            .ant-space {
                float: right;
                .ant-space-item {
                    &:first-child {
                        margin-right: 20px !important;
                    }
                }
            }

            .carousel-svg {
                svg {
                    width: 100%
                }
            }
        }

        .grid-svg {
            svg {
                width: 100%
            }
        }
    }
}
