.ant-radio-group.ant-radio-group-solid {
    .ant-radio-button-wrapper {
        // border-radius: $border-radius-small !important;
        margin: 5px;
    }

    .ant-radio-button-wrapper:not(:first-child)::before {
        content: none;
    }

    .ant-radio-button-wrapper:not(:first-child) {
        border-left: 2px solid #D0D9E6;
    }

    .ant-radio-button-wrapper .ant-radio-button-wrapper-checked {
        border-radius: 5px !important;
    }
}

.ant-radio-group.ant-radio-group-outline.ant-radio-group-large {
    .ant-radio-button-wrapper {
        width: 160px;
        height: 126px;
        margin: 5px;
        border-radius: $border-radius-small !important;
    }

    .ant-radio-button-wrapper:not(:first-child)::before {
        content: none;
    }

    .ant-radio-button-wrapper:not(:first-child) {
        border-left: 2px solid #D0D9E6;
    }
}

