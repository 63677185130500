.textSize14 {
    font-size: 14px;
    .ant-typography {
        color: $global-color-9;
    }
}

.textSize12 {
    font-size: 12px;
    color: $global-color-2;
}

.ant-table-content{
    width: 100%;
    overflow-x: auto;
    > table{
        max-width: 3412px;;
    }
}
