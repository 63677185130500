.right-col {

    .steps-container {
        .ant-steps-item{
            width: 25%
        }
    
        }
    .data-display {
        @include scrollbar($_thumb-color);
        .data-container{
            >.ant-row{
                >.ant-col:first-child{
                    >.ant-space >.ant-space-item{
                        &:nth-child(4){
                            .name{
                                margin-bottom: 2px;
                            }
                        }
                        &:nth-child(9){
                            .content{
                                >div{
                                    &::before{
                                        content: '';
                                        display: inline-block;
                                        width: 9px;
                                        height: 9px;
                                        -moz-border-radius: 7.5px;
                                        -webkit-border-radius: 7.5px;
                                        border-radius: 7.5px;
                                        background-color: #BBC6D1;
                                    }
                                    >span{
                                        padding-left: 11px;
                                         }
                                }
                              
                               
                            }

                        }
                    }
                }
            }
        }
    }


    @media screen and (max-width: 991px) {
        .data-display {
            display: none;
        }
    }
}
