.personal-details {
    .upload-image {
        text-align: center;
    }
    .avatar-upload {
        background: $global-color-8;
        border: none;
        width: 158px;
        height: 158px;
        svg{
            width: 80.65px;
            height: 66.14px;
            path{
                fill: #BBC6D1;
            }
        }
    }
    .delete-img-icon {
        position: absolute;
        top: 75%;
        left: 60%
    }
    .account-password{
      .ant-input-affix-wrapper{
         border: none !important;
         box-shadow: none;
        svg{
            width: 19px;
            height: 15px;
            path{
                fill:#DDE4EB;
            }
        }
}
    }
    .email-description{
        line-height: 16px;
        margin-top: 8.5px;
        svg{
            width: 9px;
            height: 9px;
            path{
                fill: #BBC6D1;
            }
        }
            span{
                font-size: 10px;
                line-height: 16px;
                color: #BBC6D1;
                font-family: "Poppins-LightItalic";
            }
    }
    >div{
        &:last-child{
            margin-bottom: 0;
            .mui-form-item{
                margin-bottom: 0;
            }
        }
        &:nth-child(2){
         margin-bottom: 0;
         .ant-row{
        > .ant-col{
            &:last-child{
                .mui-form-item{
                    margin-bottom: 0;
                }
            }
            &:nth-child(8){
               svg{
                width: 20px;
                height: 20px;
               }
            }
        }
         }
        }
    }
  
}
