@import './EmptyGroupList/style';
@import './Filters/style';
@import './GroupList/style';

.add-group-form {
    display: block;
    height: auto;
    margin-top: 30px;
    padding: 30px;
    background-color: $global-color-6;
    border-radius: 5px;
    border: 1px solid #E7EBEF;
    .save-button{
        span{
            line-height: 18px;
            letter-spacing: -0.12px;
        }
    }
    >div{
        margin-left: 0px !important;
    }


    .mui-form-item{
        margin-right: 0;
         label{
            color: #758CA1;
            height: auto !important;
        }
        .ant-form-item-control-input{
            border: 2px solid #DDE4EB;
            border-radius: 5px;
            input{
               width: 389px;
            }
            .ant-picker{
                box-shadow: none !important;
            }
        
        }
    }

        .mui-form-item {
            .ant-form-item-label {
                label {
                    height: $input-label-height-lg;
                }
            }
    
         
        }

}

.hide-form {
    display: none;
}
.usergroup-add-icon{
    margin-right: 20px;
    width: 50px;
    height: 50px;
    background: #F4F6F8;
    border-radius: 50%;
    @include d-flex;
justify-content: center;
align-items: center;
svg{
    width: 26px;
    height: 25px
}
}

