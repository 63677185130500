.auth-mobile-layout {
    height: 100vh;
    margin: 0 auto;
    width: 100%;
    overflow: hidden;
    padding: 0;
    max-height: 100vh;
    min-height: 100vh;

    .mobile-topbar-wrapper {
        background: $color-white;
        // position: fixed;
        // z-index: 10000;
        .mobile-topbar {
            line-height: 58px;
            padding: 20px;

            .toggle-wrapper {
                background: $color-primary;
                border-radius: 10px;
                text-align: center;
                width: 50px;
                height: 50px;
                padding: 17px 12px;
                @include d-flex;
                align-items: center;
                justify-content: center;
                box-shadow: 0px 3px 10px 0px rgba(0, 0, 0, 0.16);
                svg.stbi {
                    cursor: pointer;
                }
            }

            .help-section {
                visibility: hidden;
                @include d-flex;
                &.show-help-section {
                    visibility: visible;
                    > div {
                        > div {
                            line-height: 20px;
                            @include d-flex;
                        }
                        span {
                            font-family: "Poppins-Medium";
                            line-height: 20px;
                            color: #3b55eb;
                        }
                    }
                }
            }

            .general-menu {
                .ant-divider-vertical {
                    border-left: 1px solid $color-topbar-bottom-border;
                    margin: 0px;
                    height: 2em;
                }
            }
        }

        .menu-in-topbar {
            padding: 0px 30px;
            visibility: hidden;
            height: 0px;
            &.show-menu {
                visibility: visible;
                background-color: #ffffff;
                padding-top: 10px;
                padding-bottom: 30px;
                position: absolute;
                z-index: 1000;
                height: calc(100vh - 100px);
                overflow-y: auto;
                > .ant-row {
                    > .ant-col {
                        padding-left: 11.5px !important;
                        padding-right: 11.5px !important;
                        &:first-child {
                            .ant-space {
                                > div:first-child {
                                    background: linear-gradient(
                                        180deg,
                                        rgba(83, 109, 254, 1) 0%,
                                        rgba(59, 85, 235, 1) 100%
                                    );
                                }
                            }
                        }
                        &:nth-child(2) {
                            .ant-space {
                                > div:first-child {
                                    background: linear-gradient(
                                        180deg,
                                        rgba(59, 85, 235, 1) 0%,
                                        rgba(19, 37, 141, 1) 100%
                                    );
                                }
                            }
                        }
                        &:nth-child(3) {
                            .ant-space {
                                > div:first-child {
                                    background: linear-gradient(
                                        180deg,
                                        rgba(59, 85, 235, 1) 0%,
                                        rgba(19, 37, 141, 1) 100%
                                    );
                                }
                            }
                        }
                        &:nth-child(4) {
                            .ant-space {
                                > div:first-child {
                                    background: linear-gradient(
                                        180deg,
                                        rgba(7, 198, 255, 1) 0%,
                                        rgba(8, 158, 203, 1) 100%
                                    );
                                }
                            }
                        }
                        &:nth-child(5) {
                            .ant-space {
                                > div:first-child {
                                    background: linear-gradient(
                                        180deg,
                                        rgba(187, 198, 209, 1) 0%,
                                        rgba(117, 140, 161, 1) 100%
                                    );
                                }
                            }
                        }
                        &:nth-child(6) {
                            .ant-space {
                                > div:first-child {
                                    background: linear-gradient(
                                        180deg,
                                        rgba(36, 220, 174, 1) 0%,
                                        rgba(16, 175, 122, 1) 100%
                                    );
                                }
                            }
                        }
                        &:nth-child(7) {
                            .ant-space {
                                > div:first-child {
                                    background: linear-gradient(
                                        180deg,
                                        rgba(190, 190, 190, 1) 0%,
                                        rgba(112, 112, 112, 1) 100%
                                    );
                                }
                            }
                        }
                        &:nth-child(8) {
                            .ant-space {
                                > div:first-child {
                                    background: linear-gradient(
                                        180deg,
                                        rgba(255, 202, 133, 1) 0%,
                                        rgba(247, 146, 53, 1) 100%
                                    );
                                }
                            }
                        }

                        &:nth-child(9) {
                            .ant-space {
                                > div:first-child {
                                    background: linear-gradient(
                                        180deg,
                                        rgba(255, 238, 0, 1) 0%,
                                        rgba(245, 189, 2, 1) 100%
                                    );
                                }
                            }
                        }
                        &:nth-child(10) {
                            .ant-space {
                                > div:first-child {
                                    background: linear-gradient(
                                        180deg,
                                        rgba(4, 194, 216, 1) 0%,
                                        rgba(36, 220, 174, 1) 100%
                                    );
                                }
                            }
                        }
                        &:nth-child(11) {
                            .ant-space {
                                > div:first-child {
                                    background: linear-gradient(
                                        180deg,
                                        rgba(255, 162, 0, 1) 0%,
                                        rgba(252, 120, 134, 1) 100%
                                    );
                                }
                            }
                        }
                        &:nth-child(12) {
                            .ant-space {
                                > div:first-child {
                                    background: linear-gradient(
                                        180deg,
                                        rgba(14, 199, 255, 1) 0%,
                                        rgba(83, 109, 254, 1) 100%
                                    );
                                }
                            }
                        }
                    }
                }
            }

            a {
                text-decoration: none;
                .menu-item-wrapper {
                    height: 115px;
                    background: $color-body-background;
                    border-radius: 10px;
                    text-align: center;
                    @include d-flex;
                    align-items: center;
                    justify-content: center;
                    padding: 20px 45px;

                    > .ant-space {
                        align-items: center;
                        > div {
                            &:first-child {
                                width: 50px;
                                height: 50px;
                                border-radius: 50%;
                                display: flex;
                                align-items: center;
                                justify-content: center;
                                svg {
                                    width: 24px;
                                    height: 24px;
                                }
                            }
                            &:last-child {
                                font-family: "Poppins-Medium";
                                line-height: 20px;
                                color: #758ca1;
                            }
                        }
                    }
                    &.selected-div {
                        background: linear-gradient(180deg, rgba(252, 177, 81, 1) 0%, rgba(254, 90, 107, 1) 100%);
                        .ant-space {
                            > div {
                                &:first-child {
                                    background: transparent !important;
                                }
                                &:last-child {
                                    color: #ffffff;
                                }
                            }
                        }
                    }
                    .item-selected {
                        color: $color-white;
                    }
                }
            }
        }
    }

    @media (max-width: 992px) {
        .ant-layout-content {
            // padding-top: 100px;
            padding-bottom: 50px;
            overflow-y: auto;
        }
    }
}
@media (max-width: 399px) {
    .menu-in-topbar {
        &.show-menu {
            > div {
                > div {
                    flex: 0 0 100%;
                    max-width: 100%;
                }
            }
        }
    }
}
