.finished {
    >.ant-row{
        .ant-space{
            >.ant-space-item:last-child{
                span{
                    line-height: 21px;
                    color: #BBC6D1;
                    letter-spacing: 0.14px;
                }
            }
        }
    }
    .go-back {
        border-radius: 50%;
        width: 33px;
        height: 33px;
        padding: 0;
        @include d-flex;
        align-items: center;
        justify-content: center;
    }

    .extended-block {
        max-width: 1290px !important;
        
    }
    
    .block, .extended-block {
        @import './PayInvoice/style';
        max-width:700px;
        margin: 0 auto;
        >.ant-divider{
            margin: 12.5px 0 27.5px 0;
            border-width: 1px;
        }
        .invoice-title{
            align-items: flex-end;
            .ant-col{
                &:first-child{
                    h5{
                        font-size: 18px;
                        font-family: "Poppins-Medium";
                        line-height: 27px;
                        margin-bottom: 0px;
                        color: #4D677E;
                        letter-spacing: 0.18px;
                        font-weight: normal;
                    }
                }
            }
        }
        .preview {
            // padding: 40px;
            padding-bottom: 46px;
            height: 100%;
            background: $color-white;
            border-radius: $border-radius-medium;
            border: 1px solid #DDE4EB;
            .ant-divider{
                margin: 30px 0;
            }
            .general-details{
                padding: 38px 40px 0 40px;
            }
            .tour-details, .participants, .extras, .total,.accommodation-details,.rooms,.extra-bed, .date-of-issue, .service-list {
                padding: 0 40px;
            }
            >div:nth-child(2){
                border-width: 2px;
            }
        }
        button{
            background: transparent;
            width: 100%;
            font-size: 12px;
            font-family: "Poppins-Medium";      
            @include d-flex;
            justify-content: center;
            align-items: center;
        }
        .cancel {
            border: 2px solid #FC7886 !important;
            padding: 0 37px;
            svg{
                vertical-align: bottom;
            }

            &, .ant-typography {
                color: $global-color-13;
                line-height: 18px;
                letter-spacing: 0.12px;
            }
        }
        .edit {
            border: 2px solid #536DFE !important;
            padding: 0 41px ;
   
            svg{
                width: 19px;
                height: 19px;
                path{
                    fill:#536DFE;
                }

            }
            &, .ant-typography {
                color: $global-color-7;
                line-height: 18px;
                letter-spacing: 0.12px;
            }
            &:hover{
                background-color: #536DFE;
                .ant-typography {
                    color: #FFFFFF;
                }
                svg{
                    path{
                        fill: #FFFFFF;
                    }
                }
            }
        }

      
        .action-step{
            margin-top: 30px;
            >div{
                &:nth-child(2),&:last-child{
                    padding-left: 30px;
                }
            }
        }

        .change-status-btn {
            background-color: #3B55EB;
            color: #ffff;
            font-size: 14px !important;
        }

        .ant-select {
            width: 200px !important;
        }
    }
}
