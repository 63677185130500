.invoices-row-m1{
    .filter-content{
        row-gap: 15px !important;
    }
    .ant-table-container{
        >div{
            width: 100%;
            overflow-x: auto;
            >table{
                min-width: 1178px;
            }
        }
        .textSize14 {
            font-size: 14px;
            .ant-typography {
                color: $global-color-9;
            }
        }
        
        .textSize12 {
            font-size: 12px;
            color: $global-color-2;
        }
        
        .confirmed {
            color: $global-color-12;
        }
        
        .pending {
            color: $global-color-3;
        }
        
        .canceled {
            color: $global-color-13;
        }
    }

}
