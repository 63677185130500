.tour-details {
    font-family: "Poppins-Medium";
    .added-to {
        border: 1px dashed $global-color-2;
        border-radius: $border-radius-small;
        padding: 16px 19px;
        .added-to-text{
            span{
                font-size: 12px;
                color: #BBC6D1;
            }
        }
        span{
            line-height: 20px;
            color: #758CA1;
        }
    }
    .tour-details-content{
        padding-left: 15px;
        h5{
         font-size: 14px;
         line-height: 21px;
         letter-spacing: 0.14px;
         margin-bottom: 10px;
        }
        .itenerary , .date{
            span{
                color: #BBC6D1;
            }
        }
        .date{
            margin-top: 10px;
        }
        span{
            font-family: "Poppins-Regular";
            font-size: 12px;
            line-height: 16px;
            color: #758CA1;
        }
        .itinerary-locations{
            @include d-flex;
            flex-wrap: wrap;
            >div{
                margin-right: 5px;
            }
        }
    }
}
