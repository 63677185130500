form.extras{
    >span{
        line-height: 21px;
        color: #4D677E;
    }

    .ant-checkbox-group{
        width: 100%;
        margin-top: 17px;
        .ant-checkbox-wrapper-checked .ant-checkbox-inner{
            background-color: #24DCAE !important;
        }
        .ant-checkbox-wrapper:not(.ant-checkbox-wrapper-checked){
            .ant-checkbox-inner{
                background-color: #E7EBEF !important;
            }
        }
        .ant-checkbox + span{
            padding-left: 10px;
        }
        .info-section{
            .name{
                line-height: 21px;
                span{
                    color: #758CA1;
                    letter-spacing: 0.14px;
                }
            }
            .content{
                line-height: 18px;
                span{
                    font-size: 12px;
                    letter-spacing: 0.14px;
                    color: #BBC6D1;
                }
            }
        }
    }
}

