.filter-content {
    .search {
        width: 390px;
    }

    .ant-input {
        height: 50px;
        border-radius: 5px;
    }

    .ant-input-search-button {
        height: 50px;
    }

    .ant-input-group .ant-input {
        border-color: $global-color-6;
    }

    .ant-input-search > 
    .ant-input-group > 
    .ant-input-group-addon:last-child
    .ant-input-search-button {
        border-color: $global-color-6;
        border-radius: 0px 5px 5px 0px;
    }
}

.advanced-filter-content {
    .ant-form-item-control-input{
        svg{
            path{
                fill: #DDE4EB !important;
            }
        }
    }
    .ant-divider-horizontal {
        border-color: $global-color-2;
    }

    .ant-input-affix-wrapper {
        border: none;
    }
    .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
        height: 50px;
        width: 231px;
        border-radius: 5px;
        border-color: $global-color-6;
    }

    .ant-select-single .ant-select-selector .ant-select-selection-placeholder {
        line-height: 45px;
    }
    
    .trip-slider {
        width: 360px;
    }

    .ant-slider-rail {
        background-color: $global-color-5;
    }

    .ant-slider-track, .ant-slider-handle {
        background-color: $global-color-7;
    }

    .ant-slider-handle {
        border: solid 3px $global-color-6;
    }

    .ant-slider-dot {
        display: none;
    }

    .min-price, .max-price {
        color: $global-color-7;
        font-family: "Montserrat-Regular";
        letter-spacing: -0.14px;
        line-height: 18px;
    }
    .seperator{
        margin: 15px 19.3px;
        background-color:transparent !important;
        padding: 0 !important;
        &::after{
            content: "";
            display: block;
            width: 25px;
            height: 2px;
            background: #BBC6D1;
       
        }
    }

    >div:last-child{
        >div:first-child{
            width: 377px;
       @include d-flex;
       align-items: center;
       justify-content: space-between;
       >div{
        background-color: #FCFDFD;
        padding: 5px 100px 5px 10px;
         border-radius: 4px;
       }
        }
    }

}
