.tour-details {
    .tour-package-details{
        align-items: flex-start !important;
        .rooms{
            padding: 15px 19px;
          

        }
        svg{
            path{
                fill: #BBC6D1;
            }
        }
        >div{
            &:first-child{
                >div:nth-child(2){
                    margin-bottom: 6px;
                }
            }
        }
        .ant-col{
            &:first-child{
                font-family: "Poppins-Regular";
                .ant-space{
                    .label{
                        font-size: 12px;
                        color: #BBC6D1;
                        line-height: 16px;
                    }
                    .content{
                        font-size: 14px !important;
                        color: #4D677E !important;
                    }
                }
           
            }
        }
    }
}
