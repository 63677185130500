#registration-phone-verification-form {
    .phone-number-input {
        .ant-form-item {
            margin-bottom: 0px;
        }
    }
    >.verify-otp-form{

        >.editing-component-wrapper{
margin-top: 10px;
            > div{
                &:first-child{
                    >.phone-input{
                        margin-bottom: 0 !important;
                        .ant-form-item-control-input-content{
                            height: 100%;
                            >.ant-input-affix-wrapper{
                                height: 100% !important;
                                .ant-select{
                                    box-shadow: none !important;
                                }
                            }
                        }
                    }
                }
                &:last-child{
                 button{
                    span{
                        line-height: 21px;
                    }
                 }
                }
            }
        }

        >.verification-image{
            svg{
                path{
                    fill: #D0D9E6;
                }
            }
        }
    }

}
