.employee-personal-info {
    @import './PersonalDetails/style';

    background-color: $color-white;
    border-radius: $border-radius-small 0px 0px $border-radius-small;
    box-shadow: 0px 2px 15px 0px rgba(0,0,0,0.04);
    .ant-divider{
        border-width: 1px
    }
    > div{
        >div{
            &:nth-child(3){
                padding-top: 27.7px;
            }
            &:last-child{
                padding-bottom: 16.3px
            }
        }
        > .general-section{
            > .ant-space{
                @include d-flex;
                justify-content: space-between;
                align-items: flex-start;
                > div {
                    &:first-child{
                        .ant-space{
                            > div {
                                &:first-child{
                                    .ant-avatar{
                                        width: 100px !important;
                                        height: 100px !important;
                                        border: 7px solid #E7EBEF;
                                    }
                                }
                                &:last-child{
                                    >div{
                                        > .personal-info_name{
                                            font-size: 18px;
                                            line-height: 27px;
                                            letter-spacing: 0.18px;
                                            font-family: 'Poppins-Medium';
                                            color: $global-color-7;
                                        }
                                        > .description{
                                            > .label{
                                                font-size: 12px;
                                                color: #BBC6D1;
                                                line-height: 26px;
                                            }
                                            > .diff-color{
                                                font-size: 12px;
                                                color: #758CA1;
                                                line-height: 26px;

                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                    &:last-child{
                        button{
                            &:hover{
                                border-radius: 50%;
                                svg{
                                    path{
                                        fill: #758CA1;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
        &:last-child{
            .content{
                > .ant-space{
                    @include d-flex;
                    justify-content: space-between;
                    > div {
                        &:last-child{
                            margin-right: 5px;
                            margin-top: -10px;
                            svg{
                                width: 17.04px;
                                height: 13.27px;
                                path{
                                    fill: #DDE4EB
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

.work-info {
    @import './WorkDetails/style';
    @import './Performance/style';
    
        > div{
            > .ant-col{
                &:nth-child(2){
                    .filter-content{
                        align-items: flex-end;
                        > div:first-child{
                            span{
                                color: #A6B0BB;
                            line-height: 16px;


                            }
                        }
                    }
                    .advanced-filter-content{
                      .trip-slider{
                        width: 100%;
                      }
                    }
                }
                &:last-child{
                    > div{
                        box-shadow: 0px 2px 6px 0px rgba(0,0,0,0.05);
                    }
                }
            }
        }
}


////////////RESPONSIVE

@media (min-width:1600px){
    .work-info{
        > div{
            >div{
                &:nth-child(2){
                    .advanced-filter-content{
                        > div{
                            flex: 0 0 33.33333333%;
                            max-width: 33.33333333%;
                        }
                    }
                }
            }
        }
    }
}

@media (max-width:1399px){
    .employee-personal-info{
        >div{
            >.general-section{
                > .ant-space{
                    > .ant-space-item{
                        &:first-child{
                            .ant-space{
                                flex-direction: column;
                                &:first-child{
                                    width: 100%;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    .work-info{
        > div{
            >div{
                &:first-child{
                    .sales-graph-wrapper{
                        row-gap: 15px !important;
                        > div{
                            flex: 0 0 100%;
                            max-width: 100%;
                        }
                    }
                }
                &:nth-child(2){
                    .filter-content{
                        row-gap: 15px !important;
                    }
                    .advanced-filter-content{
                        > div{
                            flex: 0 0 50%;
                            max-width: 50%;
                            &:last-child{
                                // margin-top: 30px;
                            }
                        }
                    }
                }
            }
        }
        .employee-performance{
            > form{
>.filter-content{
    row-gap: 15px !important;
    > div{
        flex: 0 0 100%;
                            max-width: 100%;
                            &:last-child{
                                justify-content: space-between;
                            }
    }
}
            }
        }
    }
    }

    @media (max-width:1199px){
        .work-info{
            > div{
                >div{
                    &:nth-child(2){
                        .filter-content{
                    > .ant-col{
                        &:last-child{
                            >div{
                                flex-flow: wrap;
                            }
                        }
                    }
                        }
                    }
                }
            }
            .employee-performance{
                > form{
    >.filter-content{
        > div{
              &:last-child{
                 > .ant-space{
                     flex-flow: wrap;
                 }
             }
        }
    }
 }
 >.ant-row{
    >.skills-wrapper{
        >div{
            flex-direction: column;
            >.ant-space-item-split{
                display: none;
            }
        }
    }
 }
 }




        }
    }



    @media (max-width:991px){
.employee-view-one{
    padding:20px;
    flex-direction: column;
    row-gap: 20px !important;
    > div{
        flex: 0 0 100%;
        max-width: 100%;
    }
}
    }

    @media (max-width:767px){
        .work-info{
            > div{
                >div{
                    &:nth-child(2){
                        .advanced-filter-content{
                            > div{
                                flex: 0 0 100%;
                                max-width: 100%;
                                .ant-select{
                                    margin-bottom: 30px;
                                }
                                &:last-child{
                                    margin-top: 0 !important;
                                }
                            }
                        }
                    }
                }
            }
        }
    }


    @media (max-width:575px){
        .work-info{
            > div{
                >div{
                    &:first-child{
                        .sales-graph-wrapper{
                          .sales-card{
                            .ant-card-body{
                                >div{
                                    flex-direction: column;
                                    row-gap: 15px;
                                    >div{
                                        flex: 0 0 100%;
                                        max-width: 100%;
                                    }
                                }
                            }
                          }
                        }
                    }
                }
            }
            .employee-performance{
  
 >.ant-row{
    >.skills-wrapper{
        >div{
         gap: 30px !important;
            > div{

                &:first-child{
                    >div{
                    > .ant-space{
                        flex-direction: column;
                    }
                    }
                }
            }
           
        }
    }
 }
 }
        }
    }

    
    @media (max-width:399px){
        .work-info{
            .employee-performance{
                > form{
                    >.filter-content{
                        > div{
                            &:last-child{
                                > div{
                                    width: 100%;
                                    > div{
                                        &:last-child{
                                            width: 100%;
                                            button{
                                                width: 100%;
                                                span{
                                                    font-size: 12px !important;
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }

  
 >.ant-row{
    >.skills-wrapper{
        > .ant-space{
            > div{
                &:first-child{
                    width: 100%;
                    .ant-space{
                        width: 100%;
                        > div{
                            &:last-child{
                                width: 100%;
                                >div{
                                    width: 100%;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
 }
 >.add-evaluation-form{
    > form{
        > .ant-row{
            > div{
                &:first-child{
                    .ant-select-selection-placeholder{
                        font-size: 10px !important;
                    }
                }
                &:last-child{
                    .ant-btn-primary{
                        padding: 12px 15px !important;
                    }
                }
            }
        }
    }
 }
 }
        }
    }
