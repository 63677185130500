.sales-graph-wrapper {
    .ant-card-bordered {
        background: rgba($color-white, 0.6);
        border: none;
        canvas{
            width: 100% !important;
        }
        .ant-card-head {
            border-bottom: none;
        }
    }

    .sales-card {
        .total-amount-value {
            margin-bottom: 6px;
            span{
                font-size: 24px;
                color: $global-color-9;
                font-family: 'Poppins-SemiBold';
                line-height: 24px;
            }
        }
    
        .total-amount-text {
            color: $global-color-2;
            font-size: 12px;
            line-height: 24px;
            font-family: "Poppins-SemiBold";
        }

    .ant-card-body{
        .ant-col{
            text-align: end;
        }
    }
        .ant-card-head-wrapper{
            align-items: flex-start;
        }
        .ant-card-head-title {
            color: $global-color-12;
            font-size: 16px;
            font-family: 'Poppins-Medium';
            line-height: 24px;
            letter-spacing: 0.8px;
            padding: 19px 0;
            >div{
                line-height: 24px;
            }
            #subtitle {
                font-family: 'Poppins-Regular';
                color: $global-color-5;
                font-weight: 400;
                font-size: 11px;
                letter-spacing: 0.11px;
            }
        }
        .ant-card-extra {
            padding: 19px 0;
            span {
                color: $color-white;
                background: $global-color-12;
                border-radius: $border-radius-small;
                padding: 5px 17px 5px 17px;
                font-size: 14px;
                line-height: 24px;
                font-family: "Poppins-SemiBold";
            }
        }
        > .ant-card-head{
            padding: 0 29px;
       
        }
        > .ant-card-body{
            padding: 17px 28px 22px 28px;
        }
    }

  
  
}
