.data-container {
    border: 1px solid #E7EBEF;
    border-radius: 5px;
    padding: 23px;

    .border-text {
        float: left;
        font-size: 16px;
        color: $global-color-9;
        padding:0 5px;
        margin:-32px 0 0 10px;
        background:#fff;
        font-family: "Poppins-Regular";
    }
}
