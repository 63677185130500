@import './Create/style';
@import './CreateCustomInvoice/style';
@import './ViewAll/style';


.invoices-row-m1 {


    > div:nth-child(1) {


        .graph-wrapper {


            .ant-card {

                background-color: rgba(#fff , 0.6) !important;
                border-radius: 5px !important;
                border: 0 !important;
                padding: 20px !important;
                height: 100%;

                .ant-card-head {
                    padding: 0 !important;
                    margin: 0 !important;
                    border-radius: 0 !important;
                    min-height: 0 !important;

                    .ant-card-head-wrapper {
                    @include d-flex;
                    align-items: center;
                    justify-content: space-between;

                    .ant-card-head-title {
                          font-size: 14px !important;
                          font-family: 'Poppins-Regular' !important;
                          font-weight: normal !important;
                          text-transform: uppercase !important;
                          flex: none !important;
                          padding: 0 !important;
                          line-height: 24px;
                          letter-spacing: 0.7px
                    }

                     
                    .ant-card-extra {
                        @include d-flex;
                        align-items: center;
                        float: none !important;
                        padding: 0 !important;

                        span {
                          font-size: 14px !important;
                          font-family: 'Poppins-SemiBold' !important;
                          font-weight: normal !important;
                          color: #fff !important;
                          padding: 4px 11px !important;
                          border-radius: 3px !important;
                        }

                    }

                    }

                }

                .ant-card-body {
                    padding: 0 !important;
                    margin: 0 !important;
                    margin-top: 27px !important;

                    > .ant-row {

                        margin: 0 !important;

                        > .ant-col {
                            @include d-flex-i;
                            padding: 0 !important;
                            align-items: flex-end;

                            div[data-chart-source-type="G2Plot"] {
                             width: 100% !important;
                                > div {
                             width: 100% !important;
                                    canvas {
                                        max-width: 100% !important;
                                        width: 100% !important;
                                    }

                                }

                            }

                        }


                    }


                    .info-section {
                        @include d-flex;
                        flex-direction: column;
                        align-items: flex-end;
                        text-align: right;
                        margin-left: auto;
                        width: 100%;

                        .total-amount-value {
                          font-size: 24px !important;
                          font-family: 'Poppins-SemiBold' !important;
                          color: #758CA1 !important;
                          font-weight: normal !important;
                          word-break: break-all;
                            .ant-typography {
                                color: #758CA1 !important;
                                line-height: 24px;
                            }

                        }

                        .total-amount-text {
                            margin-top: 6px !important;
                            font-size: 12px !important;
                            font-family: 'Poppins-SemiBold' !important;
                            color: #BBC6D1 !important;
                            font-weight:  normal !important;
                            text-transform: uppercase !important;
                            line-height: 24px;
                        }

                    }


                }

            }


        }

    }

    > div:nth-child(2) {


  > .ant-form  {

    > .ant-row:nth-child(1) {

        > .ant-col:nth-child(1) {

            @include d-flex-i;
            align-items: flex-end;
            
            .ant-typography {
                font-size: 14px !important;
                font-family: 'Poppins-Regular' !important;
                color: #A6B0BB !important;
                color: #758CA1 !important;
                font-weight: normal !important;
                line-height: 16px;
            }

        }

        > .ant-col:nth-child(2) {

            > .ant-space {
                gap: 30px !important;
                flex-flow: wrap;


                > .ant-space-item:nth-child(2) {


                    button {
                        @include d-flex-i;
                        align-items: center;
                        padding: 0 23px;
                        background-color: #fff;
                        border-radius: 5px;
                        box-shadow: 0px 2px 3px rgba(#000 , 0.08) !important;
                       
                        
                        &::after {
                            display: none !important;
                        }
                        

                        svg {
                            width: 22.24px !important;
                            height: 17.12px !important;
                        }

                        span {
                            font-size: 14px !important;
                            font-family: 'Poppins-Medium' !important;
                            color: #536DFE !important;
                            font-weight: normal !important;
                            text-transform: uppercase !important;
                            margin-left: 15px !important;
                        }
                        &:active, &:focus{
                            background-color: #536DFE;
                            span{
                                color: #fff !important;
                            }
                            svg{
                                path{
                                    fill: #fff;
                                }
                            }
                        }
                    }
               

                }



                > .ant-space-item:nth-child(3) {

                    .ant-select-single:not(.ant-select-customize-input) .ant-select-selector .ant-select-selection-search-input {
                        height: 100%;
                    }

                }
                


            }

        }

    }

    > .ant-divider-dashed {

        border-color: #BBC6D1 !important;
        margin-top: 30px !important;
        margin-bottom: 30px !important;
        border-width: 1px 0 0;

    }

    > .advanced-filter-content {
        margin-bottom: -30px !important;
        justify-content: space-between;
        svg{
            path,circle{
                fill: #BBC6D1 !important;
            }
        }
        > .ant-col {
            width: auto !important;
            // flex: none !important;
            // max-width: none !important;
            margin-bottom: 30px !important;


            &:nth-child(1) {

                > .ant-form-item {
                    margin-bottom: 0 !important;
                .ant-picker-suffix{
                    svg{
                        width: 16.98px;
                        height: 16.98px;
                    }
                }
                }

            }

        }

        .ant-select {
            min-width: 249px !important;
        }
        .ant-picker-range .ant-picker-clear{
            right: 16px !important;
        }
        .trip-slider{
            width: 100% !important;
        }

    }

  }  



    }


    .ant-table-tbody > tr.ant-table-row:hover > td, .ant-table-tbody > tr > td.ant-table-cell-row-hover {
         background: #F4F6F8; 
    }
}

.form-preview ,.finished{
    border-radius: 0px $border-radius-small  $border-radius-small 0px;
}



////////////RESPONSIVE


@media (max-width:1800px){
    .invoice{
        >.form{ 
            margin-left: 0 !important;
            margin-right: 0 !important;
        }
        >.form-button{
            margin-right: 0;
        }
    }
}
@media (max-width:991px){
    .invoice{
        padding: 20px 20px 0 20px;
    }
    .finished{
        .block,>.ant-row, .extended-block{ 
          padding: 20px;

        }
        
      
    }
}
@media (max-width:767px){
.finished{
    .action-step{
        row-gap: 15px !important;
        >div:last-child{
            padding-left: 0 !important;
        }
        
    }

}
.invoice-calendar{
    .ant-picker-panels{
        flex-direction: column;
    }
}
}
@media (max-width:575px){
    .finished{
        .preview{
            .general-details{
                padding: 30px 30px 0 30px !important;
            }
            .tour-details,.participants,.extras,.total,.accommodation-details,.rooms,.extra-bed{
                padding: 0 30px !important;
            }
            .tour-package-details{
                .rooms{
                    padding: 15px 19px !important;
                }
            }
            .general-details,.tour-details,.accommodation-details{
                flex-direction: column;
                row-gap: 15px !important;
                >div{
                    flex: 0 0 100% !important;
                    max-width: 100% !important;
                    &:first-child{
                        >div{
                            text-align: center;
                        }
                    }
                    &:last-child{
                        >div{
                            >div{
&:first-child{
    padding-right: 7.5px;
    padding-left: 0 !important;
}
&:last-child{
    padding-left: 7.5px !important;
}
                            }
                        }
                    }
                }
            }
        }
        .rooms,.extra-bed{
            >div{
                &:first-child{
                    flex: 0 0 12% !important;
                    max-width: 12% !important;
                }
                &:last-child{
                    flex: 0 0 88% !important;
                    max-width: 88% !important;
                    >div{
                        flex-flow: nowrap;
                    }
                }
            }
        }
    }
}
@media (max-width:399px){
    .invoice{
        padding-left: 0;
        padding-right: 0;
        >.form-button{
            >div{
                width: 100%;
                >div{
                    width: 100%;
                }
            }
        }
    }
    .finished{
        .preview{
            .total{
                >div{
                    left: 0 !important;
                    flex: 0 0 100% !important;
                    max-width: 100% !important;
                }
                        }
                        .accommodation-details,.tour-package-details{
                            >div{
                                &:last-child{
                                    >div{
                                        flex-direction: column;
                                        >div{
                                            flex: 0 0 100% !important;
                                            max-width: 100% !important;
                                            &:last-child{
                                                padding-left: 0 !important;
                                            }
                                            &:last-child{
                                                padding-right: 0 !important;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                        .tour-package-details{
                            flex-direction: column;
                            row-gap: 15px !important;
                            >div{
                                flex: 0 0 100% !important;
                                max-width: 100% !important;
                                width: 100%;
                                &:last-child{
                                    padding-left: 0 !important;
                                }
                                &:last-child{
                                    padding-right: 0 !important;
                                }
                            } 
                        }
                        .participants{
                            flex-direction: column;
                            row-gap: 10px !important;
                            >div{
                                flex: 0 0 100% !important;
                                max-width: 100% !important;
                                width: 100%;
                                text-align: start !important;
                                &:last-child{
                                    padding-left: 0 !important;
                                }
                            }
                        }
        }
        .invoice-title{
            padding-left: 20px !important;
        }
        .invoice-payment{
            padding-left: 0 !important;
            .payment-block{
                padding: 20px !important;
                >div{
                    &:last-child{
                        width: 100%;
                    }
                }
            }
        }
        .extended-block{
            padding-left: 0 !important;
            padding-right: 0 !important;
          
        }
    }

}
