.cf-m1 {

> .ant-form {

> .ant-row:nth-child(1) {

        align-items: flex-end !important;
        > .ant-col {
            &:nth-child(1){
        
        
                .ant-checkbox-wrapper {
                    @include d-flex-i;
                    align-items: center !important;
                    padding-left:30px ;
        
        
                    > .ant-checkbox {
                        .ant-checkbox-inner{
                            width: 25px !important;
                            height: 25px !important;
                            &::after{
                                transform: translate(-50%, -41%) scale(0.9)!important;
                            }
                        }
                        &:not(.ant-checkbox-checked){
                            .ant-checkbox-inner{
                                background-color: #fff !important;
                                box-shadow: 0px 2px 3px rgba(#000 , 0.08) !important;
                              
                                              }
                        }
                        
        
                    }
        
        
                    > span {
                        &:nth-child(2) {
                            padding: 0 !important;
                            margin-left: 24px !important;
        
                            > .ant-space {
                                @include d-flex-i;
                                flex-direction: column !important;
        
                                .ant-space-item {
                                    &:nth-child(1) {
                                    span {
                                    font-size: 14px !important;
                                    font-family: 'Poppins-Regular' !important;
                                    color: #4D677E !important;
                                    font-weight: normal !important;
                                    line-height: 16px;
                                    }
                                    }
                                    &:nth-child(2) {
                                    span {
                                    font-size: 12px !important;
                                    font-family: 'Poppins-Regular' !important;
                                    color: #A6B0BB !important;
                                    font-weight: normal !important;
                                    line-height: 16px;
                                    }
                                    }
                                }
                            }
        
                        }
                    }
                }
        
        
            }
            &:nth-child(2){
                .ant-space {
                    .ant-space-item {
                      
                        &:nth-child(2){
                            button {
                                @include d-flex-i;
                                align-items: center;
                                padding: 0 23px;
                                background-color: #FCFDFD;
                                border-radius: 5px;
                                box-shadow: 0px 2px 3px rgba(#000 , 0.08) !important;
                               
                                
                                &::after {
                                    display: none !important;
                                }
                                
        
                                svg {
                                    width: 22.24px !important;
                                    height: 17.12px !important;
                                }
        
                                span {
                                    font-size: 14px !important;
                                    font-family: 'Poppins-Medium' !important;
                                    color: #536DFE !important;
                                    font-weight: normal !important;
                                    text-transform: uppercase !important;
                                    margin-left: 15px !important;
                                }
                            }
                        }
                   
                    }
                }
            }
        }

}


> .ant-divider-dashed {

    border-color: #BBC6D1 !important;
    margin-top: 30px !important;
    margin-bottom: 30px !important;
    border-width: 1px 0 0;

}

> .advanced-filter-content {
    margin-bottom: -30px !important;
    > .ant-col {
        width: auto !important;
        flex: none !important;
        max-width: none !important;
        margin-bottom: 30px !important;

        &:nth-child(1) {

            > .ant-form-item {
                margin-bottom: 0 !important;
                svg{
                    width: 22px;
                    height: 22px;
                    path,circle{
                        fill: #BBC6D1 !important;
                    }
                }
            }

        }
        &:nth-child(2){
            svg{
                width: 16.34px;
                height: 19.5px;
                path{
                    fill: #BBC6D1;
                }
            }
        }
    }

    .ant-select {
        min-width: 249px !important;
    }

    .ant-input-affix-wrapper {
        min-width: 249px !important;
    }

    .ant-picker {
        min-width: 249px !important;
    }

}
button{
    font-family: "Poppins-Regular";
    font-size: 14px;
}

}

}



.customer-page {

    margin-top: 30px;
        .ant-checkbox-inner{
            width: 25px !important;
            height: 25px !important;
            &::after{
                transform: translate(-50%, -41%) scale(0.9)!important;
            }
    }
 
    .customer-list {
        background: $color-white;
        border-radius: $border-radius-small;
        box-shadow: 0px 2px 6px rgba(#000 , 0.05) !important;
        line-height: 1.15 !important;
        font-size: 12px !important;
        font-family: 'Poppins-Regular' !important;
        color: #A4AAB7 !important;
        font-weight: normal !important;

        .ant-checkbox-group{
            line-height: 1.15 !important;
            font-size: 12px !important;
            font-family: 'Poppins-Regular' !important;
            color: #A4AAB7 !important;
            font-weight: normal !important;
            width: 100% !important;
            overflow-x: auto;

            table {
                min-width: 1178px;
                overflow: hidden;

                tr {
                    
                    position: relative;

                    &::before {
                        content: "";
                         position: absolute;
                         top: 0;
                         left: -30px;
                         width: calc(100% + 60px);
                         height: 100%;
                         background-color: transparent;
                         pointer-events: none;
                         @include transition(all 0.3s);
                    }

                    &:hover {

                        &::before {
                            background-color: #F4F6F8;
                        }

                    }

                    &:not(:last-child){
                        border-bottom: 1px solid #D8DDE1;
                    }
                    &:first-child{
                        >td{
                            padding-top: 36px;
                        }
                    }
                    &:last-child{
                        >td{
                            padding-bottom: 40px;
                        }
                    }

                    td {

                        padding: 24px 15px;
                        vertical-align: middle;
                        position: relative;
                        z-index: 1;

                        &:first-child{
                            padding-left: 34px;
                            padding-right: 29px;
                        }
                        &:last-child{
                            padding-right: 38px;
                        }

                        &:not(.no-width-m1) {
                        width: 20%;
                        }

                        .a-btns-m1 {
gap: 0 !important;
> .ant-space-item {

&:not(:last-child) {
margin-right: 15px;
}

}

                        }

                        .un-m1 {
                            font-size: 14px !important;
                            font-family: 'Poppins-SemiBold' !important;
                            color: #646D82 !important;
                            font-weight: normal !important;  
                            margin-bottom: 4px !important;
                        }

                        
            .description {

                font-size: 12px !important;
                font-family: 'Poppins-Regular' !important;
                color: #A4AAB7 !important;
                font-weight: normal !important;  

                &:first-child:not(:last-child) {
                    margin-bottom: 5px !important;
                }

                .label {
                    font-size: 12px !important;
                    font-family: 'Poppins-Regular' !important;
                    color: #A4AAB7 !important;
                    font-weight: normal !important;  
                }

                .content {
                    font-size: 12px !important;
                    font-family: 'Poppins-Regular' !important;
                    color: #646D82 !important;
                    font-weight: normal !important;  
                }

            }


                    }

                }


            }





        }
  
    }
    
    .footer {
        margin-top: 30px;

> .ant-row {
align-items: center !important;
    > .ant-col {
        &:nth-child(1) {
            > .ant-space{
.ant-space-item {
    button {
        height: 50px !important;
        padding: 0 32px !important;
        @include d-flex-i;
        align-items: center !important;
        font-size: 14px !important;
        font-family: 'Poppins-Regular' !important;
        color: #FFFFFF !important;
        font-weight: normal !important;
        border-radius: 5px !important;

        span{
            margin-right: 20px !important;
            letter-spacing: 0.14px;
            line-height: 21px;
        }

        svg {
            path {
                fill: #fff !important;
            }
        }
    }
}
            }
        }
    }

}
        
        .delete {
            background-color: $global-color-13;
            border-color: $global-color-13;
            color: $color-white;
        }

        .export {
            background-color: $global-color-3;
            border-color: $global-color-3;
            color: $color-white;
        }
    }
}
