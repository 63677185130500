#registration-base-form {


    >.terms-of-service {
        >div{
            margin-bottom: 35px;
        }
        .ant-checkbox-inner{
            width: 29px !important;
            height: 29px !important;
           
        }
        .ant-form-item-control-input{
            min-height: 100%;
        }
    }

    >.has-account {
        text-align: center;
        margin-top: 26px;
        .ant-form-item-control-input{
            min-height: 100%;
            .ant-form-item-control-input-content{
                line-height: 21px;
                letter-spacing: -0.14px;
            }
        }
    }
    >.phone-input{
        &:active,&:focus-within{
            .ant-form-item-control-input{
                border-color: #005AE7;
    box-shadow: 0px 3px 6px 0px rgb(0 90 231 / 22%) !important;
            }
        }
        .ant-input-prefix{
            svg{
                width: 16px;
                height: 11px;
            }
        }
    }
    > div{
        &:first-child{
            small{
                line-height: 18px;
                color: #BCC7D9;
            }
            .ant-form-item-control-input{
                min-height: 100%;
            }
        }
    }

}
