.charges-type {
    margin-bottom: 20px;
    font-size: 12px;
    .ant-space .ant-space-item:last-child {
        color: #758CA1;
    }
}
.time {
    margin-bottom: 16.9px;
    .label{
       font-size: 12px;
       font-family: "Poppins-Light"; 
       line-height: 18px;
       color: #BBC6D1;
       letter-spacing: -0.12px;
    }
    .content{
        line-height: 21px;
        font-size: 14px;
        color: $global-color-4;
        letter-spacing: -0.14px;
    }
}

.payment-block {
    margin-bottom: 5px;
}
