.avatar-upload-wrapper {

    .avatar-upload {
        display: inline-block;
        border-radius: 50%;
        padding: 11px;
        border: 2px solid #E7EBEF;
        width: 110px + (11px * 2) + (2 * 2px);
        height: 110px + (11px * 2) + (2 * 2px);

        .ant-upload {
            display: flex;
            justify-content: center;
            align-items: center;
            height: 100%;
            width: 100%;
            cursor: pointer;
        }

        .image {
            width: 110px;
            height: 110px;
            border-radius: 50%;
        }
    }

    .delete-img-icon {
        position: absolute;
        right: 0;
        bottom: 0;
        margin-right: -15px;
    }
}
