@import './InvoiceTable/style';
@import './GraphCard/style';
@import './Loading/style';


////////////RESPONSIVE

@media (max-width:991px){
.invoices-row-m1{
    padding:20px;
}
.finished{
    .block{
        padding-bottom: 20px;
    }
}
}
@media (max-width:1599px){
    .invoices-row-m1{
        .graph-wrapper{
           row-gap: 30px !important;
        }
    }
    }

@media (max-width:767px){
    .invoices-row-m1{
        .graph-wrapper{
            flex-direction: column;
            row-gap: 15px !important;
            >div{
                flex: 0 0 100% !important;
                max-width: 100% !important;
            }
        }
    } 
}
@media (max-width:575px){
.ant-input-search{
    width: 100% !important;
}
 .invoices-row-m1{
.filter-content{
    flex-direction: column;
    >div:last-child{
        >div{
            width: 100%;
            flex-direction: column;
            >div{
                width: 100%;
            }
        }
    }
}
}
}
@media (max-width:399px){
    .invoices-row-m1 > div:nth-child(1) .graph-wrapper .ant-card .ant-card-body >.ant-row {
        flex-direction: column;
        >.ant-col{
            flex: 0 0 100%;
    max-width: 100%;
        }
    }
    }