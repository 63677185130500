#add-tour-packages-page ,#add-cruise-tour-page{
    @import './GeneralDetails/style';
    @import './Accommodation/style';
    @import './Steps/style';

    .left-col{
        #packages-general-details-form{
            form {
                padding-left: 30px;
                padding-right: 30px;
            }
        }
        .ant-input-search, .accommodation-text, .ant-form-horizontal{
            padding-left: 30px;
            padding-right: 30px;
        }
        .ant-input-group{
            border: 2px solid #DDE4EB;
            border-radius: 5px;
            svg{
                width: 20px;
                height: 20px;
            }
        }
        .accommodation-text{
            margin-top: 30px;
            @include d-flex;
            align-items: center;
            .ant-typography{
                @include d-flex;
                color: #758CA1;
                line-height: 21px;
                align-items: center;
                letter-spacing: 0.14px;
                svg{
                    margin-right: 9.3px;
                    width: 15.24px;
                    height: 15.24px;
                }
            }
            button{
                padding: 0;
                padding-left: 5px;
                height: auto;
                &:hover{
                    background: none;
                }
                span{
                    text-decoration: underline;
                    line-height: 21px;
                    letter-spacing: 0.14px;
                }
           
            }
        }
    }

    @media screen and (max-width: 991px) {
        .right-col {
            background-color: #EDEFF2;
        }
    }
}

////////////RESPONSIVE

@media (max-width:1399px){
    #add-tour-packages-page{
        .left-col{
            .accommodation-text{
                flex-direction: column;
                button{
                    padding-top: 5px;
                }
            }
        }
    }
}
@media (max-width:1199px){
    #add-tour-packages-page{
        .left-col{
            .accommodation-card{
                .ant-card-meta-description{
                    >div{
                        flex-flow: nowrap;
                    }
                }
            }
        }
    }
}
@media (max-width:991px){
    #add-tour-packages-page,#add-tour-trip-page,#add-cruise-tour-page{
        padding-top: 20px;
    }
}
@media (max-width:575px){
    #add-tour-packages-page,#add-cruise-tour-page{
        .left-col{
            .accommodation-card,.ship-card{
                .ant-card-meta{
                    @include d-flex;
                    flex-direction: column;
                    > .ant-card-meta-detail{
                        width: 100%;
                        margin-top: 15px;
                    }
                }
            }
        }
    }
}