.accommodation-card-style {
    border: 1px solid #E7EBEF !important;

    .ant-divider{
        border-width: 1px;
    }
    .ant-rate {
        font-size: 14px;
        color: $global-color-3;
    }
    .ant-card-meta{
        @include d-flex;
    }
    .ant-card-meta-avatar{
        width: 192px;
        height: 216px;
        min-height: 100%;
        padding-right: 0px;
        min-width: 192px;
    }
    .ant-card-body{
        padding:0px;

    }
    .title {
        display: block;
        text-decoration: none;
        color: $global-color-9;
        font-family: 'Poppins-Medium';
        font-size: 14px;
        letter-spacing: 0.14px;
        line-height: 21px;
    }
    .accommodation-list_address, .accommodation-list_rooms, .accommodation-list_b2b_agency {
       margin-top: 5px;
       @include d-flex;
       align-items: center;
       span{
        color: $global-color-2 ;
        font-family: 'Poppins-Regular';
        font-size: 12px;
        line-height: 18px;
        letter-spacing: 0.12px;
       }
       svg{
           margin-right: 11.1px;
       }
    }
    .accommodation-list_rooms{
        svg{
            margin-right: 8.4px;
            width: 12.76px;
            height: 10.63px;
        }
    }








    &.list {
        
        .ant-card-body {
            .ant-card-meta-detail {
                padding: 0px;
                width: 100%;
                
                .ant-divider-horizontal {
                    width: 50%;
                    min-width: 50%;
                    border-color:#E7EBEF !important ;
                    margin: 19.5px 0 16.5px 0;
                }
                
                .ant-divider.ant-divider-vertical {
                    height: 100%;
                    border-color:#E7EBEF !important ;
                }
    
                .facilities {
                    font-family: 'Poppins-Light';
                    font-size: 12px;
                    line-height: 25px;
                    color: $global-color-4;
                }
                .view-more-button{
                    svg{
                        margin-right: 10px;
                    }
                }
                .price{
                    text-align: right;
                    .ant-typography{
                        color: $global-color-2;
                    }
                    span{
                        font-family: 'Poppins-Medium';
                        color: $global-color-9;
                        font-size: 14px;
                        line-height: 21px; 
                        letter-spacing: 0.14px;
                    }
                }
                .ant-card-meta-description{
                    > div{
                        >.ant-col
                        {
                            &:last-child{
                                >div{
                                    height: 100%;
                                    @include d-flex;
                                    justify-content:space-evenly;
                                    >div {
                                        &:first-child {
                                            .ant-divider-vertical {
                                                &.change-margin {
                                                    margin: 0 -4px;
                                                }
                                            }
                                        }
                                    }
                                    >div{
                                        &:last-child{
                                           height: 100%;
                                           margin-top: 60px;
                                           .ant-dropdown-trigger{
                                            width: 34px;
                                            height: 34px;
                                            @include d-flex;
                                            align-items:center;
                                            justify-content: center;
                                            &:hover,&:active,&:focus{
                                                border-radius: 50%;
                                                svg>path{
                                                 fill: #758CA1 !important;
                                                }
                                             }
                                             &.display-none {
                                                display: none;
                                             }
                                        }
                                        }
                                        &:first-child{
                                           height: 100%;
                                        }
                                        &:nth-child(2){
                                            .reservation-button{
                                                margin-bottom: 8.5px;
                                            }
                                        }
                                    }
                                }
                            }
                            &:first-child{
                                padding: 26px 0 31px 32px;
                                >div:first-child{
                                    >.ant-space{
                                        > .ant-space-item:last-child{
                                            height: 17px;
                                        }
                                    }
                                }
                                .ant-rate{
                                    svg{
                                        vertical-align: super;
                                        width: 11.77px;
                                        height: 11.19px;
                                    }
                                }
                                >.ant-space{
                                    >div:first-child{
                                        >div{
                                            >span:not(:last-child){
                                                margin-right: 20px;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
       
        }

        .ant-avatar {
            width: 100%;
            height: 100%;
            border-radius: 5px 0px 0px 5px;
        }
       
    }












    &.grid {
        .ant-divider{
            width: 100px;
            margin: 30px 0;
        }
        .ant-card-cover{
            height: 271px;
            background-color: black;
            border-radius: 5px 5px 0 0;
            > div{
                height: 100%;
                .ant-image{
                    height: 100%;
                    width: 100%;

                    img {
                        height: 100%;
                        width: 100%;
                        object-fit: cover;
                    }
                }
            }
            .accommodation-list_rooms{
                span{
                    color: #FFFFFF;
                }
                margin-bottom: 2px;
                margin-top: 0;
                svg{
                    path{
                        fill: #FFFFFF;
                    }
                }
            } 
            &::after{
                content: "";
                position: absolute;
                top: 278px;
                left: 50%;
                transform: translate(-50%, -50%);
                height: 100px;
                width: 153px;
                border-radius: 150px 150px 0 0;
                background-color:#FFFFFF ;
            }
        }
        .ant-card-body{
            .ant-card-meta-title{
                text-align: center;
              position: absolute;
              display: flex;
              justify-content: center;
              align-items: center;
              width: 100%;
              margin-top: -12px;
              margin-bottom: 0;
              >.title-content{
                @include d-flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
              }
            }
        }
        .cover-content {
            position: absolute;
            margin-top: -245px;
            margin-left: 28.8px;
            color: $global-color-6;
            >div:last-child{
                span{
                    line-height: 20px;
                    letter-spacing: 0.14px;
                }
            }


            .price {
                font-size: 18px;
                font-family: "Poppins-Medium";
                letter-spacing: 0.18px !important;
            }
        }

        .ant-dropdown-trigger {
            position: absolute;
            height: auto;
            right: 26px;
            top: 27.6px;
            color: $global-color-6;
            svg{
                path{
                    fill: #FFFFFF !important;
                }
            }
            &.display-none {
                display: none;
             }
        }
    
        .title-content {
            .address {
                .accommodation-list_address{
                    margin-top: 6.7px;
                    span{
                        line-height: 14px;
                    }
                    svg{
                        width: 10px;
                        height: 12px;
                        margin-right: 8.4px;
                    }
                }
            }
            .title{
                font-size: 16px;
                line-height:25px ;
                letter-spacing: 0.16px;
            }
            svg{
                width: 14px;
                height: 13px;
            }
        }
        .ant-card-meta-description{
            margin-top: 100px;
        .ant-row{
            padding: 30px 30px 0 30px;
            .ant-col{
                line-height: 14px;
                font-family: "Poppins-Light";
                color: #758CA1;
                font-size: 12px;
            }
        }
        .ant-space{
            padding: 0px 30px 30px 30px;
            display: flex !important;
            justify-content: space-between;
            flex-flow: wrap;
            .view-grey-button{
          svg{
            margin-left: 16.6px;
          }
            }
        }
    

        }
    }

 
   

}
