.permission-section {
    background-color: $color-white;
    border-radius: $border-radius-small;

    .add-button-wrapper {
        float: right;
        width: 300px;
        padding: 30px 30px 0 30px;
    }


    .ant-divider-horizontal {
        margin: 0;
        border-color: #E5EBF0 !important;
    }
    >div:nth-child(4){
margin-left: 30px;
margin-right: 30px;
.ant-divider-dashed{
    border-width: 1px 0 0;
    border-color: #E5EBF0 !important;
}
    }
   
    .action-icon {
        float: right;
    }
    .access-text{
        margin: 26px 30px 23px 30px;
        align-items: flex-start;
        .ant-space-item{
            @include d-flex;
            span{
                font-family: "Poppins-Light";
                font-size: 12px;
                line-height: 18px;
                letter-spacing: -0.12px;
                color: #BBC6D1;
            }
        }
        svg{
            width: 14.5px;
            height: 14.5px;
        }
    }
    .ant-input-group-addon {
        background-color: $global-color-7;
    }
    .ant-btn-text.input-button {
        color: $color-white;
    }
    .role-list {
      margin: 0 30px 0 30px;
     
    }
    .add-button {
        @include d-flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        width: 100%;
        height: 55px;
        padding: 18px 37px;
        background-color: $global-color-17 !important;
        border-color: $global-color-17 !important;
        color: $global-color-6 !important;
        font-size: 14px;
        font-family: 'Poppins-Regular';
        font-weight: normal !important;
        line-height: 1 !important;
        border: 0 !important;
        box-shadow: none !important;
        svg{
            width: 12px;
            height: 12px;
            vertical-align: middle;
            line{
                stroke-width: 3;
            }
        }

    > span:last-child {
        margin-left: 15px !important;
    }

    }
    .ant-form-item-control-input-content{
        border: 2px solid #E5EBF0;
        border-radius: 5px;
    }
    .role-action{
        @include d-flex;
        align-items: center;
        justify-content: space-between;
        padding: 20px 30px 20px 30px;
        .role-name{
            font-size: 16px;
            color: #536DFE;
            line-height: 25px;
            letter-spacing: -0.16px;
        }
    }
    .button-row{
       padding: 6px 30px 30px 30px;
        font-family: 'Poppins-Medium';
        .ant-btn-primary{
            @include d-flex;
            align-items: center;
            justify-content: center;
            text-align: center;
            width: auto;
            height: auto;
            padding: 15px 137px;
            background-color: #536DFE;
            border-color: #536DFE;
            color: #FFFFFF;
            line-height: 1 !important;
            box-shadow: none !important;
            text-transform: uppercase;
        }
        .ant-btn-dangerous{
            @include d-flex;
            align-items: center;
            margin-right: 46px;
            font-size: 12px;
            color: #FC7886;
            text-transform: uppercase;
            letter-spacing: 0.12px;
            svg{
                margin-right: 13px;
                path{
                    fill: #FC7886;
                }
            }
        }
    }
    .ant-input-group-wrapper{
        padding: 30px 30px 0 30px;
        .ant-input-group{
            border-radius: 5px;
            >input{
            border: 2px solid #DDE4EB !important;
            border-right-width: 0 !important;
            padding: 13px;
            }
        }
        .ant-input-group-addon{
            border: none !important;
            padding: 0px;
            button{
               background-color: #536DFE;
               padding: 15px 46px;
               border-radius: 0 5px 5px 0;
               height: auto;
               span{
                font-family: "Poppins-Medium";
                line-height: 21px;
                letter-spacing: 0.14px;
               }
            }
        }
    }
    .new-role{
        padding: 30px 30px 0 30px;
        input{
            border: 2px solid #DDE4EB !important;
            border-radius: 5px;
        }
    }

}
