#view-tour-activity-page {
    margin-top: 30px;
    .left-col {
        @import './GeneralData/style';
        .ant-divider{
            margin: 30px 0;
        }
    }
    .left-col-photo-gallery{
        margin-top: 19px;
        max-height: 657px;
        @include d-flex;
        >.ant-image{
            height: auto !important;
            width: 80% !important;
            max-height: 100%;
            border: none;
            padding-right: 14px;
            .ant-image-mask{
                margin-right: 14px;
            }
        }
        >.image-collection{
        max-height: 657px;
            width: 20%;
            @include d-flex;
            flex-direction: column;
            gap: 10px;
            >.ant-image{
                height: 157px !important;
                max-height: 157px;
                border: none;
                border-radius: 5px;
                img{
                    height: 100%;
                    object-fit: cover;
                    object-position: center;
                border-radius: 5px;

                }
            }
        }
    }
    
    .right-col {
        @import './Calendar/style';
        margin-bottom: 0 !important;
        border: none !important;
        box-shadow: 0px 2px 6px 0px rgba(0,0,0,0.06);
        .time-price {
            button {
                svg {
                    g {
                        line{
                            stroke: #dde4eb;
                        }
                    }
                }
            }
          }
      .duration-content{
      align-items: baseline;
      .info-section{
          color: #758CA1 !important;
          .name{
span{
    color: #758CA1 !important;
    font-size: 12px;
    line-height: 18px;
}
          }
          .content{
              font-family: "Poppins-SemiBold";
              line-height: 21px;
          }
      }
      }
    }


    .left-col, .right-col {
        padding: 30px 30px;
        background-color: $global-color-6;
        border-radius: 5px;
    }
 
    .activity-general-data {
        align-items: center;
        .ant-space-item{
            @include d-flex;
            svg:not(.agency-logo) {
                width: 15px;
                height: 15px;
                path, circle{
                    fill:#758CA1;;
                }
            }
            color: #758CA1;
            span{
                line-height: 16px;
            }
        }
      button{
          svg{
              path{
                  fill:#BBC6D1;
              }
          }
      }
      .price{
          font-family: "Poppins-SemiBold";
          font-size: 16px;
          letter-spacing: 0.16px;
      }
 
    }
    .activity-description{
        line-height: 22px;
        letter-spacing: 0.14px;
        color: #BBC6D1;
        }
    .info-text {
        margin-top: 20px;
        text-align: center;
        margin-bottom: 30px;
        font-size: 12px;
        line-height: 18px;
        letter-spacing: 0.12px;
    }
    .view-tour-activity-body{
        margin-top: 30px;
        .left-col{
            >.ant-card{
                border: none !important;
                >div>div{
                    height: 413px !important;
                    max-height: 413px;
                    >div{
                        border-radius: 5px;
                    }
                }
            }
        }
    }
    .header-text{
        position: absolute;
        top: -59px;
        .reservation-text{
            font-family: "Poppins-Medium";
            line-height: 20px
        }
        svg{
            width: 15px;
            height: 15px;
            vertical-align: bottom;
            path,circle{
                fill: #BBC6D1;
            }
        }
    }
    .view-one-header{
        align-items: center;
    
    
    }
    .btn-orange{
        @include d-flex;
        justify-content: center;
        align-items: center;
        padding: 20px 0 !important;
        height: auto !important;
        box-shadow: 0px 0px 25px 0px rgba(0,0,0,0.16) !important; 
        svg{
            margin-right: 32px;
            width: 24.86px;
            height: 30.6px;
        }
        span{
            font-family: "Poppins-Medium";
            font-size: 16px;
            line-height: 25px;
            letter-spacing: -0.16px;
            font-weight: normal !important;
        }
    }

}
////////////RESPONSIVE

@media (max-width:1599px){
#view-tour-activity-page{
    .right-col-container{
        >.right-col{
            >.ant-row{
                flex-direction: column-reverse;
                .calendar-divider{
                    display: none;
                }
                >.ant-col{
                    flex: 0 0 100%;
    max-width: 100%;
                }
            }
        }
    }
}
}
@media (max-width:1399px){
#view-tour-activity-page{
        .activity-general-data{
            row-gap: 5px !important;
        }
}
}
@media (max-width:1199px){
    #view-tour-activity-page{
        .view-tour-activity-body{
            >div{
              &:last-child{
                .btn-orange{
                    svg{
                        margin-right: 15px;
                    }
                    span{
                        font-size: 13px;
                    }
                }
              }
            }
        }
    }
}
@media (max-width:991px){
    #view-tour-activity-page{
        >div{
            padding-left: 20px;
            padding-right: 20px;
        }
        .right-col-container{
            position: relative;
            width: 100%;
            max-width: 100%;
            padding-bottom: 20px;
        }
    }
}
@media (max-width:575px){
    #view-tour-activity-page{
   .view-one-header{
    row-gap: 15px !important;
   }
   .activity-general-data{
    row-gap: 10px !important;
   }
    } 
}
@media (max-width:399px){
    #view-tour-activity-page{
  .header-text{
    padding-left: 20px;
    padding-right: 20px;
  }
        .view-tour-activity-body{
            padding-left: 0;
            padding-right: 0;
        }
   .activity-general-data{
    row-gap: 10px !important;
    >.ant-col:last-child{
        position: absolute;
        right: 25px;
        top: 15px;
    }
   }
    } 
}
