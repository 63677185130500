// Tour & Accommodation dashboard
.tour-card {
    .button {
        width: 100% !important;
        height: 40px !important;
        @include d-flex-i;
        align-items: center !important;
        justify-content: center !important;
        text-align: center !important;
        padding: 0 15px !important;
        border-radius: 30px !important;
        color: $global-color-6 !important;
        font-size: 12px !important;
        font-family: 'Poppins-Regular' !important;
        font-weight: normal !important;
        text-transform: uppercase !important;
        border: 0 !important;
        background-color: transparent !important;
        @include transition(all 0.3s);

        &:not(:last-child){
            margin-bottom: 7px !important;
            box-shadow: 0px 3px 10px 0px rgba(83,109,254,0.31) !important;
        }

        &:hover {


        }

    }

    .btn-blue {
        background-color: $global-color-1 !important;
    }

    .btn-grey {
        color: $global-color-5 !important;
        span {
        color: $global-color-5 !important;
        }
    }
}

// Empty list
.empty-list-content {
    .create-button {
        width: 535px;
        height: 60px;
        font-size: 16px;
        background-color: $global-color-3;
        color: $global-color-6;
        border-radius: 36px;
        text-transform: uppercase;
        font-family: "Poppins-Medium";
        line-height: 25px;
        letter-spacing: -0.16px;
        box-shadow: 0px 3px 15px 0px rgba(242,163,91,0.25) !important;
        @include transition(all 0.3s);
        &.ant-btn:hover {
            color: $global-color-6;
            background-color: $global-color-3;
            border-color: $global-color-3;
            transform: translateY(-8px);
            box-shadow: 0px 10px 20px 0px rgba(242,163,91,0.59) !important;
            span{
                transform: translateX(-9px);
            }
        }
    }
}

// Filter content
.filter-content {
    .button {
        height: 50px;
        border-radius: 5px;
        font-size: 12px;
    }

    .filter-button {
        width: auto !important;
        height: 50px !important;
        font-size: 14px !important;
        font-family: 'Poppins-Medium' !important;
        color: $global-color-7 !important;
        border: 2px solid #FCFDFD !important;
        @include d-flex-i;
        align-items: center !important;
        justify-content: center !important;
        line-height: 1 !important;
        padding: 0 21px !important;
        background-color: #FCFDFD !important;
        box-shadow: 0px 2px 3px rgba($color: #000000, $alpha: 0.08) !important;
        border-radius: 5px !important;
        overflow: hidden !important;


        > span:last-child {
            margin-left: 15px !important;
        }


        &.ant-btn:hover {

        }
        // &:focus-within{
        //     border: 2px solid #536DFE !important;
        // }
 
    }

    .link-anh {
        text-decoration: none !important;
    }
    
    a {
       text-decoration: none !important; 
    }
    
    .add-button {
        @include d-flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        width: auto;
        height: auto;
        padding: 18px 53px;
        background-color: $global-color-17 !important;
        border-color: $global-color-17 !important;
        color: $global-color-6 !important;
        font-size: 12px;
        font-family: 'Poppins-Medium';
        font-weight: normal !important;
        line-height: 1 !important;
        border: 0 !important;
        box-shadow: none !important;

    > span:last-child {
        margin-left: 15px !important;
    }

    }

    .change-mode-button {
        width: 50px;
        height: 50px;
        border: none;
        padding: 0px;
        background-color: #FCFDFD !important;
        box-shadow: 0px 2px 3px rgba($color: #000000, $alpha: 0.08) !important;
        border-radius: 5px;
        overflow: hidden;
        @include d-flex;
        justify-content: center;
        align-items: center;
        span {
            &[role='img'] {
                display: flex;
                justify-content: center;
                align-items: center;
                font-size: 30px;
        }   }
        svg{
            path,circle{
                fill: #BBC6D1 !important;
            }
        }
    }
}

// ViewAll
.tour-type-list-card,
.accommodation-card-style {
    .ant-btn {
        padding: 0px;
    }
    .ant-card-meta{
        margin: 0;
    }

    .view-button, .reservation-button {
        width: auto;
        height: auto;
        background-color: $global-color-3;
        border-color: $global-color-3;
        border-radius: 5px;
        text-transform: uppercase;
        padding: 13px 16px;

        span {
            margin-right: 16px;
            @include transition(all 0.3s);
            color: $global-color-6;
            font-size: 12px;
            font-family: 'Montserrat-Medium';
            line-height: 15px;
            letter-spacing: -0.12px;

        }

        &.ant-btn:hover {
            color: $global-color-6;
            background-color: $global-color-3;
            border-color: $global-color-3;
        }
        svg{
            width: 5.15px !important;
            height: 8.35px !important;
            @include transition(all 0.3s);

        }
    }
    .view-button{
        &:hover{
            span{
                transform: translateX(-10px);
            }
            svg{
                transform: translateX(11px);
            }
        }
    }

    .view-grey-button {
        width: auto;
        height: auto;
        background-color: #F4F6F8;
        border-color: $global-color-11;
        border-radius: 5px;
        text-transform: uppercase;
        padding: 13px 19px;
        text-align: start;
        @include d-flex;
        align-items: center;
        justify-content: space-between;
        span{
            font-family: 'Montserrat-Medium';
            color: $global-color-5;
            font-size: 12px;
            line-height: 15px;
            letter-spacing: -0.12px;
        }
        svg{
            path{
                fill: #BBC6D1;
            }
        }

        &.ant-btn:hover {
            color: $global-color-5;
            background-color: $global-color-11;
            border-color: $global-color-11;
        }
    }

    .view-more-button {
        @include d-flex;
        justify-content: center;
        align-items: center;
        width: 107px;
        height: 30px;
        background-color: $global-color-8;
        border-color: $global-color-11;
        color: $global-color-4;
        span{
            font-size: 12px;
            font-family: "Poppins-Regular";
            line-height: 19px;
            letter-spacing: 0.24px;
        }
        &.ant-btn:hover {
            color: $global-color-4;
            background-color: $global-color-11;
            border-color: $global-color-11;
        }
    }
}

// Add & View
#add-tour-trip-page,
#add-tour-packages-page,
#add-cruise-tour-page,
#add-tour-activity-page,
#view-tour-trip-page,
#add-accommodation-page,
#view-accommodation-page,
.add-employee {
    .button {
        width: 100%;
        height: 50px;
        border-radius: 5px;
        color: $global-color-6;
        font-size: 12px;
        font-family: "Poppins-Medium";
    }

    .btn-orange {
        background-color: $global-color-3;
        @include transition(all 0.3s);
        >div{
            line-height: 18px;
            letter-spacing: -0.12px;
        }
        &.ant-btn:hover {
            color: $global-color-6;
            background-color: $global-color-3;
            border-color: $global-color-3;
        }
        .ant-space-item{
            @include transition(all 0.3s); 
        }
        &:not(.reservation-button){
            svg{
                width: 14.26px;
                height: 9px;
            }
            &:hover{
                transform: translateY(-8px);
                box-shadow: 0px 5px 6px 0px rgba(0,0,0,0.16) !important;
                .ant-space-item{
                    &:first-child{
                        transform: translateX(-44px);
                    }  
                    &:last-child{
                        transform: translateX(36px);
                    }  
                }
    
            }
        }
      
    }

    .btn-grey {
        background-color: $global-color-10;
        border-color:$global-color-4;
        color: $global-color-4;
        font-family: "Poppins-Medium";
        font-size: 12px;
        display: flex;
        justify-content: center;
        align-items: center;
        svg{
            path{
                fill: #758CA1;
            }
            #delete{
                fill: #BBC6D1;
            }
        }
        span {
            margin-left: 10px;
        }
    }

    .add-button {
        width: 193px;
        background-color: $global-color-1;
        border-color: $global-color-1;

        &.ant-btn:hover {
            color: $global-color-6;
            background-color: $global-color-1;
            border-color: $global-color-1;
        }
    }

    .add-button-outlined {
        height: 50px;
        padding: 17px 34px;
        background-color: #FFFFFF;
        border: 2px solid #536DFE !important;
        @include transition(all 0.3s);
        @include d-flex;
        align-items: center;
        justify-content: center;
        span{
        color: $global-color-1;
        font-family: "Poppins-Medium";
        font-size: 12px;
        line-height: 18px;
        letter-spacing: 0.12px;
        }
        svg{
            margin-right: 13px;
            width: 15.64px;
            height: 15.64px;
            line{
                stroke: $global-color-7;
            }
        }
        &:hover{
            background-color: #536DFE;
            span{
                color: #FFFFFF !important;
            }
            svg{
                line{
                    stroke: #FFFFFF;
                }
            }
        }
    }

    .text-add-button {
        color: $global-color-7;
    }

    .create-button-filled {
        width: 173px;
        background-color: $global-color-7;
        border-color: $global-color-7;
        color: $global-color-6;
        border-radius: 36px;
    }

    .circle-add-button {
        border: 2px solid $global-color-7;
        color: $global-color-7;
        &:hover {
            background-color: transparent;
        }
    }

    .delete-button {
        background-color: $global-color-13;
        border-color: $global-color-13;

        &.ant-btn:hover {
            color: $global-color-6;
            background-color: $global-color-13;
            border-color: $global-color-13;
        }
    }

    .delete-button-outlined {
        background-color:#F4F6F8;
        border-color: #707070;
        color:#BBC6D1;
        @include d-flex;
        justify-content:center;
        align-items: center;
        padding: 17px 30px;
        svg{
            width: 15px;
            height: 15.63px;
            margin-right: 13px;
        }
        span{
            font-family: "Poppins-Medium";
            font-size: 12px;
            line-height: 18px;
            letter-spacing: 0.12px;
        }
        &:hover{
            background-color: #FC7886;
            color: #FFFFFF;
            svg{
                path{
                    fill:#FFFFFF;
                }
            }
        }
    }

    .circle-delete-button {
        background-color: $global-color-11;
    }

    .button-underline {
        span {
            text-decoration: underline;
        }
    }

    .ant-btn-circle {
        background-color: $global-color-13;
        border-color: $global-color-13;
        span {
            &[role='img'] {
                color: $global-color-6;
                font-size: 26px;
            }
        }
    }

    .text-circle-button {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0px;

        svg {
            background: $global-color-7;
            border-radius: 50%;
        }
        span {
            color: $global-color-7;
            margin-left: 12px;
            letter-spacing: 0.14px;

        }

        &.ant-btn:hover,:active {
            background: none;
        }
    }
        .view-one-btn{
        background-color: #3B55EB;
        span{
            font-family: "Poppins-Medium";
            letter-spacing: -0.14px;
            color: #FFFFFF;
        }
    }
}

// View
#view-tour-trip-page,
#view-tour-package-page,
#view-tour-cruise-page,
#view-tour-activity-page,
#view-accommodation-page
{
    .button {
        width: 100%;
        height: 50px;
        border-radius: 5px;
        color: $global-color-6;
        font-size: 12px;
    }

    .btn-orange {
        background-color: $global-color-3;
        border-color: $global-color-3;

        &.ant-btn:hover {
            color: $global-color-6;
            background-color: $global-color-3;
            border-color: $global-color-3;
        }
    }

    .btn-grey {
        background-color: $global-color-8;
        border-color: $global-color-8;
    }

    .add-button {
        width: 193px;
        background-color: $global-color-1;
        border-color: $global-color-1;

        &.ant-btn:hover {
            color: $global-color-6;
            background-color: $global-color-1;
            border-color: $global-color-1;
        }
    }

    .add-group-button {
        width: 267px;
        height: 69px;
        background-color: $global-color-6;
        border: 2px solid $global-color-7;
        border-radius: 5px;
        color: $global-color-7;
    }

    .search-button {
        height: 70px;
        background-color: $global-color-7;
        border-color: $global-color-7;
        color: $global-color-6;
    }

    .save-button {
        height: 50px;
        width: 100px;
        background-color: $global-color-7;
        border-color: $global-color-7;
        color: $global-color-6;
    }

    .reservation-button {
        width: 250px;
        height: 50px;
        border-radius: 5px;
        color: $global-color-6
    }
}

.add-employee, .add-customer  {
    .form-button {
        padding-top: 30px;
        max-width: 800px;
        margin: 0 auto;

        .save,.save-button {
            background: $global-color-12;
            color: $color-white;
            border-color: $global-color-12;
            @include transition(all 0.3s);
            >div{
                @include d-flex;
                align-items: center;
            }
            svg{
                @include transition(all 0.3s);
                opacity: 0;
                margin-left: 44px;
            }

            span{
                font-family: "Poppins-Medium";
                line-height: 21px;
                letter-spacing: -0.14px;
            @include transition(all 0.3s);

            }
            &:hover{
                span{
                    transform: translateX(-76px);
                }
                svg{
                    opacity: 1;
                    transform: translateX(76px);
                }
                background: #02BB8D;
            }
        }

        .back {
            background: $global-color-5;
            color: $global-color-9;
            border-color: $global-color-5;
            .ant-space-item{
                font-family: "Poppins-Medium";
                line-height: 21px;
                letter-spacing: -0.14px;
                @include d-flex;
            }
            svg{
                margin-left: 0;
                margin-right: 13px;
                path{
                    fill: $global-color-9;
                }
            }

        }
    }
}
#view-accommodation-page {
    .search-button {
        width: 200px;
    }
}

#view-tour-package-page,
#view-tour-cruise-page {
    .search-button {
        width: 150px;
    }
}
