#accommodation-details-form {
    @import './Policy/style.scss';
    > div {
        padding: 0px 30px;
        &:last-child{
            .ant-form-item{
                margin-bottom: 0;
            }
        }
        &:nth-child(6){
            margin-bottom: 56px;
            padding:42px 30px ;
            > div >div >div > div{
                >span{
                    letter-spacing: 0.14px;
                }
                >.row-typography{
                    margin-top: 20px;
                    margin-bottom: 12px;
                }
            }
            .create-section{
                .input-section.open{
                    margin-top: 30px;
                    .ant-form-item{
                        margin-bottom: 0;
                    }
                }
            }
            .ant-checkbox-inner{
                width: 25px !important;
                height: 25px !important;
            }
            .ant-checkbox + span{
                font-family: "Poppins-Light";
                letter-spacing: 0.14px;
            }
            .create-section_button{
                span{
                    margin-left: 8px;
                }
            }
           
        }
        &:nth-child(5){
            .ant-form-item:not(.row-typography .ant-form-item){
                margin-bottom: 11px;
                .ant-form-item-control-input-content{
                    >div{
                        height: 251px !important;
                        >div{
                            border-radius: 5px;
                        }
                    }
                }
            }
        }
        &.ant-form-item{
            margin-bottom: 0;
        }
        &:nth-child(4){
            >div>div>div>div{
                &:nth-child(2){
                    margin-top: 14.9px;
                    padding-left: 9.5px;
                    .ant-form-item-control-input{
                        min-height: 100%;
                    }
                }
                &:first-child{
                    padding-left: 9.5px;
                }
                &:last-child{
                    > div{
                        &:nth-child(3){
                            .ant-input-affix-wrapper{
                                padding: 0 16.9px 0 21px !important;
                            }
                        }
                    }
                }
            }
        }
        &:nth-child(5) > div > div > div{
            > .row-typography{
                margin-top: 16px;
                .mui-form-item{
                    margin-bottom: 20px;
                }
            }
        }
        .ant-select .ant-select-selection-item {
            color: #495274;
        }
    }
    .input-group-container{
        padding: 40px 30px 12px 30px;
        .checkbox-multi-select>div{
            row-gap: 15px !important;
        }
        .border-text{
            margin: -50px 0 0 -9px;
        }
    }
}
