.textSize14 {
    font-size: 14px;
    .ant-typography {
        color: $global-color-9;
    }
}

.textSize12 {
    font-size: 12px;
    color: $global-color-2;
}


.paid {
    color: $global-color-12;
}

.unpaid {
    color: $global-color-13;
}

.partial {
    color: $global-color-17;
}

.canceled {
    color: $global-color-3;
}
.draft {
    color: $global-color-2;
}
