#trip-general-details-form {
    .input-price-section {
        @include scrollbar($_thumb-color);
        overflow-x: hidden;
        margin-top: 0px;
        margin-bottom: 30px;

        .ant-form-item {
            margin-bottom: 10px;
        }
    }
}
