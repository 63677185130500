.general-section {
    padding: 26px 30px 20px 30px;
    .ant-space-horixontal{
        align-items: flex-start;
    }
}

.progress-section {
    position: relative;
    background-color: rgba(#24DCAE,0.10);
    padding: 25px;

    .ant-progress {
        display: flex;
        flex-direction: column-reverse;
        .ant-progress-inner{
            background: rgba(#24DCAE, 0.33);
        }
    }
    .ant-progress-show-info .ant-progress-outer {
        padding-right: 0px;
        padding-bottom: 20px;
    }
    .ant-progress-text {
        display: block;
        color: $global-color-12;
        font-size: 12px;
        line-height: 24px;
        margin-left: 0;
    }
    .ant-btn {
        border-radius: $border-radius-small;
        background-color: rgba(#24DCAE, 0.46);
        border-color: $global-color-12;
        color: $color-white;
        padding: 12px 16px;
        background-size: 0 100%;
        background-repeat: no-repeat;
        float: right;
        background-image: linear-gradient(#24DCAE,#24DCAE);
        &:hover{
            background-size: 100% 100%;
        }
        span{
            font-size: 12px;
            line-height: 27px;
            @include d-flex;
            align-items: center;
        }
        svg{
            margin-right: 18px;
            path{
                fill: #FFFFFF;
            }
        }
        &.close{
            background-color: #24DCAE;
            padding: 12px 28px;
        }
    }
}

.details-section {
padding: 15px 25px 0 25px;
    .info-section{
     padding-bottom: 15px;
        .name{
            margin-bottom: 4px;
            span{
                color: $global-color-2;
                font-size: 12px;
                line-height: 18px;
                font-family: 'Poppins-Regular';
                letter-spacing: -0.12px;
            }
        }
        .content{
            color: $global-color-4;
            font-family: 'Poppins-Regular';
            line-height: 21px;
            font-size: 14px;
            letter-spacing: -0.14px;
        }
    }
    .ant-divider-horizontal {
        margin: 0px;
    }
}
