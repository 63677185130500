.compare-report {
    .ant-modal-body {
        .modal-filters {
            .ant-typography {
                font-size: 12px;
                font-weight: normal;
                color: $global-color-2;
            }

            .ant-picker {
                box-shadow: none !important;
                border: 1px solid $global-color-10 !important;
            }

            .icon {
                display: flex;
                align-items: center;
                justify-content: center;

                svg {
                    path, circle {
                        stroke: $global-color-2;
                    }
                }
            }
        }

        .data {
            margin-top: 15px;

            .odd-row {
                background-color: #F9FAFB;

                &:first-child {
                    border-top: 1px solid $global-color-8;
                }
            }

            .odd-row, .eaven-row {
                display: flex;
                align-items: center;
                padding: 5px 15px;
                color: $global-color-9;

                .value {
                    font-size: 14px;
                }

                .difference {
                    display: flex;
                    font-size: 10px;
                    svg {
                        width: 15px;
                        height: 15px;
                        margin-right: 5px;
                    }
                }

                .svg-1, .svg-2, .svg-3, .svg-4, .svg-5, .svg-6, .svg-7, .revenue-svg, .sales-svg,.total-vendor-svg, .total-supplier-svg {
                    width: 34px;
                    height: 34px;
                    border-radius: 100%;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                }

                .svg-1 {
                    background-color: #089ECB;

                    svg {
                        width: 17px;
                        path {
                            stroke: $color-white;
                        }
                    }
                }

                .svg-2 {
                    background-color: $global-color-13;
                }

                .svg-3 {
                    background-color: $global-color-12;
                }

                .svg-2, .svg-3 {
                    svg {
                        #Path_6148, #Path_6149 {
                            stroke: $color-white;
                        }
                    }
                }

                .svg-4 {
                    background-color: #E4FAF5;
                }

                .svg-5 {
                    background: rgba($global-color-3, 0.15);
                }

                .svg-6 {
                    background: rgba($global-color-13, 0.15);
                }

                .svg-7 {
                    background: rgba($global-color-7, 0.15);
                }

                .revenue-svg, .sales-svg {
                    background:rgb(#24dcae, 0.15);
                }

                .total-vendor-svg, .total-supplier-svg {
                    background:rgba(#089ecb, 0.15);
                }
            }
        }

        .results {
            margin-top: 25px;
            background: rgba($global-color-7, 0.05);
            border-radius: $border-radius-small;
            padding: 25px 0px 0px 20px;

            .section-title {
                font-size: 16px;
                font-weight: 500;
                color: $global-color-7;
            }

            .info-text-1 {
                .ant-typography {
                    font-size: 12px;
                    color: #606EBE;
                }
            }

            .info-text-2 {
                .ant-typography {
                    font-size: 12px;
                    color: #606EBE;
                    font-family: 'Poppins-Italic';
                }
            }

            .ant-divider.ant-divider-horizontal {
                border-color: rgba($global-color-7,0.35) !important;
                width: 97%;
                min-width: 97%;
            }

            .button-container {
                padding-bottom: 25px;

                .marketing-button, .b2b-button {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    display: flex;
                    color: $color-white;
                    font-size: 12px;
                    svg {
                        margin-right: 15px;
                        width: 16px;
                        height: 16px;
                    }
                }
    
                .marketing-button {
                    background-color: $global-color-3;
                }
    
                .b2b-button {
                    background-color: $global-color-12;
                }
            }

            .style-component {
                display: flex;
                justify-content: flex-end;

                .col-1, .col-2, .col-3 {
                    background-color: rgba($global-color-7, 0.09);
                    border-radius: $border-radius-small;
                    width: 35px;
                    bottom: 0;
                    position: absolute;
                }

                .col-1 {
                    height: 60px;
                    margin-right: 102px;
                }

                .col-2 {
                    height: 110px;
                    margin-right: 52px;
                }

                .col-3 {
                    height: 140px;
                }
            }
        }
    }
}
