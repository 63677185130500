 .ant-upload-picture-card-wrapper {
    .ant-upload-list {
        display: flex;
        flex-wrap: wrap;

        .ant-upload-list-picture-card-container {
           width: 153px;
           height: 111px;
           order: 2;
           margin: 18px 26px 6px 0;
        }

        .ant-upload.ant-upload-select-picture-card {
            width: 100%;
            height: 252px;
            border: none;
            order: 1;
            flex: 0 0 100%;
            background-color: #F4F6F8;
        }
    }
}

.upload-info-text {
    font-size: 11px;
    color: $global-color-5;
}
.upload-info-text_container{
    margin-top: 20px;
}

