.accommodation-card ,.ship-card{
    margin-top: 30px;
    .ant-card-body{
        padding: 15px 20px;
    }
    .ant-card-meta{
        margin: 0;

        .ant-card-meta-detail {
            overflow: visible;
        }
    }
    .ant-card-meta-title {
        color: #758CA1;
          font-family: "Poppins-Medium";
          line-height: 21px;
          margin-bottom: 2px;
          letter-spacing: 0.14px;
    
        .ant-rate{
            margin-left: 14px;
            svg{
                width: 12px;
                height: 11px;
            }
        }
    }
    .description{
        margin-bottom: 2px;
       span{
        font-size: 12px;
        line-height: 18px;
        color: #BBC6D1;
        letter-spacing: 0.12px;
       }
    }

    .ant-avatar {
        width: 64px;
        height: 64px;
    }
    .selected-hotel{
        margin-top: -23px;
        @include d-flex;
        align-items: center;
        .ant-checkbox-inner{
            width: 34px !important;
            height: 34px !important;
            border-radius: 50%;
        }
        .ant-checkbox-wrapper:not(.ant-checkbox-wrapper-checked){
            .ant-checkbox-inner{
               background-color: #E7EBEF !important;
            }
        }
        button{
            margin-left: 10px;
            width: 34px !important;
            height: 34px !important;
       padding: 0px;
       @include d-flex;
       justify-content: center;
       align-items: center;
            svg{
                path{
                    fill: #BBC6D1;
                }
            }
            &:hover{
                border-radius: 50%;
                background: #F4F6F8;
                svg{
                    path{
                        fill: #758CA1;
                    }
                }
            }
        }
    }
    .ant-card-meta-avatar{
        padding-right: 22px;
    }
}
