.action-button {
    height: 41px;
    width: 41px;
    display: flex;
    justify-content: center;
    align-items: center;
    outline: 0px;
    padding: 0px;
    border: 0px;
    margin: 0px;
    border-radius: 100%;
    cursor: pointer;
    position: relative;
    background: none !important;
    &:hover{
        svg{
            path{
                fill: #758CA1;
            }
        }
    }

    svg {
        position: relative;
        z-index: 1;
        path{
            @include transition(all 0.3s);
        }
    }

    &::before {
        content: "";
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        border-radius: 100%;
        pointer-events: none;
        background-color: transparent;
        @include transition(all 0.3s);
        @include transform(scale(0));
        opacity: 0;
    }

    &:hover {
        &::before {
            background-color: #F4F6F8;
            @include transform(scale(1));
            opacity: 1;
        }
    }
}
.seasonal-tooltip{
    .ant-tooltip-inner{
        background-color: #758CA1 !important;
        font-family: "Poppins-Regular";
        line-height: 21px;
        padding: 5px 19px !important;
        letter-spacing: -0.14px;
    }
    .ant-tooltip-arrow-content{
        --antd-arrow-background-color: #758CA1 !important;
    }
}
