.ant-card {
    .ant-card-body {
        padding: 0px 0px 15px 0px;

        .ant-card-meta {
            .ant-card-meta-avatar {
                .ant-avatar.ant-avatar-square {
                    width: 120px;
                    height: 100px;
                }

                .tour-type {
                    width: 29px;
                    height: 29px;
                    background-color: #089ECB;
                    border-radius: 100%;
                    border: 2px solid $color-white;
                    margin-top: -10px;
                    margin-left: 100px;
                    position: absolute;
                    z-index: 1;
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    svg {
                        width: 11px;
                        height: 11px;

                        #fi-rr-ship {
                            fill: $color-white;
                        }
                    }
                }
            }
            .ant-card-meta-detail {
                .ant-card-meta-description {
                    .title {
                        font-size: 16px;
                        font-family: 'Poppins-Medium';
                        color: $global-color-9;
                        margin-bottom: 10px;
                    }

                    .ant-typography {
                        color: $global-color-2;
                        font-size: 12px;
                        margin-bottom: 0.5em;

                        svg {
                            width: 13px;
                            height: 13px;
                            margin-right: 5px;

                            #fi-rr-building, #fi-rr-time-quarter-to, #Path_6145, #Path_6146 {
                                fill: $global-color-2;
                            }
                        }
                    }

                    .commission {
                        border-radius: 13px;
                        background-color: #FFFAF3;
                        width: fit-content;
                        padding: 8px;
                        font-size: 12px;
                        color: $global-color-3;

                        svg {
                            width: 10px;
                            height: 10px;
                            margin-right: 5px;

                            #Path_6145, #Path_6146 {
                                fill: $global-color-3;
                            }
                        }
                    }

                    .ant-divider.ant-divider-vertical {
                        height: 100px;
                        border-color: $global-color-10;
                        border-width: 0px 0px 0px 1px;
                        margin: 0 -8px;
                    }

                    .request-nr {
                        font-family: 'Poppins-Medium';
                        margin-bottom: 10px;
                        color: $global-color-9;
                    }

                    .views {
                        font-family: 'Poppins-LightItalic';
                    }
                }
            }
        }
    }
}

.ant-divider.ant-divider-horizontal {
    margin: 0px 0px 15px 0px;
    border-top: 1px solid $global-color-10;
}

.ant-table {
    box-shadow: none;

    > div {
        width: 100%;
        overflow-x: auto;
        > table {
            min-width: 800px;
        }

        .approved {
            color: $global-color-12;
        }
        
        .canceled {
            color: $global-color-13;
        }
        
        .pending {
            color: $global-color-3;
        }
        
        .textSize14 {
            .ant-typography {
                color: $global-color-9;
            }
        }
        
        .textSize12 {
            font-size: 12px;
            color: $global-color-2;
        }

        .ant-btn.approve-btn, .ant-btn.cancel-btn {
            color: $color-white;
            font-family: 'Poppins-Medium';
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 12px;

            &::after {
                content: none;
            }
    
            .svg-wrapper {
                width: 20px;
                height: 20px;
                display: flex;
                justify-content: center;
                align-items: center;
                background-color: rgba($color-white, 0.2);
                border-radius: 100%;
                margin-left: 10px;
            }
        }

        .ant-btn.approve-btn {
            background-color: $global-color-3;

            svg {
                width: 10px;
                height: 10px;
                g {
                    path {
                        stroke: $color-white;
                    }
                }
            }
        }

        .ant-btn.cancel-btn {
            background-color: $global-color-13;
            svg {
                width: 10px;
                height: 10px;
                path {
                    fill: $color-white;
                }
            }
        }
    }
}
.request-pagination {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: 40px 0px 20px;

    .ant-pagination.mini {
        .ant-pagination-prev, .ant-pagination-next {
            button {
                background-color: $global-color-8 !important;
            }
        }
    }    
}
