.ticket-details {
    
    .ant-space {
        .ant-space-item {
            svg {
                path, line {
                    stroke: $global-color-2;
                }
            }
        }
    }
    .ant-divider-dashed{
        border-width: 1px 0 0;
    }
}

.flight-itenerary {
    padding-left: 15px;
    .type-of-trip{
        >.ant-space{
            &:first-child{
                svg{
                    width: 17.42px;
                    height:17.44px ;
                    path{
                        fill: #BBC6D1;
                    }
                }
            }
            &:last-child{
                span{
                    color: #BBC6D1;
                line-height: 21px;
                letter-spacing: -0.14px;

                }
            }
        }
        &.return{
            >div{
                margin-bottom: 21px ;
            }
        }
    }

    .ant-timeline.ant-timeline-label {
        .ant-timeline-item-label {
            top: 0;
            width: calc(25% - 18px);

            #date, #time-zone {
                font-size: 9px;
                line-height: 13px;
                letter-spacing: -0.09px;
                color: #BBC6D1;
                margin-bottom: 0;

            }

            #time {
                color: $global-color-4;
                font-size: 16px;
                font-family: "Poppins-Medium";
                line-height: 25px;
                margin-bottom: 0;
                letter-spacing: 0.16px;
                
            }
        }

        .ant-timeline-item-tail, .ant-timeline-item-head {
            left: 25%;
        }
  
        .ant-timeline-item-tail{
            border-left: 1px dashed #DEE5EC;
            height: calc(100% - 25px);
            top: 18px;
        }

        .ant-timeline-item-head-blue {
            background-color: $global-color-7;
            border-color: $global-color-7;
        }

        .ant-timeline-item-head-green {
            background-color: $global-color-12;
            border-color: $global-color-12;
        }

        .ant-timeline-item-content {
            left: calc(25% - 4px);
            top: 0;
            top: -3px;

            #departure {
                color: $global-color-7;
                font-size: 12px;
                line-height: 14px;
                margin-bottom: 6px;
            }

            #airline-name {
                color: $global-color-4;
                line-height: 24px;
                margin-bottom: 0;
            }

            #flight-number, #class {
                font-size: 12px;
                line-height: 24px;
                color: #BBC6D1;
                margin-bottom: 0;
            }
         

            #arrival {
                color: $global-color-12;
                font-size: 12px;
            }

            #transit {
                color: $global-color-3;
                font-size: 12px;
                margin-bottom: 6px;
            }
        }
    }

    .extra-bag {
        border: 1px dashed $global-color-10;
        border-radius: 5px;
        padding: 17.5px 18.5px;
        // white-space: pre-line;
        opacity: 0.1;
        >.ant-space{
            gap: 17px !important;
            align-items: flex-start;
            >div{
                &:first-child{
                    svg{
                        width: 17.37px;
                        height: 39.17px;
                    }
                }
                &:last-child{
                    .label{
                        color: #758CA1;
                        line-height: 16px;
                        margin-bottom: 2px;
                    }
                    .content{
                        font-size: 10px;
                        line-height: 16px;
                        color: #A6B0BB;
                    }
                }
            }
        }
        &.show-bag {
            opacity: 1;
        }
    }
    &.return{
        padding-bottom: 16px;
    }
}
