.ant-divider-vertical {
    height: 25em;
}
.calendar-divider{
    @include d-flex;
    justify-content: center;
}
.ant-picker-calendar{
    .calendar-header{
        margin-bottom: 22px;
    }
    .ant-picker-panel{
        border: none ;
    }
    .ant-picker-content{
        height: 100%;
        thead{
     padding-bottom: 15px;
            >tr{
                border-bottom: 1px solid #DDE4EB;
                >th{
                    font-family: "Poppins-SemiBold";
                    line-height: 21px;
                    padding-bottom: 5px;
                    color: #BBC6D1;
                }
            }
        }
        tbody{
            >tr{
                &:first-child{
                    >td{
                        padding-top: 15px;
                    }
                }
           
            }
        }
    }
  
    // .ant-picker-calendar-date-value{
    //     color: #758CA1;
    // }
    .ant-picker-cell{
        &::before{
            height: 36px;
        }
    }
    .ant-picker-cell-selected{

    }

    .ant-picker-cell-disabled:not( .ant-picker-cell-today ,.ant-picker-cell-selected){
        .ant-picker-calendar-date-value{
                color: #BBC6D1 !important;
             }
    }
    .ant-picker-calendar-date-value{
    color: #4D677E !important;
     min-width: 37px;
     height: 36px;
     width: 37px;
     @include d-flex;
     justify-content: center;
     align-items: center;
    }
    .ant-picker-cell-inner{
height: 36px;
    }
    .ant-picker-cell-today{
      .ant-picker-cell-inner{
          .ant-picker-calendar-date-value{
              color: #536DFE !important;
              font-family: "Poppins-Bold";
              line-height: 21px;
              background-color: #D7E7FF;
              border-radius: 50%;
          }
          background: transparent;
          &::before{
           border: none;
           font-family: "Poppins-Semibold";
 
           z-index: -1;
        
          }
      }
    }
    .ant-picker-cell-disabled{
        &::before{
            display: none;
        }
    }
}
