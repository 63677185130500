.change-password-page {
   display: grid;
   place-content: center;
   height: 100vh;
   margin-top: -80px;

   .ant-form {
        background: $color-white;
        padding: 45px 45px 35px 45px;
        border-radius: 10px;
        // max-width: 800px;
        // width: 800px;

        .ant-input-affix-wrapper {
            border: none;
            box-shadow: none !important;
        }

        .ant-space {
            p {
                font-size: 12px;
            }
        }
   }
}

@media screen and (max-width: 768px) {
    .change-password-page {
        margin-top: 0px;
        padding-left: 20px;
        padding-right: 20px;
    }
}
