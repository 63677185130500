.salary-job-position {
    @import './Role/style';
    
    .opacity {
        opacity: 0.3;
    }
    >div{
        &:first-child{
            > div {
                >.ant-row{
                    margin-bottom: 0;
                    padding: 39.9px 38.5px 0 42px;
                    .ant-form-item-control-input-content{
                        .ant-input-affix-wrapper {
                            border: none !important;
                            box-shadow: none !important;
                        }
                        >.ant-row{
                            >div{
                                &:nth-child(2){
                                    >.mui-form-item{
                                        margin-bottom: 0;
                                    }
                                }
                                &:last-child{
                                    >.ant-row-end{
                                        align-items: center;
                                        >.ant-col{
                                            >.ant-form-item{
                                                margin-bottom: 0;
                                            }
                                            .ant-typography{
                                                color: #758CA1;
                                                line-height: 21px;
                                                letter-spacing: -0.14px;
                                            }
                                            &:first-child{
                                                padding-right: 21px;
                                            }
                                            &:last-child{
                                                padding-left: 21px;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
            >.ant-divider{
                margin: 34px 0 29.9px 0;
                border-width: 1px;
            }
        }





        &:last-child{
margin-bottom: 0;
padding: 0 46.1px 54.9px 39.9px;
.ant-form-item-control-input-content{
      > #job-position{
        > div{
            &:first-child{
                margin-bottom: 9.2px;
            }
            &:nth-child(2){
                margin-bottom: 25.9px;
                .ant-typography{
                    font-size: 12px;
                    font-family: "Poppins-LightItalic";
                    line-height: 18px;
                    color: #BBC6D1;
                }
            }






            &:last-child{


                >button{

                    height: auto;

                    >span{
                        margin-left: 13.4px;
                        letter-spacing: -0.14px;
                        color: #089ECB;
                    }
>div{
    width: 30px;
    height: 30px;
    border-radius: 50%;
    background-color: #089ECB;
    @include d-flex;
    align-items: center;
    justify-content: center;
}
                    svg{
                       width: 15.14px;
                       height: 15.14px;
                        background: transparent;
                    }
                }




                .checkbox-multi-select{
                    >.ant-row{
                        >.ant-col{
                            flex: 0 0 25%;
    max-width: 25%;
    .ant-checkbox-wrapper:not(.ant-checkbox-wrapper-checked){
        .ant-checkbox-inner{
          background-color: #E7EBEF;
        } 
    }
    .ant-checkbox-inner{
        border-radius: 20px;
    }
    .ant-checkbox + span{
        // word-break: break-all;
        padding-left: 13px;
        line-height: 21px;
        letter-spacing: -0.14px;
        color: #758CA1;
    }
                        }
                    }
                }
            }
        }

      }







      > .add-new-role{

        >.ant-divider{
            margin: 35.5px 0 26.5px 0;
            >.ant-divider-inner-text{
                padding: 0 16px 0 11px;
                font-size: 16px;
                line-height: 25px;
                letter-spacing: 0.16px;
            }
        }
        >input{
            border: 2px solid #DDE4EB !important;
            margin-bottom: 10.5px;
        }
        >.ant-row{
            padding-top: 10px;
            .ant-btn-dangerous{
                @include d-flex;
                align-items: center;
                svg{
                    margin-right: 13.1px;
                }
                span{
                    color: #FC7886;
                    font-size: 12px;
                    font-family: "Poppins-Medium";
                    line-height: 18px;
                    letter-spacing: 0.12px;
                    text-transform: uppercase;
                }
            }
            .ant-btn-primary{
                background: #536DFE !important;
                span{
                    font-family: "Poppins-Medium";
                    line-height: 21px;
                    letter-spacing: 0.14px;
                    text-transform: uppercase;
                }
            }
        }
      }

    
}
        }
    }
}
