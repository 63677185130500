.filter-content {

    .info-section {
    
        .bigger-text-size {
            font-size: 18px !important;
            font-family: 'Poppins-SemiBold' !important;
            color: #4D677E !important;
            .ant-typography {
    font-size: 18px !important;
    font-family: 'Poppins-SemiBold' !important;
    color: #4D677E !important;
    line-height: 16px;

            }
    
        }
    
        .content {
            margin-top: 2px !important;
            font-size: 12px !important;
    font-family: 'Poppins-Regular' !important;
    color: #A6B0BB !important;
                .ant-typography {
    font-size: 12px !important;
    font-family: 'Poppins-Regular' !important;
    color: #A6B0BB !important;
    line-height: 16px;

            }
        }
    }

}


