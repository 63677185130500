.description {

    .label {
        font-size: 14px;
        color: $global-color-5;
        margin-right: 4px;
    }

    .content {
        font-size: 14px;
        color: $global-color-5;
    }
}
