.taxes {
    background-color: $color-white;
    border-radius: 10px;

    .filters {
        padding: 30px 30px 0px 30px;

        .ant-input, .ant-btn {
            background-color: $global-color-8;
        }

        .add-btn {
            display: flex;
            justify-content: center;
            align-items: center;
            background-color: $global-color-7;
            font-size: 14px;
            font-family: 'Poppins-Medium';
            color: $color-white;

            svg {
                margin-right: 15px;
            }

            &::after {
                content: none;
            }
        }
    }

    .ant-divider {
        margin: 30px 0px 14px 0px;
    }

    .ant-table {
        padding: 0px 30px 30px 30px;
        box-shadow: none;
        border-radius: 10px;
    }

    .ant-table table .ant-table-tbody > tr > td {
        border-bottom: 1px solid #E7EBEF !important;
    }
}

.ant-modal.add-tax-form {
    .ant-modal-content {
        .ant-modal-body {
            padding: 30px;
            .add-new-tax {
                .tax-icon {
                    width: 50px;
                    height: 50px;
                    border-radius: 100%;
                    background-color: rgba($global-color-7, 0.15);
                    display: flex;
                    justify-content: center;
                    align-items: center;
                }
                .ant-typography {
                    font-size: 18px;
                    font-family: 'Poppins-Medium';
                    color: $global-color-7;
                }

                .ant-btn {
                    background-color: $global-color-7;
                    color: $color-white;
                    font-size: 18px;
                    font-family: 'Poppins-Medium';
                    margin-top: 20px;
                    &::after {
                        content: none;
                    }
                }
            }
        }
    }
}

