.ant-card.sales-overview-card {
    box-shadow: 0px 2px 6px 0px rgb(0 0 0 / 5%);
    border-width: 1px;
    height: 100%;
    
    .ant-card-head-title {
        .sales-graphs_header_text {
            line-height: 27px;
            font-size: 18px;
            font-family: 'Poppins-Medium';
            color: #4D677E;
        }
    }
}
