.right-col {
    // steps
    @import './GeneralDetails/style';
    @import './Itenerary/style';
    @import './InclusionsExclusions/style';
   
    .steps-container {
        .ant-steps-item{
            width: 33.33%
        }
        .ant-steps-item-tail{
            margin-left:49%;
            top: 62px !important;
        }
        .ant-steps-item-title{
            font-family: "Poppins-Regular";
        }
        }
        .data-container{
            margin: 0 10px 0 30px;
            > .ant-card{
                border: none !important;
                .ant-card-body{
                    > div{
                        width: 698px;
                        height: 437px !important;
                        > div{
                            border-radius: 5px;
                        }
                    }
                }
            }
       
        }
    }

    .data-display {
        @include scrollbar($_thumb-color);
        height: 465px !important;
        overflow-x: hidden;
        margin-right: 14px;
        >div>div{
            &:first-child{
                .data-container{
                    margin-top: 50px;
                }
            }
        }
    }

    @media screen and (max-width: 991px) {
        .data-display {
            display: none;
        }
    }

