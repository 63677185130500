.tour-details {
    .selected-times {
        border: 2px dashed $global-color-2;
        border-radius: $border-radius-small;
        padding: 13px 15px 3px 15px;
        > div{
            margin-bottom: 10px;
            .ant-space{
                align-items: flex-start;
                .ant-space-item{
                    &:first-child{
                        svg{
                            width: 15px;
                            height: 15px;
                            path{
                                fill:#536DFE;
                            }
                        }
                    }
                    &:last-child{
                        .label{
                            font-size: 12px;
                            line-height: 20px;
                            color: #BBC6D1;
                        }
                        .content{
                            font-size: 14px;
                            color: #758CA1;
                            line-height: 20px;
                        }
                    }
                }
            }
        }
    }
    >div:first-child{
        text-align: center;
    }
}
