.search-form {
    line-height: 20px;
    padding: 0px 16px 0 12px;
    background-color: $global-color-6;
    border-radius: $border-radius-small;
    box-shadow: 0px 0px 16px 0px rgba(0,0,0,0.06);

    .ant-select-selector {
        border: none;
    }

    .ant-divider-vertical {
        height: 91px;
        border-left: 1px solid #DDE4EB !important;
    }

    .display-price {
        font-family: "Poppins-Regular";
        font-size: 12px;
        color: #536DFE;
        letter-spacing: -0.12px;
        line-height: 18px;
    }

    .ant-slider {
        width: 260px;
        margin: 30px 6px 10px;
    }
    .search-button{
        background-color: $global-color-3 !important;
        span{
            font-family: "Poppins-Medium";
            font-size: 16px;
            letter-spacing: 0.16px;
            line-height: 25px;
        }
    }
    .room-type_filters{
        width: 150px;
        // .ant-select-selection-overflow{
        //     flex-wrap: nowrap;
        // }
        >div{
            font-size: 12px;
            letter-spacing: -0.12px;
            &:first-child{
                margin-bottom: 5px;
            }
        }
        .ant-select{
            height: auto !important;
        }
        .ant-select-selection-item{
            padding-right: 0px !important;
            background: none !important;
            border: none !important;
            line-height: 2 !important;
            .ant-select-selection-item-content{
                color: #536DFE;
                line-height: 21px;
                letter-spacing: 0.14px;
            }
        }
    }
    .ant-space-item{
        padding-top: 21px;
        button{
            height: auto;
            padding: 0 0 13px 0!important;
        }
    }
}
.view-content{
    .search-form{
        .ant-picker{
            box-shadow: none !important;
        }
        .date-range{
            padding-left: 15px;
        }
        .ant-picker-input > input{
            color: #536DFE !important;
            line-height: 21px !important;
            letter-spacing: 0.14px;
        }
        .filter-guest_title,.date-range{
            font-size: 12px;
            color: #BBC6D1;
            letter-spacing: -0.12px;
        }
        .guests-options_picked{
            color: #536DFE;
            font-family: "Poppins-Regular";
            line-height: 21px;
            letter-spacing: 0.14px;

        }
       .guests_filters{
           width: 150px;
         
           button{
               svg{
                   margin-left: 10px;
               }
               &::after{
                display: none;
               }
           }
       }
    }
  
}
.reservation-guests_dropdown{
    // top: 310px !important;
    box-shadow: 0px 3px 6px 0px rgba(0,0,0,0.16);

}

.reservation-guests_dropdown_content{
    padding: 10px !important;
    width: 330px;
    span{
        font-family: "Poppins-Regular";
        color: #758CA1;
    }
    .increment-number-input{
        span{
            font-family: "Poppins-Medium";
            color: #536DFE;
            width: 20px;
            height: 20px;
            margin: 10px;
            @include d-flex;
            align-items: center;
            justify-content: center;
        }
      .ant-btn-text:focus{
            background: none !important;
        }
        .ant-btn{
            display: flex;
            align-items: center;
            justify-content: center;
            width: 20px;
            height: 20px;
            border-radius: 50%;
            background-color: #EDF0F1;
            padding: 0px !important;
            margin: 10px;
            svg{
                width: 10px;
                height: 10px;
                path , g > line{ 
                    fill: #9DB0C1;
                    stroke:#9DB0C1 ;
                }
            }
        }
    }
    .ant-space-item {
        padding-top: 0px !important;
    }

    .ant-select-selector {
        width: 150px !important;
    }

    .age-select-wrapper {
        display: flex;
        flex-wrap: wrap;
        gap: 10px;
        width: 100%;

        .ant-select {
            width: calc(50% - 5px);
        }
    }
}
