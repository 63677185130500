.ant-card.b2b-order-cards {

    .ant-card-body {
        padding: 24px 20px;
        @include d-flex;
        align-items: center;
        font-size: 12px;
        color: $global-color-2;
        line-height: 18px;
        letter-spacing: 0.12px;
    }
    &.total-suppliers {

        span {
            margin-left: 5px;
        }
        .svg-wrapper {
            width: 18px;
            height: 18px;
            background: #E4FAF5;
            display: flex;
            border-radius: 100%;
            justify-content: center;
            align-items: center;

            svg {
                width: 10px;
                height: 10px;
            }
        }
        .value {
            color: $global-color-12;
        }
    }

    &.supplier-revenue {
        .value {
            color: $global-color-12;
        }

        .item {
            margin-left: 5px;
        }

        .ant-divider.ant-divider-vertical {
            height: 30px;
            border-left: 1px solid $global-color-11;
        }
    }

    &.total-vendors {
        span {
            margin-left: 5px;
        }
        .svg-wrapper {
            width: 18px;
            height: 18px;
            background: rgba(#536DFE,0.15);
            display: flex;
            border-radius: 100%;
            justify-content: center;
            align-items: center;

            svg {
                width: 10px;
                height: 10px;
            }
        }
        .value {
            color: $global-color-7;
        }
    }

    &.vendor-sales {
        .cost-value {
            color: $global-color-13;
        }

        .profit-value {
            color: $global-color-12;
        }

        .item {
            margin-left: 5px;
        }

        .ant-divider.ant-divider-vertical {
            height: 30px;
            border-left: 1px solid $global-color-11;
        }
    }
}

.ant-card.order-cards {

    &.confirmed {
        .ant-card-extra {
            background: #E4FAF5;
        }
    }

    &.pending {
        .ant-card-extra {
            background: rgba( #FCB151,0.15);
        }
    }

    &.canceled {
        .ant-card-extra {
            background: rgba( #FC7886,0.15);
        }
    }

    &.customers {
        .ant-card-extra {
            background: rgba(#536DFE,0.15);
        }
    }

    .ant-card-extra {
        width: 32px;
        text-align: center;
        height: 32px;
        display: flex;
        border-radius: 50%;
        justify-content: center;
        align-items: center;
        margin-top: 16px;
    }

    .ant-card-body {
        @include d-flex;
        align-items: center;
        padding: 24px 20px;
        .ant-card-head_order_variation_timeline {
            font-size: 12px;
            margin-left: 15.7px;
            color: $global-color-2;
            line-height: 18px;
            letter-spacing: 0.12px; 
            font-family: 'Poppins-Regular';
        }
        .ant-card-head_order_variation {
            font-size: 12px;
            margin-left: 7.4px;
            font-family: 'Poppins-Medium';
            line-height: 18px;
            color: $global-color-12;
            letter-spacing: 0.12px; 
        }
        .ant-card-head_order_variation_decrease {
            font-size: 12px;
            margin-left: 7.4px;          
            font-family: 'Poppins-Medium';
            line-height: 18px;
            color: $global-color-13; 
            letter-spacing: 0.12px; 
        }
    }
}

.ant-card.order-cards, .ant-card.b2b-order-cards {
    box-shadow: 0px 15px 25px 0px rgba(0,0,0,0.08);
    border: none !important;
    height: 100%;

    .ant-card-head {
        padding:4px 20px 6.5px 21px;
        border-bottom: 1px dashed #E7EBEF;

        .ant-card-head-wrapper {
            align-items: flex-start;

            .ant-card-head-title {
                font-size: 12px;
                font-family: 'Poppins-Light';
                line-height: 18px;
                letter-spacing: 0.24px;
                > div {
                    font-size: 24px;
                    font-family: 'Poppins-Medium';
                    line-height: 35px;
                    letter-spacing: 0.48px; 
                }
            }
        }
    }
}
