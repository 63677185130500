@import './Filters/style';
.currently_text{
    font-size: 12px;
    font-family: "Poppins-Regular";
    color: #A6B0BB !important;
    line-height: 16px;
}
#accommodation-view-all{
    .filter-content{
        .add-button{
            span{
                font-size: 14px;
                font-family: "Poppins-Regular";
            }
        }
    }
    .advanced-filter-content{
        >div:first-child{
            svg{
                path{
                    fill: #BBC6D1 !important;
                }
            }
        }
    }
}

/////////////////RESPONSIVE

 @media (min-width:2560px){
#accommodation-view-all{
    >.ant-row{
        >.grid-col{
            flex: 0 0 16.66666667% !important;
            max-width: 16.66666667% !important;
            
        }
        >.list-col{
            .ant-card-meta-description{
                > div{
                    .ant-col:last-child{
                        >div{
                            
                            justify-content:flex-end;
                            gap: 50px !important;
                            >div{
                                &:last-child{
                                   margin-right: 30px;
                                }
                           
                            }
                        }
                    }
                }
            }
        }
                }

}
}
@media (max-width:1599px){
#accommodation-view-all{
    .accommodation-card-style{
        &.grid {
            >.ant-card-body{
                .ant-card-meta-detail{
                    >div{
                        &:last-child{
                            >div{
                                &:last-child{
                                    flex-direction: column;
                                    align-items: stretch;
                                    .view-grey-button{
                                        justify-content: center;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    .grid-col{
        flex: 0 0 33.33333333% !important;
        max-width: 33.33333333% !important;
    }
}
}
@media (max-width:1399px){
    #accommodation-view-all{
        >.ant-row{

>.list-col{
    .ant-card-meta-description{
        >div{
            >div{
                &:first-child{
                    flex: 0 0 58.33333333% !important;
                    max-width: 58.33333333% !important;
                }
                &:last-child{
                    flex: 0 0 41.66666667% !important;
                    max-width: 41.66666667% !important;
                }
            }
        }
    }
}
        }
        >form{
            >.filter-content{
                gap: 15px !important;
                >div{
                    &:last-child{
                        >div{
                            gap: 20px !important;
                        }
                    }
                }
            }
            >.advanced-filter-content{
              >.ant-col{
                flex: 0 0 50% !important;
                max-width: 50% !important;
              }  
            }
        }
 
    }
    }
    @media (max-width:1199px){
        #accommodation-view-all{
                .grid-col{
                    flex: 0 0 50% !important;
                    max-width: 50% !important;
                }
            >form{
                >.filter-content{
               >div{
                &:last-child{
                    >div{
                        flex-wrap: wrap;
                        justify-content: space-between;
                    }
                }
               }
                }

            }
     
        }
        }


        @media (max-width:991px){
            #accommodation-view-all{
                padding: 20px 0;

                >.ant-row{
                    padding-left: 15px;
                    padding-right: 15px;
                    >.list-col{
                        .ant-card-meta{
                            flex-direction: column;
                            >div{
                                &:first-child{
                                    height: 200px;
                                    width: 100%;
                                }
                                &:last-child{
>div{
>div{
    >div{
        &:first-child{
            flex: 0 0 100% !important;
            max-width: 100% !important;
            padding: 26px 32px 31px 32px;
            // >.ant-space{
            //     flex-direction: column;
            // }
        }
        &:last-child{
            flex: 0 0 100% !important;
            max-width: 100% !important;
            padding: 0 32px 31px 32px;
            >div{
                justify-content: space-between;
                >div{
                    &:first-child{
                        display: none;
                    }
                    &:nth-child(2){
                    }
                    &:last-child{

                    }
                }
            }
        }
    }
}
}
                                }
                            }
                        }
                    }
                            }
                >form{
                    padding-left: 15px;
                    padding-right: 15px;
                    >.filter-content{
                        >div{
                            &:last-child{
                                >div{
                                    gap: 30px !important;
                                }
                            }
                        }
                    }
                }

         
            }
        }



        @media (max-width:767px){
            #accommodation-view-all{
                .grid-col{
                    flex: 0 0 100% !important;
                    max-width: 100% !important;
                    .ant-card-meta-description{
                        >div{
                            &:last-child{
                                flex-direction: row !important;
                            }
                        }
                    }
                }
            }  
        }

        @media (max-width:575px){
            #accommodation-view-all{
                >form{
                    >.filter-content{
                        >div{
                            &:last-child{
                                >div{
                                    justify-content: flex-start !important;
                                    .search{
                                        width: 100%;
                                    }
                                }
                            }
                        }
                      
                    }
                    >.advanced-filter-content{
                        >.ant-col{
                          flex: 0 0 100% !important;
                          max-width: 100% !important;
                          .ant-select{
                            margin-bottom: 30px;
                          }
                        }  
                      }
                }
         
            }
        }



        @media (max-width:399px){
            #accommodation-view-all{
               >.ant-row{
                >.grid-col{
                    .ant-card-meta-description{
                        >div{
                            &:first-child{
                                text-align: center;
                            }
                            &:last-child{
                                flex-direction: column !important;
                            }
                        }
                    }
                }
                >.list-col{
                    .ant-card-meta{
                        >div{
                      
                            &:last-child{
>div{
>div{
>div{
    &:first-child{
  
        >.ant-space{
            flex-direction: column;
        }
    }

}
}
}
                            }
                        }
                    }
                }
               }
         
            }
        }

        
    