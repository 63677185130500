.my-partner-filters {
    .base-filter {
        height: 50px;
        .switch-partner {
            .ant-typography {
                color: $global-color-4;

                &.color-blue {
                    color: $global-color-7;
                }
            }

            .ant-switch-handle {
                width: 25px;
                height: 25px;
                top: 0px;
                &::before {
                    background-color: #536DFE;
                    border-radius: 50%;
                    box-shadow: 0px 3px 6px 0px rgba(0,0,0,0.16);
                }
            }
            .ant-switch {
                background: $color-white;
                min-width: 75px;
                height: 25px;

                &:focus {
                    box-shadow: none;
                }
            }

            .ant-switch-checked:focus {
                box-shadow: none;
            }
        }

        .ant-btn.add-vendor-btn {
            background-color: #089ECB;
            color: $color-white;
            display: flex;
            align-items: center;

            &::after {
                content: none;
            }
            
            svg {
                width: 16px;
                height: 16px;
                margin-right: 10px;
            }
        }
    }

    .ant-divider.ant-divider-horizontal {
        border-top: 1px dashed $global-color-2;
    }

    .ant-input-affix-wrapper {
        border: none;
    }

    .ant-input-group-wrapper {
        box-shadow: 0px 2px 3px rgb(0 0 0 / 8%);
    }

    .total-partner {
        text-align: right;

        .ant-typography {
            color: $global-color-9;
            font-family: 'Poppins-Medium';
        }
    }

    .ant-select.sort-partner {
        box-shadow: none !important;

        .ant-select-selector {
            background-color: $global-color-10;

            .ant-select-selection-placeholder {
                color: $global-color-4 !important;
            }
        }

        .ant-select-arrow {
            svg {
                g {
                    path {
                        fill: $global-color-4;
                    }
                }
            }
        }
    }
    
}

@media (max-width: 425px) {
    .my-partner-filters {
        .ant-row {
            row-gap: 15px;
            justify-content: center;
        }

        .ant-divider-horizontal {
            margin: 60px 0px 20px 0px;
        }
    }
}
