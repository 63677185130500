.language-select-toggle {
    cursor: pointer;
    font-size: 12px;
    border: none;
    background-color: transparent;
    >img {
        width: 25px;
        height: 25px;
    }
}
