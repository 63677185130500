@import './FlightList/style';
@import './Loading/style';



.flights-viewall-m1 {

> .ant-col {

&:nth-child(1){
> .ant-form{
> .ant-row {
    flex-wrap: nowrap !important;
> .ant-col {

    &:nth-child(1){

        @include d-flex-i;
        flex-wrap: wrap !important;
        width: 100% !important;
        padding-right: 30px !important;
        margin-left: -15px !important;
        margin-right: -15px !important;
        margin-bottom: -15px !important;

        > div {
            padding-left: 15px !important;
            padding-right: 15px !important;
            margin-bottom: 15px !important;

            > .ant-input {
                width: 249px !important;
                box-shadow: 0px 2px 3px rgba($color: #000000, $alpha: 0.08) !important;
                padding: 0 15px !important; 
            }

            > .ant-picker.ant-picker-range {
                width: 322px !important; 
            }
            
            > .ant-input-group-wrapper.ant-input-search {
                width: 390px !important;
                background-color: #fff !important;
                box-shadow: 0px 2px 3px rgba(0 , 0 , 0 , 0.08) !important;
                border-radius: 5px !important;
                overflow: hidden !important;
              }

              > .ant-select {
                width: 249px !important;
            }
        }

    }
  
}    
} 
}
}
&:nth-child(2){

}

}

}




