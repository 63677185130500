@import './ImagesUpload/style';

.ship-item-container {

    .ant-radio-button-wrapper:first-child {
        margin-right: 10px;
    }

    .hidden-inputs {
        &.hidden {
            display: none;
        }
        &.show {
            display: block;
        }
    }

    //add new amenity content style
    .create-section {
        .input-section {
            display: none;

            &.open {
                display: block;
            }
        }
    }
}
