.season-card {
    .ant-avatar.ant-avatar-circle {
        width: 64px;
        height: 64px;
        background-color: $global-color-3;
    }
}
.season-card{
    margin: 5px 30px !important;
    .ant-card-body{
        padding: 15px 20px;
    }
    .ant-card-meta-avatar{
        padding-right: 22px;
    }
    .ant-card-meta{
        @include d-flex;
        align-items: center;
        .ant-card-meta-detail{
            width: 100%;
            .ant-card-meta-title{
                margin-bottom: 2px;
                span{
                    font-family: "Poppins-Medium";
                    color: #758CA1;
                    letter-spacing: 0.14px;
                    line-height: 21px;
                }
            }
            >.ant-card-meta-description{
                >div{
                    >div{
                        &:first-child{
                            .description{
                                span{
                                    font-size: 12px;
                                    line-height: 18px;
                                    letter-spacing: 0.12px;
                                }
                            }
                            >div{
                                &:first-child{
                                  margin-bottom: 2px;
                                }
                            }
                        }
                        &:last-child{
                            >div{
                                margin-top: -20px
                            }
                        }
                    }
                
                }
            }
     
        }
.season-avatar{
    @include d-flex;
    align-items: center;
    .ant-avatar-string{
        @include d-flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        .avatar-price{
           font-size: 14px;
        }
        > div{
            font-family:"Poppins-Medium";
            letter-spacing: 0.14px;
            font-size: 9px;
            line-height: 12px;
        }
    }
}
    
    
        
    
    }
}