.role-page {
    .menu-section {
        @import './GroupMenu/style';
        > div{
            box-shadow: 0px 2px 15px 0px rgba(0,0,0,0.04);
        }
        .ant-divider{
            margin-bottom: 0px;
            border-color: #536DFE !important;
        }
        .ant-menu-item{
            margin-top: 0px;
            height: 100%;
            padding: 30px 31px;
            @include d-flex;
            align-items: center;
            margin-bottom: 0;
            border-bottom: 1px solid #DDE4EB;
           &:last-child{
            border-radius: 0 0 5px 5px;
           }
           &:not(:last-child){
            margin-bottom: 0
           }
            svg{
                width: 10.74px;
                height: 14.32px;
                path{
                    fill:#758CA1;
                }
            }
            span{
               color: #758CA1; 
               letter-spacing: -0.14px;
               margin-left: 12.3px;
               line-height: 21px;
            }
        }
        .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected{
            background-color: #536DFE !important;
            svg{
                path{
                    fill:#FFFFFF;
                }
            }
            span{
                color: #FFFFFF
            }
        }
    
    }
    @import './AddNewRole/style';
}


////////////RESPONSIVE

@media (max-width:1599px){
.role-page{
    .permission-section{
        > div{
            &:first-child{
                flex-direction: column;
                padding-bottom: 30px;
                align-items: flex-end;
                > div{
                    width: 100%;
                    &:first-child{
                        flex: 0 0 100%;
                        max-width: 100%;
                    }
                    &:last-child{
                        flex: 0 0 50%;
                        max-width: 50%;
                    }
                }
            }
        }
    }
}
}

@media (max-width:991px){
.role-page{
    flex-direction: column;
    row-gap: 20px !important;
    padding: 20px;
    > div{
        flex: 0 0 100%;
        max-width: 100%;
    }
}
}

@media (max-width:575px){
    .role-page{
        .permission-section{
            > div{
                &:first-child{
                    > div{
                        &:last-child{
                            flex: 0 0 100%;
                            max-width: 100%;
                        }
                    }
                }
             
                &:last-child{
                    > div{
                        &:first-child{
                            button{
                                margin-right: 0;
                            }
                        }
                        &:last-child{
                            width: 100%;
                            button{
                                width: 100%;
                                padding: 15px 112px !important;
                            }
                        }
                    }
                }
            }
            >.access-text{
                align-items: flex-start;
            }
        }
    }
}

@media (max-width:399px){
    .role-page{
        .permission-section{
          
          > .ant-input-group-wrapper{
            button{
                padding: 15px 26px !important;
            }
          }
        }
    }
}
