// reservation list 
.ant-dropdown {
    background: $color-white;
    div {
        text-align: center;
    }
}
.edit-delete-dropdown-with-text {
    width: 200px;
}
.edit-delete-dropdown {
    border: 1px solid #F4F6F8;
    box-shadow: 0px 3px 10px 0px rgba(0,0,0,0.15);
    .ant-menu-root{
        >.ant-menu-item{
 
            &:first-child{
               padding-top:10px !important;
               padding-bottom: 9.5px !important;
            }
            &:not(:last-child){
                border-bottom: 1px solid #E7EBEF;
            }
        }
    }
    .ant-menu-vertical{
        border: none !important;
    }
    .ant-menu-item{
        @include d-flex;
        align-items: center;
        height: auto !important;
        padding: 8px 20px !important;
        line-height: 21px !important;
        margin: 0 !important;
        .ant-menu-item-icon{
            width: 41px;
            height: 41px;
            @include d-flex;
            align-items: center;
            justify-content: center;
            border-radius: 50%;
            @include transition(all 0.3s);
            &:hover{
                background-color: #F4F6F8;
                svg{
                    path{
                        fill: #758CA1;
                    }
                }
            }
        }
    }
    .ant-menu-title-content{
        span{
            color: #BBC6D1;
            line-height: 21px;
            letter-spacing: 0.14px;
        }
    }
}
#view-accommodation-page,#view-tour-trip-page,#view-tour-package-page,#view-tour-cruise-page,#view-tour-activity-page,.tour-type-list-card{
    .ant-dropdown-trigger:not(.guests_filters, .reservation-list_right-col .ant-dropdown-trigger, .search-form .ant-dropdown-trigger){
        width: 34px;
        height: 34px;
        @include d-flex;
        justify-content: center;
        padding: 0 !important;
        &:hover,&:active,&:focus{
            border-radius: 50%;
            svg>path{
             fill: #758CA1 !important;
            }
         }
    }
}
.tour-style-select{
    padding: 0 !important;
    border: 1px solid #E7EBEF;
    box-shadow: 0px 1px 20px 0px rgba(0,0,0,0.13);
    >div{
        >div{
            &:last-child{
             @include d-flex;
             justify-content: space-between;
             padding:0 24px 24px 24px;
             >div{
                    input{
                        background: #E7EBEF;
                        border: 1px solid transparent !important;
                        &:focus{
                            border: 1px solid #3B55EB !important;
                            background: #FFFFFF;
                        }
                    }
                
               &:last-child{
                button{
                    width: 88px;
                    span{
                        color: #FFFFFF;
                    }
                }
               }
            }
            }
         
        }
        } 
        .ant-select-item-empty{
            >div{
                margin: 0;
                padding: 24px 24px 0 24px;
                .ant-empty-description{
                    font-size: 12px;
                    line-height: 18px;
                    color: #BBC6D1;
                }
                .ant-empty-image{
                    height: 100%;
                    svg{
                        width: 85.6px;
                        height:85.47px;
                    }
                }
            }
        }
        &.s2{
            > div{
                >.ant-space{
                    flex-direction: column;
                    >div{
                        width: 100%;
                        button{
                            width: 100% !important;
                        }
                    }
                }
            }
        }
}

@media (max-width: 399px) {
.tour-style-select{
    >div{
        >.ant-space{
            flex-direction: column;
            >div{
                width: 100%;
                button{
                    width: 100% !important;
                }
            }
        }
    }
}
}



.extra-services-dropdown{

    >div{
        border: none !important;
        box-shadow: 0px 3px 6px 0px rgba(0,0,0,0.16);

        .ant-card-head{
            padding: 0;
            margin-left: 14px;
            margin-right: 14px;
            border-bottom: 1px solid #E7EBEF;
            .ant-card-head-title{
                padding: 12px 0 14.5px 0;
                >label{
                    padding-right: 159px;
                }
                .ant-checkbox + span{
                    padding-left: 10px;
                }
                .info-section{
                    .name{
                        line-height: 21px;
                        span{
                            color: #758CA1;
                            letter-spacing: 0.14px;
                        }
                    }
                    .content{
                        line-height: 18px;
                        span{
                            font-size: 12px;
                            letter-spacing: 0.14px;
                            color: #BBC6D1;
                        }
                    }
                }
            }
        }
        .ant-card-body{
            padding: 12.5px 14px 18px 14px;
            > div{

                &:first-child{
                    font-size: 12px;
                    line-height: 18px;
                    color: #BBC6D1;
                    letter-spacing: 0.14px;
                }

            }
        }
    }
}
.ant-picker-dropdown-range{
    .ant-picker-panel{
        >.ant-picker-date-panel{
            >div{
                &:first-child{
                    padding: 19px 33px 10px 33px !important;
                    height: auto;
                    border: none;
                }
                &:last-child{
                    table{
                        >thead{
                            border-bottom: 1px solid #BBC6D1;
                            >tr{
                                >th{
                                    font-size: 15px !important;
                                    font-family: "Poppins-Bold" !important;
                                    line-height: 21px ;
                                    padding-bottom: 9px;
                                }
                            }
                        }
                        >tbody{

                        }
                    }
                }
            }
        }
    }
}
.filter-calendar{
    .ant-picker-panel{
        >.ant-picker-date-panel{
            >div{
                &:first-child{
                    padding: 19px 33px 10px 33px !important;
                    height: auto;
                    border: none;
                }
                &:last-child{
                    table{
                        >thead{
                            border-bottom: none !important;
                            border-top: 1px solid #E7EBEF;
                            >tr{
                                >th{
                                    font-size: 14px !important;
                                    font-family: "Poppins-Regular" !important;
                                    line-height: 21px ;
                                    padding-top: 26px;
                                }
                            }
                        }
                        >tbody{

                        }
                    }
                }
            }
        }
    }
}
@media (max-width:767px){
.ant-picker-datetime-panel,.ant-picker-panels{
    flex-direction: column;
}
}
