.ant-card.promotion-card {
    border-color: #E7EBEF !important;
    .ant-card-meta{
        @include d-flex;
        align-items: center;
        margin: 0;
    }
    .ant-card-body{
        padding: 26px 20px 27px 29px;
    }
    .action-button {
        height: 10px;
        &:hover {
            svg{
                path{
                    fill: #bcc6d1;
                }
            }
            &::before {
                background-color: transparent;
            }
        }
    }
    .ant-avatar {
     width: 84px !important;
     height: 84px !important;
     @include d-flex;
     justify-content: center;
     align-items: center;
     background: rgba($global-color-17 , 0.20);
     margin-right: 15px;
     svg{
        width: 34px;
        height: 34px;
        path, circle{
            fill:$global-color-17;
        }
    }
        // &.ant-avatar-icon > .anticon {
        //     color: $global-color-17;
        // }
    }

    .title {
        font-size: 16px;
        color: $global-color-17;
        line-height: 16px;
        font-family: "Poppins-SemiBold";
        margin-bottom: 5px;
    }
    .description{
        margin-bottom: 4px;
        span{
            line-height: 16px;
        }
    }

    .large-title {
        font-size: 22px;
    }

    .ant-divider-vertical {
        height: 100%;
        border-width: 1px;
        border-color: #DDE4EB;
    }
    
    .custom-color {
        color: $global-color-4;
    }

    .text {
        font-size: 12px;
        color: $global-color-4;
    }
    .ant-card-meta-detail{
        overflow: visible;
        width: 100%;
    }
    .discount-block{
        padding-left: 23px;
        font-size: 12px;
        .discount-assign-title{
            color: $global-color-2;
        line-height: 18px;


        }
        .discount-assign-content{
                color: $global-color-4;
        line-height: 18px;

        }
    }
}
