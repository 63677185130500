.worldwide-supplier-page {
    @import'./Filters/style';

    .info-text {
        display: flex;
        justify-content: space-between;
        background: rgba($global-color-12, 0.1);
        border-radius: $border-radius-small;
        padding: 10px 5px 0px 5px;

        .ant-typography {
            font-size: 12px;
            color: $global-color-12;
        }

        .ant-btn.ant-btn-text {
            padding: 0px 10px;
            margin-top: -15px;

            &:hover {
               background-color: transparent;
            }

            svg {
                path {
                    fill: $global-color-12;
                }
            }
        }
    }

    .ant-divider.ant-divider-horizontal {
        margin: 0px;
    }
}
