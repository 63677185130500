#days-duration-form, #minutes-duration-form{
    .duration-content {
        text-align: center;
        margin-top: 31px;
        margin-bottom: 9px;
        @include d-flex;
        justify-content: center;
    
        .duration-start, .duration-end {
            background-color: #E7EBEF;
            padding: 5px 100px 5px 10px;
            color: $global-color-7;
            font-size: 14px;
            border-radius: 5px;
            letter-spacing: -0.14px;
            @include d-flex;
            align-items: center;
        }
        .seperator{
            margin: 15px 19.3px;
            &::after{
                content: "";
                display: block;
                width: 21px;
                height: 1px;
                background: #BBC6D1;
           
            }
        }
    }
    .ant-checkbox-inner{
        width: 22px !important;
        height: 22px !important;
        &::after{
            transform: translate(-50%, -50%) scale(0.8)!important;
        }
    }
    .ant-checkbox + span{
        padding-left: 10.8px;
        line-height: 21px;
        letter-spacing: 0.14px;
        color: #BBC6D1;
    }
    .ant-checkbox-wrapper:not(.ant-checkbox-wrapper-checked){
        .ant-checkbox-inner{
            background-color: #E7EBEF !important;
        }
    }
 
   
    .add-button-row{
        padding-right: 30px;
    }
    .border-text{
        font-family: "Poppins-Regular";
        color: #758CA1;
        line-height: 21px;
    }
    .timeslot-form{
        >div{
            margin-bottom: 15px;
        }
        .ant-form-item-control-input{
            border: 2px solid #DDE4EB;
            border-radius: 5px;
            svg{
                width: 20px;
                height: 20px;
                path{
                    fill:#DDE4EB;
                }
            }
        }
    }
    .default-price-check{
        .ant-checkbox-checked{
            .ant-checkbox-inner{
                background-color: #536DFE !important;
            }
        }
    }
    

}

.duration-section{
    padding-top: 7px;
    .ant-typography{
        color: #758CA1;
        letter-spacing: -0.14px;
        line-height: 21px;
    }
   
}
.skip-btn{
    margin-right: 30px;
    span{
        font-family: "Poppins-Regular";
        color: #536DFE;
        text-decoration: underline;
    }
    &:hover{
        background: none;
    }
}





#days-duration-form{
    >div:last-child{
margin-bottom: 0;

.ant-col-sm-24{
    >div{
        margin-bottom: 0
    }
    .ant-form-item-control-input-content{
        @include d-flex;
        justify-content: flex-end;
        margin-right: 30px;
    }
}
    }
    .circle-add-button{
        height: 40px;
        width: 40px;
        svg{
            width: 18px;
            height: 18px;
            line{
                stroke:#536DFE;
            }
        }
    }
    .circle-delete-button{
        height: 40px;
        width: 40px;
        background-color: #F4F6F8 !important;
        svg{
            width: 18px;
            path{
                fill: #BBC6D1;
            }
        }
        &:hover{
            svg{
                path{
                    fill: #FFFFFF;
                }
            }
        }
    }
    .time-input-fields{
        padding-left: 30px;
        padding-right: 30px;
        .ant-divider-dashed{
            margin: 30px 0;
            border-width: 1px 0 0;
        }
    }

    .default-price-check{
        .ant-form-item-control-input{
            min-height: 100%;
        }
        >div{
            margin-bottom: 0;
        }
    }
}
  
 
#minutes-duration-form{

    >div:last-child{
        margin-bottom: 0;
    
    }
    .input-group-container{
        padding: 37px 30px 30px 30px;
        .border-text{
            margin: -49px 0 0 -4px;
        }
        >div{
            >.ant-col{
                    &:last-child{
                >div{
                    margin-bottom: 0;
                }

            } 
            &:first-child{
                .ant-form-item-control-input{
                    min-height: 100%;
                }
            }
            &:nth-child(3){
                .default-price-check{
                    .ant-form-item-control-input{
                        min-height: 100%;
                    }
                }
            }
            }
          
        }
    }
    .add-button-row{
        .ant-form-item{
            margin-bottom: 0;
        }
  
    }
    >div{
        &:last-child{
            .ant-form-item-control-input-content{
                >div{
                    &:first-child{
                        margin-top: 0;
                    }
                }
            }
        }
    }
    }
    .time-duration{
        button{
            height: auto !important;
            padding: 0;
            margin-right: 33px;
        }
    }
    

    