#view-tour-cruise-page {
   @import './TourItenerary/style';
   @import './TourInfo/style';

   .search-form{
    .cabin-type_selection{
        .ant-select-selection-item-content{
            line-height: 21px;
            letter-spacing: 0.14px;
            color: #536DFE;
        }
    }
   }
   .reservation-list_right-col{
    >div:nth-child(2){
        .ant-select-selection-placeholder{
            color: #758CA1 !important;
        }
    }
    .ant-checkbox + span{
        padding-left: 14px;
    }
   }

   .map-col{
    .ant-card-body{
        >div{
            height: 793px;
            max-height: 793px;
        }
    }
   }

   .ant-divider {
        border-color: $global-color-11;
   }
   .incl-excl-row{
    >div{
        padding-left: 0 !important;
    }
   }
   .view-content {
       margin-top: 30px;
   }
   .cabin-type_selection, .cabin-date_selection {
      .search-block_title{
          font-size: 12px;
          color: #BBC6D1;
      }
      .ant-form-item{
          margin-bottom: 0px;
      }
   }
   .cabin-date_selection{
    width: 250px;
    .ant-select{
        height: 30px !important;
        box-shadow: none !important;
    }
   }
   .cabin-type_selection{
    width: 150px;
    .ant-select{
        height: auto !important;
    }
   }
}

////////////RESPONSIVE

@media (max-width:1599px){
    #view-tour-cruise-page{
        .tour-itenerary-list{
            .tour-itenerary-item{
                >div{
                    row-gap: 10px !important;
                }
                .tour-itenerary-content{
                    padding-left: 0;
                }
            }
        }
    }
}
@media (max-width:1199px){
    #view-tour-cruise-page{
        .tour-itenerary-list{
            .tour-itenerary-item{
               .tour-itenerary-content{
                >.description{
                    flex-flow: wrap;
                    >.content{
                        padding-left: 33px;
                    }
                }
               }
            }
        }
    }
}
@media (max-width:991px){
    #view-tour-cruise-page{
        .tour-itenerary-list{
            border-radius: 0 0 5px 5px;
            .tour-itenerary-item{
               .tour-itenerary-content{
                padding-left: 20px;
                >.description{
                    >.content{
                        padding-left: 0 !important;
                    }
                }
               }
            }
        }
        .map-col .left-col .ant-card-body > div > div{
            border-radius: 5px 5px 0 0;
        }
    }
}
@media (max-width:575px){
    #view-tour-cruise-page{
        .tour-itenerary-list{
            .tour-itenerary-item{
               .tour-itenerary-content{
                padding-left: 0 !important;
               }
            }
        }
    }
}
@media (max-width:399px){
    #view-tour-cruise-page{
        .tour-itenerary-list{
            .tour-itenerary-item{
               .tour-itenerary-content{
                >.description{
                    flex-flow: wrap;
                    >.content{
                        padding-left: 33px !important;
                    }
                }
               }
            }
        }
    }
}
