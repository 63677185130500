.edit-agency-details-form {
    .upload-img, .agency-details {
        background-color: $color-white;
        border-radius: 10px;
        padding: 30px;
    }

    .upload-img {
        .ant-space {
            display: flex;
            justify-content: center;
            align-items: center;
        }

        .avatar-upload {
            border: none;
            background-color: $global-color-8;
            width: 94px;
            height: 94px;

            .image {
                border-radius: 0%;
            }
        }

        .delete-img-icon {
            position: absolute;
            top: 100%;
        }

        .text-content {
            font-size: 12px;
            color: $global-color-2;

            .logo {
                color: $global-color-4;
            }

            div:nth-child(2) {
                display: flex;
                justify-content: center;
                align-items: center;
                &::before, &::after {
                    content: "";
                    display: block;
                    width: 15.83px;
                    height: 1px;
                    background: #BBC6D1;
                    margin-top: 3px;
                }
                &::before {
                    margin-right: 10px;
                }
                &::after {
                    margin-left: 10px;
                }
            }

            .ant-btn {
                padding: 0px;
                span {
                    font-size: 14px;
                    font-family: 'Poppins-Medium';
                    text-decoration: underline;
                }
            }
        }
    }

    .agency-details {
        .save-btn {
            background-color: $global-color-7;
            color: $color-white;
            font-size: 12px;
            font-family: 'Poppins-Medium';

            &::after {
                content: none;
            }
        }
    }

    .ant-select {
        box-shadow: none !important;
    }
}
