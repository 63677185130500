.add-employee {
    .step-display {
        margin-bottom: 38px;
        .ant-steps-item-container{
            .ant-steps-item-content{
                width: auto;
            }
        }
    }
    .form {
        background-color: $color-white;
        max-width: 800px;
        margin: 0 auto;
        border-radius: 10px;
        border: 1px solid #DDE4EB;
        >.ant-row{
            >.ant-col{
                width: 100%
            }
        }
        .form-step {
            @import './Details/style';
            @import './JobPosition/style';
            // padding: 52px 40px 36.6px 40px;
            >.personal-details{
                >div{
                    &:first-child{
                        padding: 52px 40px 0 40px;
                    }
                    &:nth-child(2), &:nth-child(3){
                        padding: 0 40px;
                    }
                    &:last-child{
                        padding: 0 40px 36.6px 40px;
                    }
                }
            }
            .mui-form-item{
                .ant-form-item-label{
                    label{
                        height: unset;
                        color: #BBC6D1;
                    }
                }
                .ant-picker-suffix{
                    svg{
                        path , circle{
                            fill: #DDE4EB;
                        }
                    }
                }
            }
            .details-title{
                margin-bottom: 23px;
                .ant-space-item{
                    @include d-flex;
                    align-items: center;
                    justify-content: center;
                    span{
                        font-size: 16px;
                        color: #758CA1;
                        letter-spacing: 0.16px;
                        line-height: 25px;
                    }
                }
            }
            .ant-divider-with-text-center{
                &::before, &::after{
                    border-width: 1px;
                }
                .ant-divider-inner-text{
                    font-size: 12px;
                    color: #758CA1;
                    line-height: 18px;
                    padding: 0 13.5px;
                }
                margin-top: 30px;
                margin-bottom: 26px;
            }
        }
    }

 

}

////////////RESPONSIVE

@media (max-width:991px){
    .add-employee{
        padding: 20px;
        #job-position{
            .checkbox-multi-select{
                >.ant-row{
                    >.ant-col{
                        flex: 0 0 33.33333333% !important;
    max-width: 33.33333333% !important;

                    }
                }
            }
        }
    }

}




@media (max-width:767px){
    .add-employee{
        #job-position{
            .checkbox-multi-select{
                >.ant-row{
                    >.ant-col{
                        flex: 0 0 50% !important;
    max-width: 50% !important;

                    }
                }
            }
        }
    }
}

@media (max-width:575px){
    .add-employee{
        .ant-steps-item-tail{
            &::after{
                display: none;
            }
        }
        #job-position{
            >div:nth-child(2){
                >.ant-space{
                    align-items: flex-start;
                }
            }
            .checkbox-multi-select{
                >.ant-row{
                    >.ant-col{
                        flex: 0 0 100% !important;
    max-width: 100% !important;

                    }
                }
            }
        }
        .personal-details > div:last-child .mui-form-item{
            margin-bottom: 30px !important;
        }
        >.step-display{
            margin-bottom: 0;
            padding-top: 30px;
            >div{
                justify-content: flex-start;
            }
            .ant-steps-item-container{
                flex-direction: row;
                justify-content: flex-start;
                margin-bottom: 30px;
                .ant-steps-item-content{
                    min-height: unset !important;
                }
            }
        }
        >.form-button{
            >div>div{
                width: 100%;
                button{
                    width: 100%;
                }
            }
        }
  
    }
  

}


@media (max-width:399px){
    .add-employee{
        padding: 20px 0;
        .email-description{
            >div{
                align-items: flex-start;
            }
        }
    }
}