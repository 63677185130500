.add-flight {
    .go-back {
      border-radius: 50%;
        width: 33px;
        height: 33px;
        padding: 0;
        @include d-flex;
        align-items: center;
        justify-content: center;
    }


    >.form {
        margin-top: 30px;
        >div{
            &:first-child{
padding-right: 0 !important;
            }
            &:nth-child(2){
padding-left: 0 !important;
            }
            &:last-child{
                .ant-btn-primary{
                    margin-top: 0;
                }
            }
        }
        .form-step, .form-preview {
            padding: 30px;
            background-color: $color-white;
        }

        .form-step {
            @import './FlightDetails/style';
            border-radius: 10px 0px 0px 10px;
            height: 100%;
            border: 1px solid #DDE4EB;
            >.extras{
                padding: 10px 10px 10px 12.5px;
                >.ant-form-item{
                    margin-top: 30px;
                    .ant-checkbox-group{
                        width: 100%;
                        .ant-checkbox-wrapper:not(.ant-checkbox-wrapper-checked){
                            .ant-checkbox-inner{
                                background-color: #E7EBEF !important;
                            }
                        }
                        .ant-checkbox + span{
                            padding-left: 10px;
                        }
                        .info-section{
                            .name{
                                span{
                                    line-height: 21px;
                                    color: #758CA1;
                                    letter-spacing: 0.14px;
                                }
                            }
                            .content{
                                span{
                                    font-size: 12px;
                                    line-height: 18px;
                                    letter-spacing: 0.12px;
                                    color: #BBC6D1;
                                }
                            }
                        }
                    }
                }
                >span{
                    line-height: 21px;
                    color: #4D677E;
                }
            }

            .switch{
                .ant-switch-handle{
                    width: 28px;
                    height: 28px;
                    top: 0px;
                    &::before{
                        background-color: #536DFE;
                        border-radius: 50%;
                        box-shadow: 0px 3px 6px 0px rgba(0,0,0,0.16);
                    }
                }
                .ant-switch{
                    background: #E7EBEF;
                    min-width: 75px;
                    height: 28px;
                    margin-left: 12px;
                    margin-right: 12px;
                }
                .ant-space-item{
                    &:first-child{
                        span{
                         color: #758CA1;
                         letter-spacing: -0.14px;
                        }
                    }
                    &:last-child{
                        span{
                            color: #536DFE;
                            letter-spacing: -0.14px;
                        }
                    }
                }
            }
      
            .direction, .return{
                .ant-timeline{
                    .ant-timeline-item-content{
                        > div{
                            margin-top: 15px;
                        }
                    }
                    .mui-form-item{
                        .ant-form-item-label{
                            label{
                                height: unset;
                                color: #BBC6D1;
                            }
                        }
                        .ant-picker-suffix{
                            svg{
                                path , circle{
                                    fill: #DDE4EB;
                                }
                            }
                        }
                    }
                }
                .ant-card-head-title{
                    font-family: "Poppins-Medium";
                    line-height: 21px;
                    letter-spacing: 0.14px;
                }
                .ant-timeline-item-tail{
                    border-left: 1px dashed #BBC6D1;
                }
                .departure{
                    .ant-timeline-item-tail{
                        top: 17px;
                        height: calc(100% - 30px );
                    }
                    .ant-timeline-item-content > div{
                        > div:nth-last-child(-n+2){
                        .ant-row{
                            margin-bottom: 0;
                        }
                        }
                    }
                }
                .transit ,.transit-2{
                    .ant-timeline-item-tail{
                        top: 22px;
                        height: calc(100% - 33px );
                    }
                    .ant-checkbox{
                        margin-right: 5px;
                        .ant-checkbox-inner{
                            width: 20px !important;
                            height: 20px !important;
                        }
                    }
                    .ant-checkbox-checked{
                        .ant-checkbox-inner{
                            background-color: #FCB151 !important;
                        }
                    }
                }
            }
        }

        .form-preview {
            border-radius: 0px 10px  10px 0px;
            height: 100%;
            border: 1px solid #DDE4EB;

            .preview{
                .ant-timeline-item:not(.ant-timeline-item-last){
                    padding-bottom: 38px;
                }
                .ant-timeline-item-last{
                    padding-bottom: 0 !important;
                }
              
                >  .ticket-details{
                    align-items: flex-start;
                    >div{
                        &:first-child{
                            @include d-flex;
                            justify-content: center;
                            >div{
                                >span{
                                    width: 61px;
                                    height: 61px;
                                    border-radius: 50%;
                                    background: rgba(#536DFE, 0.09);
                                    @include d-flex;
                                    align-items: center;
                                    justify-content: center;
                                    svg{
                                        width: 18px;
                                        height: 24px;
                                        path{
                                            fill: #536DFE;
                                        }
                                    }
                                }
                              
                            }
                        }
                        &:last-child{
                            padding-left: 15px;
                            >h5{
                                font-family: "Poppins-Medium";
                                line-height: 25px;
                                letter-spacing: -0.16px;
                                color: #536DFE;
                                margin-bottom: 10px;
                                font-weight: normal;
                            }
                            >.ant-space{
                                .info-section{
                                    .name{
                                        span{
                                            font-size: 12px;
                                            letter-spacing: -0.12px;
                                            color: #BBC6D1;
                                            line-height: 18px;
                                        }
                                    }
                                    .content{
                                        span{
                                            letter-spacing: -0.14px;
                                            color: #758CA1;
                                            line-height: 21px;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }

            }
          
        }

        .form-button {
            &.change-color {
                background-color: $global-color-12;
            }
        }
    }
    .increment-number-input{
        padding: 7px 16px;
        span{
            color: #758CA1;
            letter-spacing: 0.14px;
            line-height: 21px;
        }
        button{
            @include d-flex;
            align-items: center;
            justify-content: center;
            width: 35px;
            height: 35px;
            padding: 0;
      
            svg{
                line{
                    stroke: #BBC6D1;
                }
            }
        }
    }
    .person-card{
        .ant-form-item-label > label {
            height: auto;
            color: #BBC6D1;
        }
    }
    .form-step{
        .participants{
            >div:nth-child(2){
                > .ant-space{
                    margin-top: 16px;
                 span{
                    font-size: 12px;
                    color: #BBC6D1;
                    line-height: 18px;
                    letter-spacing: 0.12px;
                 }
                 svg{
                    width: 18.16px;
                    height: 18.16px;
                 }
                }
            }
        
        }
    }
    .form-button{
        padding: 18px 49px;
        .ant-space-item{
            font-family: "Montserrat-Medium";
            font-size: 12px;
            line-height: 15px;
            letter-spacing: -0.12px;
        }
        svg{
            width: 14px;
            height: 9px;
        }
    }


}


////////////RESPONSIVE

@media (max-width:1599px){
    .add-flight{
        .form-step{
            .flight-details-form{
                .flight-card{
                    .ant-timeline{
                        >.departure{
                            .ant-timeline-item-content{
                                >.ant-row{
                                    >div{
                                        &:nth-child(3){
                                            >div{
                                                margin-bottom: 30px;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
        .form-preview{
            .preview{
                >.flight-itenerary:not(.return){
                    >div{
                        &:last-child{
                            flex: 0 0 100%;
                            max-width: 100%;
                            width: 100%;
                            margin-top: 15px;
                        }
                        &:nth-child(2){
                            flex: 0 0 100%;
                            max-width: 100%;
                            width: 100%; 
                        }
                    }
                }
                >.flight-itenerary.return{
                    >div{
                        &:last-child{
                            flex: 0 0 100%;
                            max-width: 100%;
                            width: 100%;
                            padding-top: 15px;
                        }
                        &:nth-child(3){
                            flex: 0 0 100%;
                            max-width: 100%;
                            width: 100%; 
                        }
                    }
                }
            }
        }
    }
}
@media (max-width:1399px){
.add-flight{
    .form-preview{
        >.preview{
            >.general-details,>.ticket-details{
                flex-direction: column;
                row-gap: 15px !important;
                >div{
                    flex: 0 0 100%;
                    max-width: 100%;
                    width: 100%;
                
                }
            }
        
          
        }
    }
    .form-step{
        .flight-direction{
            row-gap: 15px !important;
            flex-direction: column;
            >div{
                &:first-child,&:last-child{
                    width: 100%;
                }
            }
        }
        .person-card{
            .ant-card-body{
                >div{
                    >div{
                        &:nth-child(6),&:nth-child(7){
                            flex: 0 0 50%;
                            max-width: 50%;
                        }
                        // &:nth-child(7){
                        //     margin-bottom: 30px;
                        // }
                    }
                }
            }
        }
    }
}
}
@media (max-width:1199px){
.add-flight{
    .form-step{
        .prices{
            .free-carry-on-bag, .extra-baggage{
                svg{
                    right: -12px !important; 
                }
            }



            }
            .person-card{
                .ant-card-body{
                    >div{
                        >div{
                            &:nth-last-child(-n+3){
                                flex: 0 0 100%;
                                max-width: 100%;
                            }
                        }
                    }
                }
            }
        }
    }
}
@media (max-width:991px){
.add-flight{
    padding:20px;
>.form{
    >div{
        &:first-child{
            padding-left: 0 !important;
            width: 100%;
        }
        &:nth-child(2){
            width: 100%;
            padding-right: 0 !important;
        }
    }
}

    .form-step{
        border-radius: 10px !important;
        .flight-direction{
            flex-direction: row;
            >div{
                width: auto !important;
            }
        }
        .person-card{
            .ant-card-body{
                > div > div{
                    flex: 0 0 100% !important;
                    max-width: 100% !important;
                }
            }
        }
        .participants{
            >div >div{
                flex: 0 0 100% !important;
                max-width: 100% !important; 
            }
        }

    }
    .form-preview{
        border-radius: 10px !important;
        .preview{

        >.flight-itenerary:not(.return){
            >div{
                &:nth-child(2){
                    max-width: 54.16666667%;
                    flex: 0 0 54.16666667%;
                }
                &:last-child{
                    flex: 0 0 45.83333333%;
                    max-width: 45.83333333%;
                }
            }
        }
        >.flight-itenerary.return{
            >div{
                &:nth-child(3){
                    max-width: 54.16666667%;
                    flex: 0 0 54.16666667%;
                }
                &:last-child{
                    flex: 0 0 45.83333333%;
                    max-width: 45.83333333%;
                }
            }
        }
        }
    }
}
}
@media (max-width:767px){
.add-flight{
    .step-display{
        >div{
            width: 100%;
        }
        .ant-steps-item{
            max-width: 33%;
            width: 33%;
            .ant-steps-item-container{
                width: auto;
            }
        }
    }
    .form-step{
        .flight-direction{
            flex-direction: column;
            >div{
                &:first-child,&:last-child{
                    width: 100% !important;
                }
            }
        }
    }
}
}
@media (max-width:575px){
.add-flight{
    .step-display{
        margin-top: 30px;
        .ant-steps{
            row-gap: 10px !important;
            .ant-steps-item{
                max-width: 100%;
                width: 100%;
                >div{
                    flex-direction: row;
                    justify-content: flex-start;
                    .ant-steps-item-content{
                        min-height: unset;
                    }
                }
            }
        }
    }
    .form-step{
        .prices{
            .free-carry-on-bag,.extra-baggage{
                svg{
                    right: -45px !important;
                }
            }
        }
        .flight-card{
            label{
                font-size: 12px !important;
            }
        }
        .discount-card{
            .ant-radio-button-wrapper{
                width: 38px !important;
            }
        }
      
    }
    .form-preview{
        .preview{
            >.flight-itenerary:not(.return){
                >div{
                    &:last-child{
                        flex: 0 0 100%;
                        max-width: 100%;
                        width: 100%;
                        margin-top: 15px;
                    }
                    &:nth-child(2){
                        flex: 0 0 100%;
                        max-width: 100%;
                        width: 100%; 
                    }
                }
            }
            >.flight-itenerary.return{
                >div{
                    &:last-child{
                        flex: 0 0 100% ;
                        max-width: 100% ;
                        width: 100%;
                        margin-top: 15px;
                    }
                    &:nth-child(3){
                        flex: 0 0 100%;
                        max-width: 100%;
                        width: 100%; 
                    }
                }
              
            }
        }
   
    }
}
}
@media (max-width:399px){
.add-flight{
    padding: 20px 0;
    >.form{
        .form-step{
            padding: 20px;
            .flight-direction{
                .ant-select{
                    width: 179px !important;
                }
            }
            .flight-details-form{
                .direction{
                margin-bottom: 20px;
                }
                .flight-card{
                    .ant-card-body{
                        padding: 20px;
                    }
                }
            }
        }
        .form-preview{
            padding: 20px;
            .preview{
.general-details{
    >div:last-child{
        >div{
            flex-direction: column;
            >div{
                flex: 0 0 100%;
    max-width: 100%;
            }
        }
    }
}
            }
        }
        >div:last-child{
            >div{
                row-gap: 20px !important;
                >div{
                    width: 100%;
                    button{
                        width: 100%;
                    }
                }
            }
        }
    }
 
}
}