.details {
    .ant-divider-horizontal {
        margin: 0px;
    }
}

.img-wrapper {
    width: 50px;
    height: 50px;
    @include d-flex;
    justify-content: center;
    align-items: center;
        img {
            object-fit: cover;
            transform: scale(1.5);
            border-radius: 50%;
        }
    
}
