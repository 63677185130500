#view-tour-package-page {
    @import './TourInfo/style';

    .reservation-list{
        .room{
           .reservation-list_right-col{
            >div:nth-child(2){
                .ant-select-selection-placeholder{
                    color: #758CA1 !important;
                }
            }
           }
        }
    }
    .ant-divider {
        border-color: $global-color-11;
    }

    .view-content {
        margin-top: 30px;
    }
  
        .accomodation-block-details > div{
            margin-bottom: 20px;
        }
        .general-data{
            >div{
                &:first-child{
                    >div{
                        &:last-child{
                            >div{
                                @include d-flex;
                                justify-content: space-between;
                                align-items: flex-start;
                            }
                        }
                    }
                }
            }
        }
        .incl-excl-row{
            >div{
                padding-left: 0 !important;
            }
        }
     
}

#view-tour-package-page,#view-tour-trip-page,#view-tour-cruise-page, #view-accommodation-page{
    .right-col{
        background: transparent;
        max-height: 657px;
        .ant-card-body{
            max-height: 657px;
            @include d-flex;
            >.ant-image{
                height: auto !important;
                width: 80% !important;
                max-height: 100%;
                border: none;
                padding-right: 13px;
                .ant-image-mask{
                    margin-right: 13px;
                }
            }
            >.image-collection{
                max-height: 657px;
                width: 20%;
                @include d-flex;
                flex-direction: column;
                gap: 10px;
                >.ant-image{
                    height: 157px !important;
                    max-height: 157px;
                    border: none;
                    border-radius: 5px;
                    img{
                        height: 100%;
                        object-fit: cover;
                        object-position: center;
                    border-radius: 5px;

                    }
                }
            }
        }
      
    }
}
