.ant-modal.terms-and-conditions {
    .ant-modal-body {
        .ant-typography {
            font-size: 18px;
            color: $global-color-9;
        }

        .info {
            font-size: 12px;
            color: $global-color-2;

            @include scrollbar($_thumb-color);
            height: 165px;
            overflow-x: hidden;
            margin-right: 0px;
        }

        .ant-divider.ant-divider-horizontal {
            border-top: 1px solid $global-color-11;
        }

        .ant-checkbox + span {
            font-size: 12px;
            color: $global-color-4;
            font-family: 'Poppins-Light';
        }

        .ant-btn {
            background-color: $global-color-3;
            color: $color-white;
            font-family: 'Poppins-Medium';

            &::after {
                content: none;
            }

            svg {
                margin-left: 15px;
            }
        }
    }
}
