.view-one-header {
    .go-back {
       height: 40px;
       line-height: 10px;
       border: none;
    }

    .header-title {
       margin-left: 21px;
       font-size: 20px;
       color: $global-color-1;
       line-height: 20px;
    }
}
