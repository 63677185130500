.employee-list {
    background: $color-white;
    border-radius: $border-radius-small;
    // padding: 38px;
    .ant-col:last-child {
        .ant-divider {
            display: none;
        }
    }
    >.ant-col{
        >.employee-divider{
            padding: 0 32px;
            >.ant-divider{
                margin: 0px;
                border-color: #D8DDE1 !important;
            }
        }
        >.ant-row{
            padding: 33px 32px 21px 32px;
            margin-left: 0 !important;
            margin-right: 0 !important;
            @include transition(all 0.3s);
            >.ant-col{
                &:first-child{
                    >.ant-row{
                        >.ant-col{
                            &:first-child{
                                padding-left: 0 !important;
                            }
                        }
                    }
                }
            }
            >.employee-list_action_icon{
                padding-right: 0 !important;
        text-align: end;

            }
            &:hover{
background-color: #F4F6F8;
            }
        }
    }
    .ant-avatar-circle{
        width: 45px;
        height: 45px;
    }
    .diff-color_author {
        color: $global-color-18;
        font-size: 14px;
        line-height: 24px;
        font-family: 'Poppins-SemiBold';
    }
    .diff-color{
        color: $global-color-18; 
        font-family: 'Poppins-Regular';
        font-size: 12px;
        line-height: 24px;
    }

    .ant-progress {
        display: flex;
        flex-direction: column-reverse;
    }
 
    .ant-divider{
        border-width: 1px;
    }
    > div{
        > div {
            &:first-child{
                > div{
                    &:first-child{
                        > .ant-row{
                            > .ant-col{
                                &:nth-child(4){
                                    @include d-flex;
                                    align-items: flex-end;
                                }
                          
                                  
                                                .description{
                                                    > .label{
                                                        color: #A4AAB7;
                                                        font-size: 12px;
                                                        line-height: 24px;
                                                    }
                                                    > .diff-color{
                                                        color: #646D82;
                                                        font-size: 12px;
                                                        line-height: 24px;
                                                    }
                                                }
                                 
                            }
                        }
                    }
                }
            }
        }
    }
}


.employee-tooltip{
    .ant-tooltip-inner{
        background-color: #4D677E !important;
        font-family: "Poppins-Regular";
        line-height: 17px;
        font-size: 10px;
        border-radius: 4px;
        padding: 9px 18px 9px 15px;
    }
    .ant-tooltip-arrow-content{
        --antd-arrow-background-color: #4D677E !important;
    }
}
