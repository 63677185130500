.graph-wrapper {
    .ant-card-bordered {
        .ant-card-head {
            border-bottom: none;
        }
    }

    .revenue-card {
        .ant-card-head-title {
            color: $global-color-17;
        }
        .ant-card-extra {
            span {
                color: $color-white;
                background: $global-color-17;
                border-radius: $border-radius-small;
                padding: 5px 17px 5px 17px;
            }
        }
    }

    .paid-card {
        .ant-card-head-title {
            color: $global-color-12;
        }
        .ant-card-extra {
            span {
                color: $color-white;
                background: $global-color-12;
                border-radius: $border-radius-small;
                padding: 5px 17px 5px 17px;
            }
        }
    }

    .overdue-card {
        .ant-card-head-title {
            color: $global-color-13;
        }
        .ant-card-extra {
            span {
                color: $color-white;
                background: $global-color-13;
                border-radius: $border-radius-small;
                padding: 5px 17px 5px 17px;
            }
        }
    }

    .total-amount-value {
        font-size: 24px;
        color: $global-color-4;
    }

    .total-amount-text {
        font-size: 12px;
    }
}
