.cover-upload-wrapper {
    .ant-upload-picture-card-wrapper.cover-upload {
        .ant-upload.ant-upload-select-picture-card {
            width: 100%;
            height: 252px;
            border: none;
        }
    }

    .delete-img-icon {
        position: absolute;
        right: 0;
        bottom: 0;
        margin-right: -10px;
        background: #F4F6F8;
        border-radius: 50%;
    }
}

