.general-data {
    .price {
        font-size: 16px;
        color: $global-color-3;
        font-family: "Poppins-SemiBold";
        line-height: 25px;
        letter-spacing: 0.16px;
    }

    .ant-divider-vertical.ant-divider-dashed {
        height: 100%;
        border-color: #DDE4EB;
        border-width: 0px 0 0 1px;
    }
    
    >div{
        &:last-child{
            svg{
                width: 24px;
                height: 24px;
                path{
                    fill:#536DFE ;
                }
            }
        }
    }
}
