.ant-card.ant-card-bordered.group-cards {
    border: 1px solid #E7EBEF !important;
    margin-top: 20px;
    .ant-card-body{
        padding: 30px !important;
        .ant-card-meta-detail {
            overflow: visible;
        }
        .ant-card-meta-description{
            >div{
                >div:last-child{
                   @include d-flex;
                   align-items: center;
                }
            }
        }
    }
    .name{
        span{
            color: #BBC6D1;
            font-size: 12px;
            line-height: 20px;
        }
      
    }
    .content{
        line-height: 20px;
        color: #758CA1;
        span{
         color: #3B55EB;
         text-decoration: underline;
        }
        cursor: pointer;
    }
    .reservation-button{
        margin-right: 10px;
        span{
            font-size: 12px;
            font-family: "Poppins-Medium";
            letter-spacing: -0.12px;
            line-height: 18px;
        }
    }

    .usergroup-add-icon{
    margin: 0px !important;
    }

    .ant-divider.ant-divider-vertical {
        height: 50px;
        border-color: #DDE4EB;
        border-width: 1px;
    }
    
}

