.upload-container {
    .ant-upload-picture-card-wrapper {
        .ant-upload-list {
            display: flex;
            flex-wrap: wrap;

            .ant-upload-list-picture-card-container {
                flex: 0 0 20%;
                order: 2;
            }

            .ant-upload.ant-upload-select-picture-card {
                order: 1;
                flex: 0 0 100%;
            }
        }
    }

    .ant-upload.ant-upload-select-picture-card {
        width: 100%;
        height: 252px;
        border: none;
    }

    .info-text {
        font-size: 13px;
        color: $global-color-5;
    }
}
