.general-data {
    >div:first-child{
        >div:first-child{
            >span{
                font-family: "Poppins-Light";
                line-height: 22px;
                letter-spacing: 0.14px;
            }
        }
       >div:last-child{
        >div{
            display: flex !important;
            justify-content: space-between;
            align-items: flex-start;
        }
       }
    }
    .ant-typography {
        color: $global-color-9;
    }

    .price {
        font-size: 16px;
        color: $global-color-3;
    }

    .ant-divider-vertical.ant-divider-dashed {
        border-color: #DDE4EB;
    }
    .accomodation-block-details{
            svg{
                width: 24px;
                height: 24px;
                path:not(#Shape,#Shape-2){
                    fill: #536DFE;
                }
            }
        
        
    }

}
