.participants{
    >div{
        >div{
            padding-left: 0 !important;
            padding-right: 0 !important;
        }
    }
    .increment-number-input{
        padding: 7px 16px;
        width: 100%;
        span{
            color: #758CA1;
            letter-spacing: 0.14px;
            line-height: 21px;
        }
        button{
            @include d-flex;
            align-items: center;
            justify-content: center;
            width: 35px;
            height: 35px;
            padding: 0;
      
            svg{
                line{
                    stroke: #BBC6D1;
                }
            }
        }
    }
}