@import'./WorldwideSupplier/style';
@import 'MyPartners/style';
@import'./Requests/style';
@import './AgencyProfile/style';

.worldwide-supplier-page, .agency-profile-page {
    .tour-list {
        .tour-item {
            .tour-details {
                padding: 10px 20px 20px 20px;
                background-color: $color-white;
                border-radius: 5px 5px 0px 0px;
                min-height: 150px;

                .ant-avatar {
                    border-radius: 15px;
                }

                .remove-icon-wrapper {
                    display: grid;
                    justify-content: end;

                    button {
                        width: 15px;
                        svg {
                            width: 15px;
                            height: 15px;
                        }
                    }
                }

                .ant-btn.view-tour {
                    background-color: #E7F6FC;
                    border-radius: 50px;
                    color: #089ECB;
                    font-size: 12px;
                    text-decoration: none;
                    margin: 5px 0px 10px 0px;
                    height: 25px;
                    display: flex;
                    justify-content: center;
                    align-items: center;

                    svg {
                        margin-right: 5px;
                        width: 12px;
                        height: 12px;
    
                        #fi-rr-ship, #fi-rr-backpack, #Path_4247, #Path_4248 {
                            fill: #089ECB;
                        }

                        #Out_line {
                            width: 18px;
                            height: 18px;
                        }
                    }
                }

                .tour-name {
                    .ant-typography {
                        color: $global-color-9;
                        font-family: 'Poppins-Medium';
                    }
                }

                .action-button {
                    height: 20px;
                    &:hover {
                        &::before {
                            background-color: transparent;
                        }
                    }
                }

                .details {
                    font-size: 12px;
                    color: $global-color-2;
                    margin-top: 10px;

                    > div:not(:first-child) {
                        margin-top: 5px;
                    }

                    >div:first-child{
                        display: flex ;
                    }

                    svg {
                        margin-right: 5px;
                        min-width: 12px;
                        height: 12px;

                        #fi-rr-building {
                            fill: $global-color-2;
                        }
                    }

                    .ant-avatar {
                        margin-right: 5px;
                    }

                    .agency_info{
                        display: flex ;
                        align-items: center;
                        padding-top: 12px;
                        margin-top: 12px;
                        border-top: 1px solid #F4F6F8;

                        a {
                            color: #4D677E;
                            text-decoration: none;
                        }
                    }
                }
            }
            .tour-price-section {
                background-color: $global-color-8;
                border-radius: 0px 0px 5px 5px;
                padding: 20px;

                .price-content {
                    div:first-child {
                        color: $global-color-9;
                        font-family: 'Poppins-SemiBold';
                    }

                    div:nth-child(2) {
                        margin-top: 5px;
                        &.base-color {
                            color: $global-color-12;
                        }

                        &.second-color {
                            color: $global-color-3;
                        }

                        span:first-child {
                            font-family: 'Poppins-SemiBold';
                            margin-right: 5px;
                        }

                        span:nth-child(2) {
                            font-family: 'Poppins-LightItalic';
                        }
                    }
                }

                .ant-btn {
                    font-size: 12px;
                    font-family: 'Poppins-Medium';
                    height: 40px;
                    display: flex;
                    align-items: center;
                    &::after {
                        content: none;
                    }

                    &.base-button {
                        background-color: $global-color-3;
                        color: $color-white;
                        svg {
                            margin-left: 15px;
                        }
                    }

                    &.collected-button {
                        background-color: #CDFCF0;
                        color: $global-color-12;

                        .btn-text {
                            display: flex;
                            justify-content: space-between;
                            align-items: center;

                            .svg-wrapper {
                                width: 26px;
                                height: 26px;
                                border-radius: 100%;
                                background-color: $global-color-12;
                                margin-right: 10px;
                                display: flex;
                                justify-content: center;
                                align-items: center;
                            }
                            svg {
                                #icon-tick {
                                    path {
                                        stroke: $color-white;
                                    }
                                }
                            }
                        }
                    }

                    &.pending-button {
                        background-color: rgba($global-color-3, 0.18);
                        color: $global-color-3;

                        .btn-text {
                            display: flex;
                            justify-content: space-between;
                            align-items: center;

                            .svg-wrapper {
                                width: 26px;
                                height: 26px;
                                border-radius: 100%;
                                background-color: $global-color-3;
                                margin-right: 10px;
                                display: flex;
                                justify-content: center;
                                align-items: center;
                            }
                            svg {
                                width: 18px;
                                height: 18px;
                                fill: $color-white;
                            }
                        }
                    }
                }
                .ant-btn.accept-button {
                    background-color: #CDFCF0;
                    color: $global-color-12;
                    font-size: 14px;
                    font-family: 'Poppins-Medium';

                    &::after {
                        content: none;
                    }
                }

                .ant-btn.reject-button {
                    background-color: rgba($global-color-13, 0.2);
                    color: $global-color-13;
                    font-size: 14px;
                    font-family: 'Poppins-Medium';

                    &::after {
                        content: none;
                    }
                }

                .request-info {
                    margin-top: 10px;
                    font-size: 11px;
                }
            }
        }
    }
}

@media (max-width:991px){
    .worldwide-supplier-page, .agency-profile-page {
        padding: 20px;
    }
}

.view-vendor, .agency-profile-page {
    .agency-details {
        background-color: $color-white;
        border-radius: 10px;

        .general-data {
            padding: 25px 0px 25px 25px;

            .ant-avatar {
                border: 4px solid rgba($global-color-2, 0.5);
                display: flex;
                justify-content: center;
                align-items: center;
                svg {
                    width: 88px;
                    height: 88px;
                }
            }

            .contact-details {
                .ant-typography.agency-name {
                    font-size: 18px;
                    font-family: 'Poppins-Medium';
                    color: $global-color-7;
                }

                .description:first-child {
                    margin-top: 15px;
                }

                .description:not(:first-child) {
                    margin-top: 5px;
                }

                .description {
                    .ant-typography {
                        font-size: 12px;
                    }

                    .content {
                        color: $global-color-4;
                    }
                }
            }
        }

        .commission-data {
            padding: 25px;
            background-color: rgba($global-color-12, 0.1);

            .ant-space {
                display: flex;
                justify-content: space-between;

                .ant-typography {
                    font-size: 14px;
                    font-family: 'Poppins-Regular';
                    color: $global-color-12;
                }

                .commission-value {
                    font-size: 18px;
                    font-family: 'Poppins-SemiBold';
                    color: $global-color-12;
                }
            }

            svg {
                margin-right: 5px;
                margin-bottom: -2px;
                width: 11px;
                height: 11px;
                path, circle {
                    fill: $global-color-12;
                }
            }

            .ant-typography {
                font-size: 10px;
                font-family: 'Poppins-LightItalic';
                color: $global-color-12;
            }

            .ant-divider.ant-divider-horizontal {
                border-width: 1px;
                border-color: rgba($global-color-12, 0.56) !important;
                margin: 14px 0px;
            }
        }

        .more-details {
            padding: 25px;

            .ant-divider.ant-divider-horizontal {
                border-top: 1px solid $global-color-10;
            }

            .info-section {
                .name {
                    .ant-typography {
                        font-size: 12px;
                    }
                }

                .content {
                    margin-top: 5px;
                    .ant-typography {
                        color: $global-color-4;
                    }

                    > div {
                        margin-top: 5px;
                        span:first-child {
                            color: $global-color-7;
                            margin-right: 5px;
                        }
                        span:nth-child(2) {
                            color: $global-color-4;
                        }
                    }
                }
            }
        }
    }
}
