.loading-list-item {
    &.ant-skeleton  {
        width: 100%;

        .ant-skeleton-button {
            width: 100%;
            height: 120px;
        }
    }
}
