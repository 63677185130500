@import './InvoiceTable/style';
@import './GraphCard/style';
.work-info{
> div{
    > .ant-col{
        &:nth-child(2){
            .filter-content{
                align-items: flex-end;
                background-color: aqua;
            }
        }
    }
}
}
