$base-layout-sidebar-width: 650px;
$sidebar-background-color: #f7f8fa;

.base-layout {
    min-height: 100vh;
    height: 100%;
    .base-layout-container {
        @include d-flex;
        width: 100%;
    }
    .main-header {
        // width: calc(100% - 15px );
        // width: 100%;
        // max-height: 105px;
        // position: fixed;
        // z-index: 999;
        // height: 105px;
        //     display: flex;
        // justify-content: space-between;
        // align-items: center;
        position: absolute;
        width: 100%;
        top: 0;
        left: 0;
        right: 0;
        padding-bottom: 0;
        z-index: 100;
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 105px;
    }
    .sidebar {
        // position: relative;
        // width: 100%;
        // padding: 0px;
        // background: $sidebar-background-color;
        width: 32.6%;
        background-color: #f4f6f8;
        display: flex;
        height: 100%;
        justify-content: center;
        align-items: center;
        .sidebar-content {
            margin-left: auto;
            margin-right: auto;
        }
    }

    .main-content {
        //    @include d-flex;
        //    justify-content: center;
        //    align-items: center;
        position: relative;
        width: 67.4%;
        background-color: #fff;
        display: flex;
        flex-direction: column;
        align-items: center;
        height: calc(100vh - 105px);
        margin-top: 105px;
        overflow-y: auto;
        overflow-x: hidden;
        background-color: #ffffff;

        form {
            .title {
                font-size: 28px;
                color: #758ca1;
                margin-bottom: 5px;
                line-height: 100%;
            }

            .description {
                color: #bbc6d1;
                margin-bottom: 37px;
                font-size: 12px;
                line-height: 18px;
            }
        }
    }

    @media only screen and (max-width: 1200px) {
    }

    @media only screen and (max-width: 991px) {
        .main-header {
            -ms-filter: "progid:DXImageTransform.Microsoft.Shadow(Strength=10, Direction=0, Color=#203140)"; /*IE 8*/
            -moz-box-shadow: 0 5px 10px rgba(32, 49, 64, 0.1); /*FF 3.5+*/
            -webkit-box-shadow: 0 5px 10px rgba(32, 49, 64, 0.1); /*Saf3-4, Chrome, iOS 4.0.2-4.2, Android 2.3+*/
            box-shadow: 0 5px 10px rgba(32, 49, 64, 0.1); /* FF3.5+, Opera 9+, Saf1+, Chrome, IE10 */
            filter: progid:DXImageTransform.Microsoft.Shadow(Strength=10, Direction=135, Color=#203140); /*IE 5.5-7*/
        }
        .sidebar {
            position: relative;
            padding: 30px 30px;
            background: transparent;
            max-width: 100%;
            width: 100%;
        }
    }
}
