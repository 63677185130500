.menu-title {
    background-color: $color-white;
    padding-top: 26px;
    border-radius: 5px 5px 0 0 ;
}
.menu-title > span {
    padding-left: 31px;
    font-size: 16px;
    color: $global-color-7;
    font-family: "Poppins-Medium";
    line-height: 25px;
    letter-spacing: 0.16px;
}

.ant-menu-vertical {
    border-right: none;
    border-radius: 0 0 5px 5px;
}
