.verify-otp-form {
    display: flex;
    flex-direction: column;
    align-items: center;

    .verification-image {
        width: 100px;
        height: 100px;
        @include d-flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        background-color: #f7f8fa;
        margin-bottom: 15px;
        svg > g > path {
            fill: #d0d9e6;
        }
    }
    h1 {
        font-family: "Poppins-Light";
        line-height: 35px !important;
        color: #1a223f !important;
    }
    .description {
        color: #495274 !important;
        margin-top: 10px;
        margin-bottom: 50px;
        font-size: 14px !important;
        margin-bottom: 56px !important;
        text-align: center;
        line-height: 21px;
    }
    .opt-error {
        color: #ff4d4f !important;
    }
    .resend-code-text {
        margin-bottom: 11px;
        line-height: 21px;
        color: #bcc7d9;
    }
    .styles_react-code-input__CRulA {
        text-align: center;
    }

    .resend-code-button,
    .edit-button {
        padding: 0px;
        line-height: 100%;
        height: 100%;
        color: #005ae7;
        width: auto !important;
        margin-bottom: 44px;
        font-family: "Poppins-Medium";

        span {
            text-decoration: underline;
            letter-spacing: -0.14px;
            line-height: 21px;
        }
    }

    .editing-component-wrapper {
        width: 100%;
        margin-bottom: 28px;

        .ant-space-item {
            &:not(:last-of-type) {
                width: 100%;
            }

            &:last-of-type {
                button {
                    width: 167px;
                }
            }
        }
    }

    .otp-code-input {
        width: auto !important;
        margin-bottom: 32px;

        input[value]:not([value=""]) {
            border-bottom-color: #2fe479 !important;
        }
        &.numbers-filled-error {
            input[value] {
                border-bottom-color: #ff4d4f !important;
            }
        }
        input {
            height: 34px !important;
            width: 60px !important;
            border-radius: 0 !important;
            box-shadow: none !important;
            caret-color: auto;
            color: #495274;
            font-size: 20px;
            font-family: "Poppins-Light";
            border: none !important;
            border-bottom: 2px solid #bcc7d9 !important;
            line-height: 30px;

            &:focus {
                border-bottom-color: $color-input-text !important;
            }

            &:not(:last-of-type) {
                margin-right: 20px;
            }
        }
    }

    .ant-btn-primary[disabled],
    .ant-btn-primary[disabled]:active,
    .ant-btn-primary[disabled]:focus,
    .ant-btn-primary[disabled]:hover {
        border-color: transparent;
        background: #ededf5;
        text-shadow: none;
        box-shadow: none;
        font-family: Poppins;
        font-size: 14px;
        font-weight: 500;
        color: #fff;
    }
}
