.b2b-requests-page {

    @import'./Filters/style';

    .request-container {
        margin-top: 30px;

        .request-list, .request-details {
            background-color: $color-white;
            height: 100%;
        }

        .request-list {
            border-radius: 10px 0px 0px 10px;
            padding: 0.5px;

            .ant-row {
                .ant-col {
                    &.no-padding {
                        padding-right: 0px !important;
                    }
                }
                .ant-col:last-child {
                    .ant-divider.ant-divider-horizontal {
                        display: none;
                    }
                }

                .ant-col:first-child, .ant-col:last-child {
                    .ant-card {
                        border-radius: 5px;
                        &.border-left {
                            border-radius: 0px;
                            border-left: 3px solid $global-color-7;
                        }
                    }
                }
            }

            .ant-card {
                border-radius: 0px;
                &.border-left {
                    border-left: 3px solid $global-color-7;
                }
                .ant-card-body {
                    padding: 20px;

                    .ant-card-meta {
                        .ant-card-meta-avatar {
                            padding-top: 8px;
                        }
                        .ant-card-meta-detail {
                            .ant-card-meta-description {
                                padding: 10px;
                                .title {
                                    color: $global-color-9;
                                    font-family: 'Poppins-Medium';
                                    margin-bottom: 10px;
                                }
    
                                .location, .duration {
                                    font-size: 12px;
                                    color: $global-color-2;
    
                                    svg {
                                        margin-right: 5px;
                                        width: 12px;
                                        height: 12px;
                                        #fi-rr-calendar {
                                            path, circle {
                                                fill: $global-color-2;
                                            }
                                        }
    
                                        #fi-rr-building {
                                            fill: $global-color-2;
                                        }
                                    }
                                }
    
                                .ant-btn {
                                    background-color: $global-color-8;
                                    border-radius: 20px;
                                    color: $global-color-7;
                                    height: 35px;
                                    display: flex;
                                    align-items: center;

                                    &.active {
                                        background-color: $global-color-7;
                                        color: $color-white;
                                    }

                                    &::after {
                                        content: none;
                                    }
    
                                    span:first-child {
                                        font-family: 'Poppins-SemiBold';
                                        margin-right: 3px;
                                    }
    
                                    span:nth-child(2) {
                                        font-family: 'Poppins-LightItalic';
                                    }
    
                                    .pending-requests {
                                        height: 22px;
                                        width: 22px;
                                        border-radius: 100%;
                                        background-color: $global-color-13;
                                        display: flex;
                                        align-items: center;
                                        justify-content: center;
                                        margin-right: -20px;
                                        margin-top: -26px;
                                        z-index: 1;
                                        font-size: 12px;
                                        font-family: 'Poppins-Medium';
                                        color: $color-white;

                                        &.hide {
                                            display: none;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }

            .ant-divider.ant-divider-horizontal {
                margin: 0px;
                border-top: 1px solid #D8E5EF;
            }
        }

        .request-details {
            padding: 40px 25px 25px 30px;
            border-radius: 0px 10px 10px 0px;

            .empty-request-container {
                max-height:calc(100vh - 222px);
                height:calc(100vh - 222px);
                @include d-flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;

                .info-text {
                    margin-top: 50px;
                    line-height: 21px;
                    color: $global-color-2;
                    text-align: center;
                }
            }

            @import'./RequestDetails/style';
        }
    }
}

@media (max-width:991px){
    .b2b-requests-page {
        padding: 20px;
    }
}

