.activities-view-all{
    .tour-type-list-card.grid{
        .ant-card-meta-title{
            padding-top: 23px;
            >a{
                >div{
                    margin-bottom: 15.1px !important;
                }
            }
        }
        .location{
            margin-bottom: 10px;
        }
    }
}
