.auth-layout {
    .sidebar {
        border-radius: 50px;
        position: fixed;
        top: $auth-layout-offset;
        bottom: $auth-layout-offset;
        left: $auth-layout-offset;
        padding: $sidebar-padding-x;
        transition: width 0.25s ease !important;
        flex: none !important;
        max-width: none !important;
        min-width: 0 !important;
        z-index: 30;
        box-shadow: 0px 3px 10px 0px rgba(0, 0, 0, 0.16);

        &.ant-layout-sider-collapsed {
            padding: $sidebar-padding-x-collapsed;
        }

        .ant-menu {
            .ant-menu-item {
                min-height: $sidebar-element-height;
                max-height: $sidebar-element-height;
                padding: $sidebar-element-padding-y $sidebar-element-padding-x;
                margin: 0px;
                padding: 0 !important;
                border-radius: 50px;
                @include d-flex;
                align-items: center;
                position: relative;

                min-width: 100% !important;
                max-width: 100% !important;

                &::after {
                    display: none !important;
                }

                &:not(:last-child) {
                    margin-bottom: 5px !important;
                }

                svg {
                    height: 18px !important;
                    min-width: $sidebar-element-width !important;
                    max-width: $sidebar-element-width !important;
                }

                &.ant-menu-item-selected {
                    background: $color-white;
                    box-shadow: 0px 0px 16px rgba($color: #13258d, $alpha: 0.49) !important;

                    svg {
                        path {
                            fill: $global-color-1;
                        }
                    }

                    > .ant-menu-title-content a {
                        color: #3b55eb;
                    }
                }

                > .ant-menu-title-content {
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    margin: 0 !important;
                    padding: 0 !important;
                    transition: none !important;
                    @include d-flex;
                    align-items: center;
                    opacity: 1 !important;

                    a {
                        font-size: 14px;
                        font-family: "Poppins-Regular";
                        color: #fff;
                        text-decoration: none !important;
                        transition: none !important;
                        padding-left: 54px !important;
                        width: 100%;

                        &::before {
                            display: none !important;
                        }
                    }
                }
            }
        }
    }
}
